import React, { useEffect } from "react";
import { useFormik, Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import {
  updateAdminAsync,
  createAdminAsync,
} from "../../redux/adminManagementApi";
import { Link, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Col, Row, Modal } from "react-bootstrap";



const BasicDetailsForm = ({ selectedUser, isLoading, reDirect, showAdminModal, setShowAdminModal }) => {
  const dispatch = useDispatch();
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,32}$/;
  //formik validation schema
  const BasicDetailsFormSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Enter valid email")
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters")
        .required("Email is required"),
      firstName: Yup.string()
        .trim()
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters")
        .required("First name is required"),
      lastName: Yup.string()
        .trim()
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters")
        .required("Last name is required"),
      password:
        selectedUser && Object.keys(selectedUser).length === 0 ? Yup.string()
          .trim()
          .matches(
            passwordRegex,
            "Password should contain min 6 and max 32 characters with atleast one Alphanumeric and special character."
          )
          .required("Please enter password") : Yup.string(),
      confirmPassword:
        selectedUser && Object.keys(selectedUser).length === 0 ? Yup.string()
          .trim()
          .oneOf(
            [Yup.ref("password"), null],
            "Password and confirm password didn't match"
          )
          .required("Please enter confirm password") : Yup.string(),
    });
  //close add admin model after add/edit admin api call 
  useEffect(() => {
    if (reDirect === true) {
      setShowAdminModal(false)
    }
  }, [reDirect])

  return (
    <>
      <Modal
        show={showAdminModal}
        onHide={() => setShowAdminModal(false)}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">{selectedUser && selectedUser._id ? "Edit Admin" : "Add Admin"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              firstName: selectedUser && selectedUser.firstName ? selectedUser.firstName : "",
              lastName: selectedUser && selectedUser.lastName ? selectedUser.lastName : "",
              email: selectedUser && selectedUser.email ? selectedUser.email : "",
              walletAddress: selectedUser && selectedUser.walletAddress ? selectedUser.walletAddress : "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={BasicDetailsFormSchema}
            onSubmit={(values, { setErrors, resetForm }) => {

              if (!selectedUser._id) {
                return dispatch(createAdminAsync(values));
              }
              if (selectedUser._id) {
                let updatedValues = {}
                updatedValues.firstName = values.firstName
                updatedValues.lastName = values.lastName
                updatedValues.walletAddress = values.walletAddress

                if (selectedUser.email !== values.email) {
                  updatedValues.email = values.email
                }

                updatedValues.displayName = `${values.firstName} ${values.lastName}`
                if (values.password) {
                  updatedValues.password = values.password
                }

                dispatch(updateAdminAsync(updatedValues, selectedUser._id));
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              getFieldProps
            }) => (
              <Row className="justify-content-center">
                <Col lg={12}>
                  <Form autoComplete="off"
                    className="form def_form frmwtpddng"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">First Name</label>
                          <input
                            placeholder="First Name"
                            type="text"
                            className={`form-control border-less-input`}
                            name="firstName"
                            {...getFieldProps("firstName")}
                          />
                          {touched.firstName && errors.firstName ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.firstName}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Last Name</label>
                          <input
                            placeholder="Last Name"
                            type="text"
                            className={`form-control border-less-input`}
                            name="lastName"
                            {...getFieldProps("lastName")}
                          />
                          {touched.lastName && errors.lastName ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.lastName}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Wallet Address</label>
                          <input
                            placeholder="Wallet Address"
                            type="text"
                            className={`form-control border-less-input`}
                            name="walletAddress"
                            {...getFieldProps("walletAddress")}
                          //disabled={selectedUser.walletAddress ? true : false}
                          />
                          {touched.walletAddress && errors.walletAddress ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.walletAddress}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Email</label>
                          <input
                            placeholder="Email"
                            type="email"
                            className={`form-control border-less-input`}
                            name="email"
                            autoComplete="new-email"
                            {...getFieldProps("email")
                            }
                          //disabled={selectedUser.email ? true : false}
                          />
                          {touched.email && errors.email ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.email}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Password</label>
                          <input
                            placeholder="Password"
                            type="password"
                            className={`form-control  border-less-input`}
                            name="password"
                            autoComplete="new-password"
                            {...getFieldProps("password")}
                          //disabled={selectedUser.password ? true : false}
                          />
                          {touched.password && errors.password ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.password}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Confirm Password</label>
                          <input
                            placeholder="Confirm Password"
                            type="password"
                            className={`form-control border-less-input`}
                            name="confirmPassword"
                            {...getFieldProps("confirmPassword")}
                          //disabled={selectedUser.password ? true : false}
                          />
                          {touched.confirmPassword && errors.confirmPassword ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.confirmPassword}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group  mb-0 mt-4">
                      <div className="form-group mr-5 mb-1 mt-2 bg-dark">
                        <button
                          type="submit"
                          className="btn btn-blue spinnerBtn add-avtar-btn"
                          disabled={isLoading}
                        >
                          <span>{selectedUser && selectedUser._id ? "Update" : "Add"}</span>
                          {isLoading && (
                            <div className="ml-3 basic-verification-loader text-center">
                              <CircularProgress />
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BasicDetailsForm;
