import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import BasicPagination from "../../../pagination/BasicPagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { ServicesManagementActions } from '../../redux/servicesAction';
import { deleteLiveFeedAsync } from '../../redux/servicesApi';
import { toTimestamp } from '../../../../utils/appUtils';

const LiveFeedTable = ({ onFeedDetailClick }) => {

  const dispatch = useDispatch();

  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);

  const [selectedID, setSelectedID] = useState();

  const { isSubCategoryLoading, isMatchesLoading, isFeedLoading, totalFeedRecords, feedList, isLoading, feedlimit, feedsearch, feedsortType, feedskip, feedsortBy, refreshFeedList, reDirect } = useSelector(
    (state) =>
      state.servicesManagement,
    shallowEqual
  )
  //call delete feed api
  const onDeactiveClick = () => {
    dispatch(deleteLiveFeedAsync(selectedID._id))
    setSelectedID({});
    deleteClose();
  };
  //for page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : feedskip;
    dispatch(ServicesManagementActions.setFeedBatchNumber(count));
  };
  //for sort column
  const handleSort = (feedsortBy, feedsortType) => {
    dispatch(ServicesManagementActions.setFeedSort({ feedsortBy, feedsortType }));
  }
  //show delete model
  const onDeleteClick = (feed) => {
    setSelectedID(feed);
    deleteShow();
  }

  return (
    <>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">

        <div className="tblmrgn mt-0">
          <div className="mt-5">
            <div>
              <Table
                hover
                responsive
                id="myTable"
                className="mb-0 default_table with_hoverbtn mrpdng_tbl"
              >
                <thead>
                  <tr>
                    <th>Sr.no</th>
                    <th
                      onClick={() => handleSort("league", feedsortType === "desc" || feedsortType === "" ? "asc" : "desc")}
                    >
                      League{" "}
                      {feedsortType !== "" && feedsortBy === "league" && (
                        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                          {feedsortType === "desc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th>
                    <th
                      onClick={() => handleSort("matches", feedsortType === "desc" || feedsortType === "" ? "asc" : "desc")}
                    >
                      Matches{" "}
                      {feedsortType !== "" && feedsortBy === "matches" && (
                        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                          {feedsortType === "desc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th>
                    <th
                      onClick={() => handleSort("category", feedsortType === "desc" || feedsortType === "" ? "asc" : "desc")}
                    >
                      Category{" "}
                      {feedsortType !== "" && feedsortBy === "category" && (
                        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                          {feedsortType === "desc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th>
                    <th
                      onClick={() => handleSort("subcategory", feedsortType === "desc" || feedsortType === "" ? "asc" : "desc")}
                    >
                      SubCategory{" "}
                      {feedsortType !== "" && feedsortBy === "subcategory" && (
                        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                          {feedsortType === "desc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th>
                    <th>
                      Channel
                    </th>
                    <th>Feed Name</th>
                    <th>URL</th>
                    <th>Feed Creator</th>
                    <th className="wd-50">Image</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    {feedList && feedList.feed && feedList.feed.length > 0 && <th className="wd-120">Actions</th>}
                  </tr>

                </thead>
                <tbody>
                  {feedList && feedList.length > 0 ? (
                    feedList.map((feed, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className="f-w-500">
                              {feedskip * feedlimit + (index + 1)}
                            </span>
                          </td>
                          <td>
                            <span className="f-w-500">{feed?.league?.name}</span>
                          </td>
                          <td>
                            <span className="f-w-500">{feed?.matchTeam}</span>
                          </td>
                          <td>
                            <span className="f-w-500">{feed?.category?.name}</span>
                          </td>
                          <td>
                            <span className="f-w-500">{feed?.subCategory?.name}</span>
                          </td>
                          <td>
                            <span className="f-w-500">{feed?.channel}</span>
                          </td>
                          <td>
                            <span className="f-w-500">{feed?.feed_name}</span>
                          </td>
                          <td>
                            <span className="f-w-500"><a  className="text-light" href={feed?.feedUrl} target="_blank">{feed?.feedUrl}</a></span>
                          </td>
                          <td>
                            <span className="f-w-500">{feed?.feed_creator ? feed.feed_creator === 'Admin' ? 'Admin' : feed?.users?.userName : 'Goal Serve'}</span>
                          </td>
                          <td style={{ height: '20%', width: '20%' }}>
                            {feed.image ? <img className="f-w-200" style={{ height: '100px', width: '150px' }} alt="feed_image" src={feed.image} /> : 'NA'}
                          </td>
                          <td>
                            <span className="f-w-500">{toTimestamp(feed.start_date_time)}</span>
                          </td>
                          <td>
                            <span className="f-w-500">{toTimestamp(feed.end_date_time)}</span>
                          </td>
                          <td className='d-inline-flex'>
                            <a
                              title="Edit"
                              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                              onClick={() => onFeedDetailClick(feed)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Write.svg"
                                  )}
                                />
                              </span>
                            </a>

                            <a
                              title="Delete"
                              className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              onClick={() => onDeleteClick(feed)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                  {isFeedLoading && (
                    <tr>
                      <td colSpan={8} className="text-center p-0 border-0">
                        <div className="basic-verification-loader text-center">
                          <CircularProgress />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
          <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
            Displaying {feedskip * feedlimit + 1} - {feedskip * feedlimit + feedlimit} of{" "}
            {totalFeedRecords} Records
          </div>
          <div className="col-md-8 text-left mb-5 aspgntn">
            <BasicPagination
              totalRecords={totalFeedRecords}
              limit={feedlimit}
              batch={feedskip + 1}
              onBatchChange={onPageChange}
            />
          </div>

        </div>
        <Modal
          show={DeleteModal}
          onHide={deleteClose}
          centered
          className="def_modal delete_modal"
        >
          <Modal.Header>
            <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form def_form frmwtpddng">
              <p className="fn_s_18 f-w-400 text-center mb-10">
                Are you sure want to delete?
              </p>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={deleteClose}
                  >
                    Close
                  </button>
                </div>
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={onDeactiveClick}
                  >
                    Yes, Delete it
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>

  )
}

export default LiveFeedTable