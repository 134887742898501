export const AuthMap = {
    ADD_CATEGORY_START: 'ADD_CATEGORY_START',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_ERROR: 'ADD_CATEGORY_ERROR',

    GET_CATEGORY_START: 'GET_CATEGORY_START',
    GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
    GET_CATEGORY_ERROR: 'GET_CATEGORY_ERROR',
    RESET_CATEGORY: 'RESET_CATEGORY',

    ADD_SUBCATEGORY_START: 'ADD_SUBCATEGORY_START',
    ADD_SUBCATEGORY_SUCCESS: 'ADD_SUBCATEGORY_SUCCESS',
    ADD_SUBCATEGORY_ERROR: 'ADD_SUBCATEGORY_ERROR',

    GET_SUBCATEGORY_START: 'GET_SUBCATEGORY_START',
    GET_SUBCATEGORY_SUCCESS: 'GET_SUBCATEGORY_SUCCESS',
    GET_SUBCATEGORY_ERROR: 'GET_SUBCATEGORY_ERROR',

    EDIT_SUBCATEGORY_START: 'EDIT_SUBCATEGORY_START',
    EDIT_SUBCATEGORY_SUCCESS: 'EDIT_SUBCATEGORY_SUCCESS',
    EDIT_SUBCATEGORY_ERROR: 'EDIT_SUBCATEGORY_ERROR',

    DELETE_SUBCATEGORY_START: 'DELETE_SUBCATEGORY_START',
    DELETE_SUBCATEGORY_SUCCESS: 'DELETE_SUBCATEGORY_SUCCESS',
    DELETE_SUBCATEGORY_ERROR: 'DELETE_SUBCATEGORY_ERROR',

    SET_CATEGORY_BATCH_NUMBER: "SET_CATEGORY_BATCH_NUMBER",

    SET_SORT_CHANGE: "SET_SORT_CHANGE",

    SET_SEARCH_CHANGE: "SET_SEARCH_CHANGE",

    REFRESH_CATEGORY_LIST: "REFRESH_CATEGORY_LIST",

    SET_SUBCATEGORY_BATCH_NUMBER: "SET_SUBCATEGORY_BATCH_NUMBER",

    SET_SUBSORT_CHANGE: "SET_SUBSORT_CHANGE",

    SET_SUBSEARCH_CHANGE: "SET_SUBSEARCH_CHANGE",

    REFRESH_SUBCATEGORY_LIST: "REFRESH_SUBCATEGORY_LIST",

    REFRESH_FEED_LIST: "REFRESH_FEED_LIST",

    SET_FEED_BATCH_NUMBER: "SET_FEED_BATCH_NUMBER",

    SET_FEEDSORT_CHANGE: "SET_FEEDSORT_CHANGE",

    SET_FEEDSEARCH_CHANGE: "SET_FEEDSEARCH_CHANGE",

    EDIT_CATEGORY_START: 'EDIT_CATEGORY_START',
    EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
    EDIT_CATEGORY_ERROR: 'EDIT_CATEGORY_ERROR',

    DELETE_CATEGORY_START: 'DELETE_CATEGORY_START',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',

    SELECTED_CATEGORY_DETAILS: 'SELECTED_CATEGORY_DETAILS',

    SELECTED_SUBCATEGORY_DETAILS: 'SELECTED_SUBCATEGORY_DETAILS',

    SELECTED_LIVEFEED_DETAILS: 'SELECTED_LIVEFEED_DETAILS',

    REFRESH_DETAILS: "REFRESH_DETAILS",

    ADD_LIVEFEED_START: 'ADD_LIVEFEED_START',
    ADD_LIVEFEED_SUCCESS: 'ADD_LIVEFEED_SUCCESS',
    ADD_LIVEFEED_ERROR: 'ADD_LIVEFEED_ERROR',

    GET_LIVEFEED_START: 'GET_LIVEFEED_START',
    GET_LIVEFEED_SUCCESS: 'GET_LIVEFEED_SUCCESS',
    GET_LIVEFEED_ERROR: 'GET_LIVEFEED_ERROR',

    EDIT_LIVEFEED_START: 'EDIT_LIVEFEED_START',
    EDIT_LIVEFEED_SUCCESS: 'EDIT_LIVEFEED_SUCCESS',
    EDIT_LIVEFEED_ERROR: 'EDIT_LIVEFEED_ERROR',

    DELETE_LIVEFEED_START: 'DELETE_LIVEFEED_START',
    DELETE_LIVEFEED_SUCCESS: 'DELETE_LIVEFEED_SUCCESS',
    DELETE_LIVEFEED_ERROR: 'DELETE_LIVEFEED_ERROR',

    GET_LEAGUE_START: 'GET_LEAGUE_START',
    GET_LEAGUE_SUCCESS: 'GET_LEAGUE_SUCCESS',
    GET_LEAGUE_ERROR: 'GET_LEAGUE_ERROR',

    GET_ALLMATCHES_START: 'GET_ALLMATCHES_START',
    GET_ALLMATCHES_SUCCESS: 'GET_ALLMATCHES_SUCCESS',
    GET_ALLMATCHES_ERROR: 'GET_ALLMATCHES_ERROR',

    GET_ALLSUBCATEGORY_START: 'GET_ALLSUBCATEGORY_START',
    GET_ALLSUBCATEGORY_SUCCESS: 'GET_ALLSUBCATEGORY_SUCCESS',
    GET_ALLSUBCATEGORY_ERROR: 'GET_ALLSUBCATEGORY_ERROR',

    SET_MATCH_LIST: 'SET_MATCH_LIST',
    SET_SUB_CATEGORY_LIST: 'SET_SUB_CATEGORY_LIST',
    SET_LEAGUE_LIST: 'SET_LEAGUE_LIST',

    EDIT_CATEGORY_ACTION_START: 'EDIT_CATEGORY_ACTION_START',
    EDIT_CATEGORY_ACTION_SUCCESS: 'EDIT_CATEGORY_ACTION_SUCCESS',
    EDIT_CATEGORY_ACTION_ERROR: 'EDIT_CATEGORY_ERROR',

}

export const ServicesManagementActions = {
    addCategoryStart: () => ({ type: AuthMap.ADD_CATEGORY_START }),
    addCategorySuccess: (data) => ({ type: AuthMap.ADD_CATEGORY_SUCCESS, payload: data }),
    addCategoryError: (errors) => ({ type: AuthMap.ADD_CATEGORY_ERROR, payload: errors }),

    getCategoryStart: () => ({type: AuthMap.GET_CATEGORY_START}),
    getCategorySuccess: (data) => ({ type: AuthMap.GET_CATEGORY_SUCCESS, payload: data }),
    getCategoryError: (errors) => ({ type: AuthMap.GET_CATEGORY_ERROR, payload: errors }),

    addSubCategoryStart: () => ({ type: AuthMap.ADD_SUBCATEGORY_START }),
    addSubCategorySuccess: (data) => ({ type: AuthMap.ADD_SUBCATEGORY_SUCCESS, payload: data }),
    addSubCategoryError: (errors) => ({ type: AuthMap.ADD_SUBCATEGORY_ERROR, payload: errors }),

    getSubCategoryStart: () => ({ type: AuthMap.GET_SUBCATEGORY_START }),
    getSubCategorySuccess: (data) => ({ type: AuthMap.GET_SUBCATEGORY_SUCCESS, payload: data }),
    getSubCategoryError: (errors) => ({ type: AuthMap.GET_SUBCATEGORY_ERROR, payload: errors }),

    editSubCategoryStart: () => ({ type: AuthMap.EDIT_SUBCATEGORY_START }),
    editSubCategorySuccess: (data) => ({ type: AuthMap.EDIT_SUBCATEGORY_SUCCESS, payload: data }),
    editSubCategoryError: (errors) => ({ type: AuthMap.EDIT_SUBCATEGORY_ERROR, payload: errors }),

    deleteSubCategoryStart: () => ({ type: AuthMap.DELETE_SUBCATEGORY_START }),
    deleteSubCategorySuccess: (data) => ({ type: AuthMap.DELETE_SUBCATEGORY_SUCCESS, payload: data }),
    deleteSubCategoryError: (errors) => ({ type: AuthMap.DELETE_SUBCATEGORY_ERROR, payload: errors }),

    setCategoryBatchNumber: (data) => ({
        type: AuthMap.SET_CATEGORY_BATCH_NUMBER,
        payload: data,
    }),

    setSort: (data) => ({
        type: AuthMap.SET_SORT_CHANGE,
        payload: data,
    }),

    searchTextChange: (data) => ({
        type: AuthMap.SET_SEARCH_CHANGE,
        payload: data,
    }),

    refreshCategoryList: () => ({ type: AuthMap.REFRESH_CATEGORY_LIST }),

    setSubCategoryBatchNumber: (data) => ({
        type: AuthMap.SET_SUBCATEGORY_BATCH_NUMBER,
        payload: data,
    }),

    setSubSort: (data) => ({
        type: AuthMap.SET_SUBSORT_CHANGE,
        payload: data,
    }),

    searchSubTextChange: (data) => ({
        type: AuthMap.SET_SUBSEARCH_CHANGE,
        payload: data,
    }),

    refreshSubCategoryList: () => ({ type: AuthMap.REFRESH_SUBCATEGORY_LIST }),

    refreshFeedList: () => ({ type: AuthMap.REFRESH_FEED_LIST }),

    editCategoryStart: () => ({ type: AuthMap.EDIT_CATEGORY_START }),
    editCategorySuccess: (data) => ({ type: AuthMap.EDIT_CATEGORY_SUCCESS, payload: data }),
    editCategoryError: (errors) => ({ type: AuthMap.EDIT_CATEGORY_ERROR, payload: errors }),

    deleteCategoryStart: () => ({ type: AuthMap.DELETE_CATEGORY_START }),
    deleteCategorySuccess: (data) => ({ type: AuthMap.DELETE_CATEGORY_SUCCESS, payload: data }),
    deleteCategoryError: (errors) => ({ type: AuthMap.DELETE_CATEGORY_ERROR, payload: errors }),

    setSelectedCategory: (data) => ({ type: AuthMap.SELECTED_CATEGORY_DETAILS, payload: data }),

    setSubSelectedCategory: (data) => ({ type: AuthMap.SELECTED_SUBCATEGORY_DETAILS, payload: data }),

    setSelectedFeed: (data) => ({ type: AuthMap.SELECTED_LIVEFEED_DETAILS, payload: data }),

    refreshDetails: () => ({type: AuthMap.REFRESH_DETAILS}),

    addLiveFeedStart: () => ({ type: AuthMap.ADD_LIVEFEED_START }),
    addLiveFeedSuccess: (data) => ({ type: AuthMap.ADD_LIVEFEED_SUCCESS, payload: data }),
    addLiveFeedError: (errors) => ({ type: AuthMap.ADD_LIVEFEED_ERROR, payload: errors }),

    getLiveFeedStart: () => ({ type: AuthMap.GET_LIVEFEED_START }),
    getLiveFeedSuccess: (data) => ({ type: AuthMap.GET_LIVEFEED_SUCCESS, payload: data }),
    getLiveFeedError: (errors) => ({ type: AuthMap.GET_LIVEFEED_ERROR, payload: errors }),

    editLiveFeedStart: () => ({ type: AuthMap.EDIT_LIVEFEED_START }),
    editLiveFeedSuccess: (data) => ({ type: AuthMap.EDIT_LIVEFEED_SUCCESS, payload: data }),
    editLiveFeedError: (errors) => ({ type: AuthMap.EDIT_LIVEFEED_ERROR, payload: errors }),

    deleteLiveFeedStart: () => ({ type: AuthMap.DELETE_LIVEFEED_START }),
    deleteLiveFeedSuccess: (data) => ({ type: AuthMap.DELETE_LIVEFEED_SUCCESS, payload: data }),
    deleteLiveFeedError: (errors) => ({ type: AuthMap.DELETE_LIVEFEED_ERROR, payload: errors }),

    setFeedBatchNumber: (data) => ({
        type: AuthMap.SET_FEED_BATCH_NUMBER,
        payload: data,
    }),

    setFeedSort: (data) => ({
        type: AuthMap.SET_FEEDSORT_CHANGE,
        payload: data,
    }),

    searchFeedTextChange: (data) => ({
        type: AuthMap.SET_FEEDSEARCH_CHANGE,
        payload: data,
    }),

    getLeagueStart: () => ({type: AuthMap.GET_LEAGUE_START}),
    getLeagueSuccess: (data) => ({ type: AuthMap.GET_LEAGUE_SUCCESS, payload: data }),
    getLeagueError: (errors) => ({ type: AuthMap.GET_LEAGUE_ERROR, payload: errors }),

    getAllMatchesStart: () => ({type: AuthMap.GET_ALLMATCHES_START}),
    getAllMatchesSuccess: (data) => ({ type: AuthMap.GET_ALLMATCHES_SUCCESS, payload: data }),
    getAllMatchesError: (errors) => ({ type: AuthMap.GET_ALLMATCHES_ERROR, payload: errors }),

    getAllSubcategoryStart: () => ({type: AuthMap.GET_ALLSUBCATEGORY_START}),
    getAllSubcategorySuccess: (data) => ({ type: AuthMap.GET_ALLSUBCATEGORY_SUCCESS, payload: data }),
    getAllSubcategoryError: (errors) => ({ type: AuthMap.GET_ALLSUBCATEGORY_ERROR, payload: errors }),

    setMatchesList: () => ({type: AuthMap.SET_MATCH_LIST}),
    setSubCategoryList: () => ({type: AuthMap.SET_SUB_CATEGORY_LIST}),
    setLeagueList: () => ({type: AuthMap.SET_LEAGUE_LIST}),

    editCategoryActionStart: () => ({ type: AuthMap.EDIT_CATEGORY_ACTION_START }),
    editCategoryActionSuccess: (data) => ({ type: AuthMap.EDIT_CATEGORY_ACTION_SUCCESS, payload: data }),
    editCategoryActionError: (errors) => ({ type: AuthMap.EDIT_CATEGORY_ACTION_ERROR, payload: errors }),
} 
