import React, { useState, useEffect, useContext } from "react";
import { useFormik, Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Tabs, Tab, Col, Row, Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { CircularProgress } from "@material-ui/core";
import { getFeeAsync, updateFeeAsync , updateVideoAsync, updateAffiliateFeeAsync} from '../redux/feeApi';
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { Web3Context } from "../../../../web3/contexts/web3Context";
import { poolMethods } from '../../../../web3/functions/factory'
import Web3 from "web3";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

export function Configuration() {
  const { networkDetails, handleConnect } = useContext(Web3Context);
  const [getInstance, setInstance] = useState();
  const [getRewardInstance, setRewardInstance] = useState();
  const [getDbethInstance, setDbethInstance] = useState();
  
  const [selectedID, setSelectedID] = useState();
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);

  const onDeactiveClick = async () => {
    try {
      deleteClose();
      setisSubmitLoading(true)
      //call contract for set contract
      await poolMethods.setContract(getInstance, networkDetails.address, selectedID)
      //call contract for get contract
      const contractDetails = await poolMethods.getContract(getInstance, networkDetails.address, {})
      setConfigValues({
        ...configValues,
        contractDetails: contractDetails,
      })
      setSelectedID(configValues?.contractDetails)
      setisSubmitLoading(false)
    } catch (err) {
      setisSubmitLoading(false)
    }
  };

  const [isLoading, setLoading] = useState(false);
  const [configValues, setConfigValues] = useState(
    { aaveFee: {} },
    { aaveThresold: {} },
    { adminConfig: {} },
    { blackListedAsset: {} },
    { disputeConfig: {} },
    { feeDeduction: {} },
    { pairAddress: {} },
    { walletAddress: {} },
    { platformFees: {} },
    { allowance: {} },
    { refillTokens: {} },
    { affiliateFees: {} },
    { video: {} },
    { contractDetails: {} },
    { gasDetails: {} }
  );
  const [juryTokensShare, setJuryTokensShare] = useState([]);
  const [participentTokensShare, setParticipentTokensShare] = useState([]);
  const [refreshJuryTokens, setRefreshJuryTokens] = useState(false);
  const [refreshParticipentTokens, setRefreshParticipentTokens] = useState(false);
  const [juryStrikes, setJuryStrike] = useState([]);
  const [isSubmitLoading, setisSubmitLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    //console.log({networkDetails})
    (async () => {
      const instance = await poolMethods.getInstance(networkDetails.web3)
      // console.log({ instance })
      if (instance) {
        setInstance(instance);
      }

      const rewardInstance = await poolMethods.getInstanceRewardDistribution(networkDetails.web3)
      // console.log({ rewardInstance })
      if (rewardInstance) {
        setRewardInstance(rewardInstance);
      }

      const dbethInstance = await poolMethods.getInstanceDbethToken(networkDetails.web3)
      // console.log({ dbethInstance })
      if (dbethInstance) {
        setDbethInstance(dbethInstance);
      }
    })()
  }, [networkDetails.web3])
  //for get data from contract and apis
  useEffect(() => {
    (async () => {

      const aaveConfig = await poolMethods.getAaveConfig(getInstance, networkDetails.address, {})
      const aaveThreshold = await poolMethods.getAaveThresholdConfig(getInstance, networkDetails.address, {})
      const adminConfig = await poolMethods.getAdminConfig(getInstance, networkDetails.address, {})
      const blackListedConfig = await poolMethods.getBlackListedAssetConfig(getInstance, networkDetails.address, {})
      const disputeConfig = await poolMethods.getDisputeConfig(getInstance, networkDetails.address, {})
      const feeDeductionConfig = await poolMethods.getFeeDeductionConfig(getInstance, networkDetails.address, {})
      const pairAddresses = await poolMethods.getPairAddressesConfig(getInstance, networkDetails.address, {})
      const walletAddress = await poolMethods.getWalletAddressConfig(getInstance, networkDetails.address, {})
      const platformFees = await poolMethods.getPlatformFeesConfig(getInstance, networkDetails.address, {})
      const highestBetLavelrange = await poolMethods.getHighestBetLavelrange(getInstance, networkDetails.address, {})
      const allowance = await poolMethods.getAllowance(getDbethInstance, networkDetails.address, {})
      const refillTokens = await poolMethods.getRefillTokens(getDbethInstance, networkDetails.address, {})
      const affiliateLevel = await dispatch(updateAffiliateFeeAsync())
      const videoObject = await dispatch(updateVideoAsync());
      const contractDetails = await poolMethods.getContract(getInstance, networkDetails.address, {})
      //console.log("refillTokens",refillTokens);

      // Get Jury Token Share Data by passing serial data from 0 to 3 to show data in table format in set jury token TAB 
      const juryStrike = [0, 1, 2, 3];
      let juryTokensShare = [];
      for (const element of juryStrike) {
        let juryTokensSharePart = 0;
        juryTokensSharePart = await poolMethods.getJuryTokensShareConfig(getInstance, networkDetails.address, { strike: element });
        juryTokensShare[element] = juryTokensSharePart;
      }
      setJuryTokensShare(juryTokensShare);
      setJuryStrike([0, juryTokensShare[1], juryTokensShare[2], juryTokensShare[3]]);

      // Get Participent Token Share Data by passing serial data from 0 to 3 to show data in table format in set participent token TAB 
      const participentStrike = [0, 1, 2, 3, 4];
      let participentTokensShare = [];
      for (const participentElement of participentStrike) {
        let participentTokensSharePart = 0;
        participentTokensSharePart = await poolMethods.getParticipentTokensShareConfig(getInstance, networkDetails.address, { strike: participentElement });
        participentTokensShare[participentElement] = participentTokensSharePart;
      }
      setParticipentTokensShare(participentTokensShare);


      setConfigValues((preValues) => {
        return {
          ...preValues,
          aaveFee: aaveConfig,
          aaveThresold: aaveThreshold,
          adminConfig: adminConfig,
          blackListedAsset: blackListedConfig,
          disputeConfig: disputeConfig,
          feeDeduction: feeDeductionConfig,
          pairAddress: pairAddresses,
          walletAddress: walletAddress,
          platformFees: platformFees,
          highestBetLavelrange: highestBetLavelrange,
          allowance: allowance,
          refillTokens: refillTokens,
          affiliateFees: affiliateLevel,
          video: videoObject,
          contractDetails: contractDetails,
          gasDetails: affiliateLevel
        }
      })

    })()
  }, [networkDetails.web3, getInstance?.methods, refreshJuryTokens, refreshParticipentTokens, getRewardInstance?.methods, getDbethInstance?.methods])

  // const { isLoading, fee } = useSelector(
  //   (state) => state.feeSettings,
  //   shallowEqual
  // );

  //formik validation schema
  const Formik1Schema = () =>
    Yup.object().shape({
      Bet_Winner_Distribution: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter Bet Participant Distribution."),
      Bet_Looser_Distribution: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter Admin Distribution."),
    });

  const Formik2Schema = () =>
    Yup.object().shape({
      threshold: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .required("Please enter threshold."),
    });

  const Formik3Schema = () =>
    Yup.object().shape({
      admin: Yup.string()
        .test(
          "test-admin-address",
          "Please enter valid address.",
          (value) => Web3.utils.isAddress(value)
        )
        .required("Please enter admin address."),
      // foundation_factory: Yup.string()
      //   .test(
      //     "test-factory-address",
      //     "Please enter valid address.",
      //     (value) => Web3.utils.isAddress(value)
      //   )
      //   .required("Please enter foundation factory address."),
    });

  const Formik4Schema = () =>
    Yup.object().shape({
      asset: Yup.string()
        .test(
          "test-asset-address",
          "Please enter valid address.",
          (value) => Web3.utils.isAddress(value)
        )
        .required("Please enter asset address."),
    });

  const Formik5Schema = () =>
    Yup.object().shape({
      Escrow_amount: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .required("Please enter Escrow amount."),
      Jury_amount: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .required("Please enter Jury amount."),
    });

  const Formik6Schema = () =>
    Yup.object().shape({
      after_full_swap_treasury_wallet_transfer: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter after full swap treasury wallet transfer."),
      after_full_swap_without_trend_setter_treasury_wallet_transfer: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter after full swap without trend setter treasury wallet transfer."),
      dbeth_swap_amount_with_trend_setter: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter dbeth swap amount with trend setter."),
      dbeth_swap_amount_without_trend_setter: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter dbeth swap amount without trend setter."),
      bet_trend_setter_reward: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter bet trend setter reward."),
      pool_distribution_amount: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter pool distribution amount."),
      burn_amount: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter burn amount."),
      pool_distribution_amount_without_trend_setter: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter pool distribution amount without trend setter."),
      burn_amount_without_trend_setter: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter burn amount without trend setter."),
      reward_for_live_stream_creator: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .max(100, "Please enter a value lesser than 100")
        .required("Please enter reward for live stream creator."),
    });

  const Formik7Schema = () =>
    Yup.object().shape({
      Token_A: Yup.string()
        .test(
          "test-tokenA-address",
          "Please enter valid address.",
          (value) => Web3.utils.isAddress(value)
        )
        .required("Please enter Token A address."),
      Token_B: Yup.string()
        .test(
          "test-tokenB-address",
          "Please enter valid address.",
          (value) => Web3.utils.isAddress(value)
        )
        .required("Please enter Token B address."),
    });

  const Formik8Schema = () =>
    Yup.object().shape({
      Developer: Yup.string()
        .test(
          "test-developer-address",
          "Please enter valid address.",
          (value) => Web3.utils.isAddress(value)
        )
        .required("Please enter Developer address."),
      Escrow: Yup.string()
        .test(
          "test-escrow-address",
          "Please enter valid address.",
          (value) => Web3.utils.isAddress(value)
        )
        .required("Please enter Escrow address."),
      BurnAddress: Yup.string()
        .test(
          "test-burnaddress-address",
          "Please enter valid address.",
          (value) => Web3.utils.isAddress(value)
        )
        .required("Please enter BurnAddress address."),
      PoolAddress: Yup.string()
        .test(
          "test-pooladdress-address",
          "Please enter valid address.",
          (value) => Web3.utils.isAddress(value)
        )
        .required("Please enter PoolAddress address."),
    });

  const Formik9Schema = () =>
    Yup.object().shape({
      Strike: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .required("Please enter Strike."),
      Percentage: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .required("Please enter Percentage.")
        .max(100, "Please enter a value lesser than 100")
    });

  const Formik10Schema = () =>
    Yup.object().shape({
      Strike: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .required("Please enter Strike.")
        .min(0, "Please enter a value between 0 to 4")
        .max(4, "Please enter a value between 0 to 4"),
      Token: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .required("Please enter Token.")
        .min(1, "Please enter a value greater than 0")
    });

  const Formik11Schema = () =>
    Yup.object().shape({
      level0: Yup.number()
        .typeError('Must be a number')
        .required("Please enter level 0 percentage.")
        .integer('Must be integer')
        .min(1, "Please enter a value between 1 to 100")
        .max(100, "Please enter a value between 1 to 100"),
      level1: Yup.number()
        .typeError('Must be a number')
        .required("Please enter level 1 percentage.")
        .integer('Must be integer')
        .min(1, "Please enter a value between 1 to 100")
        .max(100, "Please enter a value between 1 to 100"),
      level2: Yup.number()
        .typeError('Must be a number')
        .required("Please enter level 2 percentage.")
        .integer('Must be integer')
        .min(1, "Please enter a value between 1 to 100")
        .max(100, "Please enter a value between 1 to 100"),
      level3: Yup.number()
        .typeError('Must be a number')
        .required("Please enter level 3 percentage.")
        .integer('Must be integer')
        .min(1, "Please enter a value between 1 to 100")
        .max(100, "Please enter a value between 1 to 100"),
      level4: Yup.number()
        .typeError('Must be a number')
        .required("Please enter level 4 percentage.")
        .integer('Must be integer')
        .min(1, "Please enter a value between 1 to 100")
        .max(100, "Please enter a value between 1 to 100"),
    });

  const Formik12Schema = () =>
    Yup.object().shape({
      highestBetLavelrange: Yup.number()
        .typeError('Must be a number')
        .required("Please enter highest bet level range.")
    });

  const Formik13Schema = () =>
    Yup.object().shape({
      level: Yup.number()
        .typeError('Must be a number')
        .required("Please enter level."),
      lowerBand: Yup.number()
        .typeError('Must be a number')
        .required("Please enter lower band."),
      upperBand: Yup.number()
        .typeError('Must be a number')
        .required("Please enter upper band.")
    });
  
  const Formik14Schema = () =>
    Yup.object().shape({
      videoVisibalTime: Yup.number()
        .typeError('Must be a number')
        .required("Please enter Video Visibal Time."),
    });


  const Formik15Schema = () =>
    Yup.object().shape({
      level1: Yup.number()
        .typeError('Must be a number')
        .required("Please enter level 1 percentage.")
        .integer('Must be integer')
        .min(1, "Please enter a value between 1 to 100")
        .max(100, "Please enter a value between 1 to 100"),
      level2: Yup.number()
        .typeError('Must be a number')
        .required("Please enter level 2 percentage.")
        .integer('Must be integer')
        .min(1, "Please enter a value between 1 to 100")
        .max(100, "Please enter a value between 1 to 100"),
    });

  const Formik16Schema = () =>
    Yup.object().shape({
      AddAllowance: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .min(1, "Please enter valid number")
        .required("Please enter Allowance."),
      CurrentAllowance: Yup.number()
    });

  const Formik17Schema = () =>
    Yup.object().shape({
      RefillTokens: Yup.number()
        .typeError('Must be a number')
        .integer('Must be integer')
        .min(1, "Please enter valid number")
        .required("Please enter Refill tokens."),
      CurrentTokenBalance: Yup.number()
    });

  const Formik18Schema = () =>
    Yup.object().shape({
      gasLimit: Yup.number()
        .typeError('Must be a number')
        .required("Please enter Gas Limit."),
      gasPrice: Yup.number()
        .typeError('Must be a number')
        .required("Please enter Gas Price."),
      Minimum_extra_matic_required: Yup.number()
        .typeError('Must be a number')
        .required("Please enter Minimum extra matic required.")
    });


  const juryChangeHandler = (e, index) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context

    let juryStrikeArrr = [...juryStrikes]
    juryStrikeArrr[index] = value ? Math.round(Math.abs(value)) : ""

    setJuryStrike(juryStrikeArrr)
  }

  useEffect(() => {
    if (configValues?.aaveFee?.[0] !== undefined) {
      setLoading(false)
    }
  }, [configValues])
  return (
    <>

      <Card>
        <CardHeader
          title={
            <>
              <div className="form-group m-0 d-flex align-items-center">
                <span className="pl-2">Configuration</span>
              </div>
            </>
          }
        ></CardHeader>

        <CardBody className="profile-seeting-tab config_tabs">{isLoading ? <CircularProgress /> :
          <Tabs
            defaultActiveKey="Aave Fee"
            id="justify-tab-example"
            className="mb-0 def_tab align-items-center"
            justify
          >
            <Tab eventKey="Aave Fee" title="Aave Reward Distribution">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          Bet_Winner_Distribution: configValues?.aaveFee?.[0] ?? "",
                          Bet_Looser_Distribution: configValues?.aaveFee?.[1] ?? ""
                        }}
                        validationSchema={Formik1Schema}
                        onSubmit={async (values) => {
                          const total = parseFloat(values.Bet_Winner_Distribution) + parseFloat(values.Bet_Looser_Distribution);
                          if (total != 100) {
                            //call toaster if summation of winner and looser not 100 
                            dispatch(showSuccessSnackbar("warning", "The summation of winner and looser should be 100", 3000));
                          }
                          else {
                            try {
                              setisSubmitLoading(true)
                              await poolMethods.setAaveFeeConfig(getInstance, networkDetails.address, values)//call contract for set aave fee config
                              setisSubmitLoading(false)
                            } catch (err) {
                              console.log('err', err)
                              setisSubmitLoading(false)
                            }
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Challenge Participant Distribution(%)</label>
                              <input
                                placeholder="Enter Bet Participant Distribution"
                                type="text"
                                name="Bet_Winner_Distribution"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Bet_Winner_Distribution}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Bet_Winner_Distribution && errors.Bet_Winner_Distribution ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Bet_Winner_Distribution}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Admin Distribution(%)</label>
                              <input
                                placeholder="Enter Admin Distribution"
                                type="text"
                                name="Bet_Looser_Distribution"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Bet_Looser_Distribution}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Bet_Looser_Distribution && errors.Bet_Looser_Distribution ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Bet_Looser_Distribution}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                                disabled={isSubmitLoading}
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Aave Threshold" title="Aave Threshold">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          threshold: typeof configValues?.aaveThresold === "string" ? configValues.aaveThresold : "",
                        }}
                        validationSchema={Formik2Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            await poolMethods.setAaveThreshold(getInstance, networkDetails.address, values)//call contract for set aave threshold
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Threshold (sec)</label>
                              <input
                                placeholder="Enter threshold"
                                type="text"
                                name="threshold"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.threshold}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.threshold && errors.threshold ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.threshold}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Set Admin" title="Set Admin">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          admin: typeof configValues?.adminConfig === "string" ? configValues.adminConfig : "",
                          // admin: configValues?.adminConfig?.[0] ?? "",
                          // foundation_factory: configValues?.adminConfig?.[1] ?? ""
                        }}
                        validationSchema={Formik3Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            await poolMethods.setAdminConfig(getInstance, networkDetails.address, values)//call contract for set admin config
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>admin(address)</label>
                              <input
                                placeholder="Enter admin address"
                                type="text"
                                name="admin"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.admin}
                                className="border-less-input ml-5 mb-6"

                              />
                              {touched.admin && errors.admin ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.admin}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            {/* <div>
                              <label>foundation factory(address)</label>
                              <input
                                placeholder="Enter foundation factory address"
                                type="text"
                                name="foundation_factory"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.foundation_factory}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.foundation_factory && errors.foundation_factory ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.foundation_factory}
                                  </div>
                                </div>
                              ) : null}
                              </div> */}

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Set Blacklisted Asset" title="Set Blacklisted Asset" >
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          asset: configValues?.blackListedAsset?.[0] ?? "",
                        }}
                        validationSchema={Formik4Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            await poolMethods.setBlacklistedAssetConfig(getInstance, networkDetails.address, values)//call contract for set blacklisted asset config
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>asset(address)</label>
                              <input
                                placeholder="Enter asset"
                                type="text"
                                name="asset"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.asset}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.asset && errors.asset ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.asset}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Set Dispute Config" title="Set Dispute Config">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          Escrow_amount: configValues?.disputeConfig?.[0] !== undefined ? configValues?.disputeConfig?.[0] / 1e18 : "",
                          Jury_amount: configValues?.disputeConfig?.[1] !== undefined ? configValues?.disputeConfig?.[1] / 1e18 : ""
                        }}
                        validationSchema={Formik5Schema}
                        onSubmit={async (values) => {
                          const DecimalValueEscAmo = (parseInt(values.Escrow_amount) * 1e18).toString()
                          const DecimalValueJurAmo = (parseInt(values.Jury_amount) * 1e18).toString()
                          try {
                            setisSubmitLoading(true)
                            //call contract for set dispute config
                            await poolMethods.setDisputeConfig(getInstance, networkDetails.address, { DecimalValueEscAmo, DecimalValueJurAmo })
                            //call api for update jury amount and escrow amount in database
                            await dispatch(updateFeeAsync({ Jury_Winning_Amount: values.Jury_amount, Escrow_Amount: values.Escrow_amount }))
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Escrow amount - Distribution of Amount to Jury(TRULY)</label>
                              <input
                                placeholder="Enter Escrow amount"
                                type="text"
                                name="Escrow_amount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Escrow_amount}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Escrow_amount && errors.Escrow_amount ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Escrow_amount}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Jury Stake Amount(TRULY)</label>
                              <input
                                placeholder="Enter Jury amount"
                                type="text"
                                name="Jury_amount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Jury_amount}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Jury_amount && errors.Jury_amount ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Jury_amount}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Set Fee Deduction Config" title="Set Fee Deduction Config">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          after_full_swap_treasury_wallet_transfer: configValues?.feeDeduction?.[0] ?? "",
                          after_full_swap_without_trend_setter_treasury_wallet_transfer: configValues?.feeDeduction?.[1] ?? "",
                          dbeth_swap_amount_with_trend_setter: configValues?.feeDeduction?.[2] ?? "",
                          dbeth_swap_amount_without_trend_setter: configValues?.feeDeduction?.[3] ?? "",
                          bet_trend_setter_reward: configValues?.feeDeduction?.[4] ?? "",
                          reward_for_live_stream_creator: configValues?.feeDeduction?.[5] ?? "",
                          pool_distribution_amount: configValues?.feeDeduction?.[6] ?? "",
                          burn_amount: configValues?.feeDeduction?.[7] ?? "",
                          pool_distribution_amount_without_trend_setter: configValues?.feeDeduction?.[8] ?? "",
                          burn_amount_without_trend_setter: configValues?.feeDeduction?.[9] ?? ""
                        }}
                        validationSchema={Formik6Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call contract for set fee deduction config
                            await poolMethods.setFeeDeductionConfig(getInstance, networkDetails.address, values)
                            //call api for update reward for live stream creator in database
                            await dispatch(updateFeeAsync({ Reward_for_live_stream_creator: values.reward_for_live_stream_creator }))
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>After full swap treasury wallet transfer</label>
                              <input
                                placeholder="Enter After full swap treasury wallet transfer"
                                type="text"
                                name="after_full_swap_treasury_wallet_transfer"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.after_full_swap_treasury_wallet_transfer}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.after_full_swap_treasury_wallet_transfer && errors.after_full_swap_treasury_wallet_transfer ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.after_full_swap_treasury_wallet_transfer}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>After full swap without trend setter treasury wallet transfer</label>
                              <input
                                placeholder="Enter After full swap without trend setter treasury wallet transfer"
                                type="text"
                                name="after_full_swap_without_trend_setter_treasury_wallet_transfer"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.after_full_swap_without_trend_setter_treasury_wallet_transfer}
                                className="border-less-input ml-5"
                              />
                              {touched.after_full_swap_without_trend_setter_treasury_wallet_transfer && errors.after_full_swap_without_trend_setter_treasury_wallet_transfer ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.after_full_swap_without_trend_setter_treasury_wallet_transfer}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>TRULY swap amount with trend setter</label>
                              <input
                                placeholder="Enter TRULY swap amount with trend setter"
                                type="text"
                                name="dbeth_swap_amount_with_trend_setter"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dbeth_swap_amount_with_trend_setter}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.dbeth_swap_amount_with_trend_setter && errors.dbeth_swap_amount_with_trend_setter ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.dbeth_swap_amount_with_trend_setter}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>TRULY swap amount without trend setter</label>
                              <input
                                placeholder="Enter TRULY swap amount without trend setter"
                                type="text"
                                name="dbeth_swap_amount_without_trend_setter"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dbeth_swap_amount_without_trend_setter}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.dbeth_swap_amount_without_trend_setter && errors.dbeth_swap_amount_without_trend_setter ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.dbeth_swap_amount_without_trend_setter}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Bet trend setter reward</label>
                              <input
                                placeholder="Enter Bet trend setter reward"
                                type="text"
                                name="bet_trend_setter_reward"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bet_trend_setter_reward}
                                className="border-less-input ml-5"
                              />
                              {touched.bet_trend_setter_reward && errors.bet_trend_setter_reward ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.bet_trend_setter_reward}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Reward for live stream creator</label>
                              <input
                                placeholder="Enter Reward for live stream creator"
                                type="text"
                                name="reward_for_live_stream_creator"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.reward_for_live_stream_creator}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.reward_for_live_stream_creator && errors.reward_for_live_stream_creator ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.reward_for_live_stream_creator}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Pool distribution amount</label>
                              <input
                                placeholder="Enter Pool distribution amount"
                                type="text"
                                name="pool_distribution_amount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.pool_distribution_amount}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.pool_distribution_amount && errors.pool_distribution_amount ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.pool_distribution_amount}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Burn amount</label>
                              <input
                                placeholder="Enter Burn amount"
                                type="text"
                                name="burn_amount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.burn_amount}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.burn_amount && errors.burn_amount ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.burn_amount}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Pool distribution anount without trend setter</label>
                              <input
                                placeholder="Enter Pool distribution anount without trend setter"
                                type="text"
                                name="pool_distribution_amount_without_trend_setter"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.pool_distribution_amount_without_trend_setter}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.pool_distribution_amount_without_trend_setter && errors.pool_distribution_amount_without_trend_setter ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.pool_distribution_amount_without_trend_setter}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Burn amount without trend setter</label>
                              <input
                                placeholder="Enter Burn amount without trend setter"
                                type="text"
                                name="burn_amount_without_trend_setter"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.burn_amount_without_trend_setter}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.burn_amount_without_trend_setter && errors.burn_amount_without_trend_setter ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.burn_amount_without_trend_setter}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Set Pair Addresses" title="Set Pair Addresses">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          Token_A: configValues?.pairAddress?.[0] ?? "",
                          Token_B: configValues?.pairAddress?.[1] ?? ""
                        }}
                        validationSchema={Formik7Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call contract for set pair addresses
                            await poolMethods.setPairAddresses(getInstance, networkDetails.address, values)
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Token A(address)</label>
                              <input
                                placeholder="Enter Token A"
                                type="text"
                                name="Token_A"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Token_A}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Token_A && errors.Token_A ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Token_A}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Token B(address)</label>
                              <input
                                placeholder="Enter Token B"
                                type="text"
                                name="Token_B"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Token_B}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Token_B && errors.Token_B ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Token_B}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Set Wallet Address" title="Set Wallet Address" >
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          Developer: configValues?.walletAddress?.[0] ?? "",
                          Escrow: configValues?.walletAddress?.[3] ?? "",
                          BurnAddress: configValues?.walletAddress?.[1] ?? "",
                          PoolAddress: configValues?.walletAddress?.[2] ?? "",
                        }}
                        validationSchema={Formik8Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call contract for set wallet address
                            await poolMethods.setWalletAddress(getInstance, networkDetails.address, values)
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Developer(address)</label>
                              <input
                                placeholder="Enter Developer"
                                type="text"
                                name="Developer"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Developer}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Developer && errors.Developer ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Developer}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>BurnAddress(address)</label>
                              <input
                                placeholder="Enter BurnAddress"
                                type="text"
                                name="BurnAddress"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.BurnAddress}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.BurnAddress && errors.BurnAddress ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.BurnAddress}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>PoolAddress(address)</label>
                              <input
                                placeholder="Enter PoolAddress"
                                type="text"
                                name="PoolAddress"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.PoolAddress}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.PoolAddress && errors.PoolAddress ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.PoolAddress}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Escrow(address)</label>
                              <input
                                placeholder="Enter Escrow"
                                type="text"
                                name="Escrow"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Escrow}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Escrow && errors.Escrow ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Escrow}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Set Jury Token" title="Set Jury Token" >
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">
                      <div>
                        <table className="table" style={{ color: "white" }}>
                          <thead>
                            <tr>
                              <th scope="col">Strike</th>
                              <th scope="col">Percentage(%)</th>
                            </tr>
                          </thead>
                          <tbody>

                            {typeof juryTokensShare[3] === 'string' && (
                              juryTokensShare.map((juryStrikeValue, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index}</td>
                                    <td>
                                      {index > 0 ? <div>
                                        <input
                                          placeholder="Enter Percentage"
                                          type="number"
                                          name="Percentage"
                                          value={juryStrikes[index]}
                                          onChange={(e) => juryChangeHandler(e, index)}
                                          className="border-less-input ml-5 mb-6"
                                          min="0"
                                        />
                                      </div> : 0}
                                    </td>
                                  </tr>
                                );
                              })
                            )}

                          </tbody>

                        </table>
                        {typeof juryTokensShare[3] === 'string' && <button
                          type="submit"
                          disabled={isSubmitLoading}
                          onClick={async () => {
                            let mapStrike = juryStrikes.map(data => Number(data))
                            mapStrike.shift()
                            let strikesArr = mapStrike.reduce((sum, x) => sum + x)
                            if (strikesArr === 100) {
                              try {
                                setisSubmitLoading(true)
                                //call contract for set jury tokens share per strike
                                await poolMethods.setJuryTokensSharePerStrike(getInstance, networkDetails.address, mapStrike)
                                setisSubmitLoading(false)
                              } catch (err) {
                                console.log('err', err)
                                setisSubmitLoading(false)
                              }
                            } else {
                              toast.error("Percentage sum must be 100")
                            }
                          }}
                          className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                        >
                          <span>Save</span>
                        </button>} {isSubmitLoading ? <CircularProgress /> : null}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Set Participent Token" title="Set Participent Token" >
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <div>
                        <table className="table" style={{ color: "white" }}>
                          <thead>
                            <tr>
                              <th scope="col">Strike</th>
                              <th scope="col">Token</th>
                            </tr>
                          </thead>
                          <tbody>
                            {typeof participentTokensShare[3] === 'string' && (
                              participentTokensShare.map((participentStrikeValue, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index}</td>
                                    <td>{participentStrikeValue / 1e18}</td>
                                  </tr>
                                );
                              })
                            )}

                          </tbody>

                        </table>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          Strike: configValues?.participentTokensShare?.[0] ?? "",
                          Token: configValues?.participentTokensShare?.[1] ?? ""
                        }}
                        validationSchema={Formik10Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call contract for set tokens per strike 
                            await poolMethods.setTokensPerStrike(getInstance, networkDetails.address, values, setRefreshParticipentTokens)
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div><h1 className="h-100 d-flex align-items-center justify-content-center" style={{ color: "white", 'fontSize': 19 }}>Update Details</h1></div>
                            <hr ></hr>
                            <hr style={{ 'backgroundColor': "white" }}></hr>
                            <div >
                              <label>Strike</label>
                              <input
                                placeholder="Enter Strike"
                                type="text"
                                name="Strike"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Developer}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Strike && errors.Strike ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Strike}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div >
                              <label>Token</label>
                              <input
                                placeholder="Enter Token"
                                type="text"
                                name="Token"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Token}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.Token && errors.Token ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.Token}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                              {isSubmitLoading ?
                                <span className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new">
                                  <CircularProgress />
                                </span> : null}
                            </div>
                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Set Platform Fees" title="Set Platform Fees" > 
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">
                      {/* {console.log("platformFees====>", configValues?.platformFees)} */}
                      <Formik
                        enableReinitialize
                        initialValues={{
                          level0: configValues?.platformFees?.[0] ?? "",
                          level1: configValues?.platformFees?.[1] ?? "",
                          level2: configValues?.platformFees?.[2] ?? "",
                          level3: configValues?.platformFees?.[3] ?? "",
                          level4: configValues?.platformFees?.[4] ?? "",
                        }}
                        validationSchema={Formik11Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call contract for set platform fees
                            await poolMethods.setPlatformFees(getInstance, networkDetails.address, values)
                            //call api for update platform fees in database
                            await dispatch(updateFeeAsync({ 
                                Platform_Fees_Level: [
                                  {"level": 0, "fee": values.level0},
                                  {"level": 1, "fee": values.level1},
                                  {"level": 2, "fee": values.level2},
                                  {"level": 3, "fee": values.level3},
                                  {"level": 4, "fee": values.level4}
                              ]
                              }                              
                            ))
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            {/* <div>
                              <label>Level 1</label>
                              <input
                                placeholder="Enter level 1"
                                type="text"
                                name="level1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.level1}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.level1 && errors.level1 ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.level1}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Level 2</label>
                              <input
                                placeholder="Enter level 2"
                                type="text"
                                name="level2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.level2}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.level2 && errors.level2 ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.level2}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Level 3</label>
                              <input
                                placeholder="Enter level 3"
                                type="text"
                                name="level3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.level3}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.level3 && errors.level3 ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.level3}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Level 4</label>
                              <input
                                placeholder="Enter level 4"
                                type="text"
                                name="level4"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.level4}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.level4 && errors.level4 ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.level4}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Level 5</label>
                              <input
                                placeholder="Enter level 5"
                                type="text"
                                name="level5"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.level5}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.level5 && errors.level5 ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.level5}
                                  </div>
                                </div>
                              ) : null}
                            </div> */}

                            <div>
                              <table className="table" style={{ color: "white" }}>
                                <thead>
                                  <tr>
                                    <th scope="col">Level</th>
                                    <th scope="col">Percentage</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Level 0</td>
                                    <td>
                                      <input
                                        placeholder={'Enter level 0'}
                                        type="text"
                                        name='level0'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.level0}
                                        className="border-less-input ml-5 mb-6"
                                      />
                                      {touched.level0 && errors.level0 ? (
                                        <div className="fv-plugins-message-container">
                                          <div className="fv-help-block">
                                            {errors.level0}
                                          </div>
                                        </div>
                                      ) : null}
                                   </td>
                                   </tr>

                                  <tr>
                                    <td>Level 1</td>
                                    <td>
                                      <input
                                        placeholder="Enter level 1"
                                        type="text"
                                        name="level1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.level1}
                                        className="border-less-input ml-5 mb-6"
                                      />
                                      {touched.level1 && errors.level1 ? (
                                        <div className="fv-plugins-message-container">
                                          <div className="fv-help-block">
                                            {errors.level1}
                                          </div>
                                        </div>
                                      ) : null}
                                    </td>
                                  </tr>

                                    <tr>
                                      <td>Level 2</td>
                                      <td>
                                        <input
                                          placeholder="Enter level 2"
                                          type="text"
                                          name="level2"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.level2}
                                          className="border-less-input ml-5 mb-6"
                                        />
                                        {touched.level2 && errors.level2 ? (
                                          <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                              {errors.level2}
                                            </div>
                                          </div>
                                        ) : null}
                                      </td>
                                    </tr>


                                  <tr>
                                    <td>Level 3</td>
                                    <td>
                                      <input
                                        placeholder="Enter level 3"
                                        type="text"
                                        name="level3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.level3}
                                        className="border-less-input ml-5 mb-6"
                                      />
                                      {touched.level3 && errors.level3 ? (
                                        <div className="fv-plugins-message-container">
                                          <div className="fv-help-block">
                                            {errors.level3}
                                          </div>
                                        </div>
                                      ) : null}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Level 4</td>
                                    <td>
                                      <input
                                        placeholder="Enter level 4"
                                        type="text"
                                        name="level4"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.level4}
                                        className="border-less-input ml-5 mb-6"
                                      />
                                      {touched.level4 && errors.level4 ? (
                                        <div className="fv-plugins-message-container">
                                          <div className="fv-help-block">
                                            {errors.level4}
                                          </div>
                                        </div>
                                      ) : null}
                                    </td>
                                  </tr>

                                </tbody>

                              </table>
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            {/* <Tab eventKey="Set Highest Bet Level Range" title="Set Highest Bet Level Range" >
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          highestBetLavelrange: configValues?.highestBetLavelrange ?? ""
                        }}
                        validationSchema={Formik12Schema}
                        onSubmit={async (values) => {
                          try {
                            console.log("values",values);
                            setisSubmitLoading(true)
                            await poolMethods.setHighestBetLavelrange(getInstance, networkDetails.address, values)
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Highest Bet Level Range</label>
                              <input
                                placeholder="Highest Bet Level Range"
                                type="text"
                                name="highestBetLavelrange"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.highestBetLavelrange}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.highestBetLavelrange && errors.highestBetLavelrange ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.highestBetLavelrange}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                              <button
                                type="submit"
                                disabled= {isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null }
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab> */}


            {/* <Tab eventKey="Set Bet Range On Different Levels" title="Set Bet Range On Different Levels" >
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{}}
                        validationSchema={Formik13Schema}
                        onSubmit={async (values) => {
                          try {
                            console.log("values",values);
                            setisSubmitLoading(true)
                            await poolMethods.setBetRangeOnDifferentLavels(getInstance, networkDetails.address, values)
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">

                             <div>
                              <label>level</label>
                              <input
                                placeholder="Enter Level"
                                type="text"
                                name="level"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.level && errors.level ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.level}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Lower Band</label>
                              <input
                                placeholder="Lower Band"
                                type="text"
                                name="lowerBand"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.lowerBand && errors.lowerBand ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.lowerBand}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <label>Upper Band</label>
                              <input
                                placeholder="Upper Band"
                                type="text"
                                name="upperBand"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.upperBand && errors.upperBand ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.upperBand}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                              <button
                                type="submit"
                                disabled= {isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null }
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab> */}

            <Tab eventKey="Video" title="Video Visible Time" >
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          videoVisibalTime: configValues?.video?.Video_End_Time ?? "",
                        }}
                        validationSchema={Formik14Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call api for update video visibal time in database
                            await dispatch(updateVideoAsync({ 
                               "Video_End_Time": values.videoVisibalTime
                              }                              
                            ))
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Video Visibal Time (Hours) </label>
                              <input
                                placeholder="Enter Video Visibal Time"
                                type="text"
                                name="videoVisibalTime"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.videoVisibalTime}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.videoVisibalTime && errors.videoVisibalTime ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.videoVisibalTime}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Set Affiliate" title="Set Affiliate" >
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          level1: configValues?.affiliateFees?.Affiliate_Reward_Percentage?.[0].percentage ?? "",
                          level2: configValues?.affiliateFees?.Affiliate_Reward_Percentage?.[1].percentage ?? "",
                        }}
                        validationSchema={Formik15Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call contract for set platform fees
                            await poolMethods.setPlatformFees(getInstance, networkDetails.address, values)
                            //call api for update affiliate fee in database
                            await dispatch(updateAffiliateFeeAsync({ 
                                Affiliate_Reward_Percentage: [
                                  {"level": 1, "percentage": values.level1},
                                  {"level": 2, "percentage": values.level2},  
                                ]
                              }                              
                            ))
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <table className="table" style={{ color: "white" }}>
                                <thead>
                                  <tr>
                                    <th scope="col">Level</th>
                                    <th scope="col">Reward Percentage</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Level 1</td>
                                    <td>
                                      <input
                                        placeholder="Enter level 1"
                                        type="text"
                                        name="level1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.level1}
                                        className="border-less-input ml-5 mb-6"
                                      />
                                      {touched.level1 && errors.level1 ? (
                                        <div className="fv-plugins-message-container">
                                          <div className="fv-help-block">
                                            {errors.level1}
                                          </div>
                                        </div>
                                      ) : null}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Level 2</td>
                                    <td>
                                      <input
                                        placeholder="Enter level 2"
                                        type="text"
                                        name="level2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.level2}
                                        className="border-less-input ml-5 mb-6"
                                      />
                                      {touched.level2 && errors.level2 ? (
                                        <div className="fv-plugins-message-container">
                                          <div className="fv-help-block">
                                            {errors.level2}
                                          </div>
                                        </div>
                                      ) : null}
                                    </td>
                                  </tr>

                                </tbody>

                              </table>
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Allowance" title="Allowance">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          CurrentAllowance: typeof configValues?.allowance === "string" ? (configValues.allowance/1e18).toFixed(2) : "",
                          AddAllowance: "",
                        }}
                        validationSchema={Formik16Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call contract for allowance
                            await poolMethods.setAllowance(getDbethInstance, networkDetails.address, values.AddAllowance)
                            //call contract for get allowance
                            const allowance = await poolMethods.getAllowance(getDbethInstance, networkDetails.address, {})
                            setConfigValues({
                              ...configValues,
                              allowance: allowance,
                            })
                            values.CurrentAllowance = typeof configValues?.allowance === "string" ? (configValues?.allowance/1e18).toFixed(2) : ""
                            values.AddAllowance = ""
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Current Allowance (TRULY): {values.CurrentAllowance}</label>
                            </div>
                            <div>
                              <label>Add Allowance (TRULY)</label>
                              <input
                                placeholder="Enter Allowance"
                                type="text"
                                name="AddAllowance"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.AddAllowance}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.AddAllowance && errors.AddAllowance ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.AddAllowance}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Refill tokens" title="Refill tokens">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">

                      <Formik
                        enableReinitialize
                        initialValues={{
                          CurrentTokenBalance: typeof configValues?.refillTokens === "string" ? (configValues.refillTokens/1e18).toFixed(2) : "",
                          RefillTokens: "",
                        }}
                        validationSchema={Formik17Schema}
                        onSubmit={async (values) => {
                          try {
                            setisSubmitLoading(true)
                            //call contract for set allowance for reward
                            await poolMethods.setAllowanceForReward(getDbethInstance, networkDetails.address, values.RefillTokens)
                            //call contract for set refill tokens
                            await poolMethods.setRefillTokens(getRewardInstance, networkDetails.address, values.RefillTokens)
                            const refillTokens = await poolMethods.getRefillTokens(getDbethInstance, networkDetails.address, {})
                            setConfigValues({
                              ...configValues,
                              refillTokens: refillTokens,
                            })
                            values.CurrentTokenBalance = typeof configValues?.refillTokens === "string" ? (configValues?.refillTokens/1e18).toFixed(2) : ""
                            values.RefillTokens = ""
                            setisSubmitLoading(false)
                          } catch (err) {
                            console.log('err', err)
                            setisSubmitLoading(false)
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div>
                              <label>Current Token Balance (TRULY): {values.CurrentTokenBalance}</label>
                            </div>
                            <div>
                              <label>Refill Tokens (TRULY)</label>
                              <input
                                placeholder="Enter Refill Tokens"
                                type="text"
                                name="RefillTokens"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.RefillTokens}
                                className="border-less-input ml-5 mb-6"
                              />
                              {touched.RefillTokens && errors.RefillTokens ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {errors.RefillTokens}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                              <button
                                type="submit"
                                disabled={isSubmitLoading}
                                className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                              >
                                <span>Save</span>
                              </button>{isSubmitLoading ? <CircularProgress /> : null}
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Contract - Freeze" title="Contract - Freeze">
              <div className="p-6 bg-dark">
                <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                  <div className="row justify-content-center  py-10">
                    <div className="col-xl-6 col-md-6-as def_form ">
                      
                      <Formik
                        enableReinitialize
                        initialValues={{
                          Contract_Details: typeof configValues?.contractDetails === "boolean" ? (configValues?.contractDetails) : ""
                        }}
                        onSubmit={async (values) => {
                          //call delete show function
                          deleteShow()
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                          handleSubmit
                        }) => (

                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                           
                                <button
                                  type="submit"
                                  disabled={isSubmitLoading}
                                  className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                                >
                                  <span>{values.Contract_Details ? "Unfreeze Contract" : "Freeze Contract"}</span>
                                </button>{isSubmitLoading ? <CircularProgress /> : null}
                            
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Gas Limit" title="Gas Limit">
            <div className="p-6 bg-dark">
              <div className="tblmrgn mt-0 lwpddng cstm_pddng">
                <div className="row justify-content-center  py-10">
                  <div className="col-xl-6 col-md-6-as def_form ">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        gasLimit: configValues?.gasDetails?.Gas_Limit ?? "",
                        gasPrice: configValues?.gasDetails?.Gas_Price ?? "",
                        Minimum_extra_matic_required: configValues?.gasDetails?.Minimum_extra_matic_required ?? "",
                      }}
                      validationSchema={Formik18Schema}
                      onSubmit={async (values) => {
                        try {
                          setisSubmitLoading(true)
                          //call api for update gas price, gas limit and Minimum extra matic required in database
                          await dispatch(updateFeeAsync({ 
                             "Gas_Price": values.gasPrice,
                             "Gas_Limit": values.gasLimit,
                             "Minimum_extra_matic_required": values.Minimum_extra_matic_required
                            }                              
                          ))
                          setisSubmitLoading(false)
                        } catch (err) {
                          console.log('err', err)
                          setisSubmitLoading(false)
                        }
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit
                      }) => (

                        <Form onSubmit={handleSubmit} autoComplete="off">
                          <div>
                            <label>Gas Limit </label>
                            <input
                              placeholder="Enter Gas Limit"
                              type="text"
                              name="gasLimit"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.gasLimit}
                              className="border-less-input ml-5 mb-6"
                            />
                            {touched.gasLimit && errors.gasLimit ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {errors.gasLimit}
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label>Gas Price </label>
                            <input
                              placeholder="Enter Gas Price"
                              type="text"
                              name="gasPrice"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.gasPrice}
                              className="border-less-input ml-5 mb-6"
                            />
                            {touched.gasPrice && errors.gasPrice ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {errors.gasPrice}
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <label>Minimum extra matic required </label>
                            <input
                              placeholder="Enter Minimum extra matic required"
                              type="text"
                              name="Minimum_extra_matic_required"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.Minimum_extra_matic_required}
                              className="border-less-input ml-5 mb-6"
                            />
                            {touched.Minimum_extra_matic_required && errors.Minimum_extra_matic_required ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {errors.Minimum_extra_matic_required}
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4 ">
                            <button
                              type="submit"
                              disabled={isSubmitLoading}
                              className="btn btn-blue spinnerBtn defpddng show-more-btn font-family-base mt-3 btn_new"
                            >
                              <span>Save</span>
                            </button>{isSubmitLoading ? <CircularProgress /> : null}
                          </div>
                        </Form>
                      )}
                    </Formik>

                  </div>
                </div>
              </div>
            </div>
          </Tab>

          </Tabs>}

        <Modal
          show={DeleteModal}
          onHide={deleteClose}
          centered
          className="def_modal delete_modal"
        >
          <Modal.Header>
            <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form def_form frmwtpddng">
              <p className="fn_s_18 f-w-400 text-center mb-10">
                Are you sure ?
              </p>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={deleteClose}
                  >
                    Close
                  </button>
                </div>
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={onDeactiveClick}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        </CardBody>
      </Card >
    </>
  );
}
