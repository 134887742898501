import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { UserManagement, UserManagementDetail, AvtarManagement , TopUserManagement} from "./modules/user_management";
import { Category, Subcategory , LiveFeed} from "./modules/services";
import { DashboardPage } from "./pages/DashboardPage";
import { AdminManagement, AdminManagementDetail } from "./modules/admin_management";
import { EmailManagement } from "./modules/email_management";
import { ProfileSettings } from "./modules/profile_settings";
import { ThirdPartyServices } from "./modules/third_party_services";
import AddTemplate from "./modules/email_management/pages/AddTemplate";
import { CmsPages } from "./modules/cms_pages";
import { CmsPageDetail } from "./modules/cms_pages";
import AddNotificationTemplate from "./modules/notification_management/pages/AddNotification";
import { NotificationManagement } from "./modules/notification_management/pages/notificationManagement";
import { DefaultPassword } from "./modules/default_password";
import AddCategory from "./modules/services/pages/AddCategory";
import AddSubcategory from "./modules/services/pages/AddSubcategory";
import { BetManagement } from "./modules/bet_management /pages/betManagement";
import {Configuration} from './modules/fee_settings /pages/configuration';
import {Payment} from './modules/payment/pages/payment';
import AddLiveFeed from "./modules/services/pages/AddLiveFeed";
import EventTable from "./modules/dashboards/EventTable";
import ReportsAndAnaytics from './modules/reports_and_analytics/pages/ReportsAndAnaytics';
import {ticketSupportManagement} from './modules/ticketSupportManagement/pages/ticketSupportManagement';
import {TeamManagement} from './modules/team_management/pages/teamManagement';
import BetUserResultManagement from './modules/bet_management /pages/bet_management_detail/betUserResultMangement';
import { FaqPages } from "./modules/faq_management";
import { FaqPageDetail } from "./modules/faq_management";
import { RewardManagement } from "./modules/reward_management";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute exact path="/dashboard" component={DashboardPage} />
        <ContentRoute exact path="/dashboard/event-table" component={EventTable} />
        <ContentRoute path="/profile-settings" component={ProfileSettings} />
        <ContentRoute
          exact
          path="/user-management"
          component={UserManagement}
        />
        <ContentRoute
          path="/user-management/user-management-detail"
          component={UserManagementDetail}
        />
        <ContentRoute
          path="/top-user-management"
          component={TopUserManagement}
        />

        <ContentRoute
          path="/avtar-management"
          component={AvtarManagement}
        />
        
        <ContentRoute
          exact
          path="/admin-management"
          component={AdminManagement}
        />
        <ContentRoute
          path="/admin-management/admin-management-detail"
          component={AdminManagementDetail}
        />
        <ContentRoute
          exact
          path="/email-management"
          component={EmailManagement}
        />

        <ContentRoute
          exact
          path="/email-management/add-template"
          component={AddTemplate}
        />
        <ContentRoute
          exact
          path="/email-management/edit-template/:id"
          component={AddTemplate}
        /> 
         <ContentRoute
          exact
          path="/services/category"
          component={Category}
        />
        <ContentRoute
          exact
          path="/services/category/add-category"
          component={AddCategory}
        />
        <ContentRoute
          exact
          path="/services/subcategory"
          component={Subcategory}
        />
         <ContentRoute
          exact
          path="/services/subcategory/add-sub-category"
          component={AddSubcategory}
        />
        <ContentRoute
          exact
          path="/services/live-feed"
          component={LiveFeed}
        />
        <ContentRoute
          exact
          path="/services/live-feed/add-live-feed"
          component={AddLiveFeed}
        />
        <ContentRoute exact path="/cms-pages" component={CmsPages} />
        <ContentRoute
          path="/cms-pages/cms-page-detail"
          component={CmsPageDetail}
        />
        <ContentRoute exact path="/faq-pages" component={FaqPages} />
        <ContentRoute
          path="/faq-pages/faq-page-detail"
          component={FaqPageDetail}
        />
        <ContentRoute
          path="/cms-pages/cms-page-detail"
          component={CmsPageDetail}
        />
        <ContentRoute 
          exact
          path="/bet-management"
          component={BetManagement}
        />

        <ContentRoute 
          exact
          path="/bet-management/bet-result-details/:id"
          component={BetUserResultManagement}
        />

        <ContentRoute
          exact
          path="/configuration"
          component={Configuration}
        />

        <ContentRoute
          exact
          path="/payment"
          component={Payment}
        />

        <ContentRoute
          exact
          path="/reports-and-analytics"
          component={ReportsAndAnaytics}
        />
        <ContentRoute
          exact
          path="/notification-management"
          component={NotificationManagement}
        />
        <ContentRoute
          exact
          path="/notification-management/add-template"
          component={AddNotificationTemplate}
        />
        <ContentRoute
          exact
          path="/notification-management/edit-template/:id"
          component={AddNotificationTemplate}
        />

        <ContentRoute
          exact
          path="/ticket-support-management"
          component={ticketSupportManagement}
        />

        <ContentRoute
          exact
          path="/team-management"
          component={TeamManagement}
        />

        <ContentRoute 
          exact
          path="/reward-management"
          component={RewardManagement}
        />

        {/* <ContentRoute
          exact
          path="/notification-management"
          component={NotificationManagement}
        />
        <ContentRoute
          exact
          path="/notification-management/add-template"
          component={AddNotificationTemplate}
        />
        <ContentRoute
          exact
          path="/notification-management/edit-template/:id"
          component={AddNotificationTemplate}
        /> */}

        {/* <ContentRoute
          path="/third-party-services"
          component={ThirdPartyServices}
        />
        <ContentRoute exact path="/cms-pages" component={CmsPages} />
        <ContentRoute
          path="/cms-pages/cms-page-detail"
          component={CmsPageDetail}
        />
        <ContentRoute
          path="/cms-pages/cms-page-detail"
          component={CmsPageDetail}
        />
        <ContentRoute path="/default-password" component={DefaultPassword} />
        <ContentRoute exact path="/faq-management" component={FaqManagement} /> */}
        {/* <ContentRoute exact path="/faq-management/add-faq" component={AddFAQ} /> */}
        {/* <ContentRoute
          exact
          path="/faq-management/edit-faq"
          component={UpdateFAQ}
        /> */}
        {/* 
        <ContentRoute
          exact
          path="/master-management"
          component={MasterManagement}
        />
        */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
