import axios from "axios";
import { EmailManagementActions } from "./emailManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";


//get all emails api
export const getAllEmailAsync = (searchBy, searchText, type) => {
  
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      let { skip, limit } = getState().emailManagement;
     
      dispatch(EmailManagementActions.getAllEmailStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/email/getAllEmail`,
        headers: {
          "Content-Type": "application/json",
        },
        data:{
          search: searchText,
          skip, limit,
          "email_id": ""
        }
      });
      if (data.statusCode === 200) {
        return dispatch(
          EmailManagementActions.getAllEmailSuccess(data)
        );
      }
      dispatch(EmailManagementActions.getAllEmailError());
      
    } catch (error) {

      dispatch(EmailManagementActions.getAllEmailError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//get all email template api
export const getAllEmailTemplatesAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
   
      dispatch(EmailManagementActions.getEmailTemplateStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/email/getAllEmail`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          search: "",
          skip: "", 
          limit: "",
          "email_id": ""
        }
      });

      if (data.statusCode === 200) {
        return dispatch(
          EmailManagementActions.getEmailTemplateSuccess(data)
        );
      }
      dispatch(EmailManagementActions.getEmailTemplateError());

    } catch (error) {
      dispatch(EmailManagementActions.getEmailTemplateError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//create email api
export const addEmailAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      
      dispatch(EmailManagementActions.addEmailStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/email/addEmail`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });

      if (data.statusCode === 201) {
        dispatch(EmailManagementActions.addEmailSuccess(data.responseData));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(
          showSuccessSnackbar("success", "Email Added Successfully", 3000)
        );
      }
      dispatch(EmailManagementActions.addEmailError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(EmailManagementActions.addEmailError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//update email api
export const updateEmailAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
    
      let { selectedEmail } = getState().emailManagement;
      dispatch(EmailManagementActions.updateEmailStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/email/editEmail/${selectedEmail._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.statusCode === 200) {
        dispatch(EmailManagementActions.updateEmailSuccess(data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(
          showSuccessSnackbar("success", "Email Updated Successfully", 3000)
        );
      }
      dispatch(EmailManagementActions.updateEmailError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(EmailManagementActions.updateEmailError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//delete email api
export const deleteEmailAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      
      dispatch(EmailManagementActions.deleteEmailStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/email/deleteEmail/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.statusCode === 200) {
        dispatch(EmailManagementActions.deleteEmailSuccess(data));
        return dispatch(
          showSuccessSnackbar("success", "Email Deleted Successfully", 3000)
        );
      }
      dispatch(EmailManagementActions.deleteEmailError());

      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(EmailManagementActions.deleteEmailError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
