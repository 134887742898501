import {ReportsAndAnalyticsMap} from './ReportsAndAnalyticsAction';

const initialState = {
  isLoading: false,
  reDirect: false,
  categoryChart: [],
  subCategoryChart: [],
  isCatEvoLoding: false,
  categoryEvolution: [],
  liveEvents:[],
  popularEvents: [],
  isLiveLoding: false,
  isPopularLoading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    
    case ReportsAndAnalyticsMap.GET_REPORTS_AND_ANALYTICS_START :
      return {
        ...state,
        isLoading: true,
      }

    case ReportsAndAnalyticsMap.GET_REPORTS_AND_ANALYTICS_SUCCESS :
      return {
        ...state,
        isLoading: false,
        categoryChart: action.payload.categories,
        subCategoryChart: action.payload.subCategories,

      }
    
    case ReportsAndAnalyticsMap.GET_REPORTS_AND_ANALYTICS_ERROR :
      return {
        ...state,
        isLoading: false,
    }

    case ReportsAndAnalyticsMap.GET_CATEGORY_EVOLUTION_START :
      return {
        ...state,
        isCatEvoLoding: true,
      }

    case ReportsAndAnalyticsMap.GET_CATEGORY_EVOLUTION_SUCCESS :
      return {
        ...state,
        isCatEvoLoding: false,
        categoryEvolution: action.payload,
      }
    
    case ReportsAndAnalyticsMap.GET_CATEGORY_EVOLUTION_ERROR :
      return {
        ...state,
        isCatEvoLoding: false,
    }

    case ReportsAndAnalyticsMap.GET_MOST_LIVE_EVENTS_START :
      return {
        ...state,
        isLiveLoding: true,
      }

    case ReportsAndAnalyticsMap.GET_MOST_LIVE_EVENTS_SUCCESS :
      return {
        ...state,
        isLiveLoding: false,
        liveEvents: action.payload,
      }
    
    case ReportsAndAnalyticsMap.GET_MOST_LIVE_EVENTS_ERROR :
      return {
        ...state,
        isLiveLoding: false,
    }

    case ReportsAndAnalyticsMap.GET_MOST_POPULAR_EVENTS_START :
      return {
        ...state,
        isPopularLoading: true,
      }

    case ReportsAndAnalyticsMap.GET_MOST_POPULAR_EVENTS_SUCCESS :
      return {
        ...state,
        isPopularLoading: false,
        popularEvents: action.payload,
      }
    
    case ReportsAndAnalyticsMap.GET_MOST_POPULAR_EVENTS_ERROR :
      return {
        ...state,
        isPopularLoading: false,
    }

    default:
      return { ...state };
  }
};
