import axios from 'axios';
import { CmsActions } from './cmsAction';
import { showSuccessSnackbar } from '../../snackBar/snackBar.action';

//create cms api
export const createCMSAsync = ({ CMSName, CMSPageDetails }, setShowCMSModal) => {
    return async (dispatch, getState) => {
        try {
            let { _id } = getState().auth.user;
            dispatch(CmsActions.createCMSStart());
            let { data } = await axios({
                url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_BASE_URL}/cms/addContent`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: { title: CMSName, content: CMSPageDetails }
            });
            if (data.statusCode === 201) {
                dispatch(CmsActions.createCMSSuccess());
                setShowCMSModal(false)
                return dispatch(showSuccessSnackbar('success', "Page added successfully", 3000));
            }
            dispatch(CmsActions.createCMSError());
            dispatch(showSuccessSnackbar('error', "Something went wrong!", 3000));
        } catch (error) {

            if (Object.keys(error.data).length > 0) {
                dispatch(CmsActions.createCMSError(error.data.message));

                return dispatch(
                    showSuccessSnackbar("error", error.data.message, 3000)
                );
            } else {

                return dispatch(
                    showSuccessSnackbar(
                        "error",
                        "Error while adding cms. Please try again later",
                        3000
                    )
                );
            }
        }
    }
}
//get all cms api
export const getAllcmsAsync = () => {
    return async (dispatch, getState) => {
        try {
            let { _id } = getState().auth.user;
            dispatch(CmsActions.getAllCMS());
            let { data } = await axios({
                url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_BASE_URL}/cms/getAllContent`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                //data: { title: CMSName, content: CMSPageDetails }
            });
            if (data.statusCode === 200) {
                return dispatch(CmsActions.getAllCMSSuccess(data.data.content));
            }
            dispatch(CmsActions.getAllCMSError());
            dispatch(showSuccessSnackbar('error', "Something went wrong!", 3000));
        } catch (error) {

            dispatch(CmsActions.getAllCMSError());
            dispatch(showSuccessSnackbar('error', "Error while fetching Data.", 3000));
        }
    }
}
//update cms api
export const updateCMSAsync = (cmsID, { CMSName, CMSPageDetails }, setShowCMSModal) => {
    return async (dispatch, getState) => {
        try {
            let { _id } = getState().auth.user;
            dispatch(CmsActions.updateCMSStart());
            let { data } = await axios({
                url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_BASE_URL}/cms/editContent/${cmsID}`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: { title: CMSName, content: CMSPageDetails }
            });
            if (data.statusCode === 200) {
                dispatch(CmsActions.updateCMSSuccess());
                setShowCMSModal(false)
                return dispatch(showSuccessSnackbar('success', "Page updated successfully", 3000));
            }
            dispatch(CmsActions.updateCMSError());
            return dispatch(showSuccessSnackbar('error', "Something went wrong!", 3000));
        } catch (error) {
            dispatch(CmsActions.updateCMSError());
            dispatch(showSuccessSnackbar('error', "Error while updating. Please try again after sometime..", 3000));
        }
    }
}
//delete cms api
export const deleteCMSAsync = (cmsID) => {
    return async (dispatch, getState) => {
        try {
            let { _id } = getState().auth.user;
            dispatch(CmsActions.deleteCMSStart());
            let { data } = await axios({
                url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_BASE_URL}/cms/deleteContent/${cmsID}`,
                method: 'DELETE',
            });
            if (data.statusCode === 200) {
                dispatch(CmsActions.deleteCMSSuccess());
                return dispatch(showSuccessSnackbar('success', "Page deleted successfully", 3000));
            }
            dispatch(CmsActions.deleteCMSError());
            return dispatch(showSuccessSnackbar('error', "Something went wrong!", 3000));
        } catch (error) {
            dispatch(CmsActions.deleteCMSError());
            dispatch(showSuccessSnackbar('error', "Error while updating. Please try again after sometime..", 3000));
        }
    }
}
