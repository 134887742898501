import React, { useState } from "react";
import { useFormik, Formik, Form } from "formik";
import * as Yup from "yup";
import { createFAQAsync, updateFAQAsync } from "../../redux/faqApi";
import { useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import CustomEditor from "../../../CustomEditor/CustomEditor";
import { generateSlug } from "../../../../utils/appUtils"
import { Col, Row, Modal } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddFAQ = ({ selectedFAQ, isLoading, showFAQModal, setShowFAQModal }) => {

  const dispatch = useDispatch();
  //formik validation schema
  const AddFAQFormSchema = () =>
    Yup.object().shape({
      question: Yup.string()
        .trim()
        .min(3, "Minimum 2 characters")
        .max(50, "Maximum 50 characters")
        .required("question is required"),
      answer: Yup.string()
        .trim()
        .min(3, "Minimum 2 characters")
        .required("Answer is required"),
    });
  //on hide model
  const onHideFAQModal = () => {
    setShowFAQModal(false);
  }

  return (
    <>
      <Modal
        show={showFAQModal}
        onHide={() => onHideFAQModal()}
        centered
        className="def_modal delete_modal"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">{selectedFAQ && selectedFAQ._id ? "Edit FAQ" : "Add FAQ"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
            enableReinitialize
            initialValues={{
              question: selectedFAQ && selectedFAQ.question || "",
              answer: selectedFAQ && selectedFAQ.answer || "",
            }}
            validationSchema={AddFAQFormSchema}
            onSubmit={(values) => {
              if (!selectedFAQ.question) {
                dispatch(createFAQAsync(values, setShowFAQModal));
              }
              if (selectedFAQ.question) {
                dispatch(updateFAQAsync(selectedFAQ._id, values, setShowFAQModal));
              }

            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleSubmit
            }) => (

              <Form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                  <Col lg={12}>
                    <div className="form-group">
                      <label className="form-label text-white font-size-12px inter-font font-weight-normal">Question</label>
                      <input
                        placeholder="Enter Question"
                        type="text"
                        name="question"      
                        value={values.question}
                        className={
                          errors.question && touched.question
                            ? "form-control re_inputRouded is-invalid border-less-input"
                            : "form-control re_inputRouded border-less-input"
                        }
                        
                        onChange={handleChange}
                      />
                      {touched.question && errors.question ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.question}
                          </div>
                        </div>
                      ) : null}
                    </div>
                      
                    <div className="form-group">
                      <label className="form-label text-white font-size-12px inter-font font-weight-normal">Answer</label>                    
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.answer}
                        config={{
                          removePlugins: ['link', 'MediaEmbed', "EasyImage", "ImageUpload"]
                        }}
                        className={
                          errors.answer &&
                            touched.answer
                            ? "form-control re_inputRouded is-invalid editore_bg"
                            : "form-control re_inputRouded"
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldTouched("answer", true);
                          setFieldValue("answer", data);
                        }}
                      />
                      {touched.answer && errors.answer ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.answer}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group  mb-0 mt-4">
                      <div className="form-group mr-5 mb-1 mt-2">
                        <button
                          type="submit"
                          className="btn btn-blue spinnerBtn add-avtar-btn"
                          disabled={isLoading}
                        >
                          <span>{selectedFAQ && selectedFAQ._id ? "Update" : "Add"}</span>
                          {isLoading && (
                            <div className="ml-3 basic-verification-loader text-center">
                              <CircularProgress />
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddFAQ;
