import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RewardManagementActions } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Checkbox from "./checkbox";
import {Constants} from '../../../../../constants/constants';

const RewardManagementTable = ({rewardObj, setAllRewardList, allRewardList, setRewardObj}) => {

  const dispatch = useDispatch();

  const { isLoading, rewardList, skip, limit, rewardCount} = useSelector(
    (state) => state.rewardManagement,
    shallowEqual
  );
  //on page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(RewardManagementActions.setRewardBatchNumber(count));
  };
  //save user id for reward 
  const handleClick = (e) => {
    const { id, checked, value, name} = e.target;
    setRewardObj([...rewardObj, {walletAddress: id, rewardAmount: value}])
    setAllRewardList([...allRewardList, name])
    if (!checked) {
      setRewardObj(rewardObj.filter((item) => item.walletAddress !== id))
      setAllRewardList(allRewardList.filter((item) => item !== name))
    }
  }
  //show reward type details 
  function RewardValue (activityType) {
    for (const key in Constants.rewardTypeConstants) { 
      if(key === activityType ){
        return Constants.rewardTypeConstants[key]
      }
    }
  }

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">

      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              id="myTable"
              className="mb-0 default_table with_hoverbtn mrpdng_tbl "
            >
              <thead>
                <tr>
                  <th>Sr.no</th>
                  <th>{" "}</th>
                  <th>UserName</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  <th>Wallet Address</th>
                  <th>Activity Type</th>
                  <th>Reward Amount (TRULY)</th>
                </tr>
              </thead>
              <tbody>
                {rewardList?.length ? (
                  rewardList?.map((rewardLists, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="f-w-500">
                            {skip * limit + (index + 1)}
                          </span>
                        </td>
                        <td>
                         <Checkbox type='checkbox' id={rewardLists?.userDetails?.walletAddress} 
                            name= {rewardLists?._id}
                            value= {rewardLists?.reward}
                            handleClick = {handleClick}
                            isChecked={allRewardList.includes(rewardLists?._id)}
                          />
                        </td>
                        <td>
                          <span className="f-w-500">{rewardLists?.userDetails?.userName ? rewardLists?.userDetails?.userName : '-'}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{rewardLists?.userDetails?.mobile_number ? rewardLists?.userDetails?.mobile_number: "-"}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{rewardLists?.userDetails?.email ? rewardLists?.userDetails?.email : "-"}</span>
                        </td>
                        <td>{rewardLists?.userDetails?.walletAddress}</td>
                        <td>{RewardValue(rewardLists?.activityType)}</td>
                        <td>{rewardLists?.reward}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center p-0 border-0">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {rewardCount} Records
              </div>
              <div className="col-md-8 text-left mb-5 aspgntn">
                <BasicPagination
                  totalRecords={rewardCount}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>

            </div>
          
        </div>
      </div>
    </div>
  );
};

export default RewardManagementTable;
