let enviornment;
if (process.env.REACT_APP_MODE === "production") {
  enviornment = {
    divideValue: 1e18,
    configABI: [
      {
        type: "constructor",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "Aave_APY_Admin_Distribution",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Aave_APY_Bet_Participant_Distribution",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "After_Full_Swap_Treasury_Wallet_Transfer",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "After_Full_Swap_Without_Trend_Setter_Treasury_Wallet_Transfer",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Bet_Trend_Setter_Reward",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Burn_Amount",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Burn_Amount_Without_TrendSetter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "DBETH_Swap_Amount_WithOut_Trend_Setter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "DBETH_Swap_Amount_With_Trend_Setter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Event_Creator_Reward",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "LendingPoolAddressProvider",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Pool_Distribution_Amount",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Pool_Distribution_Amount_Without_TrendSetter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "UniswapV2Factory",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "UniswapV2Router",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "WETHGateway",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "aDAI",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "aWMATIC",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "aaveTimeThreshold",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "admin",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "betLevels",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "betRanges",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "blackListedAssets",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "burnAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "countUserBets",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "developerAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "escrowAmount",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "freezeOrUnfreezeOperations",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "getAaveConfig",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAaveRecovery",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAaveTimeThresold",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAddresses",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAdmin",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getBlacklistedAsset",
        inputs: [
          {
            name: "asset_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getDisputeConfig",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getFeeDeductionConfig",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getFreezingStatus",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getHighestBetLavelrange",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getJuryTokensShare",
        inputs: [
          {
            name: "strike_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "version_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getLatestVersion",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getPairAddress",
        inputs: [
          {
            name: "tokenA_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getPlatformFeePercantageOnBetLavel",
        inputs: [
          {
            name: "_user",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getPlatformFeePercantages",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getTokensPerStrike",
        inputs: [
          {
            name: "strike_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getUniswapRouterAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getWalletAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "highestRange",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "juryTokensSharePerStrike",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "platformfeePercentOnBetLevels",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "poolAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "requirePaymentForJury",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "setAaveFeeConfig",
        inputs: [
          {
            name: "aave_apy_bet_participant_distrubution_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "aave_apy_bet_admin_distrubution_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setAaveThreshold",
        inputs: [
          {
            name: "threshold_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setAddresses",
        inputs: [
          {
            name: "lendingPoolAddressProvider_",
            type: "address",
            internalType: "address",
          },
          {
            name: "wethGateway_",
            type: "address",
            internalType: "address",
          },
          {
            name: "aWMATIC_",
            type: "address",
            internalType: "address",
          },
          {
            name: "aDAI_",
            type: "address",
            internalType: "address",
          },
          {
            name: "uniswapV2Factory",
            type: "address",
            internalType: "address",
          },
          {
            name: "uniswapV2Router",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setAdmin",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setBetRangeOnDifferentLavels",
        inputs: [
          {
            name: "betLevel_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "lowerBound_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "upperBound_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setBlacklistedAsset",
        inputs: [
          {
            name: "asset_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setDisputeConfig",
        inputs: [
          {
            name: "escrowAmount_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "requirePaymentForJury_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setFeeDeductionConfig",
        inputs: [
          {
            name: "after_full_swap_treasury_wallet_transfer_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name:
              "after_full_swap_without_trend_setter_treasury_wallet_transfer_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "dbeth_swap_amount_with_trend_setter_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "dbeth_swap_amount_without_trend_setter_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "bet_trend_setter_reward_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "Event_creator_reward_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "pool_distribution_amount_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "burn_amount_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "pool_distribution_amount_without_trendsetter_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "burn_amount_without_trendsetter",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setHighestBetLavelrange",
        inputs: [
          {
            name: "highestRange_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setJuryTokensSharePerStrike",
        inputs: [
          {
            name: "strike_",
            type: "uint256[]",
            internalType: "uint256[]",
          },
          {
            name: "requiredShare_",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setPairAddresses",
        inputs: [
          {
            name: "tokenA_",
            type: "address",
            internalType: "address",
          },
          {
            name: "tokenB_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setPlatformFeePercantageOnBetLevel",
        inputs: [
          {
            name: "betlevels",
            type: "uint256[]",
            internalType: "uint256[]",
          },
          {
            name: "feePercents",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setTokensPerStrike",
        inputs: [
          {
            name: "strike_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "requiredPayment",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setUserBets",
        inputs: [
          {
            name: "_user",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setWalletAddress",
        inputs: [
          {
            name: "developer_",
            type: "address",
            internalType: "address",
          },
          {
            name: "burnAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "poolAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "treasury_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "tokenPair",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "tokenStatisticsVersionCounter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "tokensPerStrike",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "treasury",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "event",
        name: "AaveFeeConfigUpdated",
        inputs: [
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
        ],
        anonymous: false,
      },
      {
        type: "event",
        name: "FeeDeductionConfigUpdated",
        inputs: [
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
        ],
        anonymous: false,
      },
    ],
    configAddress: "0xaBeE014AdE137B647b49f15d5A465a074717d068",
    requestManagerABI: [
      {
        type: "constructor",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "receive",
        stateMutability: "payable",
      },
      {
        type: "function",
        name: "ForwardAdminResolution",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "finalVerdictByAdmin_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "users_",
            type: "address[]",
            internalType: "address[]",
          },
          {
            name: "hash_",
            type: "bytes32[]",
            internalType: "bytes32[]",
          },
          {
            name: "maker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "taker_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardAdminResolutionForUnavailableEvidance",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "finalVerdictByAdmin_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "hash_",
            type: "bytes32[]",
            internalType: "bytes32[]",
          },
          {
            name: "maker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "taker_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardAdminWithdrawal",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "status_",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardBanBet",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardBroadcastFinalVerdict",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "hash_",
            type: "bytes32[]",
            internalType: "bytes32[]",
          },
          {
            name: "maker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "taker_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardBypassDisputeRoom",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "disputedOption_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "hash_",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "signature_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardCreateBet",
        inputs: [
          {
            name: "parentBet_",
            type: "address",
            internalType: "address",
          },
          {
            name: "eventCreator_",
            type: "address",
            internalType: "address",
          },
          {
            name: "betTakerRequiredLiquidity_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "betEndingTime_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenId_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "totalBetOptions_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "selectedOptionByUser_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenLiqidity_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isCustomized_",
            type: "bool",
            internalType: "bool",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "_betTrendSetter",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "payable",
      },
      {
        type: "function",
        name: "ForwardCreateDisputeRoom",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "disputedOption_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "hash_",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "signature_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardGetBetData",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_betInitiator",
            type: "address",
            internalType: "address",
          },
          {
            name: "_betTaker",
            type: "address",
            internalType: "address",
          },
          {
            name: "_totalBetOptions",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "_isDisputed",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "_betStatus",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetBetStatus",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_resolutionStatus",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "_isResolvedByAdim",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetJuryStatistics",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "usersStake_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "lastWithdrawal_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "userInitialStake_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isActiveStaker_",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "juryVerion_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetJuryStrike",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_strike",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetUserStrike",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_strike",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetUserVoteStatus",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
          {
            name: "betAddress",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardJoinBet",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "newBetEndingTime",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "authorisedHash",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "authorisedSignature",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "tokenLiqidity_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "selectedOptionByUser_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenId_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "payable",
      },
      {
        type: "function",
        name: "ForwardProvideVerdict",
        inputs: [
          {
            name: "hash_",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "signature_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "selectedVerdict_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardResolveBet",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "finalOption_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "hash_",
            type: "bytes32[]",
            internalType: "bytes32[]",
          },
          {
            name: "maker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "taker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "userPackage_",
            type: "address[]",
            internalType: "address[]",
          },
          {
            name: "percentagePackage_",
            type: "uint256[]",
            internalType: "uint256[]",
          },
          {
            name: "verifierHash_",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "verifierSig_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardStaking",
        inputs: [],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardWithdrawLiquidity",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "payable",
      },
      {
        type: "function",
        name: "ForwardWithdrawal",
        inputs: [],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "getAdmin",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAggregatorAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getConfigAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getDisputeResolverAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getFoundationFactoryAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getLiquidityHolderDeployer",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "setAdmin",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setAggregatorAddress",
        inputs: [
          {
            name: "aggregator_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setConfigAddress",
        inputs: [
          {
            name: "config_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setDisputeResolverAddress",
        inputs: [
          {
            name: "disputeResolver_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setFoundationFactoryAddress",
        inputs: [
          {
            name: "foundationFactory_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setLiquidityHolderDeployer",
        inputs: [
          {
            name: "holderDeployer_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
    ],
    requestManagerAddress: "0x7F17D6D4f17DDbb7f4DC498F286617c3C6BB153E",
    disputeResolutionAddress: "0xbA200F0CD8A1957ABc7048Ee53DfB274975f6831",
    rewardDistributionABI: [
      {
        type: "constructor",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
          {
            name: "tokenAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "admin",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "approveReward",
        inputs: [
          {
            name: "user_",
            type: "address[]",
            internalType: "address[]",
          },
          {
            name: "amount_",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "betDetails",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "parentBet",
            type: "address",
            internalType: "address",
          },
          {
            name: "eventCreator",
            type: "address",
            internalType: "address",
          },
          {
            name: "betInitiator",
            type: "address",
            internalType: "address",
          },
          {
            name: "betTaker",
            type: "address",
            internalType: "address",
          },
          {
            name: "isCustomised",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "winner",
            type: "address",
            internalType: "address",
          },
          {
            name: "betTakerRequiredLiquidity",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "betStartingTime",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "betEndingTime",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "winnerOption",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "totalBetOptions",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isDisputed",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "isDrawed",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "betStatus",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "bets",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "claimReward",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "disputeRooms",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "betCreator",
            type: "address",
            internalType: "address",
          },
          {
            name: "betTaker",
            type: "address",
            internalType: "address",
          },
          {
            name: "totalOptions",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "finalOption",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "userStakeAmount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isResolvedByAdmin",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "disputeCreatedAt",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isResolved",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "jurySize",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "disputedOption",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isCustomized",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "disputeCreator",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "isActiveStaker",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "isAdminWithdrawed",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "isSignatureUsed",
        inputs: [
          {
            name: "",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "isTokenValid",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "juryStrike",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "juryVersion",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "lastWithdrawal",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "refilTokens",
        inputs: [
          {
            name: "amount_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "replicatedBets",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "betTrendSetter",
            type: "address",
            internalType: "address",
          },
          {
            name: "underlyingBetCounter",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "tokenAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "updateAdmin",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "userAllowance",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "userInitialStake",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "userStrikes",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "usersStake",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
    ],
    rewardDistributionAddress: "0x03a2aba78e6feFd327015Df16FaD777100dE4760",
    dbethTokenABI: [
      {
        type: "constructor",
        inputs: [
          {
            name: "minteraddress",
            type: "address",
            internalType: "address",
          },
          {
            name: "initialSupply",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "_allowances",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_balances",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_decimal",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_name",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_symbol",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_totalSupply",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "allowance",
        inputs: [
          {
            name: "owner",
            type: "address",
            internalType: "address",
          },
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "approve",
        inputs: [
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "value",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "balanceOf",
        inputs: [
          {
            name: "account",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "decimals",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "decreaseAllowance",
        inputs: [
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "subtractedValue",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "increaseAllowance",
        inputs: [
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "addedValue",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "name",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "nonces",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "permit",
        inputs: [
          {
            name: "owner",
            type: "address",
            internalType: "address",
          },
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "deadline",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "v",
            type: "uint8",
            internalType: "uint8",
          },
          {
            name: "r",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "s",
            type: "bytes32",
            internalType: "bytes32",
          },
        ],
        outputs: [],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "symbol",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "totalSupply",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "transfer",
        inputs: [
          {
            name: "recipient",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "transferFrom",
        inputs: [
          {
            name: "sender",
            type: "address",
            internalType: "address",
          },
          {
            name: "recipient",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "version",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "pure",
      },
      {
        type: "event",
        name: "Approval",
        inputs: [
          {
            name: "owner",
            type: "address",
            indexed: true,
            internalType: "address",
          },
          {
            name: "spender",
            type: "address",
            indexed: true,
            internalType: "address",
          },
          {
            name: "value",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
        ],
        anonymous: false,
      },
      {
        type: "event",
        name: "Transfer",
        inputs: [
          {
            name: "from",
            type: "address",
            indexed: true,
            internalType: "address",
          },
          {
            name: "to",
            type: "address",
            indexed: true,
            internalType: "address",
          },
          {
            name: "value",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
        ],
        anonymous: false,
      },
    ],
    dbethTokenAddress: "0x1d12ce4247810E588f67E653e1405c3c96C77860",
  };
} else {
  enviornment = {
    divideValue: 1e18,
    configABI: [
      {
        type: "constructor",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "Aave_APY_Admin_Distribution",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Aave_APY_Bet_Participant_Distribution",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "After_Full_Swap_Treasury_Wallet_Transfer",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "After_Full_Swap_Without_Trend_Setter_Treasury_Wallet_Transfer",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Bet_Trend_Setter_Reward",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Burn_Amount",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Burn_Amount_Without_TrendSetter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "DBETH_Swap_Amount_WithOut_Trend_Setter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "DBETH_Swap_Amount_With_Trend_Setter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Event_Creator_Reward",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "LendingPoolAddressProvider",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Pool_Distribution_Amount",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "Pool_Distribution_Amount_Without_TrendSetter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "UniswapV2Factory",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "UniswapV2Router",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "WETHGateway",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "aDAI",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "aWMATIC",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "aaveTimeThreshold",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "admin",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "betLevels",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "betRanges",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "blackListedAssets",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "burnAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "countUserBets",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "developerAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "escrowAmount",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "freezeOrUnfreezeOperations",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "getAaveConfig",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAaveRecovery",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAaveTimeThresold",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAddresses",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAdmin",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getBlacklistedAsset",
        inputs: [
          {
            name: "asset_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getDisputeConfig",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getFeeDeductionConfig",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getFreezingStatus",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getHighestBetLavelrange",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getJuryTokensShare",
        inputs: [
          {
            name: "strike_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "version_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getLatestVersion",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getPairAddress",
        inputs: [
          {
            name: "tokenA_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getPlatformFeePercantageOnBetLavel",
        inputs: [
          {
            name: "_user",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getPlatformFeePercantages",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getTokensPerStrike",
        inputs: [
          {
            name: "strike_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getUniswapRouterAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getWalletAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "highestRange",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "juryTokensSharePerStrike",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "platformfeePercentOnBetLevels",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "poolAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "requirePaymentForJury",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "setAaveFeeConfig",
        inputs: [
          {
            name: "aave_apy_bet_participant_distrubution_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "aave_apy_bet_admin_distrubution_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setAaveThreshold",
        inputs: [
          {
            name: "threshold_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setAddresses",
        inputs: [
          {
            name: "lendingPoolAddressProvider_",
            type: "address",
            internalType: "address",
          },
          {
            name: "wethGateway_",
            type: "address",
            internalType: "address",
          },
          {
            name: "aWMATIC_",
            type: "address",
            internalType: "address",
          },
          {
            name: "aDAI_",
            type: "address",
            internalType: "address",
          },
          {
            name: "uniswapV2Factory",
            type: "address",
            internalType: "address",
          },
          {
            name: "uniswapV2Router",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setAdmin",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setBetRangeOnDifferentLavels",
        inputs: [
          {
            name: "betLevel_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "lowerBound_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "upperBound_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setBlacklistedAsset",
        inputs: [
          {
            name: "asset_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setDisputeConfig",
        inputs: [
          {
            name: "escrowAmount_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "requirePaymentForJury_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setFeeDeductionConfig",
        inputs: [
          {
            name: "after_full_swap_treasury_wallet_transfer_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name:
              "after_full_swap_without_trend_setter_treasury_wallet_transfer_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "dbeth_swap_amount_with_trend_setter_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "dbeth_swap_amount_without_trend_setter_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "bet_trend_setter_reward_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "Event_creator_reward_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "pool_distribution_amount_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "burn_amount_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "pool_distribution_amount_without_trendsetter_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "burn_amount_without_trendsetter",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setHighestBetLavelrange",
        inputs: [
          {
            name: "highestRange_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setJuryTokensSharePerStrike",
        inputs: [
          {
            name: "strike_",
            type: "uint256[]",
            internalType: "uint256[]",
          },
          {
            name: "requiredShare_",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setPairAddresses",
        inputs: [
          {
            name: "tokenA_",
            type: "address",
            internalType: "address",
          },
          {
            name: "tokenB_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setPlatformFeePercantageOnBetLevel",
        inputs: [
          {
            name: "betlevels",
            type: "uint256[]",
            internalType: "uint256[]",
          },
          {
            name: "feePercents",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setTokensPerStrike",
        inputs: [
          {
            name: "strike_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "requiredPayment",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setUserBets",
        inputs: [
          {
            name: "_user",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setWalletAddress",
        inputs: [
          {
            name: "developer_",
            type: "address",
            internalType: "address",
          },
          {
            name: "burnAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "poolAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "treasury_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "tokenPair",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "tokenStatisticsVersionCounter",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "tokensPerStrike",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "treasury",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "event",
        name: "AaveFeeConfigUpdated",
        inputs: [
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
        ],
        anonymous: false,
      },
      {
        type: "event",
        name: "FeeDeductionConfigUpdated",
        inputs: [
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
          {
            name: "",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
        ],
        anonymous: false,
      },
    ],
    configAddress: "0xaBeE014AdE137B647b49f15d5A465a074717d068",
    requestManagerABI: [
      {
        type: "constructor",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "receive",
        stateMutability: "payable",
      },
      {
        type: "function",
        name: "ForwardAdminResolution",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "finalVerdictByAdmin_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "users_",
            type: "address[]",
            internalType: "address[]",
          },
          {
            name: "hash_",
            type: "bytes32[]",
            internalType: "bytes32[]",
          },
          {
            name: "maker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "taker_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardAdminResolutionForUnavailableEvidance",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "finalVerdictByAdmin_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "hash_",
            type: "bytes32[]",
            internalType: "bytes32[]",
          },
          {
            name: "maker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "taker_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardAdminWithdrawal",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "status_",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardBanBet",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardBroadcastFinalVerdict",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "hash_",
            type: "bytes32[]",
            internalType: "bytes32[]",
          },
          {
            name: "maker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "taker_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardBypassDisputeRoom",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "disputedOption_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "hash_",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "signature_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardCreateBet",
        inputs: [
          {
            name: "parentBet_",
            type: "address",
            internalType: "address",
          },
          {
            name: "eventCreator_",
            type: "address",
            internalType: "address",
          },
          {
            name: "betTakerRequiredLiquidity_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "betEndingTime_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenId_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "totalBetOptions_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "selectedOptionByUser_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenLiqidity_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isCustomized_",
            type: "bool",
            internalType: "bool",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "_betTrendSetter",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "payable",
      },
      {
        type: "function",
        name: "ForwardCreateDisputeRoom",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "disputedOption_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "hash_",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "signature_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardGetBetData",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_betInitiator",
            type: "address",
            internalType: "address",
          },
          {
            name: "_betTaker",
            type: "address",
            internalType: "address",
          },
          {
            name: "_totalBetOptions",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "_isDisputed",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "_betStatus",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetBetStatus",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_resolutionStatus",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "_isResolvedByAdim",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetJuryStatistics",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "usersStake_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "lastWithdrawal_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "userInitialStake_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isActiveStaker_",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "juryVerion_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetJuryStrike",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_strike",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetUserStrike",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_strike",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardGetUserVoteStatus",
        inputs: [
          {
            name: "user_",
            type: "address",
            internalType: "address",
          },
          {
            name: "betAddress",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "ForwardJoinBet",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "newBetEndingTime",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "authorisedHash",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "authorisedSignature",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "tokenLiqidity_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "selectedOptionByUser_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenId_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "payable",
      },
      {
        type: "function",
        name: "ForwardProvideVerdict",
        inputs: [
          {
            name: "hash_",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "signature_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "selectedVerdict_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardResolveBet",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
          {
            name: "finalOption_",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "hash_",
            type: "bytes32[]",
            internalType: "bytes32[]",
          },
          {
            name: "maker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "taker_",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "userPackage_",
            type: "address[]",
            internalType: "address[]",
          },
          {
            name: "percentagePackage_",
            type: "uint256[]",
            internalType: "uint256[]",
          },
          {
            name: "verifierHash_",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "verifierSig_",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardStaking",
        inputs: [],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "ForwardWithdrawLiquidity",
        inputs: [
          {
            name: "betAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "payable",
      },
      {
        type: "function",
        name: "ForwardWithdrawal",
        inputs: [],
        outputs: [
          {
            name: "_status",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "getAdmin",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getAggregatorAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getConfigAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getDisputeResolverAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getFoundationFactoryAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "getLiquidityHolderDeployer",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "setAdmin",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setAggregatorAddress",
        inputs: [
          {
            name: "aggregator_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setConfigAddress",
        inputs: [
          {
            name: "config_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setDisputeResolverAddress",
        inputs: [
          {
            name: "disputeResolver_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setFoundationFactoryAddress",
        inputs: [
          {
            name: "foundationFactory_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "setLiquidityHolderDeployer",
        inputs: [
          {
            name: "holderDeployer_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
    ],
    requestManagerAddress: "0x7F17D6D4f17DDbb7f4DC498F286617c3C6BB153E",
    disputeResolutionAddress: "0xbA200F0CD8A1957ABc7048Ee53DfB274975f6831",
    rewardDistributionABI: [
      {
        type: "constructor",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
          {
            name: "tokenAddress_",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "admin",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "approveReward",
        inputs: [
          {
            name: "user_",
            type: "address[]",
            internalType: "address[]",
          },
          {
            name: "amount_",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "betDetails",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "parentBet",
            type: "address",
            internalType: "address",
          },
          {
            name: "eventCreator",
            type: "address",
            internalType: "address",
          },
          {
            name: "betInitiator",
            type: "address",
            internalType: "address",
          },
          {
            name: "betTaker",
            type: "address",
            internalType: "address",
          },
          {
            name: "isCustomised",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "winner",
            type: "address",
            internalType: "address",
          },
          {
            name: "betTakerRequiredLiquidity",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "betStartingTime",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "betEndingTime",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "winnerOption",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "totalBetOptions",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isDisputed",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "isDrawed",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "betStatus",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "bets",
        inputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "claimReward",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "disputeRooms",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "betCreator",
            type: "address",
            internalType: "address",
          },
          {
            name: "betTaker",
            type: "address",
            internalType: "address",
          },
          {
            name: "totalOptions",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "finalOption",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "userStakeAmount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isResolvedByAdmin",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "disputeCreatedAt",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isResolved",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "jurySize",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "disputedOption",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "isCustomized",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "disputeCreator",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "isActiveStaker",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "isAdminWithdrawed",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "isSignatureUsed",
        inputs: [
          {
            name: "",
            type: "bytes",
            internalType: "bytes",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "isTokenValid",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "juryStrike",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "juryVersion",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "lastWithdrawal",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "refilTokens",
        inputs: [
          {
            name: "amount_",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "replicatedBets",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "betTrendSetter",
            type: "address",
            internalType: "address",
          },
          {
            name: "underlyingBetCounter",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "tokenAddress",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "updateAdmin",
        inputs: [
          {
            name: "admin_",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "userAllowance",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "userInitialStake",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "userStrikes",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "usersStake",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
    ],
    rewardDistributionAddress: "0x03a2aba78e6feFd327015Df16FaD777100dE4760",
    dbethTokenABI: [
      {
        type: "constructor",
        inputs: [
          {
            name: "minteraddress",
            type: "address",
            internalType: "address",
          },
          {
            name: "initialSupply",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "_allowances",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_balances",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_decimal",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_name",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_symbol",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "_totalSupply",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "allowance",
        inputs: [
          {
            name: "owner",
            type: "address",
            internalType: "address",
          },
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "approve",
        inputs: [
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "value",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "balanceOf",
        inputs: [
          {
            name: "account",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "decimals",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "decreaseAllowance",
        inputs: [
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "subtractedValue",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "increaseAllowance",
        inputs: [
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "addedValue",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "name",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "nonces",
        inputs: [
          {
            name: "",
            type: "address",
            internalType: "address",
          },
        ],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "permit",
        inputs: [
          {
            name: "owner",
            type: "address",
            internalType: "address",
          },
          {
            name: "spender",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "deadline",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "v",
            type: "uint8",
            internalType: "uint8",
          },
          {
            name: "r",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "s",
            type: "bytes32",
            internalType: "bytes32",
          },
        ],
        outputs: [],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "symbol",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "totalSupply",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        stateMutability: "view",
      },
      {
        type: "function",
        name: "transfer",
        inputs: [
          {
            name: "recipient",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "transferFrom",
        inputs: [
          {
            name: "sender",
            type: "address",
            internalType: "address",
          },
          {
            name: "recipient",
            type: "address",
            internalType: "address",
          },
          {
            name: "amount",
            type: "uint256",
            internalType: "uint256",
          },
        ],
        outputs: [
          {
            name: "",
            type: "bool",
            internalType: "bool",
          },
        ],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "version",
        inputs: [],
        outputs: [
          {
            name: "",
            type: "string",
            internalType: "string",
          },
        ],
        stateMutability: "pure",
      },
      {
        type: "event",
        name: "Approval",
        inputs: [
          {
            name: "owner",
            type: "address",
            indexed: true,
            internalType: "address",
          },
          {
            name: "spender",
            type: "address",
            indexed: true,
            internalType: "address",
          },
          {
            name: "value",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
        ],
        anonymous: false,
      },
      {
        type: "event",
        name: "Transfer",
        inputs: [
          {
            name: "from",
            type: "address",
            indexed: true,
            internalType: "address",
          },
          {
            name: "to",
            type: "address",
            indexed: true,
            internalType: "address",
          },
          {
            name: "value",
            type: "uint256",
            indexed: false,
            internalType: "uint256",
          },
        ],
        anonymous: false,
      },
    ],
    dbethTokenAddress: "0x1d12ce4247810E588f67E653e1405c3c96C77860",
  };
}

//image dimensions constants
const ImageConstants = {
  SUB_CATEGORY_DIMENSIONS: "1024*1024",
  CATEGORY_DIMENSIONS: "1024*1024",
  LIVE_FEED_DIMENSIONS: "1024*1024",
  TOKEN_DIMENSIONS: "1024*1024",
  AVTAR_DIMENSIONS: "1024*1024",
  TEAM_DIMENSIONS: "1024*1024",
  STANDINGS_DIMENSIONS: "1024*1024",
};
//reward type constants
const rewardTypeConstants = {
  LOGIN_3DAYS: "3 straight days login",
  LOGIN_10DAYS: "10 straight days login",
  CREATED_5BETS: "5 challenges created",
  PLACED_5BETS: "5 challenges placed",
  PLACED_10BETS: "10 challenges placed",
  TAKE_1BET: "1 challenge taken",
  TAKE_10BET: "10 challenge taken",
  UPLOAD_10VIDEOS: "10 videos uploaded",
  FOLLOWERS_50: "Got 50 Followers",
  TAKE_LIVE_BET: "Create + taken or Take live challenge",
};

export const Constants = {
  rewardTypeConstants,
  ImageConstants,
  enviornment,
};
