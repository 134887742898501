import { RewardManagementMap } from "./rewardManagementAction";

const initialState = {
  isLoading: false,
  rewardList: {},
  rewardCount: "",
  refreshRewardList: true,
  refreshActivities: true,
  reDirect: false,
  skip: 0,
  limit: 10,
  rewardSearch: "",
  activityType: {value: "", label: "All"},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RewardManagementMap.GET_REWARD_LIST_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RewardManagementMap.GET_REWARD_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        rewardList: action.payload.rewardList,
        rewardCount: action.payload.rewardCount,
        refreshRewardList: false,
        reDirect: false,
      };
    }
    case RewardManagementMap.GET_REWARD_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshRewardList: false,
      };
    }
    case RewardManagementMap.SET_REWARD_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshRewardList: true,
      };
    }
    case RewardManagementMap.UPDATE_REWARD_LIST_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RewardManagementMap.UPDATE_REWARD_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshRewardList: true,
        reDirect: false,
      };
    }
    case RewardManagementMap.UPDATE_REWARD_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshRewardList: false,
      };
    }
    case RewardManagementMap.SET_REWARD_SEARCH: {
      return {
        ...state,
        rewardSearch: action.payload
      };
    }
    case RewardManagementMap.SET_ACTIVITY_TYPE_CHANGE: {
      return {
        ...state,
        activityType: action.payload,
        refreshRewardList: true,
      };
    }

    case RewardManagementMap.REFRESH_REWARD_LIST: {
      return {
        ...state,
        skip:0,
        refreshRewardList: true
      }
    }
    default:
      return { ...state };
  }
};
