import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { createAvtar } from '../../redux/userManagementApi';
import { UserManagementActions } from "../../redux/userManagementAction";
import {Constants} from '../../../../../constants/constants';

const AddAvtar = ({ addAvtar, setAddAvtar, file, setFile }) => {
  //formik validation schema
  const addServiceSchema = Yup.object().shape({
    avtarImage: Yup.string()
      .required("Please enter Avtar Image"),
  })

  const { isLoading, reDirect, refreshCategoryList } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  const dispatch = useDispatch();
  //on hide avtar model
  const onHideAvtar = () => {
    setFile({ file: null, url: null })
    setAddAvtar(false);
  }
  // save upload image details
  const uploadImage = (e, setFieldValue) => {
    setFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    setFieldValue("avtarImage", e.currentTarget.files[0])
  };
  //close add avtar model
  useEffect(() => {

    if (reDirect === true) {
      setFile({ file: null, url: null });
      dispatch(UserManagementActions.refreshUserList());//refresh details
      setAddAvtar(false);
    }
  }, [reDirect])

  return (
    <>
      <Modal
        show={addAvtar}
        onHide={() => onHideAvtar()}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Add Avtar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              avtarImage: ""
            }}
            validationSchema={addServiceSchema}
            onSubmit={values => {
              const formData = new FormData();
              formData.append('image', values.avtarImage);
              // console.log("formData", formData);
              dispatch(createAvtar(formData))
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleSubmit
            }) => (

              <Form onSubmit={handleSubmit}>

                <div className="form-group">
                  <label className="form-label inter-font font-size-12px font-weight-normal text-white">Avtar </label>
                  <div
                    className="pro_img text-center"
                    style={{
                      backgroundImage: `url(${file.url})`
                    }}
                  >
                    <input
                      type="file"
                      name="avtarImage"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => uploadImage(e, setFieldValue)}
                    />
                    <div className="edt_img_btn"><div className="upload-pic-btn"><span className="material-icons">upload</span>Upload</div></div>
                  </div>
                  {touched.avtarImage && errors.avtarImage ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.avtarImage}
                      </div>
                    </div>
                  ) : null}
                </div>

                <label className="text-white font-size-16px inter-font font-weight-normal">Suggestion for best UI Experience: </label>
                <p><b>Image Dimensions: <span className="text-danger">{Constants.ImageConstants.AVTAR_DIMENSIONS}</span></b></p>
                <p>Image Compression Tool: <span><a href="https://imagecompressor.com/" target="_blank" className="text-info">https://imagecompressor.com/</a></span></p>

                <div className="form-group  mb-0 mt-4">
                  <div className="form-group mr-5 mb-1 mt-2">
                    {isLoading ? (
                      <div className="ml-3 basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-blue spinnerBtn add-avtar-btn"
                        disabled={isLoading}
                      >
                        <span>Add</span>

                      </button>
                    )}
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddAvtar

