import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import {ReportsAndAnalyticsActions} from './ReportsAndAnalyticsAction';

export const getReportsAndAnalyticsAsync = () => {
  
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL;
        
        dispatch(ReportsAndAnalyticsActions.getReportsAndAnalyticsStart());
        const { data } = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllStats`,
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (data.statusCode === 200) {
          return dispatch(
            ReportsAndAnalyticsActions.getReportsAndAnalyticsSuccess(data.data)
          );
        }
        dispatch(ReportsAndAnalyticsActions.getReportsAndAnalyticsError());
      } catch (error) {
        dispatch(ReportsAndAnalyticsActions.getReportsAndAnalyticsError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
  };
  
  export const getCategoryEvolutionAsync = () => {
  
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL;
        
        dispatch(ReportsAndAnalyticsActions.getCategoryEvolutionStart());
        const { data } = await axios({
          method: "GET",
          url: ` ${process.env.REACT_APP_HOST}${adminBaseURL}/getCategoryEvolution`,
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (data.statusCode === 200) {
          return dispatch(
            ReportsAndAnalyticsActions.getCategoryEvolutionSuccess(data.data)
          );
        }
        dispatch(ReportsAndAnalyticsActions.getCategoryEvolutionError());
      } catch (error) {
        dispatch(ReportsAndAnalyticsActions.getCategoryEvolutionError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
  };

  export const getMostPopularEventsAsync = (values) => {
  
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL;
        
        dispatch(ReportsAndAnalyticsActions.getMostPopularEventsStart());
        const { data } = await axios({
          method: "POST",
          url: ` ${process.env.REACT_APP_HOST}${adminBaseURL}/mostPopularEvents`,
          data: values,
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (data.statusCode === 200) {
          return dispatch(
            ReportsAndAnalyticsActions.getMostPopularEventsSuccess(data.data)
          );
        }
        dispatch(ReportsAndAnalyticsActions.getMostPopularEventsError());
      } catch (error) {
        dispatch(ReportsAndAnalyticsActions.getMostPopularEventsError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
  };

  export const getMostLiveEventAsync = (values) => {
  
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL;
        
        dispatch(ReportsAndAnalyticsActions.getMostLiveEventStart());
        const { data } = await axios({
          method: "POST",
          url: ` ${process.env.REACT_APP_HOST}${adminBaseURL}/mostLiveEvents`,
          data: values,
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (data.statusCode === 200) {
          return dispatch(
            ReportsAndAnalyticsActions.getMostLiveEventSuccess(data.data)
          );
        }
        dispatch(ReportsAndAnalyticsActions.getMostLiveEventError());
      } catch (error) {
        dispatch(ReportsAndAnalyticsActions.getMostLiveEventError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
  };