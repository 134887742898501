import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { Row, Col, Grid } from 'react-bootstrap';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { CircularProgress } from "@material-ui/core";
import { Table, Modal } from "react-bootstrap";
import { TeamManagementActions } from "../redux/teamManagementAction";
import { getTeamAsync } from '../redux/teammanagementApi';
import BasicPagination from "../../pagination/BasicPagination";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import EditTeam from './editTeamTemplate';
import { getCategoryAsync , getSubCategoryAsync} from '../../services/redux/servicesApi';
import Select from "react-select";

export function TeamManagement() {

  const searchTeamRef = useRef();
  const dispatch = useDispatch();

  const { refreshTeamList, search, skip, limit, selectedTeam, sub_category_id, teamList, isLoading } = useSelector(
    (state) => state.teamManagement,
    shallowEqual
  );

  const [editTeam, setEditTeam] = useState(false);

  const [categoryOptions, setCategoryOptions] = useState([])
  const [categoryValue, setCategoryValue] = useState({ value: "", label: "All" });

  const { category , subCategory} = useSelector((state) => state.servicesManagement, shallowEqual);
  //call get all subcategory api
  useEffect(() => {
    dispatch(getSubCategoryAsync())
  }, [])
  //save category id and name in array
  useEffect(() => {
    let catArr = [{ value: "", label: "All" }]
    subCategory.map((cd) => {
      catArr.push({
        value: cd._id,
        label: cd.name
      })
    })
    setCategoryOptions(catArr)
  }, [subCategory && subCategory.length > 0])
  //get team details by category choice
  const onSelectChange = (e) => {
    setCategoryValue({ value: e.value, label: e.label })
    dispatch(TeamManagementActions.setSelectCategory(e.value));
  }
  //save search text 
  const onSearchTextChange = (e) => {
    dispatch(TeamManagementActions.searchTextChange(e.target.value));
  };
  //call api while search
  useEffect(() => {
    dispatch(TeamManagementActions.setTeamDetails(null));
    if (searchTeamRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(TeamManagementActions.refreshTeamList());
        }
      };
      let input = searchTeamRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(TeamManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, []);
  //call get all team api
  useEffect(() => {
    if (refreshTeamList) {
      dispatch(getTeamAsync(search, skip, limit, sub_category_id));
    }
  }, [refreshTeamList]);
  //for page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(TeamManagementActions.setTeamBatchNumber(count));
  };
  // save edit team details
  const onEditClick = (team) => {
    dispatch(TeamManagementActions.setTeamDetails(team));
    setEditTeam(true);
  };

  return (
    <>
      <Card>
        <CardHeader title="Team Management">
          <CardHeaderToolbar>
          <Row>
            <Col lg={6}>
              <div className="flex-wrap ap_filter_wraper justify-content-end align-items-center" style={{ width: "100%" }}>
                <div className="viral-events-dropdown">
                  <Select
                    value={{
                      value: categoryValue.value,
                      label: categoryValue.label
                    }}
                    placeholder="Select Category"
                    className={` border-0 p-0  `}
                    classNamePrefix="phoSelect"
                    options={categoryOptions}
                    onChange={onSelectChange}
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center mt-2">
                <form
                  className="subheader_filter"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="form-row">
                    <div className="form-group mr-3 mb-0">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={search}
                        onChange={onSearchTextChange}
                        ref={searchTeamRef}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
          <div className="tblmrgn mt-0">
            <div className="mt-5">
              <div>
                <Table
                  hover
                  responsive
                  className="mb-0 default_table with_hoverbtn mrpdng_tbl"
                >
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Team Name</th>
                      <th>Image</th>
                      <th>Category</th>
                      <th>Sub Category</th>
                      <th width="120px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teamList && teamList.teams && teamList.teams.length ? (
                      teamList && teamList.teams.map((teamLists, index) => (
                        <tr key={index}>
                          <td>
                            <span className="f-w-500">
                              {skip * limit + (index + 1)}
                            </span>
                          </td>
                          <td>
                            <span className="f-w-500">{teamLists.teamName}</span>
                          </td>
                          <td>
                          {
                            teamLists.teamLogo ? (
                              <>
                              <img className="f-w-500" style={{ height: '5%', width: '5%' }} alt="image" src={teamLists.teamLogo} />
                              </>
                            ): <h2> - </h2>
                          }
                          </td>
                          <td>
                            <span className="f-w-500">{teamLists?.categories?.name}</span>
                          </td>
                          <td>
                            <span className="f-w-500">{teamLists?.subcategories?.name}</span>
                          </td>
                          <td>
                            <a
                              title="Edit"
                              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                              onClick={() => onEditClick(teamLists)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Write.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No Record Found
                        </td>
                      </tr>
                    )}
                    {isLoading && (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="basic-verification-loader text-center">
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <div className="row d-flex align-items-center mr-5 ml-5 mt-4  btm-pagination">

                <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
                  Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                  {teamList && teamList.countTeams} Records
                </div>
                <div className="col-md-8 text-right mb-5 aspgntn">
                  <BasicPagination
                    totalRecords={teamList && teamList.countTeams}
                    limit={limit}
                    batch={skip + 1}
                    onBatchChange={onPageChange}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        <EditTeam editTeam={editTeam} setEditTeam={setEditTeam} />
      </Card>
    </>
  )
}
