import React from "react";
import { Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { deleteFAQAsync } from "../../redux/faqApi";
import { useDispatch } from "react-redux";

const FaqPagesTable = ({ faqLists, onSelectFAQ, onDeleteClick, isLoading, soringAction }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
        <div className="tblmrgn mt-0">
          <div className="mt-5">
            <div>
              <Table
                hover
                responsive
                className="mb-0 default_table with_hoverbtn mrpdng_tbl"
              >
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Position</th>
                    <th className="wd-120">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {faqLists && faqLists.FAQ && faqLists.FAQ.length
                    ? faqLists.FAQ.map((faqList, index, {length}) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className="f-w-500">{faqList.question}</span>
                          </td>
                          <td>
                            {index === 0 && (
                              <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 bg-light"
                              onClick={() => soringAction(faqList._id, "down")}>
                                {(
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Down-2.svg"
                                    )}
                                  />
                                )}
                              </span>
                            )}
                            {index !== 0 && index !== (length - 1) && (
                              <div>
                                <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 bg-light"
                                  onClick={() => soringAction(faqList._id, "up")}>
                                  {(
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Navigation/Up-2.svg"
                                      )}
                                    />
                                  )}
                                </span> 

                                <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 bg-light"
                                  onClick={() => soringAction(faqList._id, "down")}>
                                  {(
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Navigation/Down-2.svg"
                                      )}
                                    />
                                  )}
                                </span>
                                
                                   
                              </div>
                            )}
                            {index === (length - 1) && (
                              <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 bg-light"
                              onClick={() => soringAction(faqList._id, "up")}>
                                {(
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Up-2.svg"
                                    )}
                                  />
                                )}
                              </span>
                            )}
                          </td>
                          <td>
                            <a
                              title="Edit"
                              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                              onClick={() => onSelectFAQ(faqList)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Write.svg"
                                  )}
                                />
                              </span>
                            </a>
                            <a
                              title="Delete"
                              className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              onClick={() => onDeleteClick(faqList)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </td>
                        </tr>
                      );
                    })
                    : (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No Record Found
                        </td>
                      </tr>
                    )}
                  {isLoading && (
                    <tr>
                      <td colSpan={8} className="text-center">
                        <div className="basic-verification-loader text-center">
                          <CircularProgress />
                        </div>
                      </td>
                    </tr>
                  )}
                  </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqPagesTable;
