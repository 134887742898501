import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/redux/authReducer";
import * as userManagement from "../app/modules/user_management/redux";
import * as adminManagement from "../app/modules/admin_management/redux";
import * as servicesReducer from "../app/modules/services/redux/servicesReducer";
import * as rewardManagement from "../app/modules/reward_management/redux/index";
import * as emailManagement from "../app/modules/email_management/redux";
import * as betManagement from '../app/modules/bet_management /redux';
import * as profile from "../app/modules/profile_settings/redux";
import * as dashboard from "../app/modules/dashboards/redux";
import * as thirdParty from "../app/modules/third_party_services/redux";
import * as notificationManagement from "../app/modules/notification_management/redux";
import * as cms from "../app/modules/cms_pages/redux";
import * as defaultPassword from "../app/modules/default_password/redux";
import snackBarReducer from "../app/modules/snackBar/snackbarReducer";
import * as feeSettings from "../app/modules/fee_settings /redux";
import * as payment from '../app/modules/payment/redux';
import * as reportsAndAnaytics from "../app/modules/reports_and_analytics/redux";
import * as ticketSupportManagement from '../app/modules/ticketSupportManagement/redux';
import * as teamManagement from '../app/modules/team_management/redux';
import * as faq from "../app/modules/faq_management/redux"
export const rootReducer = combineReducers({
  snackBar: snackBarReducer,
  dashboard: dashboard.reducer,
  auth: auth.reducer,
  userManagement: userManagement.reducer,
  adminManagement: adminManagement.reducer,
  emailManagement: emailManagement.reducer,
  notificationManagement: notificationManagement.reducer,
  profile: profile.reducer,
  thirdParty: thirdParty.reducer,
  cms: cms.reducer,
  defaultPassword: defaultPassword.reducer,
  servicesManagement: servicesReducer.reducer,
  betManagement: betManagement.reducer,
  feeSettings: feeSettings.reducer,
  payment: payment.reducer,
  reportsAndAnaytics: reportsAndAnaytics.reducer,
  ticketSupportManagement: ticketSupportManagement.reducer,
  teamManagement: teamManagement.reducer,
  faq: faq.reducer,
  rewardManagement: rewardManagement.reducer
});
