import axios from "axios";
import { AdminManagementActions } from "./adminManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
//get all admin api
export const getAllAdminAsync = (searchBy, searchText, dir) => {
  return async (dispatch, getState) => {

    try {
      let { role } = getState().auth.user;
  
      let { skip, limit } = getState().adminManagement;
      dispatch(AdminManagementActions.getAllUserStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_ADMIN_BASE_URL}/getAllAdminUsers`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { search: searchText, skip, limit, sortBy: searchBy, sortType: dir }
      });
      if (data.statusCode === 200) {
        return dispatch(
          AdminManagementActions.getAllUserSuccess({data, role})
        );
      }
      dispatch(AdminManagementActions.getAllUserError());
    } catch (error) {
      dispatch(AdminManagementActions.getAllUserError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//create admin api
export const createAdminAsync = (userDetail) => {
  return async (dispatch, getState) => {
    try {
      let { _id } = getState().auth.user;
      dispatch(AdminManagementActions.createUserStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_ADMIN_BASE_URL}/addUser`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { ...userDetail, displayName: `${userDetail.firstName} ${userDetail.lastName}`, role: 2 },
      });

      if (data.statusCode === 201) {
        dispatch(AdminManagementActions.createUserSuccess());
        return dispatch(
          showSuccessSnackbar("success", "Admin added successfully", 3000)
        );
      }
      dispatch(AdminManagementActions.createUserError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      if (Object.keys(error.data).length > 0) {
        dispatch(AdminManagementActions.createUserError(error.data.message));

        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      } else {

        return dispatch(
          showSuccessSnackbar(
            "error",
            "Error while creating admin. Please try again later",
            3000
          )
        );
      }

    }
  };
};
//update admin api
export const updateAdminAsync = (user, userID) => {
  return async (dispatch, getState) => {
    try {
      dispatch(AdminManagementActions.updateUserStart());
      let { _id } = getState().auth.user;
  
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_ADMIN_BASE_URL}/editAdminUser/${userID}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: user,
      });
      if (data.statusCode === 200) {
        dispatch(AdminManagementActions.updateUserSuccess());
        return dispatch(
          showSuccessSnackbar("success", "Admin updated successfully", 3000)
        );
      }
      dispatch(AdminManagementActions.updateUserError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
     
      if (Object.keys(error.data).length > 0) {
        dispatch(AdminManagementActions.updateUserError(error.data.message));

        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      } else {

        return dispatch(
          showSuccessSnackbar(
            "error",
            "Error while updating admin. Please try again later",
            3000
          )
        );
      }
    }
  };
};
//delete admin api
export const deleteAdminAsync = (userID) => {
  return async (dispatch, getState) => {
    try {
      dispatch(AdminManagementActions.deleteUserStart());
      let { _id } = getState().auth.user;
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_ADMIN_BASE_URL}/deleteUser/${userID}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.statusCode === 202) {
        dispatch(AdminManagementActions.deleteUserSuccess());
        return dispatch(
          showSuccessSnackbar("success", data.message, 3000)
        );
      }
      dispatch(AdminManagementActions.deleteUserError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {

      if (Object.keys(error.data).length > 0) {
        dispatch(AdminManagementActions.deleteUserError(error.data.message));

        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      } else {

        return dispatch(
          showSuccessSnackbar(
            "error",
            "Error while deleting admin. Please try again later",
            3000
          )
        );
      }
    }
  };
};
