import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { TeamManagementActions } from "../redux/teamManagementAction";
import { updateTeamAsync } from '../redux/teammanagementApi';
import {Constants} from '../../../../constants/constants';

const EditTeam = ({ editTeam, setEditTeam }) => {
  //formik validation schema
  const editTeamSchema = Yup.object().shape({
    teamImage: Yup.string()
      .required("Please enter Team Image"),
  })

  const [file, setFile] = useState({ file: null, url: process.env.REACT_APP_NO_IMG });

  const { refreshTeamList, search, skip, limit, selectedTeam, teamList, isLoading, reDirect, isEditLoading } = useSelector(
    (state) => state.teamManagement,
    shallowEqual
  );

  const dispatch = useDispatch();
  //after hide team
  const onHideTeam = () => {
    setFile({ file: null, url: null })
    setEditTeam(false);
  }
  // save upload image details
  const uploadImage = (e, setFieldValue) => {
    setFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    setFieldValue("teamImage", e.currentTarget.files[0])
  };
  //close edit model
  useEffect(() => {

    if (reDirect === true) {
      setFile({ file: null, url: null });
      dispatch(TeamManagementActions.refreshTeamList());//refresh details
      setEditTeam(false);
    }
  }, [reDirect])

  return (
    <>
      <Modal
        show={editTeam}
        onHide={() => onHideTeam()}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Edit Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              teamImage: selectedTeam && selectedTeam.teamLogo || ""
            }}
            validationSchema={editTeamSchema}
            onSubmit={values => {
              const formData = new FormData();
              formData.append('image', values.teamImage);
              dispatch(updateTeamAsync(formData, selectedTeam._id))
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleSubmit
            }) => (

              <Form onSubmit={handleSubmit}>

                <div className="form-group">
                  <label className="form-label text-white font-size-12px inter-font font-weight-normal">Team Image</label>
                  <div
                    className="pro_img text-center"
                    style={{
                      backgroundImage: `url(${file.url || selectedTeam.teamLogo})`
                    }}
                  >
                    <input
                      type="file"
                      name="teamImage"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => uploadImage(e, setFieldValue)}
                    />
                    <div className="edt_img_btn">

                      <div className="upload-pic-btn"><span className="material-icons">upload</span>Upload</div>

                    </div>
                  </div>
                  {touched.teamImage && errors.teamImage ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.teamImage}
                      </div>
                    </div>
                  ) : null}
                </div>

                <label className="text-white font-size-16px inter-font font-weight-normal">Suggestion for best UI Experience: </label>
                <p><b>Image Dimensions: <span className="text-danger">{Constants.ImageConstants.TEAM_DIMENSIONS}</span></b></p>
                <p>Image Compression Tool: <span><a href="https://imagecompressor.com/" target="_blank" className="text-info">https://imagecompressor.com/</a></span></p>

                <div className="form-group  mb-0 mt-4">
                  <div className="form-group mr-5 mb-1 mt-2">
                    {isEditLoading ? (
                      <div className="ml-3 basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-blue spinnerBtn add-avtar-btn"
                        disabled={isEditLoading}
                      >
                        <span>Update</span>

                      </button>
                    )}
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditTeam;

