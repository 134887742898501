import { DashboardMap } from './dashboardAction';

const initState = {
    isLoading: false,
    userCountData: {},
    userList: {}, 
    betList: {},
    matchList: {},
    isUserLoading: false,
    isBetsLoading: false,
    isMatchesLoading: false,
    skip: 0,
    limit: 20,
    search: '',
    refreshEventList: false,
    tagUSers: {},
    blockUserList: {}
}

export function reducer(state = initState, action) {
    switch (action.type) {
        case DashboardMap.GET_USER_COUNT_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case DashboardMap.GET_USER_COUNT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                userCountData: action.payload
            }
        }
        case DashboardMap.GET_USER_COUNT_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case DashboardMap.GET_ALL_USER_START: {
            return {
                ...state,
                isUserLoading: true,
            }
        }
        case DashboardMap.GET_ALL_USER_SUCCESS: {
            return {
                ...state,
                isUserLoading: false,
                userList: action.payload
            }
        }
        case DashboardMap.GET_ALL_USER_ERROR: {
            return {
                ...state,
                isUserLoading: false,
            }
        }

        case DashboardMap.GET_ALL_BETS_START: {
            return {
                ...state,
                isBetsLoading: true,
            }
        }
        case DashboardMap.GET_ALL_BETS_SUCCESS: {
            
            return {
                ...state,
                isBetsLoading: false,
                betList: action.payload
            }
        }
        case DashboardMap.GET_ALL_BETS_ERROR: {
            return {
                ...state,
                isBetsLoading: false,
            }
        }

        case DashboardMap.GET_ALL_MATCHES_START: {
            return {
                ...state,
                isMatchesLoading: true,
            }
        }
        case DashboardMap.GET_ALL_MATCHES_SUCCESS: {
           
            return {
                ...state,
                isMatchesLoading: false,
                matchList: action.payload,
                refreshEventList: false
            }
        }
        case DashboardMap.GET_ALL_MATCHES_ERROR: {
            return {
                ...state,
                isMatchesLoading: false,
            }
        }

        case DashboardMap.SET_EVENT_BATCH_NUMBER: {
            return {
                ...state,
                skip: action.payload,
            }
        }

        case DashboardMap.SET_EVENTSEARCH_CHANGE: {
            return {
                ...state,
                search: action.payload
            }
        }

        case DashboardMap.REFRESH_EVENT_LIST: {
            return {
                ...state,
                skip: 0,
                refreshEventList: true,
            }
        }

        case DashboardMap.GET_TAG_START: {
            return {
              ...state,
              isLoading: true,
            };
        }
        case DashboardMap.GET_TAG_SUCCESS: {
            return {
              ...state,
              isLoading: false,
              tagUsers: action.payload.data,
            };
        }
        case DashboardMap.GET_TAG_ERROR: {
            return {
              ...state,
              isLoading: false,
            };
        }

        case DashboardMap.REFRESH_TAG_LIST: {
            return {
                ...state,
                tagusers: {}
            }
        }

        case DashboardMap.GET_BLOCK_USER_LIST_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case DashboardMap.GET_BLOCK_USER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                blockUserList: action.payload
            }
        }
        case DashboardMap.GET_BLOCK_USER_LIST_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }

        default: {
            return {
                ...state
            }
        }
    }
}