import axios from "axios";
import { NotificationManagementActions } from "./notificationMangementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};
//get all notification api
export const getAllNotificationAsync = (searchText, skip, limit) => {
  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL;
      
      dispatch(NotificationManagementActions.getAllNotificationStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllNotification`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {search: searchText, skip, limit}
      });
      
      if (data.statusCode === 200) {
        return dispatch(
          NotificationManagementActions.getAllNotificationSuccess(
            data
          )
        );
      }
      dispatch(NotificationManagementActions.getAllNotificationError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(NotificationManagementActions.getAllNotificationError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//get all email template api
export const getAllEmailTemplatesAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(NotificationManagementActions.getNotificationTemplateStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllTemplateEntities/${_id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        return dispatch(
          NotificationManagementActions.getNotificationTemplateSuccess(
            data.responseData
          )
        );
      }
      dispatch(NotificationManagementActions.getNotificationTemplateError());

      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(NotificationManagementActions.getNotificationTemplateError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//add notification api
export const addNotificationsync = (
  values,
) => {
  return async (dispatch, getState) => {
    try {
      
      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL;

      let { _id } = getState().auth.user;
      // console.log("id", _id );
      dispatch(NotificationManagementActions.addNotificationStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/addNotification/${_id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.statusCode === 201) {
        dispatch(
          NotificationManagementActions.addNotificationSuccess(
            data
          )
        );
        return dispatch(
          showSuccessSnackbar("success", "Notification sended successfully", 3000)
        );
      }
      dispatch(NotificationManagementActions.addNotificationError());
      return dispatch(showSuccessSnackbar("error", "Error while sending notification", 3000));
    } catch (error) {
      dispatch(NotificationManagementActions.addNotificationError(error));
      dispatch(showSuccessSnackbar("error", "Error while sending notification", 3000));
    }
  };
};
//update notification api
export const updateNotficationAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL;
      
      dispatch(NotificationManagementActions.updateNotificationStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/duplicateNotification/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
       
      if (data.statusCode === 201) {
        dispatch(
          NotificationManagementActions.updateNotificationSuccess(
            data
          )
        );
        return dispatch(
          showSuccessSnackbar("success", "Notification sended successfully", 3000)
        );
      }
      dispatch(NotificationManagementActions.updateNotificationError());

      return dispatch(showSuccessSnackbar("error", "Error while sending notification", 3000));
    } catch (error) {
      dispatch(NotificationManagementActions.updateNotificationError(error));
    
      dispatch(showSuccessSnackbar("error", "Error while sending notification", 3000));
    }
  };
};
//delete notification api
export const deleteNotificationAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL;
      let { _id } = getState().auth.user;
      dispatch(NotificationManagementActions.deleteNotificationStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/deleteNotification/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
       
      if (data.statusCode === 202) {
        dispatch(
          NotificationManagementActions.deleteNotificationSuccess(
            data
          )
        );
        return dispatch(
          showSuccessSnackbar("success", data.message, 3000)
        );
      }
      dispatch(NotificationManagementActions.deleteNotificationError());

      return dispatch(showSuccessSnackbar("error", "Error while deleting data", 3000));
    } catch (error) {
      dispatch(NotificationManagementActions.deleteNotificationError(error));
      dispatch(showSuccessSnackbar("error", "Error while deleting data", 3000));
    }
  };
};
