import React from "react";
//import { CKEditor } from "ckeditor4-react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateEmailAsync } from "../redux/emailManagementApi";
import {
  Card,
  CardHeader,
  CardBody,
} from "./../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import CustomEditor from "../../CustomEditor/CustomEditor";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditTemplate = ({ showEditTemplete, setShowEditTemplate }) => {
  const { selectedEmail, isLoading } = useSelector(
    (state) => state.emailManagement,
    shallowEqual
  );

  const history = useHistory();
  const dispatch = useDispatch();
  //call after template data save to close model
  const redirectBack = () => {
    setShowEditTemplate(false);
  };
  //formik validation schema
  const addEmailSchema = Yup.object().shape({
    mailTitle: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Email Title"
      )
      .required("Please enter Email title"),
    mailSubject: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Email subject"
      )
      .required("Please enter Email subject"),
    mailBody: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter valid Email body"
      )
      .required("Please enter Email body"),
  });
  return (
    <>
      <Modal
        show={showEditTemplete}
        onHide={() => setShowEditTemplate(false)}
        centered
        className="def_modal delete_modal"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Edit Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={selectedEmail}
            validationSchema={addEmailSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              dispatch(
                updateEmailAsync(values, setSubmitting, resetForm, redirectBack)
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Row className="justify-content-center">
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form>
                        <div className="form-group">
                          <label className="form-label text-white font-size-12px inter-font font-weight-normal">Title</label>
                          <input
                            placeholder="Enter Title"
                            type="text"
                            name="mailTitle"
                            disabled={true}
                            className={
                              errors.mailTitle && touched.mailTitle
                                ? "form-control re_inputRouded is-invalid border-less-input"
                                : "form-control re_inputRouded border-less-input"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailTitle}
                          />
                          {touched.mailTitle && errors.mailTitle ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.mailTitle}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label className="form-label text-white font-size-12px inter-font font-weight-normal">Subject</label>
                          <input
                            placeholder="Enter Subject"
                            type="text"
                            name="mailSubject"
                            className={
                              errors.mailSubject && touched.mailSubject
                                ? "form-control re_inputRouded is-invalid border-less-input"
                                : "form-control re_inputRouded border-less-input"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailSubject}
                          />
                          {touched.mailSubject && errors.mailSubject ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.mailSubject}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label className="form-label text-white font-size-12px inter-font font-weight-normal">Email Body</label>

                          <CKEditor
                            data={values.mailBody}
                            editor={ClassicEditor}
                            config={{
                              removePlugins: ['MediaEmbed', "EasyImage", "ImageUpload"]
                            }}

                            className={
                              errors.mailBody && touched.mailBody
                                ? "form-control re_inputRouded is-invalid"
                                : "form-control re_inputRouded"
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              // Set field touched value
                              setFieldTouched("mailBody", true);

                              // Set field value
                              setFieldValue("mailBody", data);
                            }}
                          />

                          {touched.mailBody && errors.mailBody ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.mailBody}</div>
                            </div>
                          ) : null}
                        </div>
                        {isSubmitting ? (
                          <div className="basic-verification-loader text-center">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="text-center">
                            <Button
                              variant="blue"
                              className="px-5 defpddng  btn btn-blue spinnerBtn add-avtar-btn d-flex justify-content-center"
                              size="lg"
                              type="submit"
                            >
                              Update
                            </Button>
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditTemplate;
