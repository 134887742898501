import axios from "axios";
import { ServicesManagementActions } from "./servicesAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

//add category api
export const addCategoryAsync = (category) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL
  
      dispatch(ServicesManagementActions.addCategoryStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/addCategory`,
        data: category,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.addCategorySuccess(data.data));

        return dispatch(
          showSuccessSnackbar("success", "Category added successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.addCategoryError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}
//get category api
export const getCategoryAsync = (category) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL

      dispatch(ServicesManagementActions.getCategoryStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/getCategory`,
        data: category,
        headers: {
          "Content-Type": "application/json",
        },
      });
    
      if (data.statusCode === 200) {
        
        dispatch(ServicesManagementActions.getCategorySuccess(data));

      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.getCategoryError(error.data.message));

      }
    }
  }
}
//add sub category api
export const addSubCategoryAsync = (subcategory) => {
  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL

      dispatch(ServicesManagementActions.addSubCategoryStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/addSubcategory`,
        data: subcategory,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.addSubCategorySuccess());
        return dispatch(
          showSuccessSnackbar("success", "Subcategory added successfully", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.addSubCategoryError(error.data.message));

        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}
//get sub category api
export const getSubCategoryAsync = (category) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL
    
      dispatch(ServicesManagementActions.getSubCategoryStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/getSubcategory`,
        data: category,
        headers: {
          "Content-Type": "application/json",
        },
      });

      
      if (data.statusCode === 200) {
        
        dispatch(ServicesManagementActions.getSubCategorySuccess(data));
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.getSubCategoryError(error.data.message));

      }
    }
  }
}
//edit category api
export const editCategoryAsync = (category, categoryId) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL
  
      dispatch(ServicesManagementActions.editCategoryStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/editCategory/${categoryId}`,
        data: category,
        headers: {
          "Content-Type": "application/json",
        },
      });
        
      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.editCategorySuccess(data));
        return dispatch(
          showSuccessSnackbar("success", "Category edited successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.editCategoryError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}
//edit sub category api
export const editSubCategoryAsync = (category, categoryId) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL
  
      dispatch(ServicesManagementActions.editSubCategoryStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/editSubCategory/${categoryId}`,
        data: category,
        headers: {
          "Content-Type": "application/json",
        },
      });
        
      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.editSubCategorySuccess(data.data));
        return dispatch(
          showSuccessSnackbar("success", "SubCategory edited successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.editSubCategoryError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}

//delete category api
export const deleteCategoryAsync = (categoryId) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL
  
      dispatch(ServicesManagementActions.deleteCategoryStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/deleteCategory/${categoryId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
        
      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.deleteCategorySuccess(data.data));
        return dispatch(
          showSuccessSnackbar("success", "Category deleted successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.deleteCategoryError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}
//delete sub category api
export const deleteSubCategoryAsync = (categoryId) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL
  
      dispatch(ServicesManagementActions.deleteSubCategoryStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/deleteSubCategory/${categoryId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
        
      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.deleteSubCategorySuccess(data.data));
        return dispatch(
          showSuccessSnackbar("success", "SubCategory deleted successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.deleteSubCategoryError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}
//add live feed api
export const addLiveFeedAsync = (liveFeed) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL
  
      dispatch(ServicesManagementActions.addLiveFeedStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/addFeed`,
        data: liveFeed,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data.statusCode === 201) {
        dispatch(ServicesManagementActions.addLiveFeedSuccess(data.data));

        return dispatch(
          showSuccessSnackbar("success", "Feed added successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.addLiveFeedError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}
//get live feed api
export const getLiveFeedAsync = (liveFeed) => {
  
  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL

      dispatch(ServicesManagementActions.getLiveFeedStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllFeeds`,
        data: liveFeed,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.getLiveFeedSuccess(data));

      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.getLiveFeedError(error.data.message));

      }
    }
  }
}
//edit lve feed api
export const editLiveFeedAsync = (liveFeedId, liveFeed ) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL
  
      dispatch(ServicesManagementActions.editLiveFeedStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/editFeed/${liveFeedId}`,
        data: liveFeed,
        headers: {
          "Content-Type": "application/json",
        },
      });
        
      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.editLiveFeedSuccess(data.data));
        return dispatch(
          showSuccessSnackbar("success", "Feed edited successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.editLiveFeedError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}

//delete live feed api
export const deleteLiveFeedAsync = (feedId) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL
  
      dispatch(ServicesManagementActions.deleteLiveFeedStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/deleteFeed/${feedId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
        
      if (data.statusCode === 202) {
        dispatch(ServicesManagementActions.deleteLiveFeedSuccess(data.data));
        return dispatch(
          showSuccessSnackbar("success", "Category deleted successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.deleteLiveFeedError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}
//get all sub category api
export const getAllSubCategoryAsync = (categoryId) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL

      dispatch(ServicesManagementActions.getAllSubcategoryStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/getAllSubCategory/${categoryId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.getAllSubcategorySuccess(data.data));

      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.getAllSubcategoryError(error.data.message));

      }
    }
  }
}
//get all league api
export const getAllLeagueAsync = (values) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL

      dispatch(ServicesManagementActions.getLeagueStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/league/getLeagueList`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values
      });

      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.getLeagueSuccess(data.data));

      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.getLeagueError(error.data.message));

      }
    }
  }
}
//get all match api
export const getAllMatchesAsync = (values) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL

      dispatch(ServicesManagementActions.getAllMatchesStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllMatches`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values
      });
      // console.log("data", data);
      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.getAllMatchesSuccess(data.data));

      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.getAllMatchesError(error.data.message));

      }
    }
  }
}
//edit category api
export const editCategoryActionAsync = (category, categoryId) => {

  return async (dispatch, getState) => {
    try {

      const adminBaseURL = process.env.REACT_APP_BASE_URL
  
      dispatch(ServicesManagementActions.editCategoryActionStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/category/editCategory/${categoryId}`,
        data: category,
        headers: {
          "Content-Type": "application/json",
        },
      });
        
      if (data.statusCode === 200) {
        dispatch(ServicesManagementActions.editCategoryActionSuccess(data));
        return dispatch(
          showSuccessSnackbar("success", "Category Action Updated successfully ", 3000)
        );
      }

    } catch (error) {
      // console.log("error", error);
      if (Object.keys(error.data).length > 0) {
        dispatch(ServicesManagementActions.editCategoryActionError(error.data.message));
  
        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      }
    }
  }
}
