import React, { useState, useEffect , useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table, Modal } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { betManagementAction } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { deleteBetAsync ,cancelBetByAdminAsync} from '../../redux/betManagementApi';
import { getUserBetResultAsync, getUserJuryVotesAsync } from '../../redux/betManagementApi';
import { BetManagementActions } from '../../redux/betManagementAction';
import { Web3Context } from "../../../../../web3/contexts/web3Context";
import { poolMethods } from '../../../../../web3/functions/factory'

const BetManagementTable = ({ }) => {

  const { networkDetails, handleConnect } = useContext(Web3Context);
  const [getInstance, setInstance] = useState();
  const { isLoading, refreshBetList, betList, search, skip, limit, sortBy, sortType} = useSelector(
    (state) => state.betManagement,
    shallowEqual
  );
  
  const history = useHistory();
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);
  const dispatch = useDispatch();
  const [deleteBet, setDeleteBet] = useState({});

  const [CancelModal, setCancelModal] = useState(false);
  const cancelClose = () => setCancelModal(false);
  const cancelShow = () => setCancelModal(true);
  const [CancelBet, setCancelBet] = useState("");
  //on change page
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(BetManagementActions.setBetBatchNumber(count));
  };
  //show delete model and save delete bet id
  const onDeleteClick = async (bet) => { 
    setDeleteBet(bet);
    deleteShow();
  };

  const onUndoClick = (bet) => {

    const betDetails = { HiddenStatus: false, id: bet._id }

    dispatch(deleteBetAsync(betDetails))

  }
  //call delete api and close delete model
  const onDeactiveClick = async () => {
    const setBet = await poolMethods.setBanBet(getInstance, networkDetails.address, {bet_id: deleteBet.bet_id})
 
    if(setBet){
      const betDetails = { id: deleteBet._id , mode: "delete", bet_amount: deleteBet.bet_amount, bet_amount_usd: deleteBet.bet_amount_usd, userId: deleteBet.user_id}
      dispatch(deleteBetAsync(betDetails));
      deleteClose();
    }   
  };
  //redirect and show bet result details
  const DetailsShow = (bet) => {
    dispatch(BetManagementActions.getBetId(bet._id))
    history.push(`/bet-management/bet-result-details/${bet._id}`)
  };

  useEffect(() => {

    (async () => {
      const instance = await poolMethods.getInstanceAdmin(networkDetails.web3)
      // console.log({ instance })
      if (instance) {
        setInstance(instance);
      }
    })()
  }, [networkDetails.web3])
  //call delete bet api 
  const handleToggle = async (bet) => {
    const betDetails = { HiddenStatus: !bet.isHidden, id: bet._id , mode: "status"}
    dispatch(deleteBetAsync(betDetails));
  
  }
  //for sort column
  const handleSort = (column, dir) => {
    dispatch(BetManagementActions.setSortForBetManagement({ column, dir }));
  };
  //save cancel bet id and show cancel model
  const cancelBet = (bet_id) => {
    setCancelBet(bet_id)
    cancelShow()
  }
  //call cancel bet by admin api
  const onCancelClick = async () => {
    dispatch(cancelBetByAdminAsync(CancelBet))
    cancelClose()
  }

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Category Name</th>
                  <th>Sub Category Name</th>
                  <th>League Name</th>
                  <th>Coin name</th>
                  {/* <th>Bet ID</th> */}
                  <th
                    onClick={() =>
                      handleSort(
                        "bet_amount",
                        sortType === "" || sortType === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                  Challenge Amount{" "}
                    {sortType !== "" && sortBy === "bet_amount" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {sortType === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "bet_amount_usd",
                        sortType === "" || sortType === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                  Challenge Amount USD{" "}
                    {sortType !== "" && sortBy === "bet_amount_usd" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {sortType === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th>Challenge Question</th>
                  <th>Odds</th>
                  <th>Match</th>
                  <th>Public</th>
                  {/* <th>Status</th> */}
                  <th>Bet Status</th>
                  <th>Dispute Status</th>
                  <th width="170px">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  betList.bets && betList.bets.length ? (
                    betList.bets.map((bet, index) => (
                      <tr key={index} className={bet.disputes && Object.keys(bet.disputes).length > 0 ? (bet.disputes && bet.disputes[0].disputeStatus === 'unresolved' ? "bg-danger" : "bg-warning") : ""}>
                        <td>
                          <span className="f-w-500">
                            {skip * limit + (index + 1)}
                          </span>
                        </td>
                        <td>
                          <span className="f-w-500">{bet.categories && bet.categories.name}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{bet.subcategories && bet.subcategories.name}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{bet.leagues && bet.leagues.name}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{bet.tokentypes && bet.tokentypes.name}</span>
                        </td>
                        {/* <td>
                          <span className="f-w-500">{bet.bet_id}</span>
                        </td> */}
                        <td>
                          <span className="f-w-500">{bet.bet_amount}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{bet.bet_amount_usd}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{bet.betQuestion !== 'undefined' ? `${bet.betQuestion.substring(0, 25)}${bet.betQuestion.length > 25 ? '...' : ''}` : ""}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{bet.odds !== 'undefined' ? bet.odds : ""}</span>
                        </td>
                        <td>
                          <span className="f-w-500">{bet.matches && bet.matches.localTeamName && bet.matches.visitorTeamName ? bet.matches.localTeamName + " VS " + bet.matches.visitorTeamName : bet.matches && bet.matches.matchName}</span>
                        </td>

                        <td>
                          <span className="f-w-500">{bet.is_public ? "Yes" : "No"}</span>
                        </td>
                        {/* <td>
                          <button onClick={() => handleToggle(bet)}
                           className={`pmnt_btn ${bet.isHidden === true
                            ? "label label-lg label-light-danger label-inline"
                            : "label label-lg label-light-success label-inline"
                            }`}>
                            {
                              bet.isHidden === false ? "Active" : "InActive"
                            }
                          </button>
                        </td> */}
                        <td>
                          <span className="f-w-500">{bet.betStatus === 'PENDING' ? "IN PROGRESS" : bet.betStatus}</span>
                        </td>
                        <td>
                            <span className="f-w-500">{bet.disputes && Object.keys(bet.disputes).length > 0 ?    ((bet.disputes && bet.disputes[0].disputeStatus.toUpperCase()) + (bet.disputes && bet.disputes.length ? " / " + bet.disputes[0].disputeResolver : "")) : ""}</span>
                        </td>

                        <td>
                        <a
                            title="View"
                            className="btn btn-icon btn-light btn-hover-success btn-sm"
                            onClick={() => DetailsShow(bet)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/custom/eye.svg"
                                )}
                              />
                            </span>
                          </a>
                    
                          {bet.isCancel &&
                            (<a
                            title="Cancel"
                            className="ml-1 btn btn-icon btn-light text-danger btn-sm"
                            onClick={() => cancelBet(bet._id)}
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/multiply.svg"
                                )}
                              />
                            </span>
                          </a>)}
                          {
                            bet.isHidden === false && !(bet.bettaker.length > 0) && !(bet.betUserCount > 0) && (
                              

                              <a
                                title="Delete"
                                className="btn btn-icon btn-light btn-hover-danger btn-sm ml-1"
                                onClick={() => onDeleteClick(bet)}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Trash.svg"
                                    )}
                                  />
                                </span>
                              </a>
                            ) 
                          }

                          
                        </td>

                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  )
                }
                {isLoading && (
                  <tr>
                    <td colSpan={12} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
            <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {betList.betCount} Records
            </div>
            <div className="col-md-8 text-left mb-5 aspgntn">
              <BasicPagination className="justify-content-end"
                totalRecords={betList.betCount}
                limit={limit}
                batch={skip + 1}
                onBatchChange={onPageChange}
              />
            </div>

          </div>

        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want to delete?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={deleteClose}
                >
                  Close
                </button>
              </div>
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={onDeactiveClick}
                >
                  Yes, Delete It
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={CancelModal}
        onHide={cancelClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want to cancel?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={cancelClose}
                >
                  Close
                </button>
              </div>
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={onCancelClick}
                >
                  Yes, Cancel It
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BetManagementTable;
