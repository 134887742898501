import axios from "axios";
import { FeeActions } from "./feeAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

//get fee api
export const getFeeAsync = (searchText) => {
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        
        dispatch(FeeActions.editFeeStart());
        const { data } = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/fee/getAllFee`,
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (data.statusCode === 200) {
          return dispatch(
            FeeActions.editFeeSuccess(data.data)
          );
        }
        dispatch(FeeActions.editFeeError());
      } catch (error) {
        dispatch(FeeActions.editFeeError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
  };
  //update fee api
  export const updateFeeAsync = (updatedValues) => {
      // console.log("updatedValues, id",);
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        dispatch(FeeActions.updateFeeStart());
        const { data } = await axios({
          method: "PUT",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/fee/updateFee`,
          headers: {
            "Content-Type": "application/json",
          },
          data: updatedValues
        });
  
        if (data.statusCode === 200) {
          dispatch(FeeActions.updateFeeSuccess(data.data));
          return dispatch(
            showSuccessSnackbar("success", "Fee Updated successfully", 3000)
          );
        }
        dispatch(FeeActions.updateFeeError());
      } catch (error) {
        dispatch(FeeActions.updateFeeError());
        dispatch(
          showSuccessSnackbar(
            "error",
            "Error while updating fee. Please try again later",
            3000
          )
        );
      }
    };
  };
//update affiliate fee api
export const updateAffiliateFeeAsync = (updatedValues) => {

  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      dispatch(FeeActions.updateAffiliateRewardStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/fee/updateAffiliateReward`,
        headers: {
          "Content-Type": "application/json",
        },
        data: updatedValues
      });

      if (updatedValues !== undefined && data.statusCode === 200) {
        dispatch(FeeActions.updateAffiliateRewardSuccess(data.data));
        return dispatch(
          showSuccessSnackbar("success", "Affiliate Reward Percentage Updated successfully", 3000)
        );
      }
      if (updatedValues === undefined && data.statusCode === 200) {
        return data.data[0]
      }
      dispatch(FeeActions.updateAffiliateRewardError());
    } catch (error) {
      dispatch(FeeActions.updateAffiliateRewardError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while update Affiliate Reward. Please try again later",
          3000
        )
      );
    }
  };
};
//update video api
export const updateVideoAsync = (updatedValues) => {

  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      dispatch(FeeActions.updateVideoStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/fee/updateVideo`,
        headers: {
          "Content-Type": "application/json",
        },
        data: updatedValues
      });

      if (updatedValues !== undefined && data.statusCode === 200) {
        dispatch(FeeActions.updateVideoSuccess(data.data));
        return dispatch(
          showSuccessSnackbar("success", "Video Visibal Time Updated successfully", 3000)
        );
      }
      if (updatedValues === undefined && data.statusCode === 200) {
        return data.data[0]?.Video
      }
      dispatch(FeeActions.updateVideoError());
    } catch (error) {
      dispatch(FeeActions.updateVideoError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating fee. Please try again later",
          3000
        )
      );
    }
  };
};