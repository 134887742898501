export const FeeMap = {
  UPDATE_FEE_START: "UPDATE_FEE_START",
  UPDATE_FEE_SUCCESS: "UPDATE_FEE_SUCCESS",
  UPDATE_FEE_ERROR: "UPDATE_FEE_ERROR",

  EDIT_FEE_START: "EDIT_FEE_START",
  EDIT_FEE_SUCCESS: "EDIT_FEE_SUCCESS",
  EDIT_FEE_ERROR: "EDIT_FEE_ERROR",

  UPDATE_AFFILIATE_REWARD_START: "UPDATE_AFFILIATE_REWARD_START",
  UPDATE_AFFILIATE_REWARD_SUCCESS: "UPDATE_AFFILIATE_REWARD_SUCCESS",
  UPDATE_AFFILIATE_REWARD_ERROR: "UPDATE_AFFILIATE_REWARD_ERROR",

  UPDATE_VIDEO_START: "UPDATE_VIDEO_START",
  UPDATE_VIDEO_SUCCESS: "UPDATE_VIDEO_SUCCESS",
  UPDATE_VIDEO_ERROR: "UPDATE_VIDEO_ERROR",

};

export const FeeActions = {
  updateFeeStart: (data) => ({
    type: FeeMap.UPDATE_FEE_START,
    payload: data,
  }),
  updateFeeSuccess: (data) => ({
    type: FeeMap.UPDATE_FEE_SUCCESS,
    payload: data,
  }),
  updateFeeError: (errors) => ({
    type: FeeMap.UPDATE_FEE_ERROR,
    payload: { errors },
  }),

  editFeeStart: (data) => ({
    type: FeeMap.EDIT_FEE_START,
    payload: data,
  }),
  editFeeSuccess: (data) => ({
    type: FeeMap.EDIT_FEE_SUCCESS,
    payload: data,
  }),
  editFeeError: (errors) => ({
    type: FeeMap.EDIT_FEE_ERROR,
    payload: { errors },
  }),

  updateAffiliateRewardStart: (data) => ({
    type: FeeMap.UPDATE_AFFILIATE_REWARD_START,
    payload: data,
  }),
  updateAffiliateRewardSuccess: (data) => ({
    type: FeeMap.UPDATE_AFFILIATE_REWARD_SUCCESS,
    payload: data,
  }),
  updateAffiliateRewardError: (errors) => ({
    type: FeeMap.UPDATE_AFFILIATE_REWARD_ERROR,
    payload: { errors },
    }),

  updateVideoStart: (data) => ({
    type: FeeMap.UPDATE_VIDEO_START,
    payload: data,
  }),
  updateVideoSuccess: (data) => ({
    type: FeeMap.UPDATE_VIDEO_SUCCESS,
    payload: data,
  }),
  updateVideoError: (errors) => ({
    type: FeeMap.UPDATE_VIDEO_ERROR,
    payload: { errors },
  }),
};
