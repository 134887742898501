import axios from "axios";
import { RewardManagementActions } from "./rewardManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
//get all reward api
export const getAllRewardAsync = (skip, limit, search, activityType) => {
    return async (dispatch, getState) => {
        try {
          const adminBaseURL = process.env.REACT_APP_BASE_URL;
          
          dispatch(RewardManagementActions.getRewardListStart());
          const { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_HOST}${adminBaseURL}/reward/getReward`,
            headers: {
              "Content-Type": "application/json",
            },
            data: {skip, limit, search, activityType}
          });
          if (data.statusCode === 200) {
            return dispatch(
              RewardManagementActions.getRewardListSuccess(data.data)
            );
          }
          dispatch(RewardManagementActions.getRewardListError());
        } catch (error) {
          dispatch(RewardManagementActions.getRewardListError());
          dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
        }
      };
};
//update all reward api
export const updateAllRewardAsync = (allRewardList) => {
  return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        
        dispatch(RewardManagementActions.updateRewardListStart());
        const { data } = await axios({
          method: "PUT",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/reward/updateUserReward`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {rewardList: allRewardList}
        });
        if (data.statusCode === 200) {
          dispatch(
            RewardManagementActions.updateRewardListSuccess(data.data)
          );
          return dispatch(
            showSuccessSnackbar("success", "User Withdrawal Successfully.", 3000)
          )
        }
        dispatch(RewardManagementActions.updateRewardListError());
      } catch (error) {
        dispatch(RewardManagementActions.updateRewardListError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
};
