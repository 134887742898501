import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AdminManagementActions } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { deleteAdminAsync ,updateAdminAsync} from "../../redux";

const AdminManagementTable = ({ onUserDetailsClick }) => {
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const [selectedID, setSelectedID] = useState();

  const dispatch = useDispatch();

  const { isLoading, adminList, skip, limit, dir, searchBy, totalAdminRecords, role } = useSelector(
    (state) => state.adminManagement,
    shallowEqual
  );
  //save admin id and show delete model
  const onDeleteClick = (id) => {
    setSelectedID(id);
    deleteShow();
  };
  //call delete api and close delete model
  const onDeactiveClick = () => {
    dispatch(deleteAdminAsync(selectedID._id));
    setSelectedID({});
    deleteClose();
  };

  const onActivateClick = (adminList) => {
    dispatch(deleteAdminAsync(adminList._id));
  };
  //on page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(AdminManagementActions.setUserBatchNumber(count));
  };
  //for sort column 
  const handleSort = (column, dir) => {
    dispatch(AdminManagementActions.setSort({ column, dir }));
  };
  //change status of admin and call update admin api
  const handleToggle = (isActive, adminLists_id) => {
    const obj = { 
      "isActive" : isActive
    }
    dispatch(updateAdminAsync( obj, adminLists_id));
  }

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">

      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              id="myTable"
              className="mb-0 default_table with_hoverbtn mrpdng_tbl "
            >
              <thead>
                <tr>
                  <th>Sr.no</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "firstName",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    First Name{" "}
                    {dir !== "" && searchBy === "firstName" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "lastName",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Last Name{" "}
                    {dir !== "" && searchBy === "lastName" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th>Email</th>
                  <th>Wallet Adrees</th>
                  {role === 1 ? <th>Status</th> : null}
                  {user.role == 1 && <th className="wd-120">Actions</th>}
                </tr>
              </thead>
              <tbody>
                {adminList && adminList.data && adminList.data.user && adminList.data.user.length ? (
                  adminList.data.user.map((adminLists, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="f-w-500">
                            {skip * limit + (index + 1)}
                          </span>
                        </td>
                        <td>
                          <span className="f-w-500">{adminLists.firstName}</span>
                        </td>
                        <td>{adminLists.lastName}</td>
                        <td>{adminLists.email}</td>
                        <td>{adminLists.walletAddress}</td>

                        {role === 1 ? 
                          <td>
                          <button 
                            value= { adminLists && adminLists.isActive }
                            onClick={() => handleToggle(adminLists.isActive, adminLists._id)} className={`pmnt_btn ${adminLists && adminLists.isActive
                            ? "label label-lg label-light-success label-inline"
                            : "label label-lg label-light-danger label-inline"
                            }`}>
                            {adminLists && adminLists.isActive ? "ACTIVE" : "INACTIVE"}
                          </button>
                        </td> : null}

                        {user.role == 1 && <td>
                          <a
                            title="Edit"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                            onClick={() => onUserDetailsClick(adminLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </a>

                          <a
                            title="Delete"
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => onDeleteClick(adminLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                          </a>
                        </td>}

                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center p-0 border-0">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {adminList.recordsTotal} Records
              </div>
              <div className="col-md-8 text-left mb-5 aspgntn">
                <BasicPagination
                  totalRecords={totalAdminRecords}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>

            </div>
          
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want to delete?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={deleteClose}
                >
                  Close
                </button>
              </div>
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={onDeactiveClick}
                >
                  Yes, Delete it
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminManagementTable;
