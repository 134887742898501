import StateChart from './StateChart';
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { getReportsAndAnalyticsAsync, getCategoryEvolutionAsync, getMostLiveEventAsync, getMostPopularEventsAsync } from '../redux/ReportsAndAnalyticsApi';
import CircularProgress from "@material-ui/core/CircularProgress";
import EvolutionChart from "./EvolutionChart";
import Select from "react-select";
import { getCategoryAsync } from '../../services/redux/servicesApi';
import { toTimestamp, toTimestampDate } from '../../../utils/appUtils';

const ReportsAndAnaytics = () => {

  const dispatch = useDispatch();

  const { categoryChart, subCategoryChart, isLoading, isCatEvoLoding, isPopularLoading, isLiveLoding, popularEvents, liveEvents } = useSelector(
    (state) => state.reportsAndAnaytics,
    shallowEqual
  );
  
  const [liveEventLimit, setLiveEventLimit] = useState(10);
  const [popularEventSkip, setPopularEventSkip] = useState(10);

  const [index, setIndex] = useState(10);

  const showLiveEventsMore = () => {
    const newIndex = index + liveEventLimit;
    setLiveEventLimit(newIndex);
  }
  //for show more popular events
  const showPopularEventsMore = () => {
    const newIndex = index + popularEventSkip;
    setPopularEventSkip(newIndex);
  }
  const { category } = useSelector((state) => state.servicesManagement, shallowEqual);

  const [categoryOptions, setCategoryOptions] = useState([])
  const [categoryValue, setCategoryValue] = useState({ value: "", label: "" });
  //call apis
  useEffect(() => {
    dispatch(getReportsAndAnalyticsAsync());//call get reports and analytics api
    dispatch(getCategoryEvolutionAsync());//call get all category evolution api
    dispatch(getCategoryAsync({"live": true}));//call get category api
  }, []);

  useEffect(() => {
    dispatch(getMostLiveEventAsync({ limit: liveEventLimit, skip: 0 }))//call get most live events api
  }, [liveEventLimit]);

  useEffect(() => {
    dispatch(getMostPopularEventsAsync({ limit: popularEventSkip, skip: 0 }))//call get most popular events api
  }, [popularEventSkip]);
  //save id and name in array
  useEffect(() => {
    let catArr = []
    category && category?.category && category.category.length && category.category.map((cd) => {
      catArr.push({
        value: cd._id,
        label: cd.name
      })
    })
    setCategoryOptions(catArr)
  }, [category && category?.category?.length])
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <div className="d-flex justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
            <div>
              {isLoading ? (
                <div colSpan={8} className="text-center p-0 border-0">
                  <div className="basic-verification-loader text-center">
                    <CircularProgress />
                  </div>
                </div>
              ) : <StateChart title="categories" values={categoryChart} />}

              {isLoading ? (
                <div colSpan={8} className="text-center p-0 border-0">
                  <div className="basic-verification-loader text-center">
                    <CircularProgress />
                  </div>
                </div>
              ) : <StateChart title="Subcategories" values={subCategoryChart} />}

              {isCatEvoLoding ? (
                <div colSpan={8} className="text-center p-0 border-0">
                  <div className="basic-verification-loader text-center">
                    <CircularProgress />
                  </div>
                </div>
              ) : <EvolutionChart />}
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xxl-4">
          <div className="justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
            <div className='viral-events-dropdown'>
              <div>
                <h1>Viral Events</h1>
              </div>
              <div>
                <Select
                  value={{
                    value: categoryValue.value,
                    label: categoryValue.label,
                  }}

                  placeholder="Select Category"
                  className={`form-control border-0 p-0 `}
                  classNamePrefix="phoSelect"
                  options={categoryOptions}
                  onChange={(e) => {
                    setCategoryValue({
                      value: e.value,
                      label: e.label
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xxl-4 order-1 ">
          <div className="d-flex justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
            <div>
              <div>
                <h1>Top-grossing Challenge Makers</h1>
                <h3 className='text-white font-weight-normal font-size-18px'>(fees generated)</h3>
              </div>
              <div className="d-flex justify-content-around text-left px-2 py-2 rounded-xl mb-2 ">
                <img style={{ height: '5%', width: '5%' }} alt='image' />
                <h5 className='font-size-10px text-white'>kinnari</h5>
                <h4 className='font-size-10px text-white'>2000 ETH</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xxl-4 order-xxl-1 ">
          <div className="d-flex justify-content-around text-center bg-dark  px-6 py-8 rounded-xl mb-7">
            <div>
              <div>
                <h1>Most Viewed live Events</h1>
              </div>
              {
                liveEvents && liveEvents.liveEvents && liveEvents.liveEvents.map((matches, index) => (
                    <div className=" bg-secondary px-6 py-8 rounded-xl mb-7 position-relative opacity-85 text-light" style={{ backgroundImage: `url(${matches.image})` }} key={index}>
                      <div className="text-left font-size-h4 mt-2 font-weight-bold">
                        <p className='font-weight-bold font-size-10px  opacity-color text-uppercase mb-1'>{toTimestamp(matches.start_date_time)}</p>

                        <p className='font-weight-bold font-size-14px  opacity-color text-uppercase mb-1'>{matches.matches.localTeamName} VS {matches.matches.visitorTeamName}</p>
                      </div>
                      <div className="justify-content-around font-size-h6 px-2 py-2 rounded-xl mb-2 font-weight-bold">
                        <p className='text-left font-weight-bold font-size-10px  opacity-color text-uppercase mb-1'>Football - {matches.matches.leagueName}</p>
                      </div>
                    </div>
                ))
              }
              {
                liveEventLimit < liveEvents.liveEventsCount ?
                  <button className="btn btn-blue spinnerBtn btn_new" onClick={showLiveEventsMore}>Show More</button> : null
              }
              {isLiveLoding ? (
                <div colSpan={8} className="text-center p-0 border-0">
                  <div className="basic-verification-loader text-center">
                    <CircularProgress />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <div className="d-flex justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
            <div>
              <div>
                <h1>Most Popular Events</h1>
              </div>
              {
                popularEvents && popularEvents.popularEvents && popularEvents.popularEvents.map((events, index) => (
                    <div className='justify-content-around bg-secondary px-3 py-3 rounded-xl link-bg-color marg-top-30px marg-bottom-30px' key={index}>
                      <p className='font-weight-bold font-size-10px  opacity-color text-uppercase mb-1 text-left '>{events.formatted_date} - {toTimestampDate(events.match_date)}</p>
                      <h3 className='text-left font-weight-bold font-size-14px text-white text-white'>{events.leagueName}</h3>
                    </div>
                ))
              }
              {
                popularEventSkip < popularEvents.popularEventsCount ?
                  <button className="btn btn-blue spinnerBtn btn_new" onClick={showPopularEventsMore}>Show More</button> : null
              }
              {isPopularLoading ? (
                <div colSpan={8} className="text-center p-0 border-0">
                  <div className="basic-verification-loader text-center">
                    <CircularProgress />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportsAndAnaytics;