
import axios from 'axios';
import { DashboardActions } from './dashboardAction';
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
    return state.environnment.environmentLists.adminBaseURL;
}
//get total user count api
export const getTotalUserCountAsync = () => {
    return async (dispatch, getState) => {
        try {
            const adminBaseURL = getAdminURL(getState())
            dispatch(DashboardActions.getUserCountStart());
            let { _id } = getState().auth.user;
            const { data } = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_HOST}${adminBaseURL}/countForDashboard/${_id}`,
            });
            if (data.responseCode === 200) {
                return dispatch(DashboardActions.getUserCountSuccess(data.responseData));
            }
            return dispatch(DashboardActions.getUserCountError());
        } catch (error) {
            dispatch(DashboardActions.getUserCountError());
            return dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
        }
    }
}

//get all user api
export const getAllUserAsync = (user) => {

    return async (dispatch, getState) => {
        try {
            const adminBaseURL = process.env.REACT_APP_BASE_URL;
            dispatch(DashboardActions.getAllUserStart());
            let { _id } = getState().auth.user;
            const { data } = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_HOST}${adminBaseURL}/admin/getAllUsers`,
                data: user
            });
            if (data.statusCode === 200) {
                return dispatch(DashboardActions.getAllUserSuccess(data));
            }
            return dispatch(DashboardActions.getAllUserError());
        } catch (error) {
            dispatch(DashboardActions.getAllUserError());
            return dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
        }
    }
}

//get all bets api
export const getAllBetsAsync = (bets) => {
    return async (dispatch, getState) => {
        try {
            const adminBaseURL = process.env.REACT_APP_BASE_URL;
            dispatch(DashboardActions.getAllBetsStart());
            let { _id } = getState().auth.user;
            const { data } = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_HOST}${adminBaseURL}/admin/getAllBets`,
                data: bets
            });
            if (data.statusCode === 200) {
                return dispatch(DashboardActions.getAllBetsSuccess(data));
            }
            return dispatch(DashboardActions.getAllBetsError());
        } catch (error) {
            dispatch(DashboardActions.getAllBetsError());
            return dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
        }
    }
}
//get all matches api
export const getAllMatchesAsync = (matches) => {
    
    return async (dispatch, getState) => {
        try {
            const adminBaseURL = process.env.REACT_APP_BASE_URL;
            dispatch(DashboardActions.getAllMatchesStart());
            let { _id } = getState().auth.user;
            const { data } = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_HOST}${adminBaseURL}/admin/getAllMatches`,
                data: matches
            });
            if (data.statusCode === 200) {
                return dispatch(DashboardActions.getAllMatchesSuccess(data));
            }
            return dispatch(DashboardActions.getAllMatchesError());
        } catch (error) {
            dispatch(DashboardActions.getAllMatchesError());
            return dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
        }
    }
}
//get tags api
export const getTagsAsync = (matchId) => {
  
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
  
        dispatch(DashboardActions.getTagStart());
  
        const { data } = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/match/getTag`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {"matchId": matchId}
        });
        if (data.statusCode === 200) {
          return dispatch(
            DashboardActions.getTagSuccess(data)
          );
        }
        dispatch(DashboardActions.getTagError());
        
      } catch (error) {
  
        dispatch(DashboardActions.getTagError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
  };
//get block user list api
export const getBlockUsersListAsync = (values) => {
    
    return async (dispatch, getState) => {
        try {
            const adminBaseURL = process.env.REACT_APP_BASE_URL;
            dispatch(DashboardActions.getBlockUserListStart());
            let { _id } = getState().auth.user;
            const { data } = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_HOST}${adminBaseURL}/admin/getRequestCount`,
                data: values
            });
            if (data.statusCode === 200) {
                return dispatch(DashboardActions.getBlockUserListSuccess(data));
            }
            return dispatch(DashboardActions.getBlockUserListError());
        } catch (error) {
            dispatch(DashboardActions.getBlockUserListError());
            return dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
        }
    }
}
  

