/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Users.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/user-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/ppl.svg")} />
            </span>
            <span className="menu-text">Administrators</span>
          </NavLink>
        </li>

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/react-bootstrap", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/room_service.svg")} />
            </span>
            <span className="menu-text">Services</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Services</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/services/category"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/services/category">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Category Management</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/services/subcategory"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/services/subcategory">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Subcategory Management</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/services/live-feed"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/services/live-feed">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Live Feed Management</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/cms-pages", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/cms-pages">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/custom/cms.svg")} />
            </span>
            <span className="menu-text">CMS Pages</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/faq-pages", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/faq-pages">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/custom/information.svg")} />
            </span>
            <span className="menu-text">FAQ Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/email-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/email-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
              />
            </span>
            <span className="menu-text">Email Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/bet-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/bet-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Spam.svg")}
              />
            </span>
            <span className="menu-text">Challenge Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/reward-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/reward-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Spam.svg")}
              />
            </span>
            <span className="menu-text">Reward Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/react-bootstrap", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">User Management</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">User Management</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/user-management"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/user-management">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Users</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/avtar-management"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/avtar-management">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Avtar Management</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/top-user-management"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/top-user-management">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Top User Management</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/configuration",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/configuration">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/settings.svg")}
              />
            </span>
            <span className="menu-text">Configuration</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/payment",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/payment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/payment.svg")} />
            </span>
            <span className="menu-text">Payment</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/reports-and-analytics",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/reports-and-analytics">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Reports And Analytics</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/notification-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notification-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/custom/bell.svg")} />
            </span>
            <span className="menu-text">Notification Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/ticket-support-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ticket-support-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/custom/7.svg")} />
            </span>
            <span className="menu-text">Ticket/Support Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/team-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/team-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Team Management</span>
          </NavLink>
        </li>
        {/*  <li
          className="menu-item  menu-item-parent"
          aria-haspopup="true"
        >
          <span className="menu-link">
            <span className="menu-text">Services</span>
          </span>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/services/category/add-category",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/services/category/add-category">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text">Add category</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/email-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/email-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
              />
            </span>
            <span className="menu-text">Email Management</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/email-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/email-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
              />
            </span>
            <span className="menu-text">Email Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/notification-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notification-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/custom/bell.svg")} />
            </span>
            <span className="menu-text">Notification Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/default-password",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/default-password">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Default Password</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/cms-pages", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/cms-pages">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/custom/cms.svg")} />
            </span>
            <span className="menu-text">CMS Pages</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/faq-management", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/faq-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/custom/information.svg")}
              />
            </span>
            <span className="menu-text">FAQ Management</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/project-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/project-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text">Project Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/ticket-support-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ticket-support-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/custom/7.svg")} />
            </span>
            <span className="menu-text">Ticket/Support Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/master-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/master-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
              />
            </span>
            <span className="menu-text">Master Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/wtRequests",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/wtRequests">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
              />
            </span>
            <span className="menu-text">Work Task Requests</span>
          </NavLink>
        </li> */}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
