export const generateSlug = async (title) => {
    let slug = title.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/ /g, '-');
    return slug
}
//set date in dd-mm-yyyy hh-mm format
export function toTimestamp(strDate){
    var today = new Date(strDate);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    var hh = String(today.getHours()).padStart(2, '0');
    var minutes = String(today.getMinutes()).padStart(2, '0');
    const dateTime = dd + '/' + mm + '/' + yyyy +' ' + hh +':'+ minutes;
    return dateTime;
}
//set date in dd-mm-yyyy format
export function toTimestampDate(strDate){
    var today = new Date(strDate);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    const dateTime = dd + '.' + mm + '.' + yyyy ;
    return dateTime;
}


