import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserManagementActions } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { deleteUserAsync } from "../../redux";
import StateChart from './StateChart';
import { getUserStates ,updateJuryAdminWithdrawStrikeLevel, sendTokenHistoryAsync} from '../../redux/userManagementApi';

import { Web3Context } from "../../../../../web3/contexts/web3Context";
import { poolMethods } from '../../../../../web3/functions/factory';
import Web3 from "web3";
import moment from 'moment'
import { Formik, Form } from "formik";
import * as Yup from "yup";

const UserManagementTable = ({ onUserDetailsClick }) => {
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);

  const [selectedID, setSelectedID] = useState();

  const [stateModel, setStateModel] = useState(false);
  const stateClose = () => setStateModel(false);
  const stateShow = () => setStateModel(true);

  const [WithdrawModal, setWithdrawModal] = useState(false);
  const WithdrawClose = () => setWithdrawModal(false);
  const WithdrawShow = () => setWithdrawModal(true);

  const [withdrawWalletAdd, setWithdrawWalletAdd] = useState("");
  const [userData, setUserData] = useState("");
  const [withdrawalProcess, setWithdrawalProcess] = useState(false);

  const [sendTokenModal, setSendTokenModal] = useState(false);
  const [userWalletAdd, setUserWalletAdd] = useState("");
  const [sendTokenUserId, setSendTokenUserId] = useState("");
  const sendTokenClose = () => setSendTokenModal(false);
  const sendTokenShow = () => setSendTokenModal(true);
  //show withdraw model and save withdraw details
  const onWithdrawClick = (userData) => {
    setWithdrawWalletAdd(userData.walletAddress);
    setUserData(userData);
    WithdrawShow()
  }
  //call withdraw contract
  const onWithdraw = async () => {
    //call contract for forward admin withdrawal
    dispatch(poolMethods.forwardAdminWithdrawal(getInstance, networkDetails.address, withdrawWalletAdd, setWithdrawalProcess ))
    setWithdrawWalletAdd("")
    WithdrawClose()
  }
  //show send token model and save bet details
  const onSendTokenClick = (userData) => {
    setUserWalletAdd(userData.walletAddress);
    setSendTokenUserId(userData._id)
    sendTokenShow();
  }
  //call send token amount contract
  const onSendToken = async(tokenAmount) => {
    //call contract for sent token amount to user
    dispatch(poolMethods.sendTokenAmountToUser(getDbethInstance, networkDetails.address, userWalletAdd,  tokenAmount, {userWalletAdd: userWalletAdd, tokenAmount: tokenAmount, user_id: sendTokenUserId}))
    setUserWalletAdd("")
    sendTokenClose()
  }
  //call api after withdraw contract
  useEffect(() => {
    if(withdrawalProcess){
      //call update jury admin withdraw strike level
      dispatch(updateJuryAdminWithdrawStrikeLevel(userData._id, userData.juryStrikeLevel))
      setWithdrawalProcess(false)
      setUserData("")
    }
  }, [withdrawalProcess])

  const [stateUsername, setStateUsername] = useState({username: "", categories: [], subcategories: []});
  
  const dispatch = useDispatch();

  const { isLoading, userList, userStats, skip, limit, dir, searchBy } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  const onDeleteClick = (id) => {
    setSelectedID(id);
    deleteShow();
  };
  //call delte user api
  const onDeactiveClick = () => {
    dispatch(deleteUserAsync(selectedID._id));
    setSelectedID({});
    deleteClose();
  };
  //show user state details
  const onStatesClick = (username, categories, subcategories) => {
    setStateUsername({username, categories, subcategories});
    stateShow();
    // dispatch(getUserStates(userId));
  }

  const onActivateClick = (userLists) => {
    dispatch(deleteUserAsync(userLists._id));
  };
  // on page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(UserManagementActions.setUserBatchNumber(count));
  };
  //for sort details
  const handleSort = (column, dir) => {
    dispatch(UserManagementActions.setSort({ column, dir }));
  };

  const { networkDetails, handleConnect } = useContext(Web3Context);
  const [getInstance, setInstance] = useState();
  const [getDbethInstance, setDbethInstance] = useState();
  useEffect(() => {
    //console.log({networkDetails})
    (async () => {
      const instance = await poolMethods.getInstanceAdmin(networkDetails.web3)
      // console.log({ instance })
      if (instance) {
        setInstance(instance);
      }

      const dbethInstance = await poolMethods.getInstanceDbethToken(networkDetails.web3)
      // console.log({ dbethInstance })
      if (dbethInstance) {
        setDbethInstance(dbethInstance);
      }
    })()
  }, [networkDetails.web3])
  //formik validation schema
  const sendTokenAmountSchema = Yup.object().shape({
    tokenAmount: Yup.number()
      .moreThan(0, 'token amount must be greater than 0')
      .typeError('Must be a number')
      .integer('Must be integer')
      .required("Please enter token amount"),
  });

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              id="myTable"
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr.no</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "userName",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    User Name{" "}
                    {dir !== "" && searchBy === "userName" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  <th>Wallet Address</th>
                  <th>Country</th>
                  <th>Joining Date</th>
                  <th>Jury</th>
                  <th>Jury Strike Level</th>
                  <th>Active P2P challenges</th>
                  <th>Sent Token Count</th>
                  <th>Status</th>
                  <th className="wd-200">Actions</th>
                </tr>
              </thead>
              <tbody>
                {userList.users && userList.users.length ? (
                  userList.users.map((userLists, index) => {
                    return (
                      <tr key={index.toString()}>
                        <td>
                          <span className="f-w-500">
                            {skip * limit + (index + 1)}
                          </span>
                        </td>
                        <td>
                          <span className="f-w-500">{userLists.userName ? userLists.userName : '-'}</span>
                        </td>
                        <td>{userLists.mobile_number ? userLists.mobile_number : '-'}</td>
                        <td>{userLists.email ? userLists.email : "-"}</td>
                        <td>{userLists?.walletAddress ? userLists?.walletAddress : '-'}</td>
                        <td>
                          {
                            userLists.country ? userLists.country : '-'
                          }
                        </td>
                        <td>
                          {userLists.createdAt ? moment(userLists.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a") : '-'}
                        </td>
                        <td>{userLists.isJury ? "Yes" : "No"}</td>
                        <td>{userLists.juryStrikeLevel}</td>
                        <td>{userLists.activeBets}</td>
                        <td>{userLists?.sentTokenCount}</td>
                        <td>
                          <span
                            className={`pmnt_btn ${userLists?.userStrikeLevel < 5
                              ? "label label-lg label-light-success label-inline"
                              : "label label-lg label-light-danger label-inline"
                              }`}
                          >
                            {userLists?.userStrikeLevel < 5 ? "Active" : "Banned"}
                          </span>
                        </td>
                        <td>
                          <button className="label label-lg label-light-danger label-inline"
                            onClick={() => onStatesClick(userLists.userName, userLists.categories, userLists.subcategories)}
                          >States</button>
                          {/* <button className="label label-lg label-light-danger label-inline"
                            onClick={() => onWithdrawClick(userLists.walletAddress)}
                          >Transfer Stack Token</button> */}
                        </td>
                        <td>
                          <a
                            title="Send Token"
                            style={{backgroundColor: "#b3b3ff", height: "30px", width: "30px"}}
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => onSendTokenClick(userLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/coin.svg"
                                )}
                              />
                            </span>
                          </a> 
                        </td>
                        <td>
                          {
                            (userLists.isJury && userLists.juryStrikeLevel > 0 && userLists.juryStrikeLevel !== userLists.juryAdminWithdrawStrikeLevel) ? 
                            <a
                              title="Transfer Stack Token"
                              style={{backgroundColor: "#e6b77a", height: "30px", width: "30px"}}
                              className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              onClick={() => onWithdrawClick(userLists)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Transfer.svg"
                                  )}
                                />
                              </span>
                            </a> : null
                          }
                        </td> 
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center p-0 border-0">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {userList.countUsers} Records
              </div>
              <div className="col-md-8 text-left mb-5 aspgntn">
                <BasicPagination
                  totalRecords={userList.countUsers}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>

            </div>
          
        </div>
      </div>

      <Modal
        show={stateModel}
        onHide={() => setStateModel(false)}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">{stateUsername.username}'s Challenges Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              {/*  <Table>
                <thead>
                  <tr className="text-white">
                    <th>{" "}</th>
                    <th>Total Challenges</th>
                    <th>Won Challenges</th>
                    <th>Lost Challenges</th>
                  </tr>
                </thead> 
                <tbody>
                  {/* {
                    userStats && userStats.coins &&
                    userStats.coins.map((coinDetails, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{coinDetails.name}</td>
                            <td>{coinDetails.totalBets}</td>
                            <td>{coinDetails.totalWon}</td>
                            <td>{coinDetails.totalLost}</td>
                          </tr>
                        </>
                      )
                    })
                  } 
                </tbody>
              </Table> */}
              <Table>
                <thead className="text-white">
                  <tr>
                    <th> Top Categories </th>
                  </tr>
                </thead>
                <tbody>
                  {stateUsername.categories && stateUsername.categories.map((cat, index)=> (
                    <tr key={index} className="mt-5">
                      <td className="fn_s_18 f-w-500 text-white">{cat.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
                
              <Table>
                <thead className="text-white">
                  <tr>
                    <th> Top SubCategories </th>
                  </tr>
                  
                </thead>
                <tbody>
                  {stateUsername.subcategories && stateUsername.subcategories.map((subCat, index) => (
                    <tr key={index} className="mt-5">
                      <td className="fn_s_18 f-w-500 text-white">{subCat.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {/* <StateChart className="text-white" title="categories" classname="sd" values={userStats.categories} />*/}
            {/* <StateChart className="text-white" title="Subcategories" values={userStats.subCategories} />*/}
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={DeleteModal}
        onHide={stateClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want to delete?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={stateClose}
                >
                  Close
                </button>
              </div>
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={onDeactiveClick}
                >
                  Yes, Deactivate
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={WithdrawModal}
        onHide={WithdrawClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
            Stack Token will be transferd to admin wallet if you proceed ?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={WithdrawClose}
                >
                  Close
                </button>
              </div>
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={onWithdraw}
                >
                  Yes
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={sendTokenModal}
        onHide={sendTokenClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Token Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{
            tokenAmount: "",
          }}
          validationSchema={sendTokenAmountSchema}
          onSubmit={(values) => {
            onSendToken(values.tokenAmount)
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldTouched,
            getFieldProps
          }) => (
          <Form onSubmit={handleSubmit} className="form def_form frmwtpddng mt-n10">
            <div className="form-group">
              <input 
              placeholder="Enter Token Amount"
              type="text"
              name="tokenAmount"
              className={
                errors.tokenAmount && touched.tokenAmount
                  ? "form-control re_inputRouded is-invalid border-less-input"
                  : "form-control re_inputRouded border-less-input"
              }
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.tokenAmount}
              />
              {touched.tokenAmount && errors.tokenAmount ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.tokenAmount}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={sendTokenClose}
                >
                  Close
                </button>
              </div>
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
          )}
        </Formik>
         
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserManagementTable;
