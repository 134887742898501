export const PaymentMap = {

  GET_PAYMENT_START: "GET_PAYMENT_START",
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_ERROR: "GET_PAYMENT_ERROR",

  ADD_PAYMENT_START: "ADD_PAYMENT_START",
  ADD_PAYMENT_SUCCESS: "ADD_PAYMENT_SUCCESS",
  ADD_PAYMENT_ERROR: "ADD_PAYMENT_ERROR",

  UPDATE_PAYMENT_START: "UPDATE_PAYMENT_START",
  UPDATE_PAYMENT_SUCCESS: "UPDATE_PAYMENT_SUCCESS",
  UPDATE_PAYMENT_ERROR: "UPDATE_PAYMENT_ERROR",

  DELETE_PAYMENT_START: "DELETE_PAYMENT_START",
  DELETE_PAYMENT_SUCCESS: "DELETE_PAYMENT_SUCCESS",
  DELETE_PAYMENT_ERROR: "DELETE_PAYMENT_ERROR",

  SELECT_PAYMENT_DETAILS: "SELECT_PAYMENT_DETAILS",

  REFRESH_DETAILS: "REFRESH_DETAILS",

  EDIT_PAYMENT_START: " EDIT_PAYMENT_START",
  EDIT_PAYMENT_SUCCESS: " EDIT_PAYMENT_SUCCESS",
  EDIT_PAYMENT_ERROR: " EDIT_PAYMENT_ERROR",


};

export const PaymentActions = {
  getPaymentStart: (data) => ({
    type: PaymentMap.GET_PAYMENT_START,
    payload: data,
  }),
  getPaymentSuccess: (data) => ({
    type: PaymentMap.GET_PAYMENT_SUCCESS,
    payload: data,
  }),
  getPaymentError: (errors) => ({
    type: PaymentMap.GET_PAYMENT_ERROR,
    payload: { errors },
  }),

  addPaymentStart: (data) => ({
    type: PaymentMap.ADD_PAYMENT_START,
    payload: data,
  }),
  addPaymentSuccess: (data) => ({
    type: PaymentMap.ADD_PAYMENT_SUCCESS,
    payload: data,
  }),
  addPaymentError: (errors) => ({
    type: PaymentMap.ADD_PAYMENT_ERROR,
    payload: { errors },
  }),

  updatePaymentStart: (data) => ({
    type: PaymentMap.UPDATE_PAYMENT_START,
    payload: data,
  }),
  updatePaymentSuccess: (data) => ({
    type: PaymentMap.UPDATE_PAYMENT_SUCCESS,
    payload: data,
  }),
  updatePaymentError: (errors) => ({
    type: PaymentMap.UPDATE_PAYMENT_ERROR,
    payload: { errors },
  }),

  deletePaymentStart: (data) => ({
    type: PaymentMap.DELETE_PAYMENT_START,
    payload: data,
  }),
  deletePaymentSuccess: (data) => ({
    type: PaymentMap.DELETE_PAYMENT_SUCCESS,
    payload: data,
  }),
  deletePaymentError: (errors) => ({
    type: PaymentMap.DELETE_PAYMENT_ERROR,
    payload: { errors },
  }),

  selectPaymentDetails: (data) => ({
    type: PaymentMap.SELECT_PAYMENT_DETAILS,
    payload: data,
  }),

  refreshDetails: () => ({type: PaymentMap.REFRESH_DETAILS}),

  editPaymentStart: (data) => ({
    type: PaymentMap.EDIT_PAYMENT_START,
    payload: data,
  }),
  editPaymentSuccess: (data) => ({
    type: PaymentMap.EDIT_PAYMENT_SUCCESS,
    payload: data,
  }),
  editPaymentError: (errors) => ({
    type: PaymentMap.EDIT_PAYMENT_ERROR,
    payload: { errors },
  }),
};
