import { TeamManagementMap } from "./teamManagementAction";

const initialState = {
  isLoading: false,
  reDirect: false,
  teamList: {},
  refreshTeamList: true,
  selectedTeam: {},
  skip: 0,
  limit: 20,
  search: "",
  isEditLoading: false,
  sub_category_id: ""
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    
    case TeamManagementMap.UPDATE_TEAM_START :
      return {
        ...state,
        isEditLoading: true,

      }

    case TeamManagementMap.UPDATE_TEAM_SUCCESS :
      return {
        ...state,
        isEditLoading: false,
        reDirect: true,
        teamList: {
          ...state,
          ...action.payload
        },
      }

    case TeamManagementMap.UPDATE_TEAM_ERROR :
      return {
      ...state,
      isEditLoading: false,    
    }

    case TeamManagementMap.GET_TEAM_START :
      return {
        ...state,
        isLoading: true,
      }

    case TeamManagementMap.GET_TEAM_SUCCESS :
      return {
        ...state,
        isLoading: false,
        isEditLoading: false,
        teamList: action.payload,
        refreshTeamList: false,
        selectedTeam: {},
        reDirect: false,
      }
    
    case TeamManagementMap.GET_TEAM_ERROR :
      return {
        ...state,
        isLoading: false,
    }
    case TeamManagementMap.SELECT_TEAM_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedTeam: action.payload,
      };
    }
    case TeamManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case TeamManagementMap.SET_TEAM_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshTeamList: true,
      };
    }
    case TeamManagementMap.REFRESH_TEAM_LIST: {
      return {
        ...state,
        skip: 0,
        refreshTeamList: true,
      };
    }
    case TeamManagementMap.SELECT_CATEGORY: {
      return {
        ...state,
        skip: 0,
        refreshTeamList: true,
        sub_category_id: action.payload
      };
    }

    default:
      return { ...state };
  }
};
