import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllFAQAsync, deleteFAQAsync, updateFAQAsync } from "../redux/faqApi";
import FaqPagesTable from "./faq_detail/faqPagesTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { FaqActions } from "../redux/faqAction";
import AddFAQ from "./faq_detail/AddFaq";

export function FaqPages() {
  const [DeleteModal, setDeleteModal] = useState(false);
  const [showFAQModal, setShowFAQModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);

  const [selectedID, setSelectedID] = useState();

  const { isLoading, faqLists, refreshFaq, selectedFAQ, skip, limit } = useSelector(
    (state) => state.faq,
    shallowEqual
  );

  const dispatch = useDispatch();

  const history = useHistory();
  //call get all faq api
  useEffect(() => {
    if (refreshFaq) {
      dispatch(getAllFAQAsync({skip, limit}));
    }
  }, [refreshFaq]);
  //show delete model and save faq id
  const onDeleteClick = (id) => {
    setSelectedID(id);
    deleteShow();
  };
  // call delete faq api and close delete model
  const onDeactiveClick = () => {
    dispatch(deleteFAQAsync(selectedID._id));
    setSelectedID({});
    deleteClose();
  };
  //save selected faq details
  const onSelectFAQ = async (faq) => {
    dispatch(FaqActions.setSelectedFAQ(faq));
    setShowFAQModal(true)
  };
  //for sort column
  const soringAction = (faq_id, action) => {
    const refID = faqLists?.refFAQArr[faq_id][action]
    const obj = { 
      action: action,
      ref_faq_id: refID
    }
    dispatch(updateFAQAsync(faq_id, obj, setShowFAQModal));//call update faq api
  }

  return (
    <>
      <Card>
        <CardHeader title="FAQ Management">
          <CardHeaderToolbar>
            <div className="form-group mr-5 mb-1 mt-2">
              <button
                className="btn_new btn-sm"
                type="button"
                onClick={() => onSelectFAQ({})}
              >
                Add
              </button>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <FaqPagesTable
          faqLists={faqLists}
          onSelectFAQ={onSelectFAQ}
          onDeleteClick={onDeleteClick}
          isLoading={isLoading}
          soringAction={soringAction}
        />
        <AddFAQ
          setShowFAQModal={setShowFAQModal}
          showFAQModal={showFAQModal}
          selectedFAQ={selectedFAQ}
          isLoading={isLoading}
        />
      </Card>

      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want to delete ?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={deleteClose}
                >
                  Close
                </button>
              </div>
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={onDeactiveClick}
                >
                  Yes, Delete it
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
