import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Modal, Table } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import BasicPagination from "../../../pagination/BasicPagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { ServicesManagementActions } from '../../redux/servicesAction';
import { getSubCategoryAsync, deleteSubCategoryAsync , editSubCategoryAsync} from '../../redux/servicesApi';


const SubcategoryTable = ({ onSubCategoryDetailClick }) => {

  const dispatch = useDispatch();

  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);

  const [selectedID, setSelectedID] = useState();

  const { subCategory, totalSubRecords, isLoading, sublimit, subsearch, subsortType, subskip, subsortBy, refreshSubCategoryList, reDirect } = useSelector(
    (state) =>
      state.servicesManagement,
    shallowEqual
  )

  //call delete sub category api 
  const onDeactiveClick = () => {
    dispatch(deleteSubCategoryAsync(selectedID._id))
    setSelectedID({});
    deleteClose();
  };
  //for page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : subskip;
    dispatch(ServicesManagementActions.setSubCategoryBatchNumber(count));
  };
  //for sort column
  const handleSort = (subsortBy, subsortType) => {
    dispatch(ServicesManagementActions.setSubSort({ subsortType, subsortBy }));
  }
  //show delete model
  const onDeleteClick = (id) => {
    setSelectedID(id);
    deleteShow();
  }
  //for change sub category status
  const handleToggle = (isActive, category_id) => {
    const obj = { 
      "isActive" : isActive
    }
    dispatch(editSubCategoryAsync( obj, category_id));//call edit sub category api
  }
  return (
    <>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">

        <div className="tblmrgn mt-0">
          <div className="mt-5">
            <div>
              <Table
                hover
                responsive
                id="myTable"
                className="mb-0 default_table with_hoverbtn mrpdng_tbl"
              >
                <thead>
                  <tr>
                    <th>Sr.no</th>
                    <th
                      onClick={() => handleSort("name", subsortType === "dsc" || subsortType === "" ? "asc" : "dsc")}
                    >
                      Name{" "}
                      {subsortType !== "" && subsortBy === "name" && (
                        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">

                          {subsortType === "dsc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th>

                    <th
                      onClick={() => handleSort("categories.name", subsortType === "asc" || subsortType === "" ? "dsc" : "asc")}
                    >
                      Category{" "}
                      {subsortBy === "categories.name" && (
                        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">

                          {subsortType === "dsc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th>
                    <th>Status</th>
                    <th className="wd-120">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {subCategory && subCategory.length !== 0 ? (
                    subCategory.map((subCategory, index) => {
                      return (
                          <tr key={index}>
                            <td>
                              <span className="f-w-500">
                                {subskip * sublimit + (index + 1)}
                              </span>
                            </td>
                            <td>
                              <span className="f-w-500">{subCategory.name}</span>
                            </td>
                            <td>
                              <span className="f-w-500">{subCategory.categories.name}</span>
                            </td>

                            <td>
                              <button 
                              value= { subCategory && subCategory.isActive }
                              onClick={() => handleToggle(subCategory.isActive, subCategory._id)} className={`pmnt_btn ${subCategory && subCategory.isActive
                                ? "label label-lg label-light-success label-inline"
                                : "label label-lg label-light-danger label-inline"
                                }`}>
                                {subCategory && subCategory.isActive ? "ACTIVE" : "INACTIVE"}
                              </button>
                            </td>

                            <td>
                              <a
                                title="Edit"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                                onClick={() => onSubCategoryDetailClick(subCategory)}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Communication/Write.svg"
                                    )}
                                  />
                                </span>
                              </a>

                              <a
                                title="Delete"
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={() => onDeleteClick(subCategory)}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Trash.svg"
                                    )}
                                  />
                                </span>
                              </a>
                            </td>
                          </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={8} className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                  {isLoading && (
                    <tr>
                      <td colSpan={8} className="text-center p-0 border-0">
                        <div className="basic-verification-loader text-center">
                          <CircularProgress />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
          <div className="col-md-8 text-left mb-5 aspgntn">
            <BasicPagination
              totalRecords={totalSubRecords}
              limit={sublimit}
              batch={subskip + 1}
              onBatchChange={onPageChange}
            />
          </div>
          <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
            Displaying {subskip * sublimit + 1} - {subskip * sublimit + sublimit} of{" "}
            {totalSubRecords} Records
          </div>
        </div>
        <Modal
          show={DeleteModal}
          onHide={deleteClose}
          centered
          className="def_modal delete_modal"
        >
          <Modal.Header>
            <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form def_form frmwtpddng">
              <p className="fn_s_18 f-w-400 text-center mb-10">
                Are you sure want to delete?
              </p>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={deleteClose}
                  >
                    Close
                  </button>
                </div>
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={onDeactiveClick}
                  >
                    Yes, Delete it
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>

  )
}

export default SubcategoryTable