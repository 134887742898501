import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useParams, Redirect, Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
} from "./../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import {
  addNotificationsync,
  updateNotficationAsync,
} from "../redux/notificationManagementApi";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { getAllUsersAsync } from "../../user_management/redux/userManagementApi";
import { MultiSelect } from "react-multi-select-component";

const AddNotificationTemplate = ({ addNotification, setAddNotification }) => {

  const dispatch = useDispatch();
  const { refreshNotificationList, isAddLoading } = useSelector(
    (state) => state.notificationManagement,
    shallowEqual
  );
  //call api for get all users
  useEffect(() => {
    dispatch(getAllUsersAsync())
  }, [])

  const { userList } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  const [userOptions, setUsersOptions] = useState([])
  //close notification model
  useEffect(() => {
    if (refreshNotificationList) {
      setAddNotification(false)
    }
  })
  //save id and username in array
  useEffect(() => {
    let UserArr = []
    userList && userList.users && userList.users.map((cd) => {
      if(cd.userName){
        UserArr.push({
          value: cd._id,
          label: cd.userName
        })
      }
    })
    setUsersOptions(UserArr)
  }, [userList && userList.users && userList.users.length > 0]);

  //formik vallidation schema
  const addNotificationSchema = Yup.object().shape({
    users: Yup.string()
      .trim()
      .required("Please choose User"),
    notificationText: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please enter Notification Text"),
  });
  return (
    <>
      <Modal
        show={addNotification}
        onHide={() => setAddNotification(false)}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Add Notification </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              notificationText: "",
              users: []
            }}
            validationSchema={addNotificationSchema}
            onSubmit={(values) => {
              const tempArr = []
              if (values.users) {
                values.users.map(ele => {
                  tempArr.push(ele.value)
                })
              }
              dispatch(addNotificationsync({ notificationText: values.notificationText, users: tempArr }))
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              getFieldProps
            }) => (
              <Row className="justify-content-center">
                <Col lg={12}>
                  <Form>
                    <div className="form-group">
                      <label className=" text-white font-size-12px inter-font fw-800">Notification Text</label>
                      <input
                        placeholder="Enter Notification Text"
                        type="text"
                        name="notificationText"
                        className={
                          errors.notificationText && touched.notificationText
                            ? "form-control re_inputRouded is-invalid border-less-input"
                            : "form-control re_inputRouded border-less-input"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notificationText}
                      />
                      {touched.notificationText && errors.notificationText ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.notificationText}</div>
                        </div>
                      ) : null}
                    </div>
                    
                    <div className="form-group">
                      <label className=" text-white font-size-12px inter-font fw-800">Target Users</label>
                      <MultiSelect
                        options={userOptions}
                        value={values.users}
                        onChange={(e) => {
                          setFieldValue('users', e)
                        }}
                        labelledBy="Select Users"
                      />
                      {touched.users && errors.users ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.users}</div>
                        </div>
                      ) : null}
                    </div>

                    {
                      isAddLoading ? (
                        <div className="ml-3 basic-verification-loader text-center">
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                          <div className="mb-1 mt-2">
                            <Button
                              variant="blue"
                              className="px-5 defpddng spinnerBtn btn_new"
                              size="lg"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      )
                    }
                  </Form>
                </Col>
              </Row>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddNotificationTemplate;
