export const UserManagementMap = {
  GET_USER_START: "GET_USER_START",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",
  CREATE_USER_START: "CREATE_USER_START",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR: "CREATE_USER_ERROR",
  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",
  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",
  REMOVE_ACTIVITY_START: "REMOVE_ACTIVITY_START",
  REMOVE_ACTIVITY_SUCCESS: "REMOVE_ACTIVITY_SUCCESS",
  REMOVE_ACTIVITY_ERROR: "REMOVE_ACTIVITY_ERROR",
  REMOVE_ALL_ACTIVITIES_START: "REMOVE_ALL_ACTIVITIES_START",
  REMOVE_ALL_ACTIVITIES_SUCCESS: "REMOVE_ALL_ACTIVITIES_SUCCESS",
  REMOVE_ALL_ACTIVITIES_ERROR: "REMOVE_ALL_ACTIVITIES_ERROR",
  GET_ALL_ACTIVITY_START: "GET_ALL_ACTIVITY_START",
  GET_ALL_ACTIVITY_SUCCESS: "GET_ALL_ACTIVITY_SUCCESS",
  GET_ALL_ACTIVITY_ERROR: "GET_ALL_ACTIVITY_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_USER_LIST: "REFRESH_USER_LIST",
  SET_USER_BATCH_NUMBER: "SET_USER_BATCH_NUMBER",
  SET_ACTIVITIES_BATCH_NUMBER: "SET_ACTIVITIES_BATCH_NUMBER",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  GET_USER_STATE_START: "GET_USER_STATE_START",
  GET_USER_STATE_SUCCESS: "GET_USER_STATE_SUCCESS",
  GET_USER_STATE_ERROR: "GET_USER_STATE_ERROR",

  GET_AVTAR_START: "GET_AVTAR_START",
  GET_AVTAR_SUCCESS: "GET_AVTAR_SUCCESS",
  GET_AVTAR_ERROR: "GET_AVTAR_ERROR",

  CREATE_AVTAR_START: "CREATE_AVTAR_START",
  CREATE_AVTAR_SUCCESS: "CREATE_AVTAR_SUCCESS",
  CREATE_AVTAR_ERROR: "CREATE_AVTAR_ERROR",

  UPDATE_AVTAR_START: "UPDATE_AVTAR_START",
  UPDATE_AVTAR_SUCCESS: "UPDATE_AVTAR_SUCCESS",
  UPDATE_AVTAR_ERROR: "UPDATE_AVTAR_ERROR",

  SET_SELECT_CHANGE: "SET_SELECT_CHANGE",

  UPDATE_JURYADMINWITHDRAWAL_START: "UPDATE_JURYADMINWITHDRAWAL_START",
  UPDATE_JURYADMINWITHDRAWAL_SUCCESS: "UPDATE_JURYADMINWITHDRAWAL_SUCCESS",
  UPDATE_JURYADMINWITHDRAWAL_ERROR: "UPDATE_JURYADMINWITHDRAWAL_ERROR",

  GET_TOP_USER_START: "GET_TOP_USER_START",
  GET_TOP_USER_SUCCESS: "GET_TOP_USER_SUCCESS",
  GET_TOP_USER_ERROR: "GET_TOP_USER_ERROR",

  REFRESH_TOP_USER_LIST: "REFRESH_TOP_USER_LIST",
  SET_TOP_USER_CHANGE: "SET_TOP_USER_CHANGE",
  SET_TOP_USER_BATCH_NUMBER: "SET_TOP_USER_BATCH_NUMBER",

  SET_TO_DATE_CHANGES: "SET_TO_DATE_CHANGES",
  SET_FROM_DATE_CHANGES: "SET_FROM_DATE_CHANGES",
  SET_TYPE_TOP_USER_CHANGES: "SET_TYPE_TOP_USER_CHANGES",
  GET_USER_WITH_DATE_CHANGES: "GET_USER_WITH_DATE_CHANGES",
  SET_SORT_FOR_TOP_USER_CHANGE: "SET_SORT_FOR_TOP_USER_CHANGE",
  RESET_FILTERS: "RESET_FILTERS",

  SEND_TOKEN_HISTORY_START: "SEND_TOKEN_HISTORY_START",
  SEND_TOKEN_HISTORY_SUCCESS: "SEND_TOKEN_HISTORY_SUCCESS",
  SEND_TOKEN_HISTORY_ERROR: "SEND_TOKEN_HISTORY_ERROR",
  
};

export const UserManagementActions = {
  getUserStart: (data) => ({
    type: UserManagementMap.GET_USER_START,
    payload: data,
  }),
  getUserSuccess: (data) => ({
    type: UserManagementMap.GET_USER_SUCCESS,
    payload: data,
  }),
  getUserError: (errors) => ({
    type: UserManagementMap.GET_USER_ERROR,
    payload: { errors },
  }),

  createUserStart: (data) => ({
    type: UserManagementMap.CREATE_USER_START,
    payload: data,
  }),
  createUserSuccess: (data) => ({
    type: UserManagementMap.CREATE_USER_SUCCESS,
    payload: data,
  }),
  createUserError: (errors) => ({
    type: UserManagementMap.CREATE_USER_ERROR,
    payload: { errors },
  }),

  updateUserStart: (data) => ({
    type: UserManagementMap.UPDATE_USER_START,
    payload: data,
  }),
  updateUserSuccess: (data) => ({
    type: UserManagementMap.UPDATE_USER_SUCCESS,
    payload: data,
  }),
  updateUserError: (errors) => ({
    type: UserManagementMap.UPDATE_USER_ERROR,
    payload: { errors },
  }),

  deleteUserStart: (data) => ({
    type: UserManagementMap.DELETE_USER_START,
    payload: data,
  }),
  deleteUserSuccess: (data) => ({
    type: UserManagementMap.DELETE_USER_SUCCESS,
    payload: data,
  }),
  deleteUserError: (errors) => ({
    type: UserManagementMap.DELETE_USER_ERROR,
    payload: { errors },
  }),

  removeActivityStart: (data) => ({
    type: UserManagementMap.REMOVE_ACTIVITY_START,
    payload: data,
  }),
  removeActivitySuccess: (data) => ({
    type: UserManagementMap.REMOVE_ACTIVITY_SUCCESS,
    payload: data,
  }),
  removeActivityError: (errors) => ({
    type: UserManagementMap.REMOVE_ACTIVITY_ERROR,
    payload: { errors },
  }),

  removeAllActivitiesStart: (data) => ({
    type: UserManagementMap.REMOVE_ALL_ACTIVITIES_START,
    payload: data,
  }),
  removeAllActivitiesSuccess: (data) => ({
    type: UserManagementMap.REMOVE_ALL_ACTIVITIES_SUCCESS,
    payload: data,
  }),
  removeAllActivitiesError: (errors) => ({
    type: UserManagementMap.REMOVE_ALL_ACTIVITIES_ERROR,
    payload: { errors },
  }),

  setSelectedUser: (data) => ({
    type: UserManagementMap.SELECT_USER_DETAILS,
    payload: data,
  }),

  countryFilters: (data) => ({
    type: UserManagementMap.COUNTRY_FILTERS,
    payload: data,
  }),
  applyFilters: (data) => ({
    type: UserManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshUserList: () => ({ type: UserManagementMap.REFRESH_USER_LIST }),
  searchTextChange: (data) => ({
    type: UserManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),

  getAllActivityStart: (data) => ({
    type: UserManagementMap.GET_ALL_ACTIVITY_START,
    payload: data,
  }),
  getAllActivitySuccess: (data) => ({
    type: UserManagementMap.GET_ALL_ACTIVITY_SUCCESS,
    payload: data,
  }),
  getAllActivityError: (errors) => ({
    type: UserManagementMap.GET_ALL_ACTIVITY_ERROR,
    payload: { errors },
  }),

  setUserBatchNumber: (data) => ({
    type: UserManagementMap.SET_USER_BATCH_NUMBER,
    payload: data,
  }),
  setActivitiesBatchNumber: (data) => ({
    type: UserManagementMap.SET_ACTIVITIES_BATCH_NUMBER,
    payload: data,
  }),

  setSort: (data) => ({
    type: UserManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),

  getUserStateStart: (data) => ({
    type: UserManagementMap.GET_USER_STATE_START,
    payload: data,
  }),
  getUserStateSuccess: (data) => ({
    type: UserManagementMap.GET_USER_STATE_SUCCESS,
    payload: data,
  }),
  getUserStateError: (errors) => ({
    type: UserManagementMap.GET_USER_STATE_ERROR,
    payload: { errors },
  }),


  getAvtarStart: (data) => ({
    type: UserManagementMap.GET_AVTAR_START,
    payload: data,
  }),
  getAvtarSuccess: (data) => ({
    type: UserManagementMap.GET_AVTAR_SUCCESS,
    payload: data,
  }),
  getAvtarError: (errors) => ({
    type: UserManagementMap.GET_AVTAR_ERROR,
    payload: { errors },
  }),

  createAvtarStart: (data) => ({
    type: UserManagementMap.CREATE_AVTAR_START,
    payload: data,
  }),
  createAvtarSuccess: (data) => ({
    type: UserManagementMap.CREATE_AVTAR_SUCCESS,
    payload: data,
  }),
  createAvtarError: (errors) => ({
    type: UserManagementMap.CREATE_AVTAR_ERROR,
    payload: { errors },
  }),

  updateAvtarStart: (data) => ({
    type: UserManagementMap.UPDATE_AVTAR_START,
    payload: data,
  }),
  updateAvtarSuccess: (data) => ({
    type: UserManagementMap.UPDATE_AVTAR_SUCCESS,
    payload: data,
  }),
  updateAvtarError: (errors) => ({
    type: UserManagementMap.UPDATE_AVTAR_ERROR,
    payload: { errors },
  }),

  onSelectChange: (data) => ({ 
    type: UserManagementMap.SET_SELECT_CHANGE,
    payload: data,
  }),

  updateJuryAdminWithdrawalStart: (data) => ({
    type: UserManagementMap.UPDATE_JURYADMINWITHDRAWAL_START,
    payload: data,
  }),
  updateJuryAdminWithdrawalSuccess: (data) => ({
    type: UserManagementMap.UPDATE_JURYADMINWITHDRAWAL_SUCCESS,
    payload: data,
  }),
  updateJuryAdminWithdrawalError: (errors) => ({
    type: UserManagementMap.UPDATE_JURYADMINWITHDRAWAL_ERROR,
    payload: { errors },
  }),

  getTopUserStart: (data) => ({
    type: UserManagementMap.GET_TOP_USER_START,
    payload: data,
  }),
  getTopUserSuccess: (data) => ({
    type: UserManagementMap.GET_TOP_USER_SUCCESS,
    payload: data,
  }),
  getTopUserError: (errors) => ({
    type: UserManagementMap.GET_TOP_USER_ERROR,
    payload: { errors },
  }),

  refreshTopUserList: () => ({ type: UserManagementMap.REFRESH_TOP_USER_LIST }),
  topUserSearchChange: (data) => ({
    type: UserManagementMap.SET_TOP_USER_CHANGE,
    payload: data,
  }),

  setTopUserBatchNumber: (data) => ({
    type: UserManagementMap.SET_TOP_USER_BATCH_NUMBER,
    payload: data,
  }),

  toDateChanges: (data) => ({ 
    type: UserManagementMap.SET_TO_DATE_CHANGES,
    payload: data,
  }),
  fromDateChanges: (data) => ({ 
    type: UserManagementMap.SET_FROM_DATE_CHANGES,
    payload: data,
  }),
  getuserWithDateChanges: (data) => ({ 
    type: UserManagementMap.GET_USER_WITH_DATE_CHANGES,
    payload: data,
  }),
  setTopUserType: (data) => ({ 
    type: UserManagementMap.SET_TYPE_TOP_USER_CHANGES,
    payload: data,
  }),
  setSortForTopUser: (data) => ({
    type: UserManagementMap.SET_SORT_FOR_TOP_USER_CHANGE,
    payload: data,
  }),
  resetFilters: (data) => ({
    type: UserManagementMap.RESET_FILTERS,
    payload: data,
  }),

  sendtokenHistoryStart: (data) => ({
    type: UserManagementMap.SEND_TOKEN_HISTORY_START,
    payload: data,
  }),
  sendtokenHistorySuccess: (data) => ({
    type: UserManagementMap.SEND_TOKEN_HISTORY_SUCCESS,
    payload: data,
  }),
  sendtokenHistoryError: (errors) => ({
    type: UserManagementMap.SEND_TOKEN_HISTORY_ERROR,
    payload: { errors },
  }),
};
