import axios from "axios";
import { TeamManagementActions } from "./teamManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

//get team list api
export const getTeamAsync = (search, skip, limit, sub_category_id) => {
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        
        dispatch(TeamManagementActions.getTeamStart());
        const { data } = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/match/getTeamList`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {search, skip, limit, sub_category_id}
        });
        if (data.statusCode === 200) {
          return dispatch(
            TeamManagementActions.getTeamSuccess(data.data)
          );
        }
        dispatch(TeamManagementActions.getTeamError());
      } catch (error) {
        dispatch(TeamManagementActions.getTeamError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
  };
  //update team api
  export const updateTeamAsync = (updatedValues, id) => {
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        dispatch(TeamManagementActions.updateTeamStart());
        const { data } = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/match/uploadTeamLogo/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: updatedValues
        });
  
        if (data.statusCode === 200) {
          dispatch(TeamManagementActions.updateTeamSuccess(data.data));
          return dispatch(
            showSuccessSnackbar("success", " Team Image Updated successfully", 3000)
          );
        }
        dispatch(TeamManagementActions.updateTeamError());
      } catch (error) {
        dispatch(TeamManagementActions.updateTeamError());
        dispatch(
          showSuccessSnackbar(
            "error",
            "Error while updating fee. Please try again later",
            3000
          )
        );
      }
    };
  };