export const AdminManagementMap = {
  GET_ALL_ADMIN_START: "GET_ALL_ADMIN_START",
  GET_ALL_ADMIN_SUCCESS: "GET_ALL_ADMIN_SUCCESS",
  GET_ALL_ADMIN_ERROR: "GET_ALL_ADMIN_ERROR",
  CREATE_ADMIN_START: "CREATE_ADMIN_START",
  CREATE_ADMIN_SUCCESS: "CREATE_ADMIN_SUCCESS",
  CREATE_ADMIN_ERROR: "CREATE_ADMIN_ERROR",
  UPDATE_ADMIN_START: "UPDATE_ADMIN_START",
  UPDATE_ADMIN_SUCCESS: "UPDATE_ADMIN_SUCCESS",
  UPDATE_ADMIN_ERROR: "UPDATE_ADMIN_ERROR",
  DELETE_ADMIN_START: "DELETE_ADMIN_START",
  DELETE_ADMIN_SUCCESS: "DELETE_ADMIN_SUCCESS",
  DELETE_ADMIN_ERROR: "DELETE_ADMIN_ERROR",
  REMOVE_ACTIVITY_START: "REMOVE_ACTIVITY_START",
  REMOVE_ACTIVITY_SUCCESS: "REMOVE_ACTIVITY_SUCCESS",
  REMOVE_ACTIVITY_ERROR: "REMOVE_ACTIVITY_ERROR",
  REMOVE_ALL_ACTIVITIES_START: "REMOVE_ALL_ACTIVITIES_START",
  REMOVE_ALL_ACTIVITIES_SUCCESS: "REMOVE_ALL_ACTIVITIES_SUCCESS",
  REMOVE_ALL_ACTIVITIES_ERROR: "REMOVE_ALL_ACTIVITIES_ERROR",
  GET_ALL_ACTIVITY_START: "GET_ALL_ACTIVITY_START",
  GET_ALL_ACTIVITY_SUCCESS: "GET_ALL_ACTIVITY_SUCCESS",
  GET_ALL_ACTIVITY_ERROR: "GET_ALL_ACTIVITY_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_USER_LIST: "REFRESH_USER_LIST",
  SET_USER_BATCH_NUMBER: "SET_USER_BATCH_NUMBER",
  SET_ACTIVITIES_BATCH_NUMBER: "SET_ACTIVITIES_BATCH_NUMBER",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
};

export const AdminManagementActions = {
  getAllUserStart: (data) => ({
    type: AdminManagementMap.GET_ALL_ADMIN_START,
    payload: data,
  }),
  getAllUserSuccess: (data) => ({
    type: AdminManagementMap.GET_ALL_ADMIN_SUCCESS,
    payload: data,
  }),
  getAllUserError: (errors) => ({
    type: AdminManagementMap.GET_ALL_ADMIN_ERROR,
    payload: { errors },
  }),

  createUserStart: (data) => ({
    type: AdminManagementMap.CREATE_ADMIN_START,
    payload: data,
  }),
  createUserSuccess: (data) => ({
    type: AdminManagementMap.CREATE_ADMIN_SUCCESS,
    payload: data,
  }),
  createUserError: (errors) => ({
    type: AdminManagementMap.CREATE_ADMIN_ERROR,
    payload: { errors },
  }),

  updateUserStart: (data) => ({
    type: AdminManagementMap.UPDATE_ADMIN_START,
    payload: data,
  }),
  updateUserSuccess: (data) => ({
    type: AdminManagementMap.UPDATE_ADMIN_SUCCESS,
    payload: data,
  }),
  updateUserError: (errors) => ({
    type: AdminManagementMap.UPDATE_ADMIN_ERROR,
    payload: { errors },
  }),

  deleteUserStart: (data) => ({
    type: AdminManagementMap.DELETE_ADMIN_START,
    payload: data,
  }),
  deleteUserSuccess: (data) => ({
    type: AdminManagementMap.DELETE_ADMIN_SUCCESS,
    payload: data,
  }),
  deleteUserError: (errors) => ({
    type: AdminManagementMap.DELETE_ADMIN_ERROR,
    payload: { errors },
  }),

  removeActivityStart: (data) => ({
    type: AdminManagementMap.REMOVE_ACTIVITY_START,
    payload: data,
  }),
  removeActivitySuccess: (data) => ({
    type: AdminManagementMap.REMOVE_ACTIVITY_SUCCESS,
    payload: data,
  }),
  removeActivityError: (errors) => ({
    type: AdminManagementMap.REMOVE_ACTIVITY_ERROR,
    payload: { errors },
  }),

  removeAllActivitiesStart: (data) => ({
    type: AdminManagementMap.REMOVE_ALL_ACTIVITIES_START,
    payload: data,
  }),
  removeAllActivitiesSuccess: (data) => ({
    type: AdminManagementMap.REMOVE_ALL_ACTIVITIES_SUCCESS,
    payload: data,
  }),
  removeAllActivitiesError: (errors) => ({
    type: AdminManagementMap.REMOVE_ALL_ACTIVITIES_ERROR,
    payload: { errors },
  }),

  setSelectedUser: (data) => ({
    type: AdminManagementMap.SELECT_USER_DETAILS,
    payload: data,
  }),

  countryFilters: (data) => ({
    type: AdminManagementMap.COUNTRY_FILTERS,
    payload: data,
  }),
  applyFilters: (data) => ({
    type: AdminManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshUserList: () => ({ type: AdminManagementMap.REFRESH_USER_LIST }),
  searchTextChange: (data) => ({
    type: AdminManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),

  getAllActivityStart: (data) => ({
    type: AdminManagementMap.GET_ALL_ACTIVITY_START,
    payload: data,
  }),
  getAllActivitySuccess: (data) => ({
    type: AdminManagementMap.GET_ALL_ACTIVITY_SUCCESS,
    payload: data,
  }),
  getAllActivityError: (errors) => ({
    type: AdminManagementMap.GET_ALL_ACTIVITY_ERROR,
    payload: { errors },
  }),

  setUserBatchNumber: (data) => ({
    type: AdminManagementMap.SET_USER_BATCH_NUMBER,
    payload: data,
  }),
  setActivitiesBatchNumber: (data) => ({
    type: AdminManagementMap.SET_ACTIVITIES_BATCH_NUMBER,
    payload: data,
  }),

  setSort: (data) => ({
    type: AdminManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),
};
