import React from 'react';
import Chart from 'react-apexcharts'


const StateChart = ({title, values}) => {

    const chartValues = [];
    const chartKeys = [];

    if(values) {
        values && values.map(ele => {
            chartValues.push(ele.count)
        });
        values && values.map(ele => {
            chartKeys.push(ele.name)
        });   
    }

    const options = {
        chart: {
            width: 360,
            type: 'donut',
        },
        dataLabels: {
            enabled: true
        },
        responsive: [{
            breakpoint: 360,
            options: {
                chart: {
                    width: 360
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            show: true
        },
        labels: chartKeys,
    };

    return (
        <>
            <div>
                <h1>Top {title}</h1>

                <div className="donut">
                    <Chart options={options} series={chartValues} type="donut" width= {360}/>
                </div>
                <div>
                    <span>
                    </span>
                </div>
            </div>
        </>
    )
}

export default StateChart;
