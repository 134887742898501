import { BetManagementMap } from "./betManagementAction";

const initialState = {
  isLoading: false,
  betList: [],
  refreshBetList: true,
  search: "",
  skip: 0,
  limit: 10,
  betTakerEvidenceList: {},
  betMakerEvidenceList: {},
  juryVotesList: [],
  resultList: {},
  betId: "",
  betResult: {},
  disputeType: {value: "", label: "All"},
  refreshResult: true,
  betData: {},
  sortBy: "",
  sortType: ""
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BetManagementMap.GET_ALL_BET_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BetManagementMap.GET_ALL_BET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        betList: action.payload.data,
        refreshBetList: false,
      };
    }
    case BetManagementMap.GET_ALL_BET_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: false,
      };
    }
    
    case BetManagementMap.DELETE_BET_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BetManagementMap.DELETE_BET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: true,
      };
    }
    case BetManagementMap.DELETE_BET_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: false,
      };
    }

    case BetManagementMap.SET_SEARCH_CHANGE: {
      return {
        ...state,
        search: action.payload,
      }
    }

    case BetManagementMap.REFRESH_BET_LIST: {
      return {
        ...state,
        skip:0,
        refreshBetList: true
      }
    }

    case BetManagementMap.SET_BET_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshBetList: true
      }
    }

    case BetManagementMap.GET_USER_BET_RESULT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BetManagementMap.GET_USER_BET_RESULT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        betTakerEvidenceList: action.payload.data?.disputeEvidence?.betTakerEvidence,
        betMakerEvidenceList: action.payload.data?.disputeEvidence?.betMakerEvidence,
        betResult: action.payload.data,
        refreshBetList: true,
        betData: action.payload.data.betData
      };
    }
    case BetManagementMap.GET_USER_BET_RESULT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: true,
      };
    }

    case BetManagementMap.GET_JURY_RESULT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BetManagementMap.GET_JURY_RESULT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        juryVotesList: action.payload,
        refreshBetList: true,
        refreshResult: true
      };
    }
    case BetManagementMap.GET_JURY_RESULT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: true,
        refreshResult: true
      };
    }


    case BetManagementMap.GIVE_RESULT_BY_ADMIN_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BetManagementMap.GIVE_RESULT_BY_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: false,
        refreshResult: false
      };
    }
    case BetManagementMap.GIVE_RESULT_BY_ADMIN_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: true,
        refreshResult: false
      };
    }

    case BetManagementMap.GET_BET_ID: {
      return {
        ...state,
        betId: action.payload,

      };
    }

    case BetManagementMap.REMOVE_BET_ID: {
      return {
        ...state,
        betId: "",
        betTakerEvidenceList: {},
        betMakerEvidenceList: {},
        betResult: {},
        juryVotesList: [],

      };
    }

    case BetManagementMap.SET_BET_TYPE_CHANGE: {
      return {
        ...state,
        disputeType: action.payload,
        skip:0,
        refreshBetList: true
      }
    }
    case BetManagementMap.SET_SORT_BET_MANAGEMENT_CHANGE: {
      return {
        ...state,
        sortBy: action.payload?.column,
        sortType: action.payload?.dir,
        refreshBetList: true,
      }
    }

    case BetManagementMap.CANCEL_BET_BY_ADMIN_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BetManagementMap.CANCEL_BET_BY_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: true,
      };
    }
    case BetManagementMap.CANCEL_BET_BY_ADMIN_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBetList: true,
      };
    }

    default:
      return { ...state };
  }
};
