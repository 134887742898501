import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Tabs, Tab , Table, Modal } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { CircularProgress } from "@material-ui/core";
import SVG from "react-inlinesvg";
import {PaymentTable} from "./payment_details/paymentTable";
import {getPaymentAsync} from '../redux/paymentApi';
import AddTokens from "./payment_details/AddTokens";
import {PaymentActions} from '../redux/paymentAction';

export function Payment() {

  const dispatch = useDispatch();
  const [allPayment, setAllPayment] = useState([]);
  const { refreshPayment , selectedPayment } = useSelector(
    (state) => state.payment,
    shallowEqual
  );

  useEffect(() => {
    if(refreshPayment){
      dispatch(getPaymentAsync());
    }
  }, [refreshPayment])

  const [addTokens, setAddTokens] = useState(false);

  const [file, setFile] = useState({ file: null, url: null });
  //on add click
  const onAddClick = () => {
    setAddTokens(true)
    setFile({ file: null, url: null })
  }
  //on edit click save edit token details
  const onEditClick = (payment) => {
    dispatch(PaymentActions.selectPaymentDetails(payment));
    setAddTokens(true);
  };
  return (
    <>
      <Card>
        <CardHeader title="Accepted Cryptocurrencies">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={() => onAddClick()}
                >
                  Add
                </button>
              </div>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <PaymentTable allPayment={allPayment} setAllPayment= {setAllPayment} onEditClick={onEditClick}/>
        <AddTokens addTokens={addTokens} setAddTokens={setAddTokens} file={file} setFile={setFile} />
      </Card>
    </>
  );
}
