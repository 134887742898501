import axios from "axios";
import { AuthActions } from "./authAction";
import { addUserDetails } from "./userInformation";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
//login api
export const loginAsync = (user) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL

      dispatch(AuthActions.loginStart());
      const newUserDetails = await addUserDetails(user);
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/login`,
        data: newUserDetails,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data.statusCode === 200) {
        dispatch(AuthActions.loginSuccess(data.data));
        return dispatch(
          showSuccessSnackbar("success", "Successfully logged in", 3000)
        );
      }

      return dispatch(AuthActions.loginError(data.message));
    } catch (error) {
      
      if (Object.keys(error.data).length > 0) {
        dispatch(AuthActions.loginError("The account does not Exist"));

      } else {

        return dispatch(
          showSuccessSnackbar("error", "Error while login in.", 3000)
        );
      }
    }
  }
}
//logout api
export const logoutAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(AuthActions.logoutStart());
      let { _id } = getState().auth.user;
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_USER_BASE_URL}/logout`,
      });
      if (data.statusCode === 200) {
        dispatch(AuthActions.logout());
        return dispatch(
          showSuccessSnackbar("success", data.message, 3000)
        );
      }
      dispatch(AuthActions.logoutError(data.message));
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(AuthActions.logoutError());
      return dispatch(
        showSuccessSnackbar(
          "error",
          "Error while logout. Please try again after sometime.",
          3000
        )
      );
    }
  }
}
//forgot password api
export const forgotPasswordAsync = (emailId, redirectToLogin) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL
      dispatch(AuthActions.forgotPasswordStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/forgotPassword`,
        data: { email: emailId },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.statusCode === 200) {
        dispatch(AuthActions.forgotPasswordSuccess(data.data));
        redirectToLogin();
        return dispatch(
          showSuccessSnackbar("success", data.message, 3000)
        );
      }
      //dispatch(AuthActions.forgotPasswordError(getErrorMsg(data)));
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {

      if (Object.keys(error.data).length > 0) {
        dispatch(AuthActions.forgotPasswordError(error.data.message));

        return dispatch(
          showSuccessSnackbar("error", error.data.message, 3000)
        );
      } else {

        return dispatch(
          showSuccessSnackbar("error", "Error while sending link to your e-mail id.", 3000)
        );
      }
    }
  }
}
//set new password api
export const setNewPasswordAsync = ({ resetToken, password }, redirectBack) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL
      dispatch(AuthActions.setNewPasswordStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/setNewPassword/${resetToken}`,
        data: { password },
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("data", data);
      if (data.statusCode === 202) {
        dispatch(AuthActions.setNewPasswordSuccess(data.message));
        redirectBack();
        return dispatch(
          showSuccessSnackbar("success", data.message, 3000)
        );
      }
      //dispatch(AuthActions.setNewPasswordError(getErrorMsg(data)));
      return dispatch(showSuccessSnackbar("error", "Error while changing password. Please try again later", 3000));
    } catch (error) {
      dispatch(AuthActions.setNewPasswordError());
      return dispatch(
        showSuccessSnackbar(
          "error",
          "Error while changing password. Please try again later",
          3000
        )
      );
    }
  }
}
//notification list api
export const notificationListAsync = () => {
  return async (dispatch, getState) => {
    try {
      let { _id } = getState().auth.user;
      const adminBaseURL = process.env.REACT_APP_ADMIN_BASE_URL
      dispatch(AuthActions.notificationListStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllNotifications/${_id}?skip=0&limit=6`,
        headers: {
          "Content-Type": "application/json",
        }
      });
      if (data.responseCode === 200) {
        //console.log(data.responseData);
        return dispatch(
          dispatch(AuthActions.notificationListSuccess(data.responseData))
        );
      }
      return dispatch(AuthActions.notificationListError());
    } catch (error) {
      return dispatch(showSuccessSnackbar("error", error.message, 3000));
    }
  }
}

//get user notification api
export const getUserNotificationAsync = () => {
  return async (dispatch, getState) => {
    try {
      let { _id } = getState().auth.user;
      const adminBaseURL = process.env.REACT_APP_USER_BASE_URL
      dispatch(AuthActions.getUserNotificationStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getUserNotifications`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          "userType": "ADMIN"
        }
      });
      if (data.statusCode === 200) {
        //console.log(data.responseData);
        return dispatch(
          dispatch(AuthActions.getUserNotificationSuccess(data))
        );
      }
      return dispatch(AuthActions.getUserNotificationError());
    } catch (error) {
      return dispatch(showSuccessSnackbar("error", error.data.message, 3000));
    }
  }
}