import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import BasicPagination from "../../../pagination/BasicPagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { ServicesManagementActions } from '../../redux/servicesAction';
import { deleteCategoryAsync ,editCategoryActionAsync} from '../../redux/servicesApi';

const CategoryTable = ({ onCategoryDetailClick }) => {

  const dispatch = useDispatch();

  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);

  const [selectedID, setSelectedID] = useState();

  const { category, totalRecords, isLoading, limit, search, sortType, skip, sortBy, refreshCategoryList, reDirect } = useSelector(
    (state) =>
      state.servicesManagement,
    shallowEqual
  )
  //call delete category api and close delete model
  const onDeactiveClick = () => {
    dispatch(deleteCategoryAsync(selectedID._id))
    setSelectedID({});
    deleteClose();
  };
  //for change page
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(ServicesManagementActions.setCategoryBatchNumber(count));
  };
  //for sort column
  const handleSort = (sortBy, sortType) => {
    dispatch(ServicesManagementActions.setSort({ sortBy, sortType }));
  }
  //show delete model
  const onDeleteClick = (id) => {
    setSelectedID(id);
    deleteShow();
  }
  //for change category position
  const soringAction = (category_id, action) => {
    const refID = category?.refCatArr[category_id][action]
    const obj = { 
      action: action,
      ref_category_id: refID
    }
    dispatch(editCategoryActionAsync( obj, category_id,));//call edit category api
  }
  //for change of category status
  const handleToggle = (isActive, category_id) => {
    const obj = { 
      "isActive" : isActive
    }
    dispatch(editCategoryActionAsync( obj, category_id));//call edit category api
  }

  return (
    <>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">

        <div className="tblmrgn mt-0">
          <div className="mt-5">
            <div>
              <Table
                hover
                responsive
                id="myTable"
                className="mb-0 default_table with_hoverbtn mrpdng_tbl"
              >
                <thead>
                  <tr>
                    <th>Sr.no</th>
                    <th
                      onClick={() => handleSort("name", sortType === "desc" || sortType === "" ? "asc" : "desc")}
                    >
                      Name{" "}
                      {sortType !== "" && sortBy === "name" && (
                        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                          {sortType === "desc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th>
                    <th>Position</th>
                    <th>Custom Category</th>
                    <th>Status</th>
                    {category && category.category && category.category.length > 0 && <th className="wd-120">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {category && category.category && category.category.length > 0 ? (
                    category.category.map((category, index, {length}) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className="f-w-500">
                              {skip * limit + (index + 1)}
                            </span>
                          </td>
                          <td>
                            <span className="f-w-500">{category.name}</span>
                          </td>
                          <td>
                            {index === 0 && (
                              <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 bg-light"
                              onClick={() => soringAction(category._id, "down")}>
                                {(
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Down-2.svg"
                                    )}
                                  />
                                )}
                              </span>
                            )}
                            {index !== 0 && index !== (length - 1) && (
                              <div>
                                <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 bg-light"
                                  onClick={() => soringAction(category._id, "up")}>
                                  {(
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Navigation/Up-2.svg"
                                      )}
                                    />
                                  )}
                                </span> 

                                <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 bg-light"
                                  onClick={() => soringAction(category._id, "down")}>
                                  {(
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Navigation/Down-2.svg"
                                      )}
                                    />
                                  )}
                                </span>
                                
                                   
                              </div>
                            )}
                            {index === (length - 1) && (
                              <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 bg-light"
                              onClick={() => soringAction(category._id, "up")}>
                                {(
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Up-2.svg"
                                    )}
                                  />
                                )}
                              </span>
                            )}
                          </td>
                          
                          <td>
                            <span>
                              {category && category.isCustom ? "Yes" : "No" }
                            </span>
                          </td>

                          <td>
                            <button 
                            value= { category && category.isActive }
                            onClick={() => handleToggle(category.isActive, category._id)} className={`pmnt_btn ${category && category.isActive
                              ? "label label-lg label-light-success label-inline"
                              : "label label-lg label-light-danger label-inline"
                              }`}>
                              {category && category.isActive ? "ACTIVE" : "INACTIVE"}
                            </button>
                          </td>
                          <td>

                          {
                            (
                              <>
                                <a
                                  title="Edit"
                                  className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                                  onClick={() => onCategoryDetailClick(category)}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Write.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </>
                            )
                          }
                            
                            <a
                              title="Delete"
                              className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              onClick={() => onDeleteClick(category)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={8} className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                  {isLoading  && (
                    <tr>
                      <td colSpan={8} className="text-center p-0 border-0">
                        <div className="basic-verification-loader text-center">
                          <CircularProgress />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
          <div className="col-md-8 text-left mb-5 aspgntn">
            <BasicPagination
              totalRecords={totalRecords}
              limit={limit}
              batch={skip + 1}
              onBatchChange={onPageChange}
            />
          </div>
          <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
            Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
            {totalRecords} Records
          </div>
        </div>
        <Modal
          show={DeleteModal}
          onHide={deleteClose}
          centered
          className="def_modal delete_modal"
        >
          <Modal.Header>
            <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form def_form frmwtpddng">
              <p className="fn_s_18 f-w-400 text-center mb-10">
                Are you sure want to delete?
              </p>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={deleteClose}
                  >
                    Close
                  </button>
                </div>
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={onDeactiveClick}
                  >
                    Yes, Delete it
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>

  )
}

export default CategoryTable