import { FeeMap } from "./feeAction";

const initialState = {
  isLoading: false,
  reDirect: false,
  fee: {},
  video: {}
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    
    case FeeMap.UPDATE_FEE_START :
      return {
        ...state,
        isLoading: true,

      }

    case FeeMap.UPDATE_FEE_SUCCESS :
      // console.log("action.payload: " + action.payload);
      return {
        ...state,
        isLoading: false,
      }

    case FeeMap.UPDATE_FEE_ERROR :
      return {
      ...state,
      isLoading: false,    
    }

    case FeeMap.EDIT_FEE_START :
      return {
        ...state,
        isLoading: true,
      }

    case FeeMap.EDIT_FEE_SUCCESS :
      return {
        ...state,
        isLoading: false,
        fee: action.payload[0],
      }
    
    case FeeMap.EDIT_FEE_ERROR :
      return {
        ...state,
        isLoading: false,
    }

    case FeeMap.UPDATE_AFFILIATE_REWARD_START :
      return {
        ...state,
        isLoading: true,
      }

    case FeeMap.UPDATE_AFFILIATE_REWARD_SUCCESS :
      return {
        ...state,
        isLoading: false,
      }
    
    case FeeMap.UPDATE_AFFILIATE_REWARD_ERROR :
      return {
        ...state,
        isLoading: false,
    }

    case FeeMap.UPDATE_VIDEO_START :
      return {
        ...state,
        isLoading: true,
      }

    case FeeMap.UPDATE_VIDEO_SUCCESS :
      return {
        ...state,
        isLoading: false,
        video: action.payload[0],
      }
    
    case FeeMap.UPDATE_VIDEO_ERROR :
      return {
        ...state,
        isLoading: false,
      }
    default:
      return { ...state };
  }
};
