export const BetManagementMap = {
  GET_ALL_BET_START: "GET_ALL_BET_START",
  GET_ALL_BET_SUCCESS: "GET_ALL_BET_SUCCESS",
  GET_ALL_BET_ERROR: "GET_ALL_BET_ERROR",

  DELETE_BET_START: "DELETE_BET_START",
  DELETE_BET_SUCCESS: "DELETE_BET_SUCCESS",
  DELETE_BET_ERROR: "DELETE_BET_ERROR",

  SET_SELECTED_BET: "SET_SELECTED_BET",
  REFRESH_BET_LIST: "REFRESH_BETLIST",
  SET_SEARCH_CHANGE: "SET_SEARCH_CHANGE",
  SET_BET_BATCH_NUMBER: "SET_BET_BATCH_NUMBER",

  GET_USER_BET_RESULT_START: "GET_USER_BET_RESULT_START",
  GET_USER_BET_RESULT_SUCCESS: "GET_USER_BET_RESULT_SUCCESS",
  GET_USER_BET_RESULT_ERROR: "GET_USER_BET_RESULT_ERROR",

  GET_JURY_RESULT_START: "GET_JURY_RESULT_START",
  GET_JURY_RESULT_SUCCESS: "GET_JURY_RESULT_SUCCESS",
  GET_JURY_RESULT_ERROR: "GET_JURY_RESULT_ERROR",

  GIVE_RESULT_BY_ADMIN_START: "GIVE_RESULT_BY_ADMIN_START",
  GIVE_RESULT_BY_ADMIN_SUCCESS: "GIVE_RESULT_BY_ADMIN_SUCCESS",
  GIVE_RESULT_BY_ADMIN_ERROR: "GIVE_RESULT_BY_ADMIN_ERROR",


  GET_BET_ID: "GET_BET_ID",
  REMOVE_BET_ID: "REMOVE_BET_ID",

  SET_BET_TYPE_CHANGE: "SET_BET_TYPE_CHANGE",
  SET_SORT_BET_MANAGEMENT_CHANGE: "SET_SORT_BET_MANAGEMENT_CHANGE",

  CANCEL_BET_BY_ADMIN_START: "CANCEL_BET_BY_ADMIN_START",
  CANCEL_BET_BY_ADMIN_SUCCESS: "CANCEL_BET_BY_ADMIN_SUCCESS",
  CANCEL_BET_BY_ADMIN_ERROR: "CANCEL_BET_BY_ADMIN_ERROR",
};

export const BetManagementActions = {
  getAllBetStart: (data) => ({
    type: BetManagementMap.GET_ALL_BET_START,
    payload: data,
  }),
  getAllBetSuccess: (data) => ({
    type: BetManagementMap.GET_ALL_BET_SUCCESS,
    payload: data,
  }),
  getAllBetError: (errors) => ({
    type: BetManagementMap.GET_ALL_BET_ERROR,
    payload: { errors },
  }),
  
  deleteBetStart: (data) => ({
    type: BetManagementMap.DELETE_BET_START,
    payload: data,
  }),
  deleteBetSuccess: (data) => ({
    type: BetManagementMap.DELETE_BET_SUCCESS,
    payload: data,
  }),
  deleteBetError: (errors) => ({
    type: BetManagementMap.DELETE_BET_ERROR,
    payload: { errors },
  }),
  
  setSelectedBet: () => ({ 
    type: BetManagementMap.SET_SELECTED_BET
  }),

  refreshBetList: () => ({ 
    type: BetManagementMap.REFRESH_BET_LIST
  }),

  searchTextChange: (data) => ({ 
    type: BetManagementMap.SET_SEARCH_CHANGE,
    payload: data,
  }),

  setBetBatchNumber: (data) => ({ 
    type: BetManagementMap.SET_BET_BATCH_NUMBER,
    payload: data,
  }),

  getUserBetResultStart: (data) => ({
    type: BetManagementMap.GET_USER_BET_RESULT_START,
    payload: data,
  }),
  getUserBetResultSuccess: (data) => ({
    type: BetManagementMap.GET_USER_BET_RESULT_SUCCESS,
    payload: data,
  }),
  getUserBetResultError: (errors) => ({
    type: BetManagementMap.GET_USER_BET_RESULT_ERROR,
    payload: { errors },
  }),

  getUserJuryVotesStart: (data) => ({
    type: BetManagementMap.GET_JURY_RESULT_START,
    payload: data,
  }),
  getUserJuryVotesSuccess: (data) => ({
    type: BetManagementMap.GET_JURY_RESULT_SUCCESS,
    payload: data,
  }),
  getUserJuryVotesError: (errors) => ({
    type: BetManagementMap.GET_JURY_RESULT_ERROR,
    payload: { errors },
  }),

  giveResultByAdminStart: (data) => ({
    type: BetManagementMap.GIVE_RESULT_BY_ADMIN_START,
    payload: data,
  }),
  giveResultByAdminSuccess: (data) => ({
    type: BetManagementMap.GIVE_RESULT_BY_ADMIN_SUCCESS,
    payload: data,
  }),
  giveResultByAdminError: (errors) => ({
    type: BetManagementMap.GIVE_RESULT_BY_ADMIN_ERROR,
    payload: { errors },
  }),

  getBetId: (data) => ({
    type: BetManagementMap.GET_BET_ID,
    payload: data,
  }),

  removeBetId: (data) => ({
    type: BetManagementMap.REMOVE_BET_ID,
    payload: data,
  }),

  setSelectBetTypeChange: (data) => ({ 
    type: BetManagementMap.SET_BET_TYPE_CHANGE,
    payload: data,
  }),
  setSortForBetManagement: (data) => ({
    type: BetManagementMap.SET_SORT_BET_MANAGEMENT_CHANGE,
    payload: data,
  }),

  cancelBetByAdminStart: (data) => ({
    type: BetManagementMap.CANCEL_BET_BY_ADMIN_START,
    payload: data,
  }),
  cancelBetByAdminSuccess: (data) => ({
    type: BetManagementMap.CANCEL_BET_BY_ADMIN_SUCCESS,
    payload: data,
  }),
  cancelBetByAdminError: (errors) => ({
    type: BetManagementMap.CANCEL_BET_BY_ADMIN_ERROR,
    payload: { errors },
  }),

};
