import React, { useEffect, useState } from "react";
import caretup from "../../../assets/img/caret-white-down.png";
import {
    MixedWidget1,
    MixedWidget14,
    ListsWidget9,
    StatsWidget11,
    StatsWidget12,
    ListsWidget1,
    AdvanceTablesWidget2,
    AdvanceTablesWidget4,
    ListsWidget3,
    ListsWidget4,
    ListsWidget8
} from "../../../_metronic/_partials/widgets";
import { getAllAdminAsync } from "../admin_management/redux/adminManagementApi"
import { getAllBetsAsync, getAllUserAsync, getAllMatchesAsync,getBlockUsersListAsync} from './redux/dashboardApi';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Table, Modal } from "react-bootstrap";
import { getCategoryAsync } from '../services/redux/servicesApi';
import Select from "react-select";
import { toTimestamp } from '../../utils/appUtils';
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

export function Demo1Dashboard() {

    const dispatch = useDispatch();
    const history = useHistory();

    const { isUserLoading, isBetsLoading, isMatchesLoading, isLoading, blockUserList, adminList, userList, betList, matchList } = useSelector((state) => state.dashboard, shallowEqual);

    const [usersToShow, setUsersToShow] = useState(10);
    const [betsToShow, setBetsToShow] = useState(10);
    const [blockUserToShow, setBlockUserToShow] = useState(20);

    const [index, setIndex] = useState(10);

    const [categoryOptions, setCategoryOptions] = useState([])
    const [categoryValue, setCategoryValue] = useState({ value: "", label: "" });
    const { category } = useSelector((state) => state.servicesManagement, shallowEqual);
    //add index to show more user
    const showUsersMore = () => {
        const newIndex = index + usersToShow;
        setUsersToShow(newIndex);
    }
    //add index to show more bets
    const showBetsMore = () => {
        const newIndex = index + betsToShow;
        setBetsToShow(newIndex);
    }
    //add index to show more block users
    const showBlockUsersMore = () => {
        const newIndex = index + usersToShow;
        setBlockUserToShow(newIndex);
    }
    //redirect to event table page
    const showMatchesMore = () => {
        history.push('/dashboard/event-table')
    }
    //save id and name in array 
    useEffect(() => {
        let catArr = []
        category && category?.category && category.category.length && category.category.map((cd, index) => {
            catArr.push({
                value: cd._id,
                label: cd.name
            })
        })
        setCategoryOptions(catArr)
    }, [category && category?.category?.length])
    //call get category api
    useEffect(() => {
        // dispatch(getAllAdminAsync())
        dispatch(getCategoryAsync({"live": true}))
    }, [])
    //call get all user api
    useEffect(() => {
        dispatch(getAllUserAsync({ limit: usersToShow, skip: 0 }))
    }, [usersToShow]);
    //call get all bets api
    useEffect(() => {
        dispatch(getAllBetsAsync({ limit: betsToShow, skip: 0 }))
    }, [betsToShow])
    //call get all match api
    useEffect(() => {
        dispatch(getAllMatchesAsync({ limit: 10, skip: 0, categoryId: categoryValue.value }))
    }, [categoryValue])
    //call get all block user api
    useEffect(() => {
        dispatch(getBlockUsersListAsync({limit: blockUserToShow, skip: 0 }))
    }, [blockUserToShow])

    return (<>
        <div className="row">
            {/* <div className="col-lg-6 col-xxl-4">
                <div className="d-flex justify-content-around bg-white px-6 py-8 rounded-xl mb-7">
                    <span className="svg-icon svg-icon-6x svg-icon-primary d-block my-2">
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Add-user.svg"
                            )}
                        >
                        </SVG>
                    </span>
                    <div>
                        <div
                            className="text-primary font-weight-bold font-size-h6 mt-2"
                        >
                            Total Admins
                        </div>  
                        <a
                            href="#"
                            className="text-primary font-weight-bold font-size-h2 mt-2"
                        >
                            {adminList.recordsTotal}
                        </a>
                        {isLoading && (
                            <span className="ml-3 spinner spinner-dark"></span>
                        )}
                    </div>
                </div>
            </div> */}

            <div className="col-lg-6 col-xxl-4">
                <div className="d-flex justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
                    <div>
                        <div>
                            <h1 className="font-weight-normal">Users in the platform</h1>
                        </div>
                        <div className="link-bg-color marg-top-30px marg-bottom-30px">
                            <a
                                href="#"
                                className="text-white font-weight-normal   font-size-14px mt-2"
                            >
                                {userList && userList.recordsTotal}
                            </a>
                        </div>
                        {
                            userList && userList.data && userList.data.user && (
                                <>
                                    <div className="marg-top-30px marg-bottom-30px pos-relative">
                                        <h1 className="font-weight-normal">Top Users</h1>
                                    </div>
                                    <div className="caredt-up-btn">
                                        <button className="bg-transparent border-0"><img src={caretup} /></button>
                                    </div>
                                </>
                            )
                        }

                        {
                            userList && userList.data && userList.data.user &&
                            userList.data.user.map((user, index) => (
                                <div className="d-flex justify-content-between text-white font-size-10px" key={index}>
                                    <img style={{ height: '5%', width: '5%' }} alt="user image" src={user.picture} />
                                    <p>{user.userName}</p>
                                    <div className="">
                                        <p>{user.activeBets} active challenges</p>
                                    </div>
                                </div>
                            ))
                        }

                        {
                            usersToShow < userList.recordsTotal ?
                                <button className="btn btn-blue spinnerBtn show-more-btn font-family-base mt-3 btn_new" onClick={showUsersMore}>Show More</button> : null
                        }

                        {isUserLoading && (
                            <span className="ml-3 spinner"></span>
                        )}
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-xxl-4">
                <div className="d-flex justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
                    <div>
                        <div>

                            <h1 className="font-weight-normal">P2P challenges</h1>
                        </div>
                        <div className="link-bg-color marg-top-30px marg-bottom-30px">

                            <a
                                href="#"
                                className="text-white font-weight-normal   font-size-14px mt-2"
                            >
                                {betList && betList.recordsTotal}
                            </a>
                        </div>

                        {
                            betList && betList.data && betList.data.bets && (
                                <>
                                    <div className="pos-relative">

                                        <h1 className="font-weight-normal">Top P2P challenges</h1>
                                    </div>
                                    <div className="caredt-up-btn">
                                        <button className="bg-transparent border-0"><img src={caretup} /></button>
                                    </div>
                                </>
                            )
                        }

                        {betList && betList.data && betList.data.bets
                            && betList.data.bets.map((bets, index) => (
                                    <div className='justify-content-around bg-colr1 px-3 py-3 rounded-xl mb-5 text-white' key={index}>
                                        <div>
                                            <p className="fn_s_12 text-uppercase text-left">{bets?.match?.localTeamName}
                                             
                                                {bets?.match?.localTeamName ? "VS" : ""}

                                             {bets?.match?.visitorTeamName}</p>
                                            <div className="text-left fn_s_18 mt-2">
                                                {
                                                    bets.betQuestion ? <p>{bets.betQuestion}</p> : (
                                                        <>
                                                            <p > {bets?.match?.localTeamName} will win {bets?.match?.visitorTeamName} </p>
                                                        </>
                                                    )
                                                }
                                            </div>

                                            <div className="d-flex ">
                                                <img style={{ height: '5%', width: '5%' }} src={bets.user && bets.user.picture} alt='image' />
                                                <p className="font-size-10px ml-3 mb-0"> Challenge Maker - {bets.user && bets.user.userName}</p>
                                            </div>
                                            <div className='text-left'>
                                                <p className="font-size-10px">{bets.bettaker.length} challenge takers</p>
                                            </div>
                                        </div>
                                    </div>
                            ))
                        }

                        {
                            betsToShow < betList.recordsTotal ?
                                <button className="btn btn-blue spinnerBtn show-more-btn font-family-base mt-3 btn_new" onClick={showBetsMore}>Show More</button>
                                : null
                        }

                        {isBetsLoading && (
                            <span className="ml-3 spinner"></span>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-xxl-4">
                <div className="d-flex justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
                    <div>
                        <div>

                            <h1 className="font-weight-normal">Prediction markets</h1>
                        </div>
                        <div className="link-bg-color marg-top-30px marg-bottom-30px">

                            <a
                                href="#"
                                className="text-white font-weight-normal   font-size-14px mt-2"
                            >
                                {betList && betList.recordsTotal}
                            </a>
                        </div>

                        {
                            betList && betList.data && betList.data.bets && (
                                <>
                                    <div className="pos-relative">

                                        <h1 className="font-weight-normal">Top prediction markets</h1>
                                    </div>
                                    <div className="caredt-up-btn">
                                        <button className="bg-transparent border-0"><img src={caretup} /></button>
                                    </div>

                                </>
                            )
                        }

                        {betList && betList.data && betList.data.bets
                            && betList.data.bets.map((bets, index) => (
                                    <div className='justify-content-around bg-colr1 px-3 py-3 rounded-xl mb-5 text-white' key={index}>
                                        <div>
                                            <p className="fn_s_12 text-uppercase text-left inter-font fw-800">League of Legends: DAMWON KIA vs. PSG Talon</p>
                                            <div className="text-left fn_s_18 mt-2 font-weight-normal">
                                                <p > PSG Talon to make first blood </p>
                                            </div>
                                            <div className="d-flex yes-no-block justify-content-between mb-4">
                                                <div>
                                                    <span>
                                                        <p className="font-size-10px text-white inter-font fw-800 mb-1 green-text">YES</p>
                                                        <h4 className="font-size-15x text-white inter-font fw-800">0.462 ETH</h4>
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <p className="font-size-10px green-text inter-font fw-800 pink-text mb-1" >NO</p>
                                                        <h4 className="font-size-15x text-white inter-font fw-800">0.538 ETH</h4>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex ">
                                                <img style={{ height: '5%', width: '5%' }} src={bets.user && bets.user.picture} alt='image' />
                                                <p className="font-size-10px ml-3 mb-0"> Challenge Maker - {bets.user && bets.user.userName}</p>
                                            </div>
                                            <div className='text-left'>
                                                <p className="font-size-10px mb-0 font-weight-normal">{bets.bettaker.length} challenge takers</p>
                                            </div>
                                        </div>
                                    </div>
                            ))
                        }

                        {
                            betsToShow < betList.recordsTotal ?
                                <button className="btn btn-blue spinnerBtn show-more-btn font-family-base mt-3 btn_new" onClick={showBetsMore}>Show More</button>
                                : null
                        }

                        {isBetsLoading && (
                            <span className="ml-3 spinner"></span>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-xxl-4">
                <div className="justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
                    <div>
                        <div>

                            <h1 className="font-weight-normal">Events</h1>
                        </div>
                        {
                            matchList && matchList.data && matchList.data.matches && (
                                <>
                                    <div className="d-flex justify-content-around px-2 py-2 rounded-xl mb-2">
                                        <div className="link-bg-color text-white w-49">
                                            <h2 className="font-size-12px">Active</h2>
                                            <a href="#" className="text-white font-weight-normal   font-size-14px mt-2">0</a>
                                        </div>
                                        <div className="link-bg-color  text-white w-49">
                                            <h2 className="font-size-12px">Finished</h2>
                                            <a href="#" className="text-white font-weight-normal   font-size-14px mt-2">49</a>
                                        </div>
                                    </div>

                                    <div className=" marg-bottom-30px pos-relative">
                                        <h1 className="font-weight-normal">Viral Events</h1>
                                    </div>
                                    <div className="caredt-up-btn">
                                        <button className="bg-transparent border-0"><img src={caretup} /></button>
                                    </div>
                                </>
                            )
                        }


                        <div className="viral-events-dropdown mb-13">
                            <Select
                                value={{
                                    value: categoryValue.value,
                                    label: categoryValue.label,
                                }}

                                placeholder="Select Category"
                                className={`form-control border-0 p-0 `}
                                classNamePrefix="phoSelect"
                                options={categoryOptions}
                                onChange={(e) => {
                                    setCategoryValue({
                                        value: e.value,
                                        label: e.label
                                    })

                                }}
                            />
                        </div>
                        {matchList && matchList.data && matchList.data.matches
                            && matchList.data.matches.map((matches, index) => (
                                    <div className=" justify-content-around bg-secondary px-6 py-8 rounded-xl mb-7 position-relative opacity-85 text-light" style={{ backgroundImage: `url(${matches?.subCategory?.imageUrl ? matches?.subCategory?.imageUrl : matches?.category?.imageUrl})` }} key={index}>
                                        <div className="text-left font-size-10px mt-2 font-weight-bold inter-font ">
                                            <p className="text-left font-weight-bold font-size-10px  opacity-color text-uppercase mb-1">{toTimestamp(matches.match_date)}</p>

                                            <p className="text-left font-weight-bold font-size-14px text-white mb-1">{matches.localTeamName} VS {matches.visitorTeamName}</p>
                                        </div>
                                        <div className="text-left font-weight-bold font-size-10px  opacity-color text-uppercase mb-1">
                                            <p>Football - {matches.leagueName}</p>
                                        </div>
                                    </div>
                            ))
                        }

                        {isMatchesLoading ? null : (
                            <button className="btn btn-blue spinnerBtn show-more-btn font-family-base mt-3 btn_new" onClick={showMatchesMore}>Show More</button>
                        )}

                        {isMatchesLoading && (
                            <span className="ml-3 spinner"></span>
                        )}
                    </div>
                </div>
            </div>

            {/* <div className="col-lg-6 col-xxl-4">
                <div className="justify-content-around text-center bg-dark px-6 py-8 rounded-xl mb-7">
                    <div>
                        <div>
                            <h1 className="font-weight-normal">Restricted Country Users.</h1>
                        </div>
                        <div className="mt-4">
                            { blockUserList && blockUserList?.data && blockUserList?.data?.countryList
                                && blockUserList?.data?.countryList.map((country, index) => (
                                    <div className="d-flex justify-content-between text-white font-size-10px" key={index}>
                                        <p>{country.countryName}</p>
                                        <div className="">
                                            <p> {country.count} requests</p>
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                blockUserToShow < blockUserList?.data?.totalCountries ?
                                    <button className="btn btn-blue spinnerBtn show-more-btn font-family-base mt-3 btn_new" onClick={showBlockUsersMore}>Show More</button> : null
                            }

                            {isLoading && (
                                <span className="ml-3 spinner"></span>
                            )}
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <StatsWidget11 className="card-stretch card-stretch-half gutter-b" />
                <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
            </div>

            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
                <ListsWidget1 className="card-stretch gutter-b" />
            </div>


            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget4 className="card-stretch gutter-b" />
            </div>


            <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                <MixedWidget14 className="card-stretch gutter-b" />
            </div> */}

        </div>
    </>);
}



