export const FaqMap = {
    CREATE_FAQ_START: 'CREATE_FAQ_START',
    CREATE_FAQ_SUCCESS: 'CREATE_FAQ_SUCCESS',
    CREATE_FAQ_ERROR: 'CREATE_FAQ_ERROR',
    GET_ALL_FAQ_START: 'GET_ALL_FAQ_START',
    GET_ALL_FAQ_SUCCESS: 'GET_ALL_FAQ_SUCCESS',
    GET_ALL_FAQ_ERROR: 'GET_ALL_FAQ_ERROR',  
    UPDATE_FAQ_START: 'UPDATE_FAQ_START',
    UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
    UPDATE_FAQ_ERROR: 'UPDATE_FAQ_ERROR',
    DELETE_FAQ_START: 'DELETE_FAQ_START',
    DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
    DELETE_FAQ_ERROR: 'DELETE_FAQ_ERROR',
    SET_SELECTED_FAQ: 'SET_SELECTED_FAQ',
}

export const FaqActions = {
    getAllFAQStart: (data) => ({ type: FaqMap.GET_ALL_FAQ_START, payload: data }),
    getAllFAQSuccess: (data) => ({ type: FaqMap.GET_ALL_FAQ_SUCCESS, payload: data }),
    getAllFAQError: () => ({ type: FaqMap.GET_ALL_FAQ_ERROR }),

    createFAQStart: () => ({ type: FaqMap.CREATE_FAQ_START }),
    createFAQSuccess: (data) => ({ type: FaqMap.CREATE_FAQ_SUCCESS, payload: data }),
    createFAQError: (errors) => ({ type: FaqMap.CREATE_FAQ_ERROR, payload: { errors } }),

    updateFAQStart: () => ({ type: FaqMap.UPDATE_FAQ_START }),
    updateFAQSuccess: (data) => ({ type: FaqMap.UPDATE_FAQ_SUCCESS, payload: data }),
    updateFAQError: (errors) => ({ type: FaqMap.UPDATE_FAQ_ERROR, payload: { errors } }),

    deleteFAQStart: () => ({ type: FaqMap.DELETE_FAQ_START }),
    deleteFAQSuccess: (data) => ({ type: FaqMap.DELETE_FAQ_SUCCESS, payload: data }),
    deleteFAQError: (errors) => ({ type: FaqMap.DELETE_FAQ_ERROR, payload: { errors } }),

    setSelectedFAQ: (data) => ({ type: FaqMap.SET_SELECTED_FAQ, payload: data }),
}