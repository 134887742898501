import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table, Modal } from "react-bootstrap";
import BasicPagination from "../../pagination/BasicPagination";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { TicketsAndSupportActions } from "../redux/tickets&SupportAction";
import { getAllTicketsAsync, updateRemainingBudgetAsync } from "../redux/tickets&SupportApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewTicketSupportManagement from "./viewTicketSupportManagement";
import { toTimestamp } from '../../../utils/appUtils';

const TicketSupportManagementTable = () => {

  const dispatch = useDispatch();

  const [getInstance, setInstance] = useState();
  const [DetailsModal, setDetailsModal] = useState(false);

  const {
    refreshAllTickets,
    getAllTicketsList,
    skip,
    limit,
    isLoading,
    selectedTicket,
    createFaqModal,
    comments,
  } = useSelector((state) => state.ticketSupportManagement, shallowEqual);
  //save selected ticket data
  const setSelectedTicket = (ticket) => {
    dispatch(TicketsAndSupportActions.setSelectedTicket(ticket));
  };
  
  const DetailsClose = () => setDetailsModal(false);
  //on page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(TicketsAndSupportActions.setTicketBatchNumber(count));
  };
  //save ticket details and show ticket support model
  const DetailsShow = (item) => {
    setSelectedTicket(item);
    setDetailsModal(true);
  };

  const [reply, setReply] = useState(false);
  const handleShowReply = () => setReply(true);
  const handleReply = () => setReply(false);
  //call api for get all tickets
  useEffect(() => {
    if (refreshAllTickets) {
      dispatch(getAllTicketsAsync({ skip, limit }));
    }
  }, [refreshAllTickets, dispatch]);

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>User Name</th>
                  <th>Subject</th>
                  <th>Raised Date</th>
                  <th>Status</th>
                  <th width="150px">Action</th>
                </tr>
              </thead>
              <tbody>
                {getAllTicketsList && getAllTicketsList.data && getAllTicketsList.data.supportTickets && getAllTicketsList.data.supportTickets.length ? (
                  getAllTicketsList.data.supportTickets.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="f-w-500">
                            {skip * limit + (index + 1)}
                          </span>
                        </td>
                        <td>{item.userDetails && item.userDetails.userName}</td>
                        <td>{item.subject}</td>
                        <td>{toTimestamp(item.createdAt)}</td>
                        <td>
                          <span
                            className={`pmnt_btn dup-btn-bg text-white ${item.status === "CLOSED"
                              ? "label label-lg label-light-danger label-inline"
                              : "label label-lg label-light-success label-inline"
                              }`}
                          > {item.status}
                          </span>
                        </td>
                        <td>
                          <a
                            title="Edit"
                            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
                            onClick={() => DetailsShow(item)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-dark">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/custom/eye.svg"
                                )}
                              />
                            </span>
                          </a>
                        </td>

                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center p-0 border-0">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
            <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {getAllTicketsList && getAllTicketsList.data && getAllTicketsList.data.recordsTotal} Records
            </div>
            <div className="col-md-8 text-left mb-5 aspgntn">
              <BasicPagination
                totalRecords={getAllTicketsList && getAllTicketsList.data && getAllTicketsList.data.recordsTotal}
                limit={limit}
                batch={skip + 1}
                onBatchChange={onPageChange}
              />
            </div>

          </div>

        </div>

      </div>
      <Modal
        show={DetailsModal}
        onHide={DetailsClose}
        centered
        size="lg"
        className="def_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">
            Support Ticket Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewTicketSupportManagement
            selectedTicket={selectedTicket}
            isLoading={isLoading}
            handleReply={handleReply}
            handleShowReply={handleShowReply}
            DetailsClose={DetailsClose}
            DetailsModal={DetailsModal}
            reply={reply}
            comments={comments}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TicketSupportManagementTable;
