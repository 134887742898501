export const RewardManagementMap = {
    GET_REWARD_LIST_START: "GET_REWARD_LIST_START",
    GET_REWARD_LIST_SUCCESS: "GET_REWARD_LIST_SUCCESS",
    GET_REWARD_LIST_ERROR: "GET_REWARD_LIST_ERROR",

    SET_REWARD_BATCH_NUMBER: "SET_REWARD_BATCH_NUMBER",

    UPDATE_REWARD_LIST_START: "UPDATE_REWARD_LIST_START",
    UPDATE_REWARD_LIST_SUCCESS: "UPDATE_REWARD_LIST_SUCCESS",
    UPDATE_REWARD_LIST_ERROR: "UPDATE_REWARD_LIST_ERROR",

    SET_REWARD_SEARCH: "SET_REWARD_SEARCH",
    SET_ACTIVITY_TYPE_CHANGE: "SET_ACTIVITY_TYPE_CHANGE",
    REFRESH_REWARD_LIST: "REFRESH_REWARD_LIST"
};

export const RewardManagementActions = {
    getRewardListStart: (data) => ({
        type: RewardManagementMap.GET_REWARD_LIST_START,
        payload: data,
    }),
    getRewardListSuccess: (data) => ({
        type: RewardManagementMap.GET_REWARD_LIST_SUCCESS,
        payload: data,
    }),
    getRewardListError: (errors) => ({
        type: RewardManagementMap.GET_REWARD_LIST_ERROR,
        payload: { errors },
    }),

    setRewardBatchNumber: (data) => ({
        type: RewardManagementMap.SET_REWARD_BATCH_NUMBER,
        payload: data,
    }),

    updateRewardListStart: (data) => ({
        type: RewardManagementMap.UPDATE_REWARD_LIST_START,
        payload: data,
    }),
    updateRewardListSuccess: (data) => ({
        type: RewardManagementMap.UPDATE_REWARD_LIST_SUCCESS,
        payload: data,
    }),
    updateRewardListError: (errors) => ({
        type: RewardManagementMap.UPDATE_REWARD_LIST_ERROR,
        payload: { errors },
    }),

    setRewardSearch: (data) => ({
        type: RewardManagementMap.SET_REWARD_SEARCH,
        payload: data,
    }),
    setActivityTypeChange: (data) => ({
        type: RewardManagementMap.SET_ACTIVITY_TYPE_CHANGE,
        payload: data,
    }),
    refreshRewardList: (data) => ({
        type: RewardManagementMap.REFRESH_REWARD_LIST,
        payload: data,
    }),
};
