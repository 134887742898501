import axios from "axios";
import { UserManagementActions } from "./userManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

const getCommonBaseURL = (state) => {
  return state.environnment.environmentLists.commonBaseURL;
};
//get all user api
export const getAllUsersAsync = (searchText, skip, limit, isJury, dir, searchBy ) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      
      dispatch(UserManagementActions.getUserStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/getAllUsersList`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {skip, limit, search: searchText, isJury: isJury, sortBy: searchBy ,sortType: dir}
      });
      if (data.statusCode === 200) {
        return dispatch(
          UserManagementActions.getUserSuccess(data.data)
        );
      }
      dispatch(UserManagementActions.getUserError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(UserManagementActions.getUserError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//create user api
export const createUserAsync = (userDetail) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      let { _id } = getState().auth.user;
      dispatch(UserManagementActions.createUserStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/createUser/${_id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: userDetail,
      });

      if (data.responseCode === 200) {
        dispatch(UserManagementActions.createUserSuccess(data.responseData));
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(UserManagementActions.createUserError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(UserManagementActions.createUserError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while creating user. Please try again later",
          3000
        )
      );
    }
  };
};
//update user api
export const updateUserAsync = (user, userID, formData) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      const commonBaseURL = getCommonBaseURL(getState());
      dispatch(UserManagementActions.updateUserStart());
      let { _id } = getState().auth.user;
      let profileResult;
      if (formData) {
        const { data } = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_HOST}${commonBaseURL}/uploadImage`,
          data: formData,
        });
        profileResult = data;
      }
      let finalUserDetails = user;
      if (formData && profileResult && profileResult.responseCode === 200) {
        finalUserDetails = {
          ...finalUserDetails,
          profilePicture: profileResult.responseData,
        };
      }
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/updateUser/${_id}/${userID}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: finalUserDetails,
      });
      if (data.responseCode === 200) {
        dispatch(UserManagementActions.updateUserSuccess(data.responseData));
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(UserManagementActions.updateUserError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(UserManagementActions.updateUserError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating user. Please try again later",
          3000
        )
      );
    }
  };
};
//delete user api
export const deleteUserAsync = (userID) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      dispatch(UserManagementActions.deleteUserStart());
      let { _id } = getState().auth.user;
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/deleteUser/${_id}/${userID}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        dispatch(UserManagementActions.deleteUserSuccess(data.responseData));
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(UserManagementActions.deleteUserError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(UserManagementActions.deleteUserError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while deleting user. Please try again later",
          3000
        )
      );
    }
  };
};

export const removeAllActivitiesAsync = (userId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(UserManagementActions.removeAllActivitiesStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/removeAllActivities/${_id}/${userId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (data.responseCode === 200) {
        dispatch(
          UserManagementActions.removeAllActivitiesSuccess(data.responseData)
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      return dispatch(UserManagementActions.removeAllActivitiesError());
    } catch (error) {
      dispatch(UserManagementActions.removeAllActivitiesError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while creating user. Please try again later",
          3000
        )
      );
    }
  };
};

export const removeActivityAsync = (userId, activityId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(UserManagementActions.removeActivityStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/removeActivity/${_id}/${userId}/${activityId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (data.responseCode === 200) {
        dispatch(
          UserManagementActions.removeActivitySuccess(data.responseData)
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      return dispatch(UserManagementActions.removeActivityError());
    } catch (error) {
      dispatch(UserManagementActions.removeActivityError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while creating user. Please try again later",
          3000
        )
      );
    }
  };
};

export const setDefaultPasswordAsync = (setDefaultPassword) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(UserManagementActions.setDefaultPasswordStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/setDefaultPassword/${_id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: setDefaultPassword,
      });
      if (data.responseCode === 200) {
        dispatch(
          UserManagementActions.setDefaultPasswordSuccess(data.responseData)
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      return dispatch(UserManagementActions.setDefaultPasswordError());
    } catch (error) {
      dispatch(UserManagementActions.setDefaultPasswordError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while setting default password. Please try again later",
          3000
        )
      );
    }
  };
};

export const getDefaultPasswordAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(UserManagementActions.getDefaultPasswordStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getDefaultPassword/${_id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        dispatch(
          UserManagementActions.getDefaultPasswordSuccess(data.responseData)
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      return dispatch(UserManagementActions.getDefaultPasswordError());
    } catch (error) {
      dispatch(UserManagementActions.getDefaultPasswordError());
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while getting default password. Please try again later",
          3000
        )
      );
    }
  };
};

export const getAllUserActivitiesAsync = (userId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      let { activitySkip, activityLimit } = getState().userManagement;
      dispatch(UserManagementActions.getAllActivityStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllUserActivities/${_id}/${userId}?skip=${activitySkip}&limit=${activityLimit}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        return dispatch(
          UserManagementActions.getAllActivitySuccess(data.responseData)
        );
      }
      dispatch(UserManagementActions.getAllActivityError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(UserManagementActions.getAllActivityError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };

};
//get user states api
export const getUserStates = (userId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      dispatch(UserManagementActions.getUserStateStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/bet/getUserStats/624d7697c4567642552a9e70`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data.statusCode === 200) {
        return dispatch(
          UserManagementActions.getUserStateSuccess(data.data)
        );
      }
      dispatch(UserManagementActions.getAllUserError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(UserManagementActions.getUserStateError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
}

//get avtar api
export const getAvtar = (userId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      dispatch(UserManagementActions.getAvtarStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/getAvatar`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      if (data.statusCode === 200) {
        return dispatch(
          UserManagementActions.getAvtarSuccess(data.data)
        );
      }
      dispatch(UserManagementActions.getAvtarError());
    } catch (error) {
      dispatch(UserManagementActions.getAvtarError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
}

//create avtar api
export const createAvtar = (avtar) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      dispatch(UserManagementActions.createAvtarStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/uploadAvatar`,
        headers: {
          "Content-Type": "application/json",
        },
        data: avtar
      });
      
      if (data.statusCode === 200) {
        dispatch(
          UserManagementActions.createAvtarSuccess(data.data)
        );
      }
      return dispatch(
        showSuccessSnackbar("success", "Avtar Created Successfully!", 3000)
      );
    } catch (error) {
      dispatch(UserManagementActions.createAvtarError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
}
//update avatar api
export const updateAvtar = (userId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      dispatch(UserManagementActions.updateAvtarStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/avatarStatusChange/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("data", data);
      if (data.statusCode === 202) {
        dispatch(
          UserManagementActions.updateAvtarSuccess()
        );
      }
      return dispatch(
        showSuccessSnackbar("success", data.message, 3000)
      );
    } catch (error) {
      dispatch(UserManagementActions.updateAvtarError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
}
//update jury admin withdraw strike level api
export const updateJuryAdminWithdrawStrikeLevel = (userId, juryStrikeLevel ) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      
      dispatch(UserManagementActions.updateJuryAdminWithdrawalStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/updateJuryAdminWithdrawStrikeLevel/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {"juryStrikeLevel": juryStrikeLevel}
      });
      if (data.statusCode === 200) {
        return dispatch(
          UserManagementActions.updateJuryAdminWithdrawalSuccess(data.data)
        );
      }
      dispatch(UserManagementActions.updateJuryAdminWithdrawalError());
      // return dispatch(
      //   showSuccessSnackbar("success", data.responseMessage, 3000)
      // );
    } catch (error) {
      dispatch(UserManagementActions.updateJuryAdminWithdrawalError());
      // dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//get top users api
export const getTopUsersAsync = (searchText, skip, limit, fromDate , toDate, type , sortBy, sortType) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      
      dispatch(UserManagementActions.getTopUserStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/getTopUserList`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {skip, limit, search: searchText, fromDate , toDate, type: type ,sortBy, sortType}
      });
      if (data.statusCode === 200) {
        return dispatch(
          UserManagementActions.getTopUserSuccess(data.data)
        );
      }
      dispatch(UserManagementActions.getTopUserError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(UserManagementActions.getTopUserError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//send token history api
export const sendTokenHistoryAsync = (values) => {

  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      // dispatch(UserManagementActions.sendtokenHistoryStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/user/sendTokenHistory`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values
      });
      
      if (data.statusCode === 200) {
        // return dispatch(
        //   UserManagementActions.sendtokenHistorySuccess(data.data)
        // );
      }
    } catch (error) {
      // dispatch(UserManagementActions.sendtokenHistoryError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
}