export const ReportsAndAnalyticsMap = {
  
  GET_REPORTS_AND_ANALYTICS_START: "GET_REPORTS_AND_ANALYTICS_START",
  GET_REPORTS_AND_ANALYTICS_SUCCESS: "GET_REPORTS_AND_ANALYTICS_SUCCESS",
  GET_REPORTS_AND_ANALYTICS_ERROR: "GET_REPORTS_AND_ANALYTICS_ERROR",

  GET_MOST_LIVE_EVENTS_START: "GET_MOST_LIVE_EVENTS_START",
  GET_MOST_LIVE_EVENTS_SUCCESS: "GET_MOST_LIVE_EVENTS_SUCCESS",
  GET_MOST_LIVE_EVENTS_ERROR: "GET_MOST_LIVE_EVENTS_ERROR",

  GET_MOST_POPULAR_EVENTS_START: "GET_MOST_POPULAR_EVENTS_START",
  GET_MOST_POPULAR_EVENTS_SUCCESS: "GET_MOST_POPULAR_EVENTS_SUCCESS",
  GET_MOST_POPULAR_EVENTS_ERROR: "GET_MOST_POPULAR_EVENTS_ERROR",

    
  GET_CATEGORY_EVOLUTION_START: "GET_CATEGORY_EVOLUTION_START",
  GET_CATEGORY_EVOLUTION_SUCCESS: "GET_CATEGORY_EVOLUTION_SUCCESS",
  GET_CATEGORY_EVOLUTION_ERROR: "GET_CATEGORY_EVOLUTION_ERROR",
};

export const ReportsAndAnalyticsActions = {

  getReportsAndAnalyticsStart: (data) => ({
    type: ReportsAndAnalyticsMap.GET_REPORTS_AND_ANALYTICS_START,
    payload: data,
  }),
  getReportsAndAnalyticsSuccess: (data) => ({
    type: ReportsAndAnalyticsMap.GET_REPORTS_AND_ANALYTICS_SUCCESS,
    payload: data,
  }),
  getReportsAndAnalyticsError: (errors) => ({
    type: ReportsAndAnalyticsMap.GET_REPORTS_AND_ANALYTICS_ERROR,
    payload: { errors },
  }),

  getMostLiveEventStart: (data) => ({
    type: ReportsAndAnalyticsMap.GET_MOST_LIVE_EVENTS_START,
    payload: data,
  }),
  getMostLiveEventSuccess: (data) => ({
    type: ReportsAndAnalyticsMap.GET_MOST_LIVE_EVENTS_SUCCESS,
    payload: data,
  }),
  getMostLiveEventError: (errors) => ({
    type: ReportsAndAnalyticsMap.GET_MOST_LIVE_EVENTS_ERROR,
    payload: { errors },
  }),

  getMostPopularEventsStart: (data) => ({
    type: ReportsAndAnalyticsMap.GET_MOST_POPULAR_EVENTS_START,
    payload: data,
  }),
  getMostPopularEventsSuccess: (data) => ({
    type: ReportsAndAnalyticsMap.GET_MOST_POPULAR_EVENTS_SUCCESS,
    payload: data,
  }),
  getMostPopularEventsError: (errors) => ({
    type: ReportsAndAnalyticsMap.GET_MOST_POPULAR_EVENTS_ERROR,
    payload: { errors },
  }),

  getCategoryEvolutionStart: (data) => ({
    type: ReportsAndAnalyticsMap.GET_CATEGORY_EVOLUTION_START,
    payload: data,
  }),
  getCategoryEvolutionSuccess: (data) => ({
    type: ReportsAndAnalyticsMap.GET_CATEGORY_EVOLUTION_SUCCESS,
    payload: data,
  }),
  getCategoryEvolutionError: (errors) => ({
    type: ReportsAndAnalyticsMap.GET_CATEGORY_EVOLUTION_ERROR,
    payload: { errors },
  }),
};
