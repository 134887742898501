import React, { useEffect, useRef, useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Row, Col, Grid } from 'react-bootstrap';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAllUsersAsync, UserManagementActions, getAvtar, getTopUsersAsync } from "../redux";
import UserManagementTable from "./user_management_detail/userManagementTable";
import AvtarManagementTable from "./user_management_detail/avtarManagementTable";
import AddAvtar from './user_management_detail/addAvtarForm';
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import TopUserManagementTable from "./user_management_detail/topUserManagementTable";
import DatePicker from 'react-datepicker';

export function UserManagement() {
  const dispatch = useDispatch();

  const history = useHistory();

  const searchRef = useRef();

  const { refreshUserList, searchText, skip, limit , isJury, dir, searchBy} = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  const selectOptions = [{ value: "", label: "All" }, { value: "true", label: "Jury" }]
  //call get all user api
  useEffect(() => {
    if(refreshUserList){
      dispatch(getAllUsersAsync(searchText, skip, limit, isJury.value, dir, searchBy));
    } 
  }, [refreshUserList]);
  //call api while search
  useEffect(() => {
    if (searchRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(UserManagementActions.refreshUserList());
        }
      };
      let input = searchRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(UserManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, []);
  //save search text
  const onSearchTextChange = (e) => {
    dispatch(UserManagementActions.searchTextChange(e.target.value));
  };
  //save edit user details
  const onUserDetailsClick = (user) => {
    dispatch(UserManagementActions.setSelectedUser(user));
    history.push("/user-management/user-management-detail");//redirect to user-management-detail
  };
  //save selected type
  const onSelectChange = (e) => {
    dispatch(UserManagementActions.onSelectChange({ value: e.value, label: e.label }));
  }

  return (
    <>
      <Card>
        <CardHeader title="User Management">
          <CardHeaderToolbar>
            <Row>
              <Col lg={6}>
                <div className="flex-wrap ap_filter_wraper justify-content-end align-items-center" style={{ width: "80%" }}>
                  <div className="viral-events-dropdown">
                    <Select
                      value={{
                        value: isJury.value,
                        label: isJury.label
                      }}
                      isSearchable={false}
                      placeholder="Select Category"
                      className={` border-0 p-0  `}
                      classNamePrefix="phoSelect"
                      options={selectOptions}
                      onChange={onSelectChange}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center mt-2">
                  <form autoComplete="off"
                    className="subheader_filter"
                    onSubmit={(e) => e.preventDefault()}
                  >
                  <div className="form-row">
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={searchText}
                        onChange={onSearchTextChange}
                        ref={searchRef}
                      />
                    </div>
                  </div>
                  </form>
                </div>
              </Col>  
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <UserManagementTable onUserDetailsClick={onUserDetailsClick} />
      </Card>
    </>
  );
}




export function AvtarManagement() {

  const dispatch = useDispatch();

  const history = useHistory();

  const searchRef = useRef();

  const { refreshAvtarList, searchText } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  const [addAvtar, setAddAvtar] = useState(false);

  const [file, setFile] = useState({ file: null, url: null });

  useEffect(() => {
    if(refreshAvtarList){
      dispatch(getAvtar());
    }
  }, [refreshAvtarList]);

  const onAvtarDetailsClick = () => {
    setAddAvtar(true);
  };

  return (
    <>
      <Card>
        <CardHeader title="Avtar Management">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form autoComplete="off"
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-5 mb-1 mt-2">
                    <button
                      className="btn_new btn-sm"
                      type="button"
                      onClick={() => onAvtarDetailsClick()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <AvtarManagementTable />
        <AddAvtar addAvtar={addAvtar} setAddAvtar={setAddAvtar} file={file} setFile={setFile} />
      </Card>
    </>
  );
}

export function TopUserManagement() {
  const dispatch = useDispatch();

  const history = useHistory();

  const searchTopUserRef = useRef();

  const { refreshTopUserList, topUserSearch, topUserSkip, topUserLimit, fromDate , toDate, type , topUserSearchBy, topUserDir} = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  const selectOptions = [
  // { value: "", label: "All" },
  { value: "followers", label: "Followers" },
  { value: "followings", label: "Followings" }, 
  { value: "totalBets", label: "Bets" },
  { value: "winBetCount", label: "Won Bets" },
  { value: "lossBetCount", label: "Lost Bets" },
  { value: "highestPayingBets", label: "Highest Paying Bets" },]

  useEffect(() => {
    if(refreshTopUserList){
      dispatch(getTopUsersAsync(topUserSearch, topUserSkip, topUserLimit, fromDate , toDate, type.value, topUserSearchBy, topUserDir ));
    } 
  }, [refreshTopUserList]);

  useEffect(() => {
    if (searchTopUserRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(UserManagementActions.refreshTopUserList());
        }
      };
      let input = searchTopUserRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(UserManagementActions.topUserSearchChange(""));
        input.value = "";
      };
    }
  }, []);

  const onTopUserSearchChange = (e) => {
    dispatch(UserManagementActions.topUserSearchChange(e.target.value));
  };

  const onTypeSelectChange = (e) => {
    dispatch(UserManagementActions.setTopUserType({ value: e.value, label: e.label }));
  };

  const handleToDateChange = (date) => {
    dispatch(UserManagementActions.toDateChanges(date));
  }
  const handleFromDateChange = (date) => {
    dispatch(UserManagementActions.fromDateChanges(date));
  }
  const getUserWithDateChange = () => {
    dispatch(UserManagementActions.getuserWithDateChanges());
  }

  const resetChanges = () => {
    dispatch(UserManagementActions.resetFilters());
  }

  return (
    <>
      <Card>
        <CardHeader title="Top User Management">
          <CardHeaderToolbar>  
          <Row>
            <Col lg={4}>
              <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center" style={{ width: "10%" }}>
                <div className="form-row">
                  <div className="form-group mr-3 mb-1 mt-3">
                    <DatePicker
                      selected={fromDate}
                      autoComplete="off"
                      placeholderText="From date"
                      onChange={date => handleFromDateChange(date)}
                      name="fromDate"
                      value={fromDate}
                      maxDate={new Date()}
                      dateFormat="MMMM d, yyyy"
                    />
                  </div>
                </div>
              </div>
            </Col> 
            <Col lg={1}>
              <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center" style={{ width: "10%" }}>
                <div className="form-row">
                  <div className="form-group mr-3 mb-1 mt-3">
                  <DatePicker
                    name="toDate"
                    placeholderText="To date"
                    autoComplete="off"
                    value={toDate}
                    dateFormat="MMMM d, yyyy"
                    selected={toDate}
                    maxDate={new Date()}
                    onChange={date => handleToDateChange(date)}
                  />
                  </div>
                </div>
              </div>
            </Col> 
            <Col lg={3}>
                <div className="flex-wrap ap_filter_wraper justify-content-end align-items-center">
                  <div className="viral-events-dropdown">
                    <Select
                      value={{
                        value: type.value,
                        label: type.label
                      }}
                      isSearchable={false}
                      placeholder="Select Category"
                      className={` border-0 p-0  `}
                      classNamePrefix="phoSelect"
                      options={selectOptions}
                      onChange={(e) => onTypeSelectChange(e)}
                    />
                  </div>
                </div>
              </Col>
            <Col lg={2}>
              <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
                <form autoComplete="off"
                  className="subheader_filter"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="form-row">
                    <div className="form-group mr-5 mb-1 mt-2">
                      <button
                        className="btn_new btn-sm"
                        type="button"
                        onClick={() => getUserWithDateChange()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Col> 
            <Col lg={2}>
              <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
                <form autoComplete="off"
                  className="subheader_filter"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="form-row">
                    <div className="form-group mr-5 mb-1 mt-2">
                      <button
                        className="btn_new btn-sm"
                        type="button"
                        onClick={() => resetChanges()}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Col>
            {/*<Col lg={2}>
              <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
                <form autoComplete="off"
                  className="subheader_filter"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="form-row">
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={topUserSearch}
                        onChange={onTopUserSearchChange}
                        ref={searchTopUserRef}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Col> */}
          </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <TopUserManagementTable />
      </Card>
    </>
  );
}




