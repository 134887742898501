import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { addCategoryAsync, editCategoryAsync } from '../redux/servicesApi';
import { ServicesManagementActions } from '../redux/servicesAction'
import Checkbox from "./checkbox";
import {Constants} from '../../../../constants/constants';

const AddCategory = ({ addCategory, setAddCategory, selectedCategory, file, setFile }) => {
  //formik validation schema
  const addServiceSchema = Yup.object().shape({
    categoryName: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please enter category Name"),
    categoryImage: Object.keys(selectedCategory).length === 0 ? Yup.string()
      .required("Please enter category Image") : Yup.string(),
  })

  const { isModelLoading, reDirect, refreshCategoryList } = useSelector(
    (state) => state.servicesManagement,
    shallowEqual
  );

  const dispatch = useDispatch();
  const history = useHistory();

  //on hide add category model
  const onHideCategory = () => {
    setFile({ file: null, url: null })
    dispatch(ServicesManagementActions.refreshDetails())//refresh details
    setAddCategory(false);
  }
  //save upload image details
  const uploadImage = (e, setFieldValue) => {
    setFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    setFieldValue("categoryImage", e.currentTarget.files[0])
  };

  const handleClick = (e, setFieldValue) => {
    const { checked } = e.target
    // console.log("checked",checked);
    setFieldValue('isCustom', checked)
  }
  //close add category model
  useEffect(() => {
    if (reDirect === true) {
      setFile({ file: null, url: null })
      setAddCategory(false);
    }
  }, [reDirect])

  return (
    <>

      <Modal
        show={addCategory}
        onHide={() => onHideCategory()}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font-size-18px font-weight-normal text-white">{selectedCategory._id ? "Edit Category" : "Add Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              categoryName: selectedCategory.name || "", categoryImage: "", 
            }}
            validationSchema={addServiceSchema}
            onSubmit={values => {

              if (!selectedCategory._id) {
                const formData = new FormData();
                formData.append('image', values.categoryImage);
                formData.append('name', values.categoryName);
                formData.append('isCustom', true);
                dispatch(addCategoryAsync(formData, setAddCategory));
              }
              if (selectedCategory._id) {
                const formData = new FormData();
                if (selectedCategory.name !== values.categoryName) {
                  formData.append('name', values.categoryName);
                }
                if (values.categoryImage !== "") {
                  formData.append('image', values.categoryImage);
                }
            
                dispatch(editCategoryAsync(formData, selectedCategory._id));
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleSubmit
            }) => (

              <Form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-group">
                  <label className="form-label text-white">Category Name</label>
                  <h1 className="text-white">{selectedCategory.isCustom === false && values.categoryName}</h1>
                  {( !selectedCategory._id || selectedCategory.isCustom === true ) && <input
                    placeholder="Enter Category Name"
                    type="text"
                    name="categoryName"
                    className={
                      errors.categoryName && touched.categoryName
                        ? "form-control re_inputRouded is-invalid border-less-input"
                        : "form-control re_inputRouded border-less-input"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.categoryName}
                  />}
                  {touched.categoryName && errors.categoryName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.categoryName}
                      </div>
                    </div>
                  ) : null}
                </div>
                  
                <div className="form-group">
                  <label className="form-label text-white">Category Image</label>
                  <div
                    className="pro_img text-center"
                    style={{
                      backgroundImage: `url(${file.url ||
                        selectedCategory.imageUrl})`
                    }}
                  >
                    <input
                      type="file"
                      name="categoryImage"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => uploadImage(e, setFieldValue)}
                    />
                    <div className="edt_img_btn">
                      {/* <div className="upload-pic-btn">
                        <span className="material-icons">edit</span>{selectedCategory.imageUrl ? "EDIT" : "Upload"}
                      </div> */}
                      {/* <div className="upload-pic-btn"><span className="material-icons">upload</span>Upload</div> */}
                      <div className="edt_img_btn"><div className="upload-pic-btn"><span className="material-icons">upload</span>Upload</div></div>
                    </div>
                  </div>
                  {touched.categoryImage && errors.categoryImage ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.categoryImage}
                      </div>
                    </div>
                  ) : null}
                </div>

                <label className="text-white font-size-16px inter-font font-weight-normal">Suggestion for best UI Experience: </label>
                <p><b>Image Dimensions: <span className="text-danger">{Constants.ImageConstants.CATEGORY_DIMENSIONS}</span></b></p>
                <p>Image Compression Tool: <span><a href="https://imagecompressor.com/" target="_blank" className="text-info">https://imagecompressor.com/</a></span></p>

                <div className="form-group  mb-0 mt-4">
                  <div className="form-group mr-5 mb-1 mt-2">
                    {isModelLoading ? (
                      <div className="ml-3 basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-blue spinnerBtn add-avtar-btn"
                        disabled={isModelLoading}
                      >
                        <span>{selectedCategory._id ? "Update" : "Add"}</span>

                      </button>
                    )}
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddCategory

