import { Constants } from "../../constants/constants";
import { showSuccessSnackbar } from "../../app/modules/snackBar/snackBar.action";
import {updateAllRewardAsync, RewardManagementActions} from '../../app/modules/reward_management/redux';
import Web3 from "web3";
import {UserManagementActions} from '../../app/modules/user_management/redux/userManagementAction';
import { sendTokenHistoryAsync} from '../../app/modules/user_management/redux/userManagementApi';

function getInstance(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 != '') {
            try {
                let Instance = await new web3.eth.Contract(
                    Constants.enviornment.configABI,
                    Constants.enviornment.configAddress
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({ error: "Issue with instance" });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

function getInstanceAdmin(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 != '') {
            try {
                let Instance = await new web3.eth.Contract(
                    Constants.enviornment.requestManagerABI,
                    Constants.enviornment.requestManagerAddress
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({ error: "Issue with instance" });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

function getInstanceRewardDistribution(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 != '') {
            try {
                let Instance = await new web3.eth.Contract(
                    Constants.enviornment.rewardDistributionABI,
                    Constants.enviornment.rewardDistributionAddress
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({ error: "Issue with instance" });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

function getInstanceDbethToken(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 != '') {
            try {
                let Instance = await new web3.eth.Contract(
                    Constants.enviornment.dbethTokenABI,
                    Constants.enviornment.dbethTokenAddress
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({ error: "Issue with instance" });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};


function setAaveFeeConfig(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setAaveFeeConfig(data.Bet_Winner_Distribution, data.Bet_Looser_Distribution)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            resolve(error);
        }
    });
}

function setAaveThreshold(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setAaveThreshold(data.threshold)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            resolve(error);
        }
    });
}

function setAdminConfig(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setAdmin(data.admin)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            resolve(error);
        }
    });
}

function setBlacklistedAssetConfig(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setBlacklistedAsset(data.asset)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            resolve(error);
        }
    });
}

function setDisputeConfig(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setDisputeConfig(data.DecimalValueEscAmo, data.DecimalValueJurAmo)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            // console.log({error})
            reject(error);
        }
    });
}

function setFeeDeductionConfig(ercInstance, walletAddress, data) {
    console.log({ ercInstance, walletAddress, data })
    console.log("hiiiiii")
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setFeeDeductionConfig(
                    data.after_full_swap_treasury_wallet_transfer,
                    data.after_full_swap_without_trend_setter_treasury_wallet_transfer,
                    data.dbeth_swap_amount_with_trend_setter,
                    data.dbeth_swap_amount_without_trend_setter,
                    data.bet_trend_setter_reward,
                    data.reward_for_live_stream_creator,
                    data.pool_distribution_amount,
                    data.burn_amount,
                    data.pool_distribution_amount_without_trend_setter,
                    data.burn_amount_without_trend_setter
                )
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}

function setPairAddresses(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setPairAddresses(data.Token_A, data.Token_B)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            resolve(error);
        }
    });
}


function setWalletAddress(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setWalletAddress(data.Developer, data.BurnAddress, data.PoolAddress, data.Escrow)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            resolve(error);
        }
    });
}

function setJuryTokensSharePerStrike(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setJuryTokensSharePerStrike([1,2,3], data)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                    //setRefreshJuryTokens((preValues) => !preValues);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            resolve(error);
        }
    });
}

function setTokensPerStrike(ercInstance, walletAddress, data, setRefreshParticipentTokens) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        let token = data.Token*1e18
        // console.log({token})
        try {
            return await ercInstance.methods
                .setTokensPerStrike(data.Strike, token.toString())
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                    setRefreshParticipentTokens((preValues) => !preValues);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            resolve(error);
        }
    });
}

function forwardAdminWithdrawal(ercInstance, walletAddress, data, setWithdrawalProcess) {
    return async (dispatch, getState) => {
        // console.log({ ercInstance, walletAddress, data })
        return new Promise(async (resolve, reject) => {
            try {
                return await ercInstance.methods
                    .ForwardAdminWithdrawal(data)
                    .send({ from: walletAddress })
                    .on("receipt", function (receipt) {
                        // console.log({ receipt })
                        resolve(receipt);
                        setWithdrawalProcess(true)
                        return dispatch(showSuccessSnackbar("success", "Stack Token transferd successfully.", 3000));
                    })
                    .on("error", function (error, receipt) {
                        // console.log({ error })
                        reject({ error: error });
                        return dispatch(showSuccessSnackbar("error", "error!", 3000))
                    });
            } catch (error) {
                resolve(error);
            }
        });
    }
    
    
}

async function getAaveConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getAaveConfig()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getAaveThresholdConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getAaveTimeThresold()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getAdminConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getAdmin()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getBlackListedAssetConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getBlacklistedAsset()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getDisputeConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getDisputeConfig()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getFeeDeductionConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getFeeDeductionConfig()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getPairAddressesConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getPairAddress()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getWalletAddressConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getWalletAddress()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getJuryTokensShareConfig(ercInstance, walletAddress, data) {
    try {
        let tokenStatisticsVersion = await getTokenStatisticsVersionCounterConfig(ercInstance, walletAddress, data);
            if(typeof tokenStatisticsVersion !== 'undefined'){
                return await ercInstance.methods
                    .getJuryTokensShare(data.strike, tokenStatisticsVersion)
                    
                    .call({ from: walletAddress }, (error, result) => {
                        if (error)
                            return error
                        else
                            return result
                    });
        }
    } catch (error) {
        return error
    }
};

async function getTokenStatisticsVersionCounterConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .tokenStatisticsVersionCounter()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getParticipentTokensShareConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getTokensPerStrike(data.strike)
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

function setAdminResolution(ercInstance, walletAddress, data) {
    // console.log({ data })

    return new Promise(async (resolve, reject) => {
        try {
            var reverseHash = data.hash.slice().reverse();
            return await ercInstance.methods
                .ForwardAdminResolution(data.betAddress, data.verdict, data.juryNotVoted, reverseHash, data.maker, data.taker)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            reject(error);
        }
    });
}

function setAdminResolutionForUnavailableEvidance(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            var reverseHash = data.hash.slice().reverse();
            return await ercInstance.methods
                .ForwardAdminResolutionForUnavailableEvidance(data.betAddress, data.verdict, reverseHash, data.maker, data.taker)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            reject(error);
        }
    });
}

// function getInstanceFactory(web3) {

//     return new Promise(async (resolve, reject) => {
//         if (web3 && web3 != '') {
//             try {
//                 let Instance = await new web3.eth.Contract(
//                     Constants.enviornment.factoryABI,
//                     Constants.enviornment.factoryAddress
//                 );

//                 if (Instance) {
//                     resolve(Instance);
//                 } else {
//                     reject({ error: "Issue with instance" });
//                 }
//             } catch (error) {
//                 reject(error);
//             }
//         }
//     });
// };

function setBanBet(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .ForwardBanBet(data.bet_id)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log("receipt", { receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            reject(error);
        }
    });
}

function setPlatformFees(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setPlatformFeePercantageOnBetLevel([1,2,3,4,5],[data.level0, data.level1, data.level2, data.level3, data.level4])
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            // console.log({error});
            resolve(error);
        }
    });
}

function setHighestBetLavelrange(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setHighestBetLavelrange(data.highestBetLavelrange)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            // console.log({error});
            resolve(error);
        }
    });
}

async function getPlatformFeesConfig(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getPlatformFeePercantages()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

async function getHighestBetLavelrange(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .getHighestBetLavelrange()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        return error
    }
};

function setBetRangeOnDifferentLavels(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setBetRangeOnDifferentLavels(data.level, data.lowerBand, data.upperBand)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            // console.log({ error });
            resolve(error);
        }
    });
}


function rewardWithdrawal(ercInstance, walletAddress, wallerAddressArray, rewardArray, allRewardList) {
    // console.log({ ercInstance, walletAddress, wallerAddressArray, rewardArray })
    return async (dispatch, getState) => {
        
        return new Promise(async (resolve, reject) => {
            try {
                return await ercInstance.methods
                    .approveReward(wallerAddressArray, rewardArray)
                    //.approveReward(['0xd0dce50946ce6eb835c73b40908731a6e49c2748'], ['500000000000000000'])
                    .send({ from: walletAddress })
                    .on("receipt", function (receipt) {
                        // console.log({ receipt })
                        resolve(receipt);
                        return dispatch(updateAllRewardAsync(allRewardList));
                    })
                    .on("error", function (error, receipt) {
                        // console.log({ error })
                        reject({ error: error });
                        dispatch(RewardManagementActions.updateRewardListError());
                        return dispatch(showSuccessSnackbar("error", "error!", 3000))
                    });
            } catch (error) {
                // console.log({error});
                resolve(error);
                dispatch(RewardManagementActions.updateRewardListError());
                return dispatch(showSuccessSnackbar("error", "error!", 3000))
            }
        });
    }  
}

function setAllowance(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            const dataToSend = Web3.utils.toWei(data, 'ether');
            return await ercInstance.methods
                .approve(Constants.enviornment.disputeResolutionAddress,(dataToSend).toString())
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            // console.log({ error })
            resolve(error);
        }
    });
}

function setAllowanceForReward(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            const dataToSend = Web3.utils.toWei(data, 'ether');
            return await ercInstance.methods
                .approve(Constants.enviornment.rewardDistributionAddress,(dataToSend).toString())
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            // console.log({ error })
            resolve(error);
        }
    });
}

function setRefillTokens(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            const dataToSend = Web3.utils.toWei(data, 'ether');
            // console.log({ dataToSend })
            return await ercInstance.methods
                .refilTokens((dataToSend).toString())
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            // console.log({error});
            resolve(error);
        }
    });
}

async function getAllowance(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .allowance(walletAddress,Constants.enviornment.disputeResolutionAddress)
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        // console.log({error});
        return error
    }
};

async function getRefillTokens(ercInstance, walletAddress, data) {
    try {
        return await ercInstance.methods
            .balanceOf(Constants.enviornment.rewardDistributionAddress)
            .call({ from: walletAddress });
    } catch (error) {
        // console.log({error});
        return error
    }
};

async function getContract(ercInstance, walletAddress, data) {
    
    try {
        return await ercInstance.methods
            .getFreezingStatus()
            .call({ from: walletAddress }, (error, result) => {
                if (error)
                    return error
                else
                    return result
            });
    } catch (error) {
        // console.log({error});
        return error
    }
};

function setContract(ercInstance, walletAddress, Contract_Details) {
    // return async (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            try {
                return await ercInstance.methods
                    .freezeOrUnfreezeOperations()
                    .send({ from: walletAddress })
                    .on("receipt", function (receipt) {
                        resolve(receipt);
                        //return dispatch(showSuccessSnackbar("success", "Contract updated successfully", 3000))
                    })
                    .on("error", function (error, receipt) {
                        
                        reject({ error: error });
                        //return dispatch(showSuccessSnackbar("error", "error!", 3000))
                    });
            } catch (error) {
                console.log({error});
                resolve(error);
                //return dispatch(showSuccessSnackbar("error", "error!", 3000))
            }
        });
    //  }  
}

function setRefillTokens2(ercInstance, walletAddress, data) {
    // console.log({ ercInstance, walletAddress, data })
    return new Promise(async (resolve, reject) => {
        try {
            const dataToSend = Web3.utils.toWei(data, 'ether');
            // console.log({ dataToSend })
            return await ercInstance.methods
                .refilTokens((dataToSend).toString())
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    // console.log({ receipt })
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    // console.log({ error })
                    reject({ error: error });
                });
        } catch (error) {
            // console.log({error});
            resolve(error);
        }
    });
}

function sendTokenAmountToUser(ercInstance, walletAddress, userWalletAdd, amount, values) {
    return async (dispatch, getState) => {
        // console.log({ ercInstance, walletAddress, amount })
        dispatch(UserManagementActions.sendtokenHistoryStart());
        return new Promise(async (resolve, reject) => {
            try {
                const dataToSend = Web3.utils.toWei(amount, 'ether');
                console.log({ dataToSend })
                return await ercInstance.methods
                    .transfer(userWalletAdd, (dataToSend).toString())
                    //.transfer(userWalletAdd, (dataToSend).toString())
                    .send({ from: walletAddress })
                    .on("receipt", function (receipt) {
                        // console.log({ receipt })
                        resolve(receipt);
                        dispatch(sendTokenHistoryAsync(values))
                        dispatch(showSuccessSnackbar("success", "Token Amount sent successfully.", 3000));
                        return dispatch(
                            UserManagementActions.sendtokenHistorySuccess()
                        )
                    })
                    .on("error", function (error, receipt) {
                        // console.log({ error })
                        reject({ error: error });
                        dispatch(showSuccessSnackbar("error", "error!", 3000))
                        return dispatch(UserManagementActions.sendtokenHistoryError());
                    });
            } catch (error) {
                console.log({error});
                resolve(error);
            }
        });
    }
    
    
}


export const poolMethods = {
    getInstance,
    setAaveFeeConfig,
    setAaveThreshold,
    setAdminConfig,
    setBlacklistedAssetConfig,
    setDisputeConfig,
    setFeeDeductionConfig,
    setPairAddresses,
    setWalletAddress,
    getAaveConfig,
    getAaveThresholdConfig,
    getAdminConfig,
    getBlackListedAssetConfig,
    getDisputeConfig,
    getFeeDeductionConfig,
    getPairAddressesConfig,
    getWalletAddressConfig,
    setAdminResolution,
    getInstanceAdmin,
    setAdminResolutionForUnavailableEvidance,
    //getInstanceFactory,
    setBanBet,
    getJuryTokensShareConfig,
    getParticipentTokensShareConfig,
    setJuryTokensSharePerStrike,
    setTokensPerStrike,
    forwardAdminWithdrawal,
    setPlatformFees,
    getPlatformFeesConfig,
    setHighestBetLavelrange,
    getHighestBetLavelrange,
    setBetRangeOnDifferentLavels,
    rewardWithdrawal,
    setRefillTokens,
    setAllowance,
    getRefillTokens,
    getAllowance,
    getInstanceRewardDistribution,
    getInstanceDbethToken,
    setAllowanceForReward,
    getContract,
    setContract,
    sendTokenAmountToUser

}