export const DashboardMap = {
    GET_USER_COUNT_START: 'GET_USER_COUNT_START',
    GET_USER_COUNT_SUCCESS: 'GET_USER_COUNT_SUCCESS',
    GET_USER_COUNT_ERROR: 'GET_USER_COUNT_ERROR',
    
    GET_ALL_USER_START: 'GET_ALL_USER_START',
    GET_ALL_USER_SUCCESS: 'GET_ALL_USER_SUCCESS',
    GET_ALL_USER_ERROR: 'GET_ALL_USER_ERROR',

    GET_ALL_BETS_START: 'GET_ALL_BETS_START',
    GET_ALL_BETS_SUCCESS: 'GET_ALL_BETS_SUCCESS',
    GET_ALL_BETS_ERROR: 'GET_ALL_BETS_ERROR',

    GET_ALL_MATCHES_START: 'GET_ALL_MATCHES_START',
    GET_ALL_MATCHES_SUCCESS: 'GET_ALL_MATCHES_SUCCESS',
    GET_ALL_MATCHES_ERROR: 'GET_ALL_MATCHES_ERROR',

    SET_EVENT_BATCH_NUMBER: "SET_EVENT_BATCH_NUMBER",

    SET_EVENTSEARCH_CHANGE: "SET_EVENTSEARCH_CHANGE",

    REFRESH_EVENT_LIST: "REFRESH_EVENT_LIST",

    GET_TAG_START: "GET_TAG_START",
    GET_TAG_SUCCESS: "GET_TAG_SUCCESS",
    GET_TAG_ERROR: "GET_TAG_ERROR",

    REFRESH_TAG_LIST: "REFRESH_TAG_LIST",

    GET_BLOCK_USER_LIST_START: 'GET_BLOCK_USER_LIST_START',
    GET_BLOCK_USER_LIST_SUCCESS: 'GET_BLOCK_USER_LIST_SUCCESS',
    GET_BLOCK_USER_LIST_ERROR: 'GET_USER_COUNT_ERROR',

}

export const DashboardActions = {
    getUserCountStart: (data) => ({ type: DashboardMap.GET_USER_COUNT_START, payload: data }),
    getUserCountSuccess: (data) => ({ type: DashboardMap.GET_USER_COUNT_SUCCESS, payload: data }),
    getUserCountError: (data) => ({ type: DashboardMap.GET_USER_COUNT_ERROR, payload: data }),

    getAllUserStart: (data) => ({ type: DashboardMap.GET_ALL_USER_START, payload: data }),
    getAllUserSuccess: (data) => ({ type: DashboardMap.GET_ALL_USER_SUCCESS, payload: data }),
    getAllUserError: (data) => ({ type: DashboardMap.GET_ALL_USER_ERROR, payload: data }),

    getAllBetsStart: (data) => ({ type: DashboardMap.GET_ALL_BETS_START, payload: data }),
    getAllBetsSuccess: (data) => ({ type: DashboardMap.GET_ALL_BETS_SUCCESS, payload: data }),
    getAllBetsError: (data) => ({ type: DashboardMap.GET_ALL_BETS_ERROR, payload: data }),

    getAllMatchesStart: (data) => ({ type: DashboardMap.GET_ALL_MATCHES_START, payload: data }),
    getAllMatchesSuccess: (data) => ({ type: DashboardMap.GET_ALL_MATCHES_SUCCESS, payload: data }),
    getAllMatchesError: (data) => ({ type: DashboardMap.GET_ALL_MATCHES_ERROR, payload: data }),

    setEventBatchNumber: (data) => ({
        type: DashboardMap.SET_EVENT_BATCH_NUMBER,
        payload: data,
    }),

    searchEventTextChange: (data) => ({
        type: DashboardMap.SET_EVENTSEARCH_CHANGE,
        payload: data,
    }),

    refreshEventList: () => ({ type: DashboardMap.REFRESH_EVENT_LIST }),

    getTagStart: (data) => ({
        type: DashboardMap.GET_TAG_START,
        payload: data,
    }),
    getTagSuccess: (data) => ({
        type: DashboardMap.GET_TAG_SUCCESS,
        payload: data,
    }),
    getTagError: (errors) => ({
        type: DashboardMap.GET_TAG_ERROR,
        payload: { errors },
    }),

    refreshTagList: () => ({ type: DashboardMap.REFRESH_TAG_LIST }),

    getBlockUserListStart: (data) => ({ type: DashboardMap.GET_BLOCK_USER_LIST_START, payload: data }),
    getBlockUserListSuccess: (data) => ({ type: DashboardMap.GET_BLOCK_USER_LIST_SUCCESS, payload: data }),
    getBlockUserListError: (data) => ({ type: DashboardMap.GET_BLOCK_USER_LIST_ERROR, payload: data }),

}