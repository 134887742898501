import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Tabs, Tab , Table, Modal } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import {updatePaymentAsync, deletePaymentAsync} from '../../redux/paymentApi'
import Checkbox from "./checkbox";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function PaymentTable({allPayment, setAllPayment, onEditClick}) {

  const dispatch = useDispatch();

  const { isLoading, payment } = useSelector(
    (state) => state.payment,
    shallowEqual
  );
  //call delete payment api
  const onDeleteClick = (id) => {
    dispatch(deletePaymentAsync(id))
  }
  //change payment status
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setAllPayment([...allPayment, id]);
    if (!checked) {
      setAllPayment(allPayment.filter((item) => item !== id));
    }
    dispatch(updatePaymentAsync(id))//call update payment api
  }
  //set active token id in array for checkbox select
  useEffect(() => {
    if(payment.length > 0){
        const allPay = Array.from(payment.filter(obj => obj.status === "ACTIVE"), ({ _id }) => _id)
        setAllPayment(allPay);
    }
  }, [payment])

 
  return (
    <>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              id="myTable"
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                    <th>{" "}</th>
                  <th className="mt-5">Cryptocurrency</th>
                  <th className="mt-5">Price(USD)</th>
                  <th className="mt-5">Icon</th>
                  <th className="wd-120">Actions</th>
                </tr>
              </thead>
              <tbody>
              {payment && payment.length ? (
                payment.map((payments, index) => {
                  return (
                  <tr key={index.toString()}>
                    <td>
                    <Checkbox type='checkbox' id={payments._id} 
                        handleClick = {handleClick}
                        isChecked={allPayment.includes(payments._id)}/> 
                    </td>
                    <td>
                        <span className="f-w-500">{payments.short_name}</span>
                    </td>
                    <td>
                        <span className="f-w-500">{payments.tokenPriceUsd && payments.tokenPriceUsd.toFixed(4)}</span>
                    </td>
                    <td>
                        <span className="f-w-500"><img height="40px" width="40px" src={payments.tokenImageUrl} alt="icon not found" /></span>
                    </td>
                    <td>
                      <a
                        title="Edit"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                        onClick={() => onEditClick(payments)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                      <a
                      title="Delete"
                      className="btn btn-icon btn-light btn-hover-danger btn-sm"
                      onClick={() => onDeleteClick(payments._id)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                      </span>
                    </a>
                    </td>
                  </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Record Found
                  </td>
                </tr>
              )}
              {isLoading && (
                <tr>
                  <td colSpan={8} className="text-center p-0 border-0">
                    <div className="basic-verification-loader text-center">
                      <CircularProgress />
                    </div>
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

    </div>
    </>
  );
}
