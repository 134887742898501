import axios from "axios";
import { BetManagementActions } from "./betManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

//get all bets api
export const getAllBetAsync = (skip, limit, search, disputeType, sortType, sortBy) => {
  
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      dispatch(BetManagementActions.getAllBetStart());

      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/bet/getAllBet?skip=${skip}&limit=${limit}&search=${search}&disputeType=${disputeType}&sortType=${sortType}&sortBy=${sortBy}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.statusCode === 200) {
        return dispatch(
          BetManagementActions.getAllBetSuccess(data)
        );
      }
      dispatch(BetManagementActions.getAllBetError());
      
    } catch (error) {
      // console.log({error})
      dispatch(BetManagementActions.getAllBetError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

//delete bet api
export const deleteBetAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
 
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
    
      dispatch(BetManagementActions.deleteBetStart());

      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/bet/updateBetStatus`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      
      if (data.statusCode === 200) {
        dispatch(BetManagementActions.deleteBetSuccess(data));
        
        if(data.data.bets.betStatus == 'CANCELLED'){
          return dispatch(
            showSuccessSnackbar("success", `Bet Cancelled Successfully`, 3000)
          )
        }
        else {
          return dispatch(
            showSuccessSnackbar("success", `Bet Cancellation Failed`, 3000)
          )
        }
        
      }
      dispatch(BetManagementActions.deleteBetError());
 
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(BetManagementActions.deleteBetError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//get user bet result api
export const getUserBetResultAsync = (id) => {
  
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      dispatch(BetManagementActions.getUserBetResultStart());

      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/result/getUserBetResult?id=${id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {"bet_id": id}
      });
      if (data.statusCode === 200) {
        return dispatch(
          BetManagementActions.getUserBetResultSuccess(data)
        );
      }
      dispatch(BetManagementActions.getUserBetResultError());
      
    } catch (error) {

      dispatch(BetManagementActions.getUserBetResultError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//get user jury votes api
export const getUserJuryVotesAsync = (id) => {
  
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      
      dispatch(BetManagementActions.getUserJuryVotesStart());
      
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/result/getJuryVotesForAdmin`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {"bet_id": id}
      });
      if (data.statusCode === 200) {
        return dispatch(
          BetManagementActions.getUserJuryVotesSuccess(data)
        );
      }
      dispatch(BetManagementActions.getUserJuryVotesError());
      
    } catch (error) {

      dispatch(BetManagementActions.getUserJuryVotesError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//give result by admin api
export const giveResultByAdminAsync = (values) => {
  
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      dispatch(BetManagementActions.giveResultByAdminStart());

      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/result/giveResultByAdmin`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values
      });
   
      if(data.statusCode === 200){
        dispatch(BetManagementActions.giveResultByAdminSuccess(data))
        
        return dispatch(
          showSuccessSnackbar("success", `The result has been submitted sucessfully`, 3000)
        )
      }
      dispatch(BetManagementActions.giveResultByAdminError());
      
    } catch (error) {
      // console.log({error})
      dispatch(BetManagementActions.giveResultByAdminError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
//cancel bet by admin api
export const cancelBetByAdminAsync = (bet_id) => {
  
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;

      dispatch(BetManagementActions.cancelBetByAdminStart());

      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/bet/cancelBetByAdmin`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {bet_id: bet_id}
      });
      if (data.statusCode === 200) {
        return dispatch(
          BetManagementActions.cancelBetByAdminSuccess(data)
        );
      }
      dispatch(BetManagementActions.cancelBetByAdminError());
      
    } catch (error) {
      // console.log({error})
      dispatch(BetManagementActions.cancelBetByAdminError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};


