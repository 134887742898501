import React, { useEffect, useRef, useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAllAdminAsync, AdminManagementActions } from "../redux";
import AdminManagementTable from "./admin_management_detail/adminManagementTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import BasicDetailsForm from "./admin_management_detail/basicDetailsForm";

export function AdminManagement() {

  const [showAdminModal, setShowAdminModal] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const searchRef = useRef();

  const { refreshUserList, searchBy, searchText, dir, selectedUser, isLoading, reDirect, role } = useSelector(
    (state) => state.adminManagement,
    shallowEqual
  );
  //call get all admin list api
  useEffect(() => {
    if(refreshUserList){
      dispatch(getAllAdminAsync(searchBy, searchText, dir));
    }
  }, [refreshUserList]);
  //call api while search
  useEffect(() => {
    if (searchRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(AdminManagementActions.refreshUserList());
        }
      };
      let input = searchRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(AdminManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, []);
  //save search text 
  const onSearchTextChange = (e) => {
    dispatch(AdminManagementActions.searchTextChange(e.target.value));
  };
  //save edit user details and show edit admin model
  const onUserDetailsClick = (user) => {
    dispatch(AdminManagementActions.setSelectedUser(user));
    setShowAdminModal(true)
  };

  return (
    <>
      <Card className="admn-mangment-tbl">
        <CardHeader title="Admin Management">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form autoComplete="off"
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-5 mb-1 mt-2">
                    {role === 1 ? <button
                      className="btn_new btn-sm"
                      type="button"
                      onClick={() => onUserDetailsClick({})}
                    >
                      Add
                    </button> : null}
                  </div>
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <AdminManagementTable onUserDetailsClick={onUserDetailsClick} />
        <BasicDetailsForm
          selectedUser={selectedUser}
          isLoading={isLoading}
          reDirect={reDirect}
          setShowAdminModal={setShowAdminModal}
          showAdminModal={showAdminModal}
        />
      </Card>
    </>
  );
}
