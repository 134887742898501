import React, { useState } from "react";
import { useFormik, Formik, Form } from "formik";
import * as Yup from "yup";
import { createCMSAsync, updateCMSAsync } from "../../redux/cmsApi";
import { useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import CustomEditor from "../../../CustomEditor/CustomEditor";
import { generateSlug } from "../../../../utils/appUtils"
import { Col, Row, Modal } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CkEditor from './ckEditor';

const AddCMS = ({ selectedCMS, isLoading, showCMSModal, setShowCMSModal }) => {

  const [slugVal, setSlugVal] = useState("")

  const dispatch = useDispatch();
  //formik validation schema
  const AddCMSFormSchema = () =>
    Yup.object().shape({
      CMSName: Yup.string()
        .trim()
        .min(3, "Minimum 2 characters")
        .max(50, "Maximum 50 characters")
        .required("CMS Name is required"),
      CMSPageDetails: Yup.string()
        .trim()
        .min(3, "Minimum 2 characters")
        .required("CMS Page Details is required"),
    });
  //on hide model
  const onHideCmsModal = () => {
    setShowCMSModal(false);
    setSlugVal("");
  }

  return (
    <>
      <Modal
        show={showCMSModal}
        onHide={() => onHideCmsModal()}
        centered
        className="def_modal delete_modal"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">{selectedCMS && selectedCMS._id ? "Edit CMS" : "Add CMS"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              CMSName: selectedCMS.title || "",
              CMSPageDetails: selectedCMS.content || "",
            }}
            validationSchema={AddCMSFormSchema}
            onSubmit={(values) => {
              if (!selectedCMS.title) {
                dispatch(createCMSAsync(values, setShowCMSModal));
              }
              if (selectedCMS.title) {
                dispatch(updateCMSAsync(selectedCMS._id, values, setShowCMSModal));
              }

            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleSubmit
            }) => (

              <Form onSubmit={handleSubmit} className="form def_form frmwtpddng">
                <Row className="justify-content-center">
                  <Col lg={12}>
                    
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            {selectedCMS.title ? (
                              <label className="form-label text-white font-size-12px inter-font font-weight-normal ">Edit CMS</label>
                            ) : (
                              <label className="form-label">Add CMS</label>
                            )}
                            <input
                              placeholder="CMS Name"
                              type="text"
                              className={`form-control border-less-input`}
                              name="CMSName"
                              disabled={selectedCMS.title ? true : false}
                              onChange={async (e) => {
                                handleChange("CMSName")(e)
                                let generatedSlug = await generateSlug(selectedCMS.title ? selectedCMS.title : e.target.value)
                                setSlugVal(generatedSlug)
                              }}
                              onBlur={handleBlur("CMSName")}
                              value={values.CMSName}
                            />
                            {touched.CMSName && errors.CMSName ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {errors.CMSName}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label text-white font-size-12px inter-font font-weight-normal">Slug</label>
                            <input
                              placeholder="Slug"
                              type="text"
                              className={`form-control border-less-input`}
                              disabled={true}
                              value={selectedCMS.slug || slugVal}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-label text-white font-size-12px inter-font font-weight-normal">CMS Page Details</label>

                        <CKEditor

                          editor={ClassicEditor}

                          data={values.CMSPageDetails}

                          config={{
                            removePlugins: ['link', 'MediaEmbed', "EasyImage", "ImageUpload"]
                          }}

                          className={
                            errors.CMSPageDetails &&
                              touched.CMSPageDetails
                              ? "form-control re_inputRouded is-invalid editore_bg"
                              : "form-control re_inputRouded"
                          }

                          onChange={(event, editor) => {
                            const data = editor.getData();

                            setFieldTouched("CMSPageDetails", true);

                            setFieldValue("CMSPageDetails", data);
                          }}

                        />
                        {touched.CMSPageDetails && errors.CMSPageDetails ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.CMSPageDetails}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group  mb-0 mt-4">
                        <div className="form-group mr-5 mb-1 mt-2">
                          <button
                            type="submit"
                            className="btn btn-blue spinnerBtn add-avtar-btn"
                            disabled={isLoading}
                          >
                            <span>{selectedCMS.title && selectedCMS.content ? "Update" : "Add"}</span>
                            {isLoading && (
                              <div className="ml-3 basic-verification-loader text-center">
                                <CircularProgress />
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                  </Col>
                </Row>
              </Form>

            )}

          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddCMS;
