import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserManagementActions } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { deleteUserAsync } from "../../redux";
import StateChart from './StateChart';
import { getUserStates ,updateJuryAdminWithdrawStrikeLevel} from '../../redux/userManagementApi';
import { Web3Context } from "../../../../../web3/contexts/web3Context";
import { poolMethods } from '../../../../../web3/functions/factory';
import Web3 from "web3";
import {BetManagementActions} from '../../../bet_management /redux/betManagementAction';
import { useHistory } from "react-router-dom";

const TopUserManagement = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, topUserList, topUserSkip, topUserLimit , topUserSearchBy, topUserDir} = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );
  //on page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : topUserSkip;
    dispatch(UserManagementActions.setTopUserBatchNumber(count));
  };
  //for sort column
  const handleSort = (column, dir) => {
    dispatch(UserManagementActions.setSortForTopUser({ column, dir }));
  };
  //show highest paying challenge details
  const DetailsShow = async (bet) => {
    dispatch(BetManagementActions.getBetId(bet))
    // history.push(`/bet-management/bet-result-details/${bet}`)
  }

  return (
    <>
      <div className="pl-3">
        <h1 className="px-6 pt-0 dash_wt_bx pb-0"> 
          {topUserList?.HighestPayingBet?._id && 
            <div>
            <span>Highest Paying Challenge: </span>
              <span onClick={() => DetailsShow(topUserList?.HighestPayingBet?._id)}>
                <ins><a href={`/bet-management/bet-result-details/${topUserList?.HighestPayingBet?._id}`} target="_blank" className="text-info">
                  {topUserList?.HighestPayingBet?.betQuestion}
                </a></ins>
              </span>
            </div>
          }
        </h1>
      </div>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
        <div className="tblmrgn mt-0">
          <div className="mt-5">
            <div>
              <Table
                hover
                responsive
                id="myTable"
                className="mb-0 default_table with_hoverbtn mrpdng_tbl"
              >
                <thead>
                  <tr>
                    <th>Sr.no</th>
                   {/* <th
                      onClick={() =>
                        handleSort(
                          "userName",
                          topUserDir === "" || topUserDir === "dsc" ? "asc" : "dsc"
                        )
                      }
                    >
                      User Name{" "}
                      {topUserDir !== "" && topUserSearchBy === "userName" && (
                        <span className="svg-icon svg-icon-sm svg-icon-white ml-1">
                          {topUserDir === "dsc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th> */}
                    <th>User Name</th>
                    <th>Followers</th>
                    <th>Following</th>
                    <th>Total Challenges</th>
                    <th>Won Challenges</th>
                    <th>Lost Challenges</th>
                    {/* <th
                      onClick={() =>
                        handleSort(
                          "followers",
                          topUserDir === "" || topUserDir === "dsc" ? "asc" : "dsc"
                        )
                      }
                    >
                      Followers{" "}
                      {topUserDir !== "" && topUserSearchBy === "followers" && (
                        <span className="svg-icon svg-icon-sm svg-icon-white ml-1">
                          {topUserDir === "dsc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th> */}
                   { /* <th
                      onClick={() =>
                        handleSort(
                          "followings",
                          topUserDir === "" || topUserDir === "dsc" ? "asc" : "dsc"
                        )
                      }
                    >
                      Following{" "}
                      {topUserDir !== "" && topUserSearchBy === "followings" && (
                        <span className="svg-icon svg-icon-sm svg-icon-white ml-1">
                          {topUserDir === "dsc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th> */}
                    {/* <th
                      onClick={() =>
                        handleSort(
                          "totalBets",
                          topUserDir === "" || topUserDir === "dsc" ? "asc" : "dsc"
                        )
                      }
                    >
                      Total Challenges{" "}
                      {topUserDir !== "" && topUserSearchBy === "totalBets" && (
                        <span className="svg-icon svg-icon-sm svg-icon-white ml-1">
                          {topUserDir === "dsc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th> */}
                    {/* <th
                      onClick={() =>
                        handleSort(
                          "winBetCount",
                          topUserDir === "" || topUserDir === "dsc" ? "asc" : "dsc"
                        )
                      }
                    >
                      Won Challenges{" "}
                      {topUserDir !== "" && topUserSearchBy === "winBetCount" && (
                        <span className="svg-icon svg-icon-sm svg-icon-white ml-1">
                          {topUserDir === "dsc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th>
                    <th
                      onClick={() =>
                        handleSort(
                          "lossBetCount",
                          topUserDir === "" || topUserDir === "dsc" ? "asc" : "dsc"
                        )
                      }
                    >
                      Lost Challenges{" "}
                      {topUserDir !== "" && topUserSearchBy === "lossBetCount" && (
                        <span className="svg-icon svg-icon-sm svg-icon-white ml-1">
                          {topUserDir === "dsc" ? (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Down-2.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Up-2.svg"
                              )}
                            />
                          )}
                        </span>
                      )}
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {topUserList.users && topUserList.users.length ? (
                    topUserList.users.map((topUserLists, index) => {
                      return (
                        <tr key={index.toString()}>
                          <td>
                            <span className="f-w-500">
                              {topUserSkip * topUserLimit + (index + 1)}
                            </span>
                          </td>
                          <td>
                            <span className="f-w-500">{topUserLists.userName}</span>
                          </td>
                          {/* <td>{topUserLists.email}</td> */}
                          <td>{topUserLists.followers === 0 ? '-' : topUserLists.followers}</td>
                          <td>{topUserLists.followings === 0 ? '-' : topUserLists.followings}</td>
                          <td>{topUserLists.totalBets === 0 ? '-' : topUserLists.totalBets}</td>
                          <td>{topUserLists.winBetCount === 0 ? '-' : topUserLists.winBetCount}</td>
                          <td>{topUserLists.lossBetCount === 0 ? '-' : topUserLists.lossBetCount}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8} className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                  {isLoading && (
                    <tr>
                      <td colSpan={8} className="text-center p-0 border-0">
                        <div className="basic-verification-loader text-center">
                          <CircularProgress />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            
              <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
                <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
                  Displaying {topUserSkip * topUserLimit + 1} - {topUserSkip * topUserLimit + topUserLimit} of{" "}
                  {topUserList?.countUsers} Records
                </div>
                <div className="col-md-8 text-left mb-5 aspgntn">
                  <BasicPagination
                    totalRecords={topUserList?.countUsers}
                    limit={topUserLimit}
                    batch={topUserSkip + 1}
                    onBatchChange={onPageChange}
                  />
                </div>
              </div>  
          </div>
        </div>
      </div>
    </>
  );
};

export default TopUserManagement;
