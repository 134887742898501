import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
} from "./../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { addSubCategoryAsync, editSubCategoryAsync } from "../redux/servicesApi"
import { ServicesManagementActions } from '../redux/servicesAction'
import {Constants} from '../../../../constants/constants';

const AddSubcategory = ({ setShowCatModal, showCatModal, selectedSubCategory, file, setFile }) => {
  //formik validation schema
  const addServiceSchema = Yup.object().shape({
    category_id: Yup.string()
      .trim()
      .required("Please choose category"),
    name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please enter subcategory name"),
    image: Object.keys(selectedSubCategory).length === 0 ? Yup.string()
      .required("Please choose subcategory image") : Yup.string(),
  })

  const [categoryOptions, setCategoryOptions] = useState([])
  const [standingsImageFile, setStandingsImageFile] = useState({ file: null, url: selectedSubCategory && selectedSubCategory.standings_image ? selectedSubCategory.standings_image : null });
  const dispatch = useDispatch();

  const { category, isModelLoading, reDirect } = useSelector(
    (state) =>
      state.servicesManagement,
    shallowEqual
  )
  //save category id and model in array
  useEffect(() => {
    let catArr = []
    category && category.category && category.category.length > 0 && 
    category.category.map((cd) => {
        catArr.push({
          value: cd._id,
          label: cd.name,
        })
    })
    setCategoryOptions(catArr)
  }, [category && category.category && category.category.length > 0])
  //close model after redirect
  useEffect(() => {
    if (reDirect === true) {
      setFile({ file: null, url: null })
      setStandingsImageFile({ file: null, url: null })
      setShowCatModal(false);
    }
  }, [reDirect])
  //close sub category model
  const onHideSubCategory = () => {
    dispatch(ServicesManagementActions.refreshDetails());//refresh details
    setFile({ file: null, url: null })
    setStandingsImageFile({ file: null, url: null })
    setShowCatModal(false)
  }
  //save upload image details
  const uploadImage = (e, setFieldValue) => {
    setFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    setFieldValue("image", e.currentTarget.files[0])
  };
  //save upload standing image details
  const uploadStandingsImage = (e, setFieldValue) => {
    setStandingsImageFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    setFieldValue("standings_image", e.currentTarget.files[0])
  };

  return (
    <>
      <Modal
        size="lg"
        show={showCatModal}
        onHide={() => onHideSubCategory()}
        centered
        className="def_modal mx-auto"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">{selectedSubCategory._id ? "Edit Subcategory" : "Add Subcategory"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              category_label: selectedSubCategory.categories && selectedSubCategory.categories.name || "",
              category_id: selectedSubCategory.category_id || "",
              name: selectedSubCategory.name || "",
              image: "",
              standings_image: ""
            }}
            validationSchema={addServiceSchema}
            onSubmit={async (values) => {

              if (!selectedSubCategory._id) {

                const formData = new FormData();
                formData.append('image', values.image);
                formData.append('name', values.name);
                formData.append('category_id', values.category_id);
                if (selectedSubCategory.standings_image !== "") {
                  // console.log("in");
                  formData.append('standings_image', values.standings_image);
                }
                // console.log("formData",formData);
                return dispatch(addSubCategoryAsync(formData, setShowCatModal))
              }
              if (selectedSubCategory._id) {
                const formData = new FormData();
                if (selectedSubCategory.name !== values.name) {
                  formData.append('name', values.name);
                }
                if (values.image !== "") {
                  formData.append('image', values.image);
                }
                if (selectedSubCategory.category_id !== values.category_id) {
                  formData.append('category_id', values.category_id);
                }
                if (selectedSubCategory.standings_image !== values.standings_image) {
                  formData.append('standings_image', values.standings_image);
                }
                dispatch(editSubCategoryAsync(formData, selectedSubCategory._id));
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                  <Col lg={6}>
                    <div className="form-group">
                      <label className="form-label inter-font font-size-12px font-weight-normal text-white">Category</label>
                      <h1 className="text-white">{selectedSubCategory._id && values.category_label}</h1>
                      <div className="viral-events-dropdown mb-13">
                        {!selectedSubCategory._id && <Select
                          value={{
                            value: values.category_id,
                            label: values.category_label,
                          }}

                          placeholder="Select Category"
                          className={` border-0 p-0  `}
                          classNamePrefix="phoSelect"
                          options={categoryOptions}
                          onChange={(e) => {
                            // Set field value
                            setFieldValue("category_id", e.value);
                            setFieldValue("category_label", e.label);
                          }}

                        />}
                      </div>
                      {touched.category_id && errors.category_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.category_id}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label inter-font font-size-12px font-weight-normal text-white">Subcategory Name</label>
                      <h1 className="text-white">{selectedSubCategory._id && values.name}</h1>
                      {!selectedSubCategory._id && <input
                        placeholder="Enter Subcategory Name"
                        type="text"
                        name="name"
                        className={
                          errors.name && touched.name
                            ? "form-control re_inputRouded is-invalid border-less-input"
                            : "form-control re_inputRouded border-less-input"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />}
                      {touched.name && errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.name}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label inter-font font-size-12px font-weight-normal text-white">Subcategory Image</label>
                      <div
                        className="pro_img text-center"
                        style={{
                          backgroundImage: `url(${file.url ||
                            selectedSubCategory.imageUrl})`,
                        }}
                      >
                        <input
                          type="file"
                          name="image"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => uploadImage(e, setFieldValue)}
                        />
                        {/* <div className="edt_img_btn">
                          <span className="material-icons">edit</span>{selectedSubCategory.imageUrl ? "EDIT" : "Upload"}
                        </div> */}
                        <div className="edt_img_btn"><div className="upload-pic-btn"><span className="material-icons">upload</span>Upload</div></div>
                      </div>
                      {touched.image && errors.image ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.image}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <label className="text-white font-size-16px inter-font font-weight-normal">Suggestion for best UI Experience: </label>
                    <p><b>Image Dimensions: <span className="text-danger">{Constants.ImageConstants.SUB_CATEGORY_DIMENSIONS}</span></b></p>
                    <p>Image Compression Tool: <span><a href="https://imagecompressor.com/" target="_blank" className="text-info">https://imagecompressor.com/</a></span></p>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group">
                      <label className="form-label inter-font font-size-12px font-weight-normal text-white">Standings Image</label>
                      <div
                        className="pro_img text-center"
                        style={{
                          backgroundImage: `url(${standingsImageFile.url ||
                            selectedSubCategory.standings_image})`,
                        }}
                      >
                        <input
                          type="file"
                          name="image"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => uploadStandingsImage(e, setFieldValue)}
                        />
                      {/* <div className="edt_img_btn">
                        <span className="material-icons">edit</span>{selectedSubCategory.imageUrl ? "EDIT" : "Upload"}
                      </div> */}
                      <div className="edt_img_btn"><div className="upload-pic-btn"><span className="material-icons">upload</span>Upload</div></div>
                      </div>
                      {touched.standings_image && errors.standings_image ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.standings_image}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <label className="text-white font-size-16px inter-font font-weight-normal">Suggestion for best UI Experience: </label>
                    <p><b>Image Dimensions: <span className="text-danger">{Constants.ImageConstants.STANDINGS_DIMENSIONS}</span></b></p>
                    <p>Image Compression Tool: <span><a href="https://imagecompressor.com/" target="_blank" className="text-info">https://imagecompressor.com/</a></span></p>

                    <div className="form-group flex-wrap justify-content-center align-items-center mb-0 mt-4">
                      <div className="form-group mr-5 mb-1 mt-2">
                        {isModelLoading ? (
                          <div className="ml-3 basic-verification-loader text-center">
                            <CircularProgress />
                          </div>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-blue spinnerBtn btn_new w-100 py-4"
                            disabled={isModelLoading}
                          >
                            <span className="m-auto text-center">{selectedSubCategory._id ? "Update" : "Add"}</span>

                          </button>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form> 
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddSubcategory