import React, { useEffect, useRef, useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Formik, Form } from "formik";
import CategoryTable from "./services_detail/CategoryTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import SubcategoryTable from "./services_detail/SubcategoryTable";
import { getCategoryAsync, getSubCategoryAsync, getLiveFeedAsync, getAllLeagueAsync } from '../redux/servicesApi';
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddSubcategory from "./AddSubcategory";
import AddLiveFeed from "./AddLiveFeed";
import AddCategory from "./AddCategory";
import { ServicesManagementActions } from '../redux/servicesAction';
import LiveFeedTable from './services_detail/LiveFeedTable';

export function Category() {

  const history = useHistory();

  const { refreshCategoryList,reDirect, selectedCategory, category, isLoading, limit, search, sortType, skip, sortBy } = useSelector(
    (state) => state.servicesManagement,
    shallowEqual
  );
  const categoryBody = {
    search,
    sortBy,
    sortType,
    skip,
    limit,
    "mode": "admin",
    "live": true
  }

  const searchRef = useRef();

  const dispatch = useDispatch();

  const [addCategory, setAddCategory] = useState(false);

  const [file, setFile] = useState({ file: null, url: selectedCategory && selectedCategory.imageUrl ? selectedCategory.imageUrl : null });
  //call api while search
  useEffect(() => {
    if (searchRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(ServicesManagementActions.refreshCategoryList());
        }
      };
      let input = searchRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(ServicesManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, []);
  //call get category api
  useEffect(() => {
    //if(refreshCategoryList){
      dispatch(getCategoryAsync(categoryBody))
    //} 
  }, [sortType, skip, refreshCategoryList]);
  //save edit category details 
  const onCategoryDetailClick = (category) => {
    dispatch(ServicesManagementActions.setSelectedCategory(category));
    setAddCategory(true);
  }
  //save search text
  const onSearchTextChange = (e) => {
    dispatch(ServicesManagementActions.searchTextChange(e.target.value));
  };
  // on add cllick
  const onAddClick = () => {
    setAddCategory(true)
    setFile({ file: null, url: null })
  }

  return (
    <>
      <Card>
        <CardHeader title="Category Management">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form autoComplete="off"
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-5 mb-1 mt-2">
                    <button
                      className="btn_new btn-sm"
                      type="button"
                      onClick={() => onAddClick()}
                    >
                      Add
                    </button>
                  </div>
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={search}
                      onChange={onSearchTextChange}
                      ref={searchRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CategoryTable onCategoryDetailClick={onCategoryDetailClick} />
      </Card>

      <AddCategory addCategory={addCategory} setAddCategory={setAddCategory} selectedCategory={selectedCategory} setFile={setFile} file={file} />
    </>
  );
}


export function Subcategory() {

  const history = useHistory();
  const dispatch = useDispatch();

  const [showCatModal, setShowCatModal] = useState(false);

  const { selectedSubCategory, sublimit, subsearch, subsortType, subskip, subsortBy, refreshSubCategoryList, reDirect } = useSelector(
    (state) =>
      state.servicesManagement,
    shallowEqual
  )

  const SubcategoryBody = {
    search: subsearch,
    sortBy: subsortBy,
    sortType: subsortType,
    skip: subskip,
    limit: sublimit,
    "mode": "admin"
  }

  const [file, setFile] = useState({ file: null, url: selectedSubCategory && selectedSubCategory.imageUrl ? selectedSubCategory.imageUrl : null });

  const searchSubRef = useRef();

  //call get category api
  useEffect(() => {
    dispatch(getCategoryAsync())
  }, [])
  //call sub category api
  useEffect(() => {
    //if(refreshSubCategoryList){
    dispatch(getSubCategoryAsync(SubcategoryBody));
    //}
  }, [dispatch, subsortType, subskip, subsortBy, refreshSubCategoryList]);
  //on search text
  const onSearchTextChange = (e) => {
    dispatch(ServicesManagementActions.searchSubTextChange(e.target.value));
  };
  //call api while search
  useEffect(() => {

    if (searchSubRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(ServicesManagementActions.refreshSubCategoryList());
        }
      };
      let input = searchSubRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(ServicesManagementActions.searchSubTextChange(""));
        input.value = "";
      };
    }
  }, []);

  //save edit sub category details
  const onSubCategoryDetailClick = (subCategory) => {
    dispatch(ServicesManagementActions.setSubSelectedCategory(subCategory));
    setShowCatModal(true)
  }
  // on add click
  const onAddClick = () => {
    setShowCatModal(true)
    setFile({ file: null, url: null })
  }

  return (
    <>
      <Card>
        <CardHeader title="Subcategory Management">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form autoComplete="off"
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-5 mb-1 mt-2">
                    <button
                      className="btn_new btn-sm"
                      type="button"
                      onClick={() => onAddClick()}
                    >
                      Add
                    </button>
                  </div>
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={subsearch}
                      onChange={onSearchTextChange}
                      ref={searchSubRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <SubcategoryTable onSubCategoryDetailClick={onSubCategoryDetailClick} />
        <AddSubcategory
          setShowCatModal={setShowCatModal}
          showCatModal={showCatModal}
          selectedSubCategory={selectedSubCategory}
          setFile={setFile}
          file={file}
        />
      </Card>
    </>
  );
}

export function LiveFeed() {

  const history = useHistory();

  const { refreshFeedList, reDirect, selectedFeed, category, isLoading, feedlimit, feedsearch, feedsortType, feedskip, feedsortBy } = useSelector(
    (state) => state.servicesManagement,
    shallowEqual
  );

  const FeedBody = {
    search: feedsearch,
    sortBy: feedsortBy,
    sortType: feedsortType,
    skip: feedskip,
    limit: feedlimit
  }

  const [addLiveFeed, setAddLiveFeed] = useState(false);

  const searchLiveFeedRef = useRef();

  const dispatch = useDispatch();
  // on add click
  const onAddClick = () => {
    setAddLiveFeed(true)
  }
  //call api while search
  useEffect(() => {
    if (searchLiveFeedRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(ServicesManagementActions.refreshFeedList());
        }
      };
      let input = searchLiveFeedRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(ServicesManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, []);
  //call get live feed api
  useEffect(() => {
    //if(refreshFeedList){
      dispatch(getLiveFeedAsync(FeedBody));
    //}
  }, [feedsortType, feedskip, feedsortBy, refreshFeedList]);
  //save edit feed details
  const onFeedDetailClick = (Feed) => {
    dispatch(ServicesManagementActions.setSelectedFeed(Feed));
    setAddLiveFeed(true);
  }
  //save search text
  const onSearchTextChange = (e) => {
    dispatch(ServicesManagementActions.searchFeedTextChange(e.target.value));
  };

  return (
    <>
      <Card>
        <CardHeader title="Live Feed">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form autoComplete="off"
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-5 mb-1 mt-2">
                    <button
                      className="btn_new btn-sm"
                      type="button"
                      onClick={() => onAddClick()}
                    >
                      Add
                    </button>
                  </div>
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={feedsearch}
                      onChange={onSearchTextChange}
                      ref={searchLiveFeedRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <LiveFeedTable onFeedDetailClick={onFeedDetailClick} selectedFeed={selectedFeed} />
        <AddLiveFeed addLiveFeed={addLiveFeed} setAddLiveFeed={setAddLiveFeed} selectedFeed={selectedFeed} />
      </Card>
    </>
  );
}
