import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { PaymentActions } from '../../redux/paymentAction';
import { addPaymentAsync, editPaymentAsync } from '../../redux/paymentApi';
import {Constants} from '../../../../../constants/constants';

const AddTokens = ({ addTokens, setAddTokens, file, setFile }) => {
  //formik validation schema
  const addTokenSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please enter Token Name"),
    short_name: Yup.string()
      .trim()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please enter Short Name"),
    image: Yup.string()
      .required("Please enter Token Icon"),
  })

  const { refreshPayment, isModelLoading , selectedPayment} = useSelector(
    (state) => state.payment,
    shallowEqual
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const active = true;
  const contractAddress = 0;
  const onHideTokens = () => {
    setFile({ file: null, url: null })
    setAddTokens(false);
    dispatch(PaymentActions.refreshDetails());// for refresh details
  }
  //save image details
  const uploadImage = (e, setFieldValue) => {
    setFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    setFieldValue("image", e.currentTarget.files[0])
  };
  //close add token model after added
  useEffect(() => {
    if (refreshPayment === true) {
      setFile({ file: null, url: null })
      setAddTokens(false);
    }
  }, [refreshPayment])

  return (
    <>
      <Modal
        size="lg"
        show={addTokens}
        onHide={() => onHideTokens()}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">{selectedPayment._id ? "Edit Token" : "Add Token"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{  
              name: selectedPayment.name || "", 
              short_name: selectedPayment.short_name || "", 
              image: selectedPayment.tokenImageUrl || "", 
              address: selectedPayment.contractAddress || ""
            }}
            validationSchema={addTokenSchema}
            onSubmit={values => {
              const formData = new FormData();              
              formData.append('name', values.name);
              formData.append('short_name', values.short_name);
              formData.append('active', active);
              formData.append('contractAddress', values.address);
              
              if(selectedPayment._id){
                if(values.image !== selectedPayment.tokenImageUrl){
                  formData.append('image', values.image);
                }
                formData.append('_id', selectedPayment._id);
                dispatch(editPaymentAsync(formData));
              }
              else {
                formData.append('image', values.image);
                dispatch(addPaymentAsync(formData));
              }
              
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleSubmit
            }) => (

              <Form autoComplete="off" onSubmit={handleSubmit}>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="form-group">
                    <label className="form-label text-white font-size-16px inter-font font-weight-normal">Token Name</label>
                    <input
                      placeholder="Enter Token Name"
                      type="text"
                      name="name"
                      className={
                        errors.name && touched.name
                          ? "form-control re_inputRouded is-invalid border-less-input"
                          : "form-control re_inputRouded border-less-input"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {touched.name && errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="form-label text-white font-size-16px inter-font font-weight-normal">Token Short Name</label>
                    <input
                      placeholder="Enter Token Short Name"
                      type="text"
                      name="short_name"
                      disabled={selectedPayment._id ? true : false}
                      className={
                        errors.short_name && touched.short_name
                          ? "form-control re_inputRouded is-invalid border-less-input"
                          : "form-control re_inputRouded border-less-input"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.short_name}
                    />
                    {touched.short_name && errors.short_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {errors.short_name}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="form-label text-white font-size-16px inter-font font-weight-normal">Token Address</label>
                    <input
                      placeholder="Enter Token Address"
                      type="text"
                      name="address"
                      className={
                        errors.address && touched.address
                          ? "form-control re_inputRouded is-invalid border-less-input"
                          : "form-control re_inputRouded border-less-input"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {touched.address && errors.address ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {errors.address}
                        </div>
                      </div>
                    ) : null}
                  </div>

                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <label className="form-label text-white font-size-16px inter-font font-weight-normal">Token Icon</label>
                    <div
                      className="pro_img text-center"
                      style={{
                        backgroundImage: `url(${file.url || selectedPayment.tokenImageUrl})`
                      }}
                    >
                      <input
                        type="file"
                        name="image"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => uploadImage(e, setFieldValue)}
                      />
                      <div className="edt_img_btn">

                        <div className="upload-pic-btn"><span className="material-icons">upload</span>Upload</div>

                      </div>
                    </div>
                    {touched.image && errors.image ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {errors.image}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <label className="text-white font-size-16px inter-font font-weight-normal">Suggestion for best UI Experience: </label>
                  <p><b>Image Dimensions: <span className="text-danger">{Constants.ImageConstants.TOKEN_DIMENSIONS}</span></b></p>
                  <p>Image Compression Tool: <span><a href="https://imagecompressor.com/" target="_blank" className="text-info">https://imagecompressor.com/</a></span></p>

                  <div className="form-group ">
                    <div className="form-group mr-5 mb-1 mt-2">
                      {isModelLoading ? (
                        <div className="ml-3 basic-verification-loader text-center">
                          <CircularProgress />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-blue spinnerBtn add-avtar-btn"
                          disabled={isModelLoading}
                        >
                          <span>{selectedPayment._id ? "Edit" : "Add"}</span>

                        </button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
             
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddTokens;

