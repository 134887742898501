import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import BasicDetailsForm from "./basicDetailsForm";
import {
  Card,
  CardBody,
  CardHeader,
} from "./../../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function AdminManagementDetail() {
  const [showAdminModal, setShowAdminModal] = useState(false);
  const { selectedUser, isLoading, reDirect } = useSelector(
    (state) => state.adminManagement,
    shallowEqual
  );

  return (
    <BasicDetailsForm
      selectedUser={selectedUser}
      isLoading={isLoading}
      reDirect={reDirect}
      setShowAdminModal={setShowAdminModal}
      showAdminModal={showAdminModal}
    />
  );
}
