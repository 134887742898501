import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table, Modal, input } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { betManagementAction } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { useHistory, Link, useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { deleteBetAsync } from '../../redux/betManagementApi';
import { getUserBetResultAsync, giveResultByAdminAsync, getUserJuryVotesAsync } from '../../redux/betManagementApi';
import { BetManagementActions } from '../../redux/betManagementAction';
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Tabs, Tab } from "react-bootstrap";
import { toTimestamp } from '../../../../utils/appUtils';
import queryString from 'query-string';
import { object } from "prop-types";
import { Web3Context } from "../../../../../web3/contexts/web3Context";
import { poolMethods } from '../../../../../web3/functions/factory'
import { showSuccessSnackbar } from "../../../snackBar/snackBar.action";

const BetUserResultManagement = ({ }) => {
  const { networkDetails, handleConnect } = useContext(Web3Context);
  const [getInstance, setInstance] = useState();
  const { isLoading, refreshBetList, betList, search, skip, refreshResult, limit, betResult, betId, juryVotesList, betTakerEvidenceList, betMakerEvidenceList, betData } = useSelector(
    (state) => state.betManagement,
    shallowEqual
  );
  const [isSubmitLoading, setisSubmitLoading] = useState(false);
  const [resultValue, setResultValue] = useState("");
  const history = useHistory();

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    setResultValue("");
    if (id !== "") {
      dispatch(getUserBetResultAsync(id))//call get user bet result api
      dispatch(getUserJuryVotesAsync(id))//call user jury vote api
    }

    return () => dispatch(BetManagementActions.removeBetId());
  }, [id]);

  useEffect(() => {

    (async () => {
      const instance = await poolMethods.getInstanceAdmin(networkDetails.web3)
      // console.log({ instance })
      if (instance) {
        setInstance(instance);
      }
    })()
  }, [networkDetails.web3])
  //save result value
  const onValueChange = (event) => {
    setResultValue(event.target.value);
  }
  //form submit 
  const formSubmit = async (event) => {
    event.preventDefault();
    try {
      //show toaster if there is no value is selected
      if(resultValue === "" || resultValue === undefined){
        dispatch(showSuccessSnackbar("warning", "Please Select the option to continue", 3000));
      }
      else {
        setisSubmitLoading(true)
        let juryNotVoted = juryVotesList.data.filter(obj => obj.vote_option == null)
        // console.log(juryNotVoted)
        let juryWallets = []
        if (juryNotVoted) {
          juryWallets = Array.from(juryNotVoted, ({ users }) => users.walletAddress)
        }
        
        let winnerOption = ""
        if (betResult.viewType === "DISPUTE_RESOLVE") {
          winnerOption = resultValue === "A" ? betData['bet_opposite_side_option'] : betData['bet_creator_side_option'];
        }
        if (["RESULT_RESOLVE", "RESULT_REVIEW"].includes(betResult.viewType)) {
          winnerOption = resultValue
        }
        // console.log({ betData, betResult, winnerOption })
        let verdict = 0
        if (resultValue !== "Void") {
          if (betData['bet_creator_side_option'] === winnerOption) {
            verdict = parseInt(betData['bet_creator_side_option_index']) + 1
          } else if (betData['bet_opposite_side_option'] === winnerOption) {
            verdict = parseInt(betData['bet_opposite_side_option_index']) + 1
          }
        }
        // console.log({ verdict })

        const objectData = {
          betAddress: betData.bet_id,
          verdict: verdict,
          juryNotVoted: juryWallets,
          hash: betResult.HashSignatureObject && betResult.HashSignatureObject.hash,
          maker: betResult.HashSignatureObject && betResult.HashSignatureObject.makerSignature,
          taker: betResult.HashSignatureObject && betResult.HashSignatureObject.takerSignature
        }

        if (typeof (betResult.HashSignatureObject.hash) == "string") {
          objectData.hash = JSON.parse(betResult.HashSignatureObject.hash)
        }
        // console.log({resultValue})
        if (betResult.viewType === "DISPUTE_RESOLVE") {
          //call contract for set admin resolution
          let setAdminResolution = await poolMethods.setAdminResolution(getInstance, networkDetails.address, objectData)
          // console.log({ setAdminResolution })
          //call give result by admin api
          if (setAdminResolution) {
            dispatch(giveResultByAdminAsync({ "result": resultValue, "bet_id": id, viewType: betResult.viewType }))
          }
        }
        else {
          //call contract for set admin resolution for unavailable evidance
          let setAdminResolutionForUnavailableEvidance = await poolMethods.setAdminResolutionForUnavailableEvidance(getInstance, networkDetails.address, objectData)
          // console.log({ setAdminResolutionForUnavailableEvidance })
          //call give result by admin api
          if (setAdminResolutionForUnavailableEvidance) {
            dispatch(giveResultByAdminAsync({ "result": resultValue, "bet_id": id, viewType: betResult.viewType }))
          }
        }
        setisSubmitLoading(false)
      }
    } catch (err) {
      // console.log('err', err)
      setisSubmitLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (refreshResult === false) {
        history.push('/bet-management')
      }
    }, 3000); 
  }, [refreshResult])
  //save winneroption in useState
  useEffect(() => {
    if(["RESULT_RESOLVE", "RESULT_REVIEW"].includes(betResult.viewType)){
      setResultValue(betResult?.resultData?.winnerOption)
    }
  }, [betResult])


  return (

    <Card>

      <CardHeader title={
        <>
          <div className="form-group m-0 d-flex align-items-center ">
            <Link className="btn btn-link px-0 text-white" to="/bet-management">
              <SVG
                className="h-50 align-self-center text-white right-caret-btn"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Arrow-left.svg"
                )}
              />
              Back
            </Link>
            <span className="pl-2">Result Management</span>
          </div>
        </>
      }>
        <CardHeaderToolbar>
          <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
            <form autoComplete="off"
              className="subheader_filter"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="form-row">
              </div>
            </form>
          </div>
        </CardHeaderToolbar>
      </CardHeader>

      <h1 className="ml-8">Challenge Details</h1>
      <div className="row">
        <div className="ml-11 res_manage_radio_main" >
          <div className="res_manage_radio_main" >
            <div className="mb-md-4 res_manage_radio">

              <div className="form-check-inline mr-4">
                <label className="form-check-label" htmlFor="radio1">
                  Challenge Type: {betResult?.bet?.bettypes?.name ?? ""} <br />
                    {betResult?.bet?.categories?.name ?? ""} 
                    {betResult?.bet?.subcategories?.name ? "-" : ""}  
                    {betResult?.bet?.subcategories?.name ?? ""} 
                    {betResult?.bet?.match?.leagueName ? "-" : ""}
                    {betResult?.bet?.match?.leagueName ?? ""}
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="ml-11 res_manage_radio_main" >
          <div className="res_manage_radio_main" >
            <div className="mb-md-4 res_manage_radio">
              <div className="form-check-inline mr-4">
                <label className="form-check-label" htmlFor="radio1">
                  {betResult?.bet?.createdAt ? toTimestamp(betResult?.bet?.createdAt) : "" ?? ""} <br />
                  {betResult?.bet?.match?.localTeamName && betResult?.bet?.match?.visitorTeamName ? 
                    betResult?.bet?.match?.localTeamName + " VS " + betResult?.bet?.match?.visitorTeamName : 
                    betResult?.bet?.match?.matchName ?? ""}
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="ml-11 res_manage_radio_main" >
          <div className="res_manage_radio_main" >
            <div className="mb-md-4 res_manage_radio">
              <div className="form-check-inline mr-4">
                <label className="form-check-label" htmlFor="radio1">
                    Made By: {betResult?.bet?.users?.displayName && betResult?.bet?.users?.displayName !== "" ? betResult?.bet?.users?.displayName : betResult?.bet?.users?.userName ?? ""}<br />
                    Taken By: {betResult?.betTakerData?.displayName && betResult?.betTakerData?.displayName !== "" ? betResult?.betTakerData?.displayName : betResult?.betTakerData?.userName ?? ""}
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>


      <h1 className="ml-8">{betData && `Question/Market: ${betData?.betQuestion ?? ""}`}</h1>
      <div className="row">

        <div className="ml-11 res_manage_radio_main" >
          <div className="res_manage_radio_main" >
            <div className="mb-md-4 res_manage_radio">
              <div className="form-check-inline mr-4">
                <label className="form-check-label" htmlFor="radio1">
                  Result 1: {betData?.betOptionOne ?? ""} <br />
                  Result 2: {betData?.betOptionTwo ?? ""}
                  <span></span>
                </label>
              </div>

            </div>
          </div>
        </div>
      </div>
     <div className="row">
        
        {
          
          betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence && Object.values(betResult.disputeEvidence.betMakerEvidence).length > 0 && (
            <>

              <div className="col-lg-9 col-xxl-6">
                <div className="d-flex justify-content-around text-center bg-dark rounded-xl mb-7 p-5 proof_bt_maker_main h-100">
                  <div>
                    <div className="py-5">
                      <h1 className="font-weight-normal">Proof By Challenge Maker</h1>
                    </div>

                    <div className="proof_bt_maker">
                      <div className="p-2">
                        <div className="row">
                          {
                            betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence &&
                              betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofImagesUrls &&
                              betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofImagesUrls.length > 0 ? (
                              <div>
                                {
                                  betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofImagesUrls.map((ele, index) => (
                                    <div key={index} className="col-lg-4 custom-col-2 mt-2">
                                      <a href={ele} target="_blank">
                                        <img src={ele} alt='Image' style={{ height: 200, width: 200 }} />
                                      </a>
                                    </div>
                                  ))
                                }
                              </div>
                            ) : null
                          }

                          {
                            betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence &&
                              betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofVideosUrls &&
                              betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofVideosUrls.length > 0 ? (
                              <div>
                                {
                                  betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofVideosUrls.map((ele, index) => (
                                    <div key={index} className="col-lg-4 custom-col-2 mt-2">
                                      <video width="240" height="240" controls>
                                        <source src={ele.url} type="video/mp4" />
                                        <source src={ele.url} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </div>
                                  ))
                                }
                              </div>
                            ) : null
                          }

                        </div>
                      </div>
                    </div>

                    <div className="proof_bt_make_links text-left ">
                      <div className="pt-4 text-white pl-5">
                        {
                          betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence &&
                          betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofLinks &&
                          betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofLinks.length > 0 &&
                          betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence.proofLinks.map((ele, index) => (
                            <p key={index}><a href={ele} target="_blank" className="text-white">{ele}</a></p>
                          ))
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </>
          )
        }
        {isLoading && <CircularProgress />}
        {
          betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence && Object.values(betResult.disputeEvidence.betTakerEvidence).length > 0 && (
            <>
              <div className="col-lg-9 col-xxl-6">
                <div className="d-flex justify-content-around text-center bg-dark rounded-xl mb-7 p-5 proof_bt_maker_main h-100">
                  <div>
                    <div className="py-5">
                      <h1 className="font-weight-normal">Proof By Challenge Taker</h1>
                    </div>
                    <div className="proof_bt_maker">
                      <div className="p-2">
                        <div className="row">
                          {
                            betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence &&
                              betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofImagesUrls &&
                              betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofImagesUrls.length > 0 ? (
                              <div >
                                {
                                  betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofImagesUrls.map((ele, index) => (
                                    <div key={index} className="col-lg-4 custom-col-2 mt-2">
                                      <a href={ele} target="_blank">
                                        <img src={ele} alt='Image' style={{ height: 200, width: 200 }} />
                                      </a>
                                    </div>
                                  ))
                                }
                              </div>
                            ) : null
                          }

                          {
                            betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence &&
                              betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofVideosUrls &&
                              betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofVideosUrls.length > 0 ? (
                              <div >
                                
                                {
                                  betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofVideosUrls.map((ele, index) => (
                                    <div key={index} className="col-lg-4 custom-col-2 mt-2">
                                      <video width="240" height="240" controls>
                                        <source src={ele.url} type="video/mp4" />
                                        <source src={ele.url} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </div>
                                  ))
                                }
                              </div>
                            ) : null
                          }
                        </div>
                      </div>

                    </div>
                    <div>
                      <div className="proof_bt_make_links text-left ">
                        <div className="pt-4 text-white pl-5">
                          {
                            betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence &&
                            betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofLinks &&
                            betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofLinks.length > 0 &&
                            betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence.proofLinks.map((ele, index) => (
                              <p key={index}><a href={ele} target="_blank" className="text-white">{ele}</a></p>
                            ))
                          }
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>
            </>
          )
        }

      </div>
        
      <div className="row mt-5 mb-4">
        {
              
          betResult && betResult.resultData && betResult.resultData.result && Object.values(betResult.resultData.result).length > 0 && 
          (betResult && betResult.resultData && betResult.resultData.result && betResult.resultData.result.proofImagesUrls && betResult.resultData.result.proofImagesUrls.length > 0 || 
            betResult && betResult.resultData && betResult.resultData.result && betResult.resultData.result.proofVideosUrls && betResult.resultData.result.proofVideosUrls.length > 0 || 
            betResult && betResult.resultData && betResult.resultData.result && betResult.resultData.result.proofLinks && betResult.resultData.result.proofLinks.length > 0) && (
            <>

              <div className="col-lg-9 col-xxl-6">
                <div className="d-flex justify-content-around text-center bg-dark rounded-xl mb-7 p-5 proof_bt_maker_main h-100">
                  <div>
                    <div className="py-5">
                      <h1 className="font-weight-normal">Proof By Result Giver</h1>
                    </div>

                    <div className="proof_bt_maker">
                      <div className="p-2">
                        <div className="row">
                          {
                            betResult && betResult.resultData && betResult.resultData.result &&
                              betResult && betResult.resultData && betResult.resultData.result.proofImagesUrls &&
                              betResult && betResult.resultData && betResult.resultData.result.proofImagesUrls.length > 0 ? (
                              <div>
                                {
                                  betResult && betResult.resultData && betResult.resultData.result.proofImagesUrls.map((ele, index) => (
                                    <div key={index} className="col-lg-4 custom-col-2 mt-2">
                                      <a href={ele} target="_blank">
                                        <img src={ele} alt='Image' style={{ height: 200, width: 200 }} />
                                      </a>
                                    </div>
                                  ))
                                }
                              </div>
                            ) : null
                          }

                          {
                            betResult && betResult.resultData && betResult.resultData.result &&
                              betResult && betResult.resultData && betResult.resultData.result.proofVideosUrls &&
                              betResult && betResult.resultData && betResult.resultData.result.proofVideosUrls.length > 0 ? (
                              <div>
                                {
                                  betResult && betResult.resultData && betResult.resultData.result.proofVideosUrls.map((ele, index) => (
                                    <div key={index} className="col-lg-4 custom-col-2 mt-2">
                                      <video width="240" height="240" controls>
                                        <source src={ele.url} type="video/mp4" />
                                        <source src={ele.url} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </div>
                                  ))
                                }
                              </div>
                            ) : null
                          }

                        </div>
                      </div>
                    </div>

                    <div className="proof_bt_make_links text-left ">
                      <div className="pt-4 text-white pl-5">
                        {
                          betResult && betResult.resultData && betResult.resultData.result &&
                          betResult && betResult.resultData && betResult.resultData.result.proofLinks &&
                          betResult && betResult.resultData && betResult.resultData.result.proofLinks.length > 0 &&
                          betResult && betResult.resultData && betResult.resultData.result.proofLinks.map((ele, index) => (
                            <p key={index}><a href={ele} target="_blank" className="text-white">{ele}</a></p>
                          ))
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </>
          )
        }
      </div>

      <div>
        {
          juryVotesList && juryVotesList.data && juryVotesList.data.length > 0 && (
            <>
              <h1>Jury Votes (A - Challenge Taker, B - Challenge Maker, Void - Draw)</h1>

              <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
                <div className="tblmrgn mt-0">
                  <div className="mt-5">
                    <div>
                      <Table
                        hover
                        responsive
                        className="mb-0 default_table with_hoverbtn mrpdng_tbl"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Name</th>
                            <th>Accept On</th>
                            <th>Vote</th>
                            <th>Vote On</th>
                            {/* <th>Result</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            juryVotesList && juryVotesList.data && juryVotesList.data.length > 0 ?
                              juryVotesList.data.map((ele, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{ele.users.userName}</td>
                                    <td>{toTimestamp(ele.createdAt)}</td>
                                    <td>{ele.vote_option}</td>
                                    <td>{toTimestamp(ele.updatedAt)}</td>
                                    {/* <td>{ele.strikeLevel ? `Strike : ${ele.strikeLevel}`</td> */}
                                  </tr>
                              )) : (
                                <tr>
                                  <td colSpan={13} className="text-center">
                                    No Record Found
                                  </td>
                                </tr>
                              )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }

      </div>

      <div className="ml-8 mt-8 res_manage_radio_main" >

        {
          betResult.isShowResultView === "yes" ? (
            <>
              {betResult.viewType === "" ? null : <h1>Provide Result</h1>}
              <div className="res_manage_radio_main" >
                {
                  betResult.viewType === "DISPUTE_RESOLVE" ? (
                    <>
                      <form onSubmit={(e) => formSubmit(e)}>
                        <div className="mb-md-4 res_manage_radio">
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value="A"
                                defaultChecked={resultValue === "A"}
                                onChange={(e) => onValueChange(e)}
                                name="DISPUTE_RESOLVE"
                              />
                              A
                              <span></span>
                            </label>
                          </div>
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value="B"
                                defaultChecked={resultValue === "B"}
                                onChange={(e) => onValueChange(e)}
                                name="DISPUTE_RESOLVE"
                              />
                              B
                              <span></span>
                            </label>
                          </div>
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value="Void"
                                defaultChecked={resultValue === "Void"}
                                onChange={(e) => onValueChange(e)}
                                name="DISPUTE_RESOLVE"
                              />
                              Void
                              <span></span>
                            </label>
                          </div>
                        </div>
                        {betResult?.bet?.betStatus !== "RESULT" && <button disabled={isSubmitLoading} className="btn btn-default" type="submit">
                          Save
                        </button>}    <span className="result-loader">{isSubmitLoading && <CircularProgress /> }</span>
                      </form>
                    </>
                  ) : null
                }

                {
                  betResult.viewType === "RESULT_RESOLVE" ? (
                    <>
                      <form onSubmit={(e) => formSubmit(e)}>
                        <h1>{betResult && betResult.bet && betResult.bet.betQuestion ? betResult.bet.betQuestion : null}</h1>
                        <div className="mb-md-4 res_manage_radio">
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value={betResult.bet.betOptionOne}
                                defaultChecked={betResult.resultData && betResult.resultData.winnerOption === betResult.bet.betOptionOne || resultValue === betResult.bet.betOptionOne}
                                onChange={(e) => onValueChange(e)}
                                name="RESULT_RESOLVE"
                              />
                              {betResult.bet.betOptionOne}
                              <span></span>
                            </label>
                          </div>
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value={betResult.bet.betOptionTwo}
                                defaultChecked={betResult.resultData && betResult.resultData.winnerOption === betResult.bet.betOptionTwo || resultValue === betResult.bet.betOptionTwo}
                                onChange={(e) => onValueChange(e)}
                                name="RESULT_RESOLVE"
                              />
                              {betResult.bet.betOptionTwo}
                              <span></span>
                            </label>
                          </div>
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value="Void"
                                defaultChecked={betResult.resultData && (betResult.resultData.winnerOption === "Void" || betResult.resultData.winnerOption === "void") || resultValue === "Void"}
                                onChange={(e) => onValueChange(e)}
                                name="RESULT_RESOLVE"
                              />
                              Void
                              <span></span>
                            </label>
                          </div>
                        </div>
                        {betResult?.bet?.betStatus !== "RESULT" && 
                        <button disabled={isSubmitLoading} className="btn btn-default" type="submit">
                          Save
                        </button>}    <span className="result-loader">{isSubmitLoading && <CircularProgress /> }</span>
                      </form>
                    </>
                  ) : null
                }

                {
                  betResult.viewType === "RESULT_REVIEW" ? (
                    <>
                      <form onSubmit={(e) => formSubmit(e)}>
                        <h1>{betResult && betResult.bet && betResult.bet.betQuestion ? betResult.bet.betQuestion : null}</h1>
                        <div className="mb-md-4 res_manage_radio">
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value={betResult.bet.betOptionOne}
                                defaultChecked={betResult.resultData && betResult.resultData.winnerOption === betResult.bet.betOptionOne || resultValue === betResult.bet.betOptionOne}
                                onChange={(e) => onValueChange(e)}
                                name="RESULT_REVIEW"
                              />
                              {betResult.bet.betOptionOne}
                              <span></span>
                            </label>
                          </div>
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value={betResult.bet.betOptionTwo}
                                defaultChecked={betResult.resultData && betResult.resultData.winnerOption === betResult.bet.betOptionTwo || resultValue === betResult.bet.betOptionTwo}
                                onChange={(e) => onValueChange(e)}
                                name="RESULT_REVIEW"
                              />
                              {betResult.bet.betOptionTwo}
                              <span></span>
                            </label>
                          </div>
                          <div className="form-check-inline mr-4">
                            <label className="form-check-label" htmlFor="radio1">
                              <input
                                type="radio"
                                value="Void"
                                defaultChecked={betResult.resultData && (betResult.resultData.winnerOption === "Void" || betResult.resultData.winnerOption === "void") ||resultValue === "Void"}
                                onChange={(e) => onValueChange(e)}
                                name="RESULT_REVIEW"
                              />
                              Void
                              <span></span>
                            </label>
                          </div>
                        </div>
                        {betResult?.bet?.betStatus !== "RESULT" && <button disabled={isSubmitLoading} className="btn btn-default" type="submit">
                          Save
                        </button>}    <span className="result-loader">{isSubmitLoading && <CircularProgress /> }</span>
                      </form>
                    </>
                  ) : null
                }
              </div>
            </>
          ) : null
        }

        {
          betResult && betResult.disputeEvidence && betResult.disputeEvidence.betMakerEvidence &&
            betResult.disputeEvidence.betMakerEvidence.disputeResolver === "ADMIN" && betResult.bet.betStatus === "RESULT" ? (
            <>
              <h1>Result given by Admin - {betResult.disputeEvidence.betMakerEvidence.disputeResult}</h1>
            </>
          ) : (
            <>
              {
                betResult && betResult.disputeEvidence && betResult.disputeEvidence.betTakerEvidence &&
                  betResult.disputeEvidence.betTakerEvidence.disputeResolver === "ADMIN" && betResult.bet.betStatus === "RESULT" ? (
                  <>
                    <h1>Result given by Admin - {betResult.disputeEvidence.betTakerEvidence.disputeResult}</h1>
                  </>
                ) : null
              }
            </>
          )
        }
      </div>

    </Card>
  );
};

export default BetUserResultManagement;
