import axios from 'axios';
import { FaqActions } from './faqAction';
import { showSuccessSnackbar } from '../../snackBar/snackBar.action';
//create faq api
export const createFAQAsync = (values, setShowFAQModal) => {
    return async (dispatch, getState) => {
        try {
            dispatch(FaqActions.createFAQStart());
            let { data } = await axios({
                url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_BASE_URL}/faq/addFAQ`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: values
            });
            if (data.statusCode === 201) {
                dispatch(FaqActions.createFAQSuccess());
                setShowFAQModal(false)
                return dispatch(showSuccessSnackbar('success', "FAQ added successfully", 3000));
            }
            dispatch(FaqActions.createFAQError());
            dispatch(showSuccessSnackbar('error', "Something went wrong!", 3000));
        } catch (error) {

            if (Object.keys(error.data).length > 0) {
                dispatch(FaqActions.createFAQError(error.data.message));

                return dispatch(
                    showSuccessSnackbar("error", error.data.message, 3000)
                );
            } else {

                return dispatch(
                    showSuccessSnackbar(
                        "error",
                        "Error while adding faq. Please try again later",
                        3000
                    )
                );
            }
        }
    }
}
//get faq list api
export const getAllFAQAsync = (values) => {
    return async (dispatch, getState) => {
        try {
            dispatch(FaqActions.getAllFAQStart());
            let { data } = await axios({
                url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_BASE_URL}/faq/getAllFAQ`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                // data: values
            });
            if (data.statusCode === 200) {
                return dispatch(FaqActions.getAllFAQSuccess(data.data));
            }
            dispatch(FaqActions.getAllFAQError());
            dispatch(showSuccessSnackbar('error', "Something went wrong!", 3000));
        } catch (error) {
            dispatch(FaqActions.getAllFAQError());
            dispatch(showSuccessSnackbar('error', "Error while fetching Data.", 3000));
        }
    }
}
//update faq api
export const updateFAQAsync = (faqID, values, setShowFAQModal) => {
    return async (dispatch, getState) => {
        try {
            dispatch(FaqActions.updateFAQStart());
            let { data } = await axios({
                url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_BASE_URL}/faq/editFAQ/${faqID}`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: values
            });
            if (data.statusCode === 200) {
                dispatch(FaqActions.updateFAQSuccess());
                setShowFAQModal(false)
                return dispatch(showSuccessSnackbar('success', "FAQ updated successfully", 3000));
            }
            dispatch(FaqActions.updateFAQError());
            return dispatch(showSuccessSnackbar('error', "Something went wrong!", 3000));
        } catch (error) {
            dispatch(FaqActions.updateFAQError());
            dispatch(showSuccessSnackbar('error', "Error while updating. Please try again after sometime..", 3000));
        }
    }
}
//delete faq api
export const deleteFAQAsync = (faqID) => {
    return async (dispatch, getState) => {
        try {
            dispatch(FaqActions.deleteFAQStart());
            let { data } = await axios({
                url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_BASE_URL}/faq/deleteFAQ/${faqID}`,
                method: 'DELETE',
            });
            if (data.statusCode === 200) {
                dispatch(FaqActions.deleteFAQSuccess());
                return dispatch(showSuccessSnackbar('success', "FAQ deleted successfully", 3000));
            }
            dispatch(FaqActions.deleteFAQError());
            return dispatch(showSuccessSnackbar('error', "Something went wrong!", 3000));
        } catch (error) {
            dispatch(FaqActions.deleteFAQError());
            dispatch(showSuccessSnackbar('error', "Error while updating. Please try again after sometime..", 3000));
        }
    }
}
