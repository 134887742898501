import React, { useEffect, useRef, useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useHistory } from "react-router-dom";
import { Row, Col, Grid } from 'react-bootstrap';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  BetManagementActions,
} from "../redux";
import { getAllBetAsync } from '../redux/betManagementApi';
import BetManagementTable from "./bet_management_detail/betManagementTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Select from "react-select";

export function BetManagement() {

  const dispatch = useDispatch();

  const searchBetRef = useRef();

  const history = useHistory();

  const { refreshBetList, search, skip, limit, disputeType, sortType, sortBy } = useSelector(
    (state) => state.betManagement,
    shallowEqual
  );
  //call get all bets api
  useEffect(() => {
    if (refreshBetList) {
      dispatch(getAllBetAsync(skip, limit, search, disputeType.value, sortType, sortBy ));
    }
  }, [refreshBetList])
  //call api while search
  useEffect(() => {
    if (searchBetRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(BetManagementActions.refreshBetList());
        }
      };
      let input = searchBetRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(BetManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, []);
  //save search text
  const onSearchTextChange = (e) => {
    dispatch(BetManagementActions.searchTextChange(e.target.value));
  };
  //save select type value
  const onSelectChange = (e) => {
    dispatch(BetManagementActions.setSelectBetTypeChange({ value: e.value, label: e.label }));
  }

  const categoryOptions = [{ value: "", label: "All" }, { value: "resolved_dispute", label: "Resolved Dispute" }, { value: "unresolved_dispute", label: "Unresolved Dispute" }, { value: "review_result", label: "No action by user" }]

  return (
    <>
      <Card>
        <CardHeader title="Challenge Management" className="text-white">
          <CardHeaderToolbar className="bet-manges-main">
            <Row>
              <Col lg={6}>
                <div className="flex-wrap ap_filter_wraper justify-content-end align-items-center" style={{ width: "100%" }}>
                  <div className="viral-events-dropdown">
                    <Select
                      value={{
                        value: disputeType.value,
                        label: disputeType.label
                      }}
                      placeholder="Select Category"
                      className={` border-0 p-0  `}
                      classNamePrefix="phoSelect"
                      options={categoryOptions}
                      onChange={onSelectChange}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
                  <form
                    className="subheader_filter"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="form-row">
                      <div className="form-group mr-3 mb-0">
                        <input
                          name="Search"
                          placeholder="Search. . ."
                          type="text"
                          className="form-control"
                          value={search}
                          onChange={onSearchTextChange}
                          ref={searchBetRef}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <BetManagementTable />
      </Card>
    </>
  );
}
