import React, { useEffect, useState, useRef } from 'react'
import { Col, Row, Button } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import BasicPagination from "../pagination/BasicPagination";
import { getAllBetsAsync, getAllUserAsync, getAllMatchesAsync, getTagsAsync } from './redux/dashboardApi';
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { toTimestamp } from '../../utils/appUtils';
import { DashboardActions } from './redux/dashboardAction';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const EventTable = () => {

  const dispatch = useDispatch();

  const eventRef = useRef();

  const { skip, limit, tagUsers, search, matchList, refreshEventList, isMatchesLoading } = useSelector((state) => state.dashboard, shallowEqual);

  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(DashboardActions.setEventBatchNumber(count));
  };

  const [showTagUsers, setShowTagUsers] = useState(false);

  const showUserModel = (matches) => {
    dispatch(getTagsAsync(matches._id));
  }

  const onHideUserModel = () => {
    setShowTagUsers(false);
    dispatch(DashboardActions.refreshTagList())
  }


  useEffect(() => {
    dispatch(getAllMatchesAsync({ limit, skip, searchText: search }))
  }, [skip, refreshEventList])

  useEffect(() => {
    if (eventRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(DashboardActions.refreshEventList());
        }
      };
      let input = eventRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(DashboardActions.searchEventTextChange(""));
        input.value = "";
      };
    }
  }, []);

  const onSearchTextChange = (e) => {
    dispatch(DashboardActions.searchEventTextChange(e.target.value));
  };

  useEffect(() => {
    if (tagUsers && tagUsers[0] && tagUsers[0].tags) {
      setShowTagUsers(true);
    }
  }, [tagUsers]);

  return (
    <>

      <Card>
        <CardHeader title={
          <>
            <div className="form-group m-0 d-flex align-items-center ">
              <Link className="btn btn-link px-0 text-white" to="/dashboard">
                <SVG
                  className="h-50 align-self-center text-white right-caret-btn"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Arrow-left.svg"
                  )}
                />
                Back
              </Link>
              <span className="pl-2">Event Table</span>
            </div>
          </>
        }>
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form autoComplete="off"
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={search}
                      onChange={onSearchTextChange}
                      ref={eventRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
          <div className="tblmrgn mt-0">
            <div className="mt-5">
              <div>
                <Table
                  hover
                  responsive
                  id="myTable"
                  className="mb-0 default_table with_hoverbtn mrpdng_tbl"
                >
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      <th>Event Name</th>
                      <th>category</th>
                      <th>subcategory</th>
                      <th>created date</th>
                      <th>end date</th>
                      <th>Show Tags</th>
                      <th>Active P2P bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {matchList && matchList.data && matchList.data.matches
                      ? matchList.data.matches.map((matches, index) => (
                          <tr key={index}>
                            <td>
                              <span className="f-w-500">
                                {skip * limit + (index + 1)}
                              </span>
                            </td>
                            <td>
                              <span className="f-w-500">
                                {`${matches.localTeamName} VS ${matches.visitorTeamName}`}
                              </span>
                            </td>
                            <td>
                              <span className="f-w-500">
                                {matches.category.name}
                              </span>
                            </td>
                            <td>
                              <span className="f-w-500">
                                {matches.subCategory.name}
                              </span>
                            </td>
                            <td>
                              <span className="f-w-500">
                                {toTimestamp(matches.match_date)}
                              </span>
                            </td>
                            <td>
                              <span className="f-w-500">
                                {toTimestamp(Number(matches.match_end_time))}
                              </span>
                            </td>
                            <td>
                              <span className="f-w-500">
                                <button className="dup-btn-bg" onClick={() => showUserModel(matches)}>Open</button>
                              </span>
                            </td>
                            <td>
                              <span className="f-w-500">
                                {matches.activeBets.length}
                              </span>
                            </td>
                          </tr>
                      )) : (
                        <tr>
                          <td colSpan={8} className="text-center">
                            No Record Found
                          </td>
                        </tr>
                      )}

                    {isMatchesLoading && (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="basic-verification-loader text-center">
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    )}

                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
            <div className="col-md-8 text-left mb-5 aspgntn">
              <BasicPagination
                totalRecords={matchList && matchList.recordsTotal}
                limit={limit}
                batch={skip + 1}
                onBatchChange={onPageChange}
              />
            </div>
            <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {matchList && matchList.recordsTotal} Records
            </div>
          </div>
        </div>
        <Modal
          show={showTagUsers}
          onHide={() => onHideUserModel()}
          centered
          className="def_modal delete_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fn_s_18 color_blk f-w-700">Tag Users</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form def_form frmwtpddng">
              <div>
                {
                  tagUsers && tagUsers[0] && tagUsers[0].tags && tagUsers[0].tags.length > 0 ?
                    tagUsers[0].tags.map((user, index) => (
                      <>
                        <div key={index} className="d-flex justify-content-between align-content-center text-left px-2 py-2 rounded-xl mb-2">
                          <img style={{ height: '5%', width: '5%' }} src={user && user.picture} alt="user image" />
                          <h3 className="font-size-12px text-white inter-font f-w-700 modal-title h4">{user.userName}</h3>
                          {
                            tagUsers && tagUsers[0].tags && tagUsers[0].tagDetails.length > 0 &&
                            tagUsers[0].tagDetails.map((tag, index) => (
                              <div className="f-w-500" key={index}>
                                <span className="dup-btn-bg p-2">{tag.userId === user._id && tag.tagText}</span>
                              </div>
                            ))
                          }
                        </div>
                      </>
                    )) : (
                      <h3> No tags are reported yet</h3>
                    )
                }
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Card>

    </>

  )
}

export default EventTable;