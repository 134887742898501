import { PaymentMap } from "./paymentAction";

const initialState = {
  isLoading: false,
  reDirect: false,
  payment: [],
  refreshPayment: true,
  isModelLoading: false,
  selectedPayment: {}
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    
    case PaymentMap.GET_PAYMENT_START :
      return {
        ...state,
        isLoading: true,
      }

    case PaymentMap.GET_PAYMENT_SUCCESS :
      return {
        ...state,
        isLoading: false,
        payment: action.payload.tokens,
        refreshPayment: false,
      }

    case PaymentMap.GET_PAYMENT_ERROR :
      return {
      ...state,
      isLoading: false, 
      refreshPayment: false,   
    }

    case PaymentMap.ADD_PAYMENT_START :
      return {
        ...state,
        isModelLoading: true,
      }

    case PaymentMap.ADD_PAYMENT_SUCCESS :
      return {
        ...state,
        isModelLoading: false,
        refreshPayment: true,
      }
    
    case PaymentMap.ADD_PAYMENT_ERROR :
      return {
        ...state,
        isModelLoading: false,
        refreshPayment: false,
    }

    case PaymentMap.UPDATE_PAYMENT_START :
      return {
        ...state,
        isLoading: true,
        
      }

    case PaymentMap.UPDATE_PAYMENT_SUCCESS :
      return {
        ...state,
        isLoading: false,
        refreshPayment: true,
      }

    case PaymentMap.UPDATE_PAYMENT_ERROR :
      return {
      ...state,
      isLoading: false,
      refreshPayment: false,    
    }

    case PaymentMap.DELETE_PAYMENT_START :
      return {
        ...state,
        isLoading: true,
        
      }

    case PaymentMap.DELETE_PAYMENT_SUCCESS :
      return {
        ...state,
        isLoading: false,
        refreshPayment: true,
      }

    case PaymentMap.DELETE_PAYMENT_ERROR :
      return {
      ...state,
      isLoading: false, 
      refreshPayment: false,   
    }

    case PaymentMap.SELECT_PAYMENT_DETAILS :
      return {
        ...state,
        selectedPayment: action.payload,
    }

    case PaymentMap.REFRESH_DETAILS: 
      return {
        ...state,
        selectedPayment: {},
      }
    
    case PaymentMap.EDIT_PAYMENT_START :
      return {
        ...state,
        isModelLoading: true,
      }

    case PaymentMap.EDIT_PAYMENT_SUCCESS :
      return {
        ...state,
        isModelLoading: false,
        refreshPayment: true,
      }
    
    case PaymentMap.EDIT_PAYMENT_ERROR :
      return {
        ...state,
        isModelLoading: false,
        refreshPayment: false,
    }

    default:
      return { ...state };
  }
};
