import axios from "axios";
import { PaymentActions } from "./paymentAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

//get payments api
export const getPaymentAsync = () => {
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        
        dispatch(PaymentActions.getPaymentStart());
        const { data } = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tokenType/getTokenType`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {userType: "admin"}
        });
        if (data.statusCode === 200) {
          return dispatch(
            PaymentActions.getPaymentSuccess(data.data)
          );
        }
        dispatch(PaymentActions.getPaymentError());
      } catch (error) {
        dispatch(PaymentActions.getPaymentError());
        dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
      }
    };
  };
  //update payment api 
  export const updatePaymentAsync = (id) => {
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        dispatch(PaymentActions.updatePaymentStart());
        const { data } = await axios({
          method: "PUT",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tokenType/updateStatus/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (data.statusCode === 202) {
          dispatch(PaymentActions.updatePaymentSuccess(data));
          return dispatch(
            showSuccessSnackbar("success", "Token updated successfully", 3000)
          );
        }
        dispatch(PaymentActions.updatePaymentError());
      } catch (error) {
        dispatch(PaymentActions.updatePaymentError());
        dispatch(
          showSuccessSnackbar(
            "error",
            "Error while updating token status. Please try again later",
            3000
          )
        );
      }
    };
  };
  //delete payment api
  export const deletePaymentAsync = (id) => {
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        dispatch(PaymentActions.deletePaymentStart());
        const { data } = await axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tokenType/deleteTokenType/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
        });
        
        if (data.statusCode === 202) {
          dispatch(PaymentActions.deletePaymentSuccess(data));
          return dispatch(
            showSuccessSnackbar("success", "Token deleted successfully", 3000)
          );
        }
        dispatch(PaymentActions.deletePaymentError());
      } catch (error) {
        dispatch(PaymentActions.deletePaymentError());
        dispatch(
          showSuccessSnackbar(
            "error",
            "Error while deleting token. Please try again later",
            3000
          )
        );
      }
    };
  };
  //add payment api
  export const addPaymentAsync = (paymentDetails) => {
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        dispatch(PaymentActions.addPaymentStart());
        const { data } = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tokenType/addTokenType`,
          headers: {
            "Content-Type": "application/json",
          },
          data: paymentDetails
        });
  
        if (data.statusCode === 200) {
          dispatch(PaymentActions.addPaymentSuccess(data.responseData));
          return dispatch(
            showSuccessSnackbar("success", "Token added successfully!", 3000)
          );
        }
        dispatch(PaymentActions.addPaymentError());
        return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
      } catch (error) {
        dispatch(PaymentActions.addPaymentError());
        dispatch(
          showSuccessSnackbar(
            "error",
            "Error while creating token. Please try again later",
            3000
          )
        );
      }
    };
  };
  //edit payment api
  export const editPaymentAsync = (paymentDetails) => {
    return async (dispatch, getState) => {
      try {
        const adminBaseURL = process.env.REACT_APP_BASE_URL;
        dispatch(PaymentActions.editPaymentStart());
        const { data } = await axios({
          method: "PUT",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/tokenType/updateTokenTypeDetails`,
          headers: {
            "Content-Type": "application/json",
          },
          data: paymentDetails
        });
  
        if (data.statusCode === 200) {
          dispatch(PaymentActions.editPaymentSuccess(data.responseData));
          return dispatch(
            showSuccessSnackbar("success", "Token edited successfully!", 3000)
          );
        }
        dispatch(PaymentActions.editPaymentError());
        return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
      } catch (error) {
        dispatch(PaymentActions.editPaymentError());
        dispatch(
          showSuccessSnackbar(
            "error",
            "Error while creating token. Please try again later",
            3000
          )
        );
      }
    };
  };