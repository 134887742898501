import { AdminManagementMap } from "./adminManagementAction";

const initialState = {
  isLoading: false,
  adminList: {},
  activityList: {},
  refreshUserList: true,
  refreshActivities: true,
  selectedUser: {},
  country: "",
  minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  maxDate: new Date(),
  searchBy: "",
  searchText: "",
  activitySkip: 0,
  activityLimit: 10, 
  skip: 0,
  limit: 10,
  dir: "",
  reDirect: false,
  defaultPassword: "",
  role: "", 
  totalAdminRecords: ""
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AdminManagementMap.GET_ALL_ADMIN_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.GET_ALL_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        adminList: action.payload.data,
        totalAdminRecords: action.payload.recordsTotal,
        refreshUserList: false,
        refreshActivities: true,
        role: action.payload.role,
        reDirect: false,
      };
    }
    case AdminManagementMap.GET_ALL_ADMIN_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshUserList: false,
      };
    }
    case AdminManagementMap.CREATE_ADMIN_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.CREATE_ADMIN_SUCCESS: {
      return {
        ...state,
        refreshUserList: true,
        reDirect: true,
      };
    }
    case AdminManagementMap.CREATE_ADMIN_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminManagementMap.UPDATE_ADMIN_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.UPDATE_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshUserList: true,
        refreshActivities: true,
        selectedUser: {
          ...state.selectedUser,
          ...action.payload,
        },
        reDirect: true,
      };
    }
    case AdminManagementMap.UPDATE_ADMIN_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminManagementMap.DELETE_ADMIN_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        refreshUserList: true,
        selectedUser: null,
      };
    }
    case AdminManagementMap.DELETE_ADMIN_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminManagementMap.SET_DEFAULT_PASSWORD_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.SET_DEFAULT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.SET_DEFAULT_PASSWORD_ERROR: {
      return {
        ...state,
      };
    }
    case AdminManagementMap.GET_DEFAULT_PASSWORD_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.GET_DEFAULT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        defaultPassword: action.payload.defaultPassword,
      };
    }
    case AdminManagementMap.GET_DEFAULT_PASSWORD_ERROR: {
      return {
        ...state,
      };
    }
    case AdminManagementMap.REMOVE_ALL_ACTIVITIES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case AdminManagementMap.REMOVE_ALL_ACTIVITIES_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminManagementMap.SELECT_USER_DETAILS: {
      return {
        ...state,
        selectedUser: action.payload,
        refreshActivities: true,
        reDirect: false,
      };
    }
    case AdminManagementMap.REMOVE_ACTIVITY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.REMOVE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshActivities: true,
      };
    }
    case AdminManagementMap.REMOVE_ACTIVITY_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminManagementMap.REMOVE_ALL_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshActivities: true,
        selectedUser: {
          ...state.selectedUser,
          ...action.payload,
        },
      };
    }
    case AdminManagementMap.APPLY_FILTERS:
    case AdminManagementMap.COUNTRY_FILTERS: {
      return {
        ...state,
        ...action.payload,
        refreshUserList: true,
        refreshActivities: true,
        searchText: "",
        skip: initialState.skip,
        limit: initialState.limit,
      };
    }
    case AdminManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case AdminManagementMap.REFRESH_USER_LIST: {
      return {
        ...state,
        skip: 0,
        refreshUserList: true,
      };
    }
    case AdminManagementMap.SET_USER_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshUserList: true,
      };
    }

    case AdminManagementMap.SET_ACTIVITIES_BATCH_NUMBER: {
      return {
        ...state,
        activitySkip: action.payload,
        refreshActivities: true,
      };
    }

    case AdminManagementMap.GET_ALL_ACTIVITY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminManagementMap.GET_ALL_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activityList: action.payload,
        refreshActivities: false,
      };
    }
    case AdminManagementMap.GET_ALL_ACTIVITY_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshActivities: false,
      };
    }
    case AdminManagementMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
        refreshUserList: true,
        refreshActivities: true,
      };
    }
    default:
      return { ...state };
  }
};
