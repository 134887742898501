import supportedChains from "./chains";
export function ellipseAddress(address) {
  const width = 6;
  return `${address.slice(0, width)}...${address.slice(-4)}`;
}

export function getChainData(chainId) {
  console.log("chainId: ", chainId);
  // const chainData = supportedChains.filter(
  //   (chain) => chain.chain_id === chainId
  // )[0];

  const chainData = supportedChains.filter((chain) => {
    const isMatch = chain.chain_id === chainId;
    if (isMatch) {
      console.log("chain: ", chain);
    }
    return isMatch;
  })[0];

  console.log("chainData: 111111111111111111111111", chainData);

  if (!chainData) {
    return { isChainValid: false };
  }
  chainData.isChainValid = true;
  return chainData;
}
