import React, { useEffect, useRef, useState, useContext } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAllRewardAsync, RewardManagementActions , updateAllRewardAsync} from "../redux";
import RewardManagementTable from "./reward_management_detail/rewardManagementTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { poolMethods } from '../../../../web3/functions/factory'
import { Web3Context } from "../../../../web3/contexts/web3Context";
import Web3 from "web3";
import { Row, Col, Grid } from 'react-bootstrap';
import Select from "react-select";

export function RewardManagement() {

  const { networkDetails, handleConnect } = useContext(Web3Context);
  const [getInstance, setInstance] = useState();
  const [rewardObj, setRewardObj] = useState([]);
  const [allRewardList, setAllRewardList] = useState([]);
  const dispatch = useDispatch();
  const searchRewardRef = useRef();

  const categoryOptions = [{ value: "", label: "All" }, 
  { value: "LOGIN_3DAYS", label: "3 straight days login" }, 
  { value: "LOGIN_10DAYS", label: "10 straight days login" },
  { value: "CREATED_5BETS", label: "5 challenges created" },
  { value: "PLACED_5BETS", label: "5 challenges placed" },
  { value: "PLACED_10BETS", label: "10 challenges placed" },
  { value: "TAKE_1BET", label: "1 challenge taken" },
  { value: "TAKE_10BET", label: "10 challenge taken" },
  { value: "UPLOAD_10VIDEOS", label: "10 videos uploaded" }, 
  { value: "FOLLOWERS_50", label: "Got 50 Followers" },
  { value: "TAKE_LIVE_BET", label: "Create + taken or Take live challenge" }]

  const history = useHistory();

  const { refreshRewardList, isLoading, reDirect, skip, limit, rewardSearch, activityType} = useSelector(
    (state) => state.rewardManagement,
    shallowEqual
  );

  useEffect(() => {
    (async () => {
    const instance = await poolMethods.getInstanceRewardDistribution(networkDetails.web3)
      // console.log({ instance })
      if (instance) {
        setInstance(instance);
      }
    })()
  }, [networkDetails.web3])
  //call api for search
  useEffect(() => {
    if (searchRewardRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(RewardManagementActions.refreshRewardList());
        }
      };
      let input = searchRewardRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(RewardManagementActions.setRewardSearch(""));
        input.value = "";
      };
    }
  }, []);
  //call api for get reward list
  useEffect(() => {
    if(refreshRewardList){
      dispatch(getAllRewardAsync( skip, limit, rewardSearch , activityType.value));
      setAllRewardList([])
      setRewardObj([])
    }
  }, [refreshRewardList]);
  // for Approve and send claim notification
  const submitChanges = async () => {
    if(rewardObj.length){
      dispatch(RewardManagementActions.updateRewardListStart());
      let wallerAddressArray = [];
      let rewardArray = []
      rewardObj.forEach((item) => {
        wallerAddressArray.push(item.walletAddress)
        rewardArray.push((item.rewardAmount*1e18).toString())
      });
      //call contract for reward withdrawal
      dispatch(poolMethods.rewardWithdrawal(getInstance, networkDetails.address, wallerAddressArray, rewardArray, allRewardList))
    }
  }
  //save search text
  const onSearchTextChange = (e) => {
    dispatch(RewardManagementActions.setRewardSearch(e.target.value));
  };
  //for seletct activity type 
  const onSelectChange = (e) => {
    dispatch(RewardManagementActions.setActivityTypeChange({ value: e.value, label: e.label }));
  }
  
  return (
    <>
      <Card className="admn-mangment-tbl">
        <CardHeader title="Reward Management">
          <CardHeaderToolbar>
            <Row>
              <Col lg={4}>
                <div className="flex-wrap ap_filter_wraper justify-content-end align-items-center" style={{ width: "100%" }}>
                  <div className="viral-events-dropdown">
                    <Select
                      value={{
                        value: activityType.value,
                        label: activityType.label
                      }}
                      placeholder="Select Category"
                      className={` border-0 p-0  `}
                      classNamePrefix="phoSelect"
                      options={categoryOptions}
                      onChange={onSelectChange}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center mt-3">
                  <form
                    className="subheader_filter"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="form-row">
                      <div className="form-group mr-3 mb-0">
                        <input
                          name="Search"
                          placeholder="Search. . ."
                          type="text"
                          className="form-control"
                          value={rewardSearch}
                          onChange={onSearchTextChange}
                          ref={searchRewardRef}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
              <Col lg={5}>
                <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
                  <form autoComplete="off"
                    className="subheader_filter"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="form-row">
                      <div className="form-group mr-5 mb-1 mt-2">
                        <button
                          className="btn_new btn-sm"
                          type="button"
                          onClick={() => submitChanges()}
                        >
                          Approve and send claim notification
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          
          </CardHeaderToolbar>
        </CardHeader>
       <RewardManagementTable  
       rewardObj={rewardObj} setRewardObj={setRewardObj}
       allRewardList={allRewardList} setAllRewardList={setAllRewardList}/>
      </Card>
    </>
  );
}
