import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserManagementActions, updateAvtar } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const AvtarManagementTable = () => {


  const dispatch = useDispatch();

  const { isLoading, avtarList } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );
    //call update api for delete
    const onDeleteClick = (userId) => {
      dispatch(updateAvtar(userId))
    };
    //call update api for undo
    const onUndoClick = (userId) => {
  
      dispatch(updateAvtar(userId))//call update avtar api
  
    }
  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              id="myTable"
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr.no</th>
                  <th>  
                    Avtar
                  </th>
                  <th className="mt-5">Status</th>
                  <th className="wd-120">Actions</th>
                </tr>
              </thead>
              <tbody>
                {avtarList && avtarList.length > 0 ? (
                  avtarList.map((avtarLists, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="f-w-500">
                            {index + 1}
                          </span>
                        </td>
                        <td>
                          <img style={{height: '5%', width: '5%'}} alt="avtar" src={avtarLists.img}/>
                        </td>
                        <td>
                          <span
                            className={`pmnt_btn ${avtarLists.status === "INACTIVE"
                                ? "label label-lg label-light-danger label-inline"
                                : "label label-lg label-light-success label-inline"
                              }`}
                          >
                            {avtarLists.status}
                          </span>
                        </td>
                      
                          {avtarLists.status === "ACTIVE" ? (
                            <td>
                              <a
                                title="Delete"
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={() => onDeleteClick(avtarLists._id)}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                                </span>
                              </a>
                            </td>
                            ) : (
                            <td>
                              <a
                                title="undo"
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={() => onUndoClick(avtarLists._id)}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Text/Undo.svg"
                                  )}
                                />
                                </span>
                              </a>
                            </td>
                          )
                        }

                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center p-0 border-0">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AvtarManagementTable;
