import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AuthMap } from './servicesAction';

const initialState = {
    category: [],
    subCategory: [],
    isLoading: false,
    message: "",
    errors: "",
    totalRecords: null,
    totalSubRecords: null,
    totalFeedRecords: null,
    limit: 10,
    search: "",
    sortBy:"",
    skip: 0,
    sortType: "",
    sublimit: 10,
    subsearch: "",
    subsortBy:"",
    subskip: 0,
    subsortType: "",
    refreshCategoryList: true,
    selectedCategory: {},
    selectedSubCategory: {},
    selectedFeed: {},
    reDirect: false,
    refreshSubCategoryList: true,
    isModelLoading: false,
    feedlimit: 10,
    feedsearch: "",
    feedsortBy:"",
    feedskip: 0,
    feedsortType: "",
    refreshFeedList: true,
    leagueList: [],
    matchesList: [],
    feedList: [],
    isMatchesLoading: false,
    isSubCategoryLoading: false,
    isFeedLoading: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AuthMap.ADD_CATEGORY_START: {
            return {
                ...state,
                isModelLoading: true,

            }
        }

        case AuthMap.ADD_CATEGORY_SUCCESS: {
            return {
                ...state,
                isModelLoading: false,
                refreshCategoryList: true,
                reDirect: true,
            }
        }

        case AuthMap.ADD_CATEGORY_ERROR: {
            return {
                ...state,
                isModelLoading: false,
                refreshCategoryList: false,
            }
        }

        case AuthMap.GET_CATEGORY_START: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case AuthMap.GET_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                category: action.payload.data,
                totalRecords: action.payload.recordsTotal,
                refreshCategoryList: false,
                reDirect: false,
                selectedCategory: {},

            }
        }

        case AuthMap.GET_CATEGORY_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case AuthMap.SET_CATEGORY_BATCH_NUMBER: {
            return {
                ...state,
                skip: action.payload,
            }
        }

        case AuthMap.SET_SORT_CHANGE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortType: action.payload.sortType,
            }

        }

        case AuthMap.SET_SEARCH_CHANGE: {
            return {
                ...state,
                search: action.payload
            }
        }

        case AuthMap.REFRESH_CATEGORY_LIST: {
            return {
                ...state,
                skip: 0,
                refreshCategoryList: true, 
            }
        }

        case AuthMap.EDIT_CATEGORY_START: {
            return {
                ...state,
                isModelLoading: true,

            }
        }

        case AuthMap.EDIT_CATEGORY_SUCCESS: {
            return {
                ...state,
                isModelLoading: false,
                reDirect: true,
                refreshCategoryList: true,
            }
        }

        case AuthMap.EDIT_CATEGORY_ERROR: {
            return {
                ...state,
                isModelLoading: false,
                refreshCategoryList: false,

            }
        }

        case AuthMap.DELETE_CATEGORY_START: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case AuthMap.DELETE_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshCategoryList: true,
            }
        }

        case AuthMap.DELETE_CATEGORY_ERROR: {
            return {
                ...state,
                isLoading: false,
                refreshCategoryList: false,
            }
        }

        case AuthMap.SELECTED_CATEGORY_DETAILS: {
            return {
                ...state,
                selectedCategory: action.payload,
                reDirect: false,
            }
        }

        case AuthMap.RESET_CATEGORY: {
            return {
                ...state,
                category: [],
            }
        }

        case AuthMap.ADD_SUBCATEGORY_START: {
            return {
                ...state,
                isModelLoading: true,

            }
        }

        case AuthMap.ADD_SUBCATEGORY_SUCCESS: {
            return {
                ...state,
                isModelLoading: false,
                refreshSubCategoryList: true,
                reDirect: true,
            }
        }

        case AuthMap.ADD_SUBCATEGORY_ERROR: {
            return {
                ...state,
                isModelLoading: false,
                refreshSubCategoryList: false,

            }
        }

        case AuthMap.GET_SUBCATEGORY_START: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case AuthMap.GET_SUBCATEGORY_SUCCESS: {

            return {
                ...state,
                isLoading: false,
                subCategory: action.payload.data.subcategory,
                totalSubRecords: action.payload.recordsTotal,
                selectedSubCategory: {},
                refreshSubCategoryList: false,
                reDirect: false,
            }
        }

        case AuthMap.GET_SUBCATEGORY_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case AuthMap.EDIT_SUBCATEGORY_START: {
            return {
                ...state,
                isModelLoading: true,

            }
        }

        case AuthMap.EDIT_SUBCATEGORY_SUCCESS: {
            return {
                ...state,
                isModelLoading: false,
                reDirect: true,
                refreshSubCategoryList: true, 
            }
        }

        case AuthMap.EDIT_SUBCATEGORY_ERROR: {
            return {
                ...state,
                isModelLoading: false,
                refreshSubCategoryList: false,

            }
        }

        case AuthMap.DELETE_SUBCATEGORY_START: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case AuthMap.DELETE_SUBCATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshSubCategoryList: true,
            }
        }

        case AuthMap.DELETE_SUBCATEGORY_ERROR: {
            return {
                ...state,
                isLoading: false,
                refreshSubCategoryList: false,
            }
        }

        case AuthMap.SET_SUBCATEGORY_BATCH_NUMBER: {
            return {
                ...state,
                subskip: action.payload,
            }
        }

        case AuthMap.SET_SUBSORT_CHANGE: {
            return {
                ...state,
                subsortBy: action.payload.subsortBy,
                subsortType: action.payload.subsortType
            }

        }

        case AuthMap.SET_SUBSEARCH_CHANGE: {
            return {
                ...state,
                subsearch: action.payload
            }
        }

        case AuthMap.REFRESH_SUBCATEGORY_LIST: {
            return {
                ...state,
                skip: 0,
                refreshSubCategoryList: true,
            }
        }

        case AuthMap.SELECTED_SUBCATEGORY_DETAILS: {
            return {
                ...state,
                selectedSubCategory: action.payload,
                reDirect: false,
            }
        }

        case AuthMap.REFRESH_DETAILS: {
            return {
                ...state,
                selectedSubCategory: {},
                selectedCategory: {},
                selectedFeed: {},
            }
        }

        case AuthMap.ADD_LIVEFEED_START: {
            return {
                ...state,
                isModelLoading: true,

            }
        }

        case AuthMap.ADD_LIVEFEED_SUCCESS: {
            return {
                ...state,
                isModelLoading: false,
                refreshFeedList: true,
                reDirect: true,
            }
        }

        case AuthMap.ADD_LIVEFEED_ERROR: {
            return {
                ...state,
                isModelLoading: false,
                refreshFeedList: false,

            }
        }

        case AuthMap.GET_LIVEFEED_START: {
            return {
                ...state,
                isFeedLoading: true,
            }
        }

        case AuthMap.GET_LIVEFEED_SUCCESS: {
            
            return {
                ...state,
                feedList: action.payload.data.feed,
                refreshFeedList: false,
                reDirect: false,
                selectedFeed: {},
                totalFeedRecords: action.payload.recordsTotal,
                isFeedLoading: false,
            }
        }

        case AuthMap.GET_LIVEFEED_ERROR: {
            return {
                ...state,
                isFeedLoading: false,
            }
        }

        case AuthMap.EDIT_LIVEFEED_START: {
            return {
                ...state,
                isModelLoading: true,

            }
        }

        case AuthMap.EDIT_LIVEFEED_SUCCESS: {
            return {
                ...state,
                isModelLoading: false,
                reDirect: true,
                refreshFeedList: true,
            }
        }

        case AuthMap.EDIT_LIVEFEED_ERROR: {
            return {
                ...state,
                isModelLoading: false,
                refreshFeedList: false,

            }
        }

        case AuthMap.SET_FEED_BATCH_NUMBER: {
            return {
                ...state,
                feedskip: action.payload,
            }
        }

        case AuthMap.SET_FEEDSORT_CHANGE: {
            return {
                ...state,
                feedsortBy: action.payload.feedsortBy == "category" ? "category.name" :  action.payload.feedsortBy == "subcategory" ? "subcategory.name" :  action.payload.feedsortBy,
                feedsortType: action.payload.feedsortType,
            }

        }

        case AuthMap.SET_FEEDSEARCH_CHANGE: {
            return {
                ...state,
                feedsearch: action.payload
            }
        }

        case AuthMap.REFRESH_FEED_LIST: {
            return {
                ...state,
                feedskip: 0,
                refreshFeedList: true, 
            }
        }

        case AuthMap.SELECTED_LIVEFEED_DETAILS: {
            return {
                ...state,
                selectedFeed: action.payload,
                reDirect: false,
            }
        }

        case AuthMap.DELETE_LIVEFEED_START: {
            return {
                ...state,
                isFeedLoading: true,
            }
        }

        case AuthMap.DELETE_LIVEFEED_SUCCESS: {
            return {
                ...state,
                isFeedLoading: false,
                refreshFeedList: true,
            }
        }

        case AuthMap.DELETE_LIVEFEED_ERROR: {
            return {
                ...state,
                isFeedLoading: false,
                refreshFeedList: false,
            }
        }

        case AuthMap.GET_LEAGUE_START: {
            return {
                ...state,
                isLoading: false, 

            }
        }

        case AuthMap.GET_LEAGUE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                leagueList: action.payload.leagues,

            }
        }

        case AuthMap.GET_LEAGUE_ERROR: {
            return {
                ...state,
                isLoading: false,

            }
        }

        case AuthMap.GET_ALLMATCHES_START: {
            return {
                ...state,
                isMatchesLoading: true,

            }
        }

        case AuthMap.GET_ALLMATCHES_SUCCESS: {
            return {
                ...state,
                isMatchesLoading: false,
                matchesList: action.payload.matches,
    
            }
        }

        case AuthMap.GET_ALLMATCHES_ERROR: {
            return {
                ...state,
                isMatchesLoading: false,

            }
        }

        case AuthMap.GET_ALLSUBCATEGORY_START: {
            return {
                ...state,
                isSubCategoryLoading: true,

            }
        }

        case AuthMap.GET_ALLSUBCATEGORY_SUCCESS: {
            return {
                ...state,
                isSubCategoryLoading: false,
                subCategory: action.payload.subcategory,
    
            }
        }

        case AuthMap.GET_ALLSUBCATEGORY_ERROR: {
            return {
                ...state,
                isSubCategoryLoading: false,

            }
        }

        case AuthMap.SET_MATCH_LIST: {
            return {
                ...state,
                isModelLoading: false,
                matchesList: [],
    
            }
        }

        case AuthMap.SET_SUB_CATEGORY_LIST: {
            return {
                ...state,
                subCategory: [],
                leagueList: [],
                matchesList: [],
            }
        }

        case AuthMap.SET_LEAGUE_LIST: {
            return {
                ...state,
                isModelLoading: false,
                matchesList: [],
                leagueList: [],
            }
        }

        case AuthMap.EDIT_CATEGORY_ACTION_START: {
            return {
                ...state,
                isLoading: true,

            }
        }

        case AuthMap.EDIT_CATEGORY_ACTION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reDirect: true,
                refreshCategoryList: true,
            }
        }

        case AuthMap.EDIT_CATEGORY_ACTION_ERROR: {
            return {
                ...state,
                isLoading: false,
                refreshCategoryList: false,

            }
        }

        default: return { ...state }
    }
};