import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { Formik, Form, useFormik } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { getAllMatchesAsync, editLiveFeedAsync, getAllLeagueAsync, getAllSubCategoryAsync, getCategoryAsync, addLiveFeedAsync } from "../redux/servicesApi"
import { ServicesManagementActions } from '../redux/servicesAction'
import DateTimePicker from 'react-datepicker';
import { setMinutes } from "date-fns";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import {Constants} from '../../../../constants/constants';

const AddLiveFeed = ({ setAddLiveFeed, addLiveFeed, selectedFeed }) => {

  const [leaguefield, setLeagueField] = useState({ value: "", label: "" });
  const [matchesfield, setMatchesField] = useState({ value: "", label: "" });
  const [categoryField, setCategoryField] = useState({ value: "", label: "" })
  const [subcategoryField, setSubcategoryField] = useState({ value: "", label: "" })
  const [feedField, setFeedField] = useState({ value: "" })
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [feedNameField, setFeedNameField] = useState({ value: "" })
  
  const [maxHours, setMaxHours] = useState("");

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubCategoryOptions] = useState([]);
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [matchesOptions, setMatchesOptions] = useState([]);
  
  const [file, setFile] = useState({ file: null, url: selectedFeed && selectedFeed.image ? selectedFeed.image : null });
  const dispatch = useDispatch();

  const { isSubCategoryLoading, isMatchesLoading, isModelLoading, reDirect, subCategory, category, leagueList, matchesList } = useSelector(
    (state) =>
      state.servicesManagement,
    shallowEqual
  )

  const {user} = useSelector(
    (state) =>
      state.auth,
    shallowEqual
  )
  
  const [customCategories, setCustomCategories] = useState([]);
  
  const initialValue = {
    league_label: selectedFeed && selectedFeed.league && selectedFeed.league.name || leaguefield.label || "",
    league_id: selectedFeed && selectedFeed.leagueId || leaguefield.value || "",
    matches_label: selectedFeed && selectedFeed.matchTeam || matchesfield.label || "",
    matches_id: selectedFeed && selectedFeed.matchId || matchesfield.value || "",
    channel: selectedFeed && selectedFeed.channel || feedField.value || "",
    category_label: selectedFeed && selectedFeed.category && selectedFeed.category.name || categoryField.label || "",
    category_id: selectedFeed && selectedFeed.category_id || categoryField.value || "",
    subcategory_label: selectedFeed && selectedFeed.subCategory && selectedFeed.subCategory.name || subcategoryField.label || "",
    subcategory_id: selectedFeed && selectedFeed.sub_category_id || subcategoryField.value || "",
    startdate: selectedFeed && selectedFeed.start_date_time && new Date(selectedFeed.start_date_time) || startDate || "",
    enddate: selectedFeed && selectedFeed.end_date_time && new Date(selectedFeed.end_date_time) || endDate || "",
    image: selectedFeed && selectedFeed.image || file.file || "",
    feed_name: selectedFeed && selectedFeed.feed_name || feedNameField.value || ""
  }
  //formik validation schema
  const addServiceSchema = Yup.object().shape({
    // league_id: customCategories.includes(initialValue.category_id) ? Yup.string() :
    //   Yup.string()
    //   .trim()
    //   .required("Please choose league") ,
    // matches_id: customCategories.includes(initialValue.category_id) ? Yup.string() : 
    //   Yup.string()
    //   .trim()
    //   .required("Please choose matches") ,
    league_id: (initialValue.league_id === "" || leaguefield.value === "") ? Yup.string() :
      Yup.string()
      .trim()
      .required("Please choose league") ,
    matches_id: (initialValue.league_id === "" || leaguefield.value === "") ? Yup.string() :
      Yup.string()
      .trim()
      .required("Please choose matches") ,
    channel: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(300, "Maximum 50 characters")
      .required("Please enter channel"),
    feed_name: (initialValue.league_id !== "" || leaguefield.value !== "") ? Yup.string() :
      Yup.string()
      .trim()
      .required("Please choose feed Name") ,
    // subcategory_id: (initialValue.category_id !== "" && subcategoryOptions.length === 0) ? Yup.string() : 
    //   Yup.string()
    //   .trim()
    //   .required("Please choose subcategory"),
    subcategory_id: (initialValue.subcategory_id === "" || subcategoryField.value === "") ? Yup.string() :
    Yup.string()
      .trim()
      .required("Please choose subcategory"),
    category_id: Yup.string()
      .trim()
      .required("Please choose category"),
    startdate: Yup.string()
      .trim()
      .required("Please choose Start Date"),
    enddate: Yup.string()
      .trim()
      .required("Please choose End Date"),
    image: Object.keys(selectedFeed).length === 0 ? Yup.string()
      .required("Please choose image") : Yup.string(),
  })
  
  const filterPassedTime = (time) => {
    // console.log("time", time);
    const currentDate = new Date()
    const selectedDate = new Date(time)
    let hours = (time.getTime() - startDate.getTime()) / 36e5
    // console.log("hours", hours);
    return hours < 0 || hours > 12 ? false : true
  };

  useEffect(() => {
    dispatch(getCategoryAsync());//call get category api
  }, []);
  //save category id and name in array
  useEffect(() => {
    let catArr = []
    let cusCat = []
    category?.category && category.category.map((cd) => {
        catArr.push({
          value: cd._id,
          label: cd.name
        }) 
    })
    // category?.category && category.category.map((cd) => {
    //   if(cd.isCustom === true){
    //     cusCat.push(cd._id)
    //   }  
    // })
    setCategoryOptions(catArr)
    // setCustomCategories(cusCat)
  }, [category?.category?.length > 0])
  //save sub category id and name in array
  useEffect(() => {
    let subcatArr = []
    subCategory && subCategory.length > 0 && subCategory.map((cd) => {
      subcatArr.push({
        value: cd._id,
        label: cd.name
      })
    })
    setSubCategoryOptions(subcatArr)
  }, [subCategory && subCategory.length > 0])
  //save league id and name in array
  useEffect(() => {
    let leagueArr = []
    leagueList && leagueList.length > 0 && leagueList.map((cd) => {
      leagueArr.push({
        value: cd._id,
        label: cd.name
      })
    })
    setLeagueOptions(leagueArr)
  }, [leagueList && leagueList.length > 0, ])
  //save match id and name in array
  useEffect(() => {
    let matchArr = []
    matchesList && matchesList.length > 0 && matchesList.map((cd) => {
      if(cd.localTeamName && cd.visitorTeamName){
        matchArr.push({
          value: cd._id,
          label: `${cd.localTeamName} VS ${cd.visitorTeamName}`
        })
      }else{
        matchArr.push({
          value: cd._id,
          label: cd.matchName
        })
      }
    })
    setMatchesOptions(matchArr)
  }, [matchesList && matchesList.length > 0,])
  //after redirect
  useEffect(() => {
    if (reDirect === true) {
      setAddLiveFeed(false)
      setLeagueField({ value: "", label: "" })
      setMatchesField({ value: "", label: "" })
      setCategoryField({ value: "", label: "" })
      setSubcategoryField({ value: "", label: "" })
      setFeedField({ value: "" })
      setFeedNameField({ value: "" })
      setStartDate("");
      setEndDate("");
      setFile({ file: null, url: null });
      setMaxHours("");
    }
  }, [reDirect])

  //close feed model
  const onHideFeed = () => {
    setAddLiveFeed(false)
    setLeagueField({ value: "", label: "" })
    setMatchesField({ value: "", label: "" })
    setCategoryField({ value: "", label: "" })
    setSubcategoryField({ value: "", label: "" })
    setFeedField({ value: "" })
    setFeedNameField({ value: "" })
    setFile({ file: null, url: null })
    setStartDate("");
    setEndDate("");
    setMaxHours("");
    dispatch(ServicesManagementActions.refreshDetails())
    setSubCategoryOptions([])
    setLeagueOptions([])
    setMatchesOptions([])
  }
  //save upload image details
  const uploadImage = (e, setFieldValue) => {
    setFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    setFieldValue("image", e.currentTarget.files[0])
  };
 
  return (
    <>
      <Modal
        size="lg"
        show={addLiveFeed}
        onHide={() => onHideFeed()}
        centered
        className="def_modal delete_modal"
      >
        
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">{selectedFeed._id ? "Edit Feed" : "Add Feed"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={initialValue}
            validationSchema={addServiceSchema}
            onSubmit={async (values) => {

              var diff = (values.enddate.getTime() - values.startdate.getTime()) / 1000;
              diff /= (60 * 60);
              // const roundOFF = Math.abs(Math.round(diff));
              // roundOFF <= 12 && 
              if (diff >= 0) {

                const formData = new FormData();
                if(values.league_id !== ""){
                  formData.append("leagueId", values.league_id);
                }
                if(values.matches_id !== ""){
                  formData.append("matchId", values.matches_id);
                }
                if(values.subcategory_id !== ""){
                  formData.append("sub_category_id", values.subcategory_id);
                }
                if(values.feed_name !== ""){
                  formData.append("feed_name", values.feed_name);
                }
            
                formData.append("channel", values.channel);
                formData.append("category_id", values.category_id);
                formData.append("start_date_time", new Date(values.startdate).getTime());
                formData.append("end_date_time", new Date(values.enddate).getTime());
                formData.append("image", values.image);
                formData.append("feed_creator", "Admin");
                formData.append("user_id", user?._id);

                if (selectedFeed._id) {

                  dispatch(editLiveFeedAsync(selectedFeed._id, formData))
                }
                if (!selectedFeed._id) {
                  
                  dispatch(addLiveFeedAsync(formData))
                }
              }
              else {
                // dispatch(showSuccessSnackbar("warning", "differnce between From date and To date is invalid", 3000))
                dispatch(showSuccessSnackbar("warning", "Ending time is Invalid", 3000))
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                  <Col lg={6}>
                    <div className="form-group">
                      <label className="form-label text-white font-size-16px inter-font font-weight-normal">Category</label>
                      <div className="viral-events-dropdown ">
                        <Select
                          value={{
                            value: values.category_id,
                            label: values.category_label,
                          }}
                          placeholder="Select Category"
                          className={` border-0 p-0 `}
                          classNamePrefix="phoSelect"
                          options={categoryOptions}
                          onChange={(e) => {
                            setFieldValue("category_id", e.value);
                            setFieldValue("category_label", e.label);
                            setCategoryField({
                              value: e.value,
                              label: e.label
                            })
                            setFieldValue("subcategory_id", "");
                            setFieldValue("subcategory_label", "");
                            setSubcategoryField({
                              value: "",
                              label: ""
                            })
                            setFieldValue("league_id", "");
                            setFieldValue("league_label", "");
                            setLeagueField({
                              value: "",
                              label: ""
                            })
                            setFieldValue("matches_id", "");
                            setFieldValue("matches_label", "");
                            setMatchesField({
                              value: "",
                              label: ""
                            })
                            dispatch(ServicesManagementActions.setSubCategoryList())
                            dispatch(getAllSubCategoryAsync(e.value))
                          }}
                        />
                      </div>
                      {touched.category_id && errors.category_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.category_id}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    
                    { 
                    <div className="form-group">
                      <label className="form-label text-white font-size-16px inter-font font-weight-normal">SubCategory</label>
                      <div className="viral-events-dropdown ">
                        <Select
                          value={{
                            value: values.subcategory_id,
                            label: values.subcategory_label,
                          }}
                          placeholder="Select SubCategory"
                          className={`border-0 p-0 `}
                          classNamePrefix="phoSelect"
                          options={subcategoryOptions}
                          onChange={(e) => {
                            setFieldValue("subcategory_id", e.value);
                            setFieldValue("subcategory_label", e.label);
                            setSubcategoryField({
                              value: e.value,
                              label: e.label
                            })
                            setFieldValue("league_id", "");
                            setFieldValue("league_label", "");
                            setLeagueField({
                              value: "",
                              label: ""
                            })
                            setFieldValue("matches_id", "");
                            setFieldValue("matches_label", "");
                            setMatchesField({
                              value: "",
                              label: ""
                            })
                            dispatch(ServicesManagementActions.setLeagueList())
                            dispatch(getAllLeagueAsync({category_id: categoryField.value, sub_category_id: e.value}));
                          }}
                        />
                      </div>
                    </div>}
                    {isSubCategoryLoading && (
                        <span colSpan={2}>
                          <div>
                            <CircularProgress />
                          </div>
                        </span>
                    )}
                    {touched.subcategory_id && errors.subcategory_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {errors.subcategory_id}
                        </div>
                      </div>
                    ) : null}
                    {/* customCategories.includes(values.category_id) ? null :  */}
                    {<div className="form-group">
                      <label className="form-label text-white font-size-16px inter-font font-weight-normal">League</label>
                      <div className="viral-events-dropdown ">
                        <Select
                          value={{
                            value: values.league_id,
                            label: values.league_label,
                          }}
                          placeholder="Select League"
                          className={` border-0 p-0 `}
                          classNamePrefix="phoSelect"
                          options={leagueOptions}
                          onChange={async (e) => {
                            setFieldValue("league_id", e.value);
                            setFieldValue("league_label", e.label);
                            setLeagueField({
                              value: e.value,
                              label: e.label
                            })
                            setFieldValue("matches_id", "");
                            setFieldValue("matches_label", "");
                            setMatchesField({
                              value: "",
                              label: ""
                            })
                            dispatch(ServicesManagementActions.setMatchesList())
                            await dispatch(getAllMatchesAsync({category_id: categoryField.value, sub_category_id: subcategoryField.value, leagueId: e.value}))
                          }}
                        />
                      </div>
                    </div>}
                    {touched.league_id && errors.league_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {errors.league_id}
                        </div>
                      </div>
                    ) : null}

                    {/* customCategories.includes(values.category_id) ? null : */}
                    {<div className="form-group">
                      <label className="form-label text-white font-size-16px inter-font font-weight-normal">Matches</label>
                      <div className="viral-events-dropdown ">
                        <Select
                          value={{
                            value: values.matches_id,
                            label: values.matches_label,
                          }}
                          placeholder="Select Matches"
                          className={` border-0 p-0 `}
                          classNamePrefix="phoSelect"
                          options={matchesOptions}
                          onChange={(e) => {
                            // Set field value
                            setFieldValue("matches_id", e.value);
                            setFieldValue("matches_label", e.label);
                            setMatchesField({
                              value: e.value,
                              label: e.label
                            })
                          }}
                        />
                      </div>
                    </div>}
                    {isMatchesLoading && (
                      <tr>
                        <td colSpan={2}>
                          <div>
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    )}
                    {touched.matches_id && errors.matches_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {errors.matches_id}
                        </div>
                      </div>
                    ) : null}

                  <div className="d-flex form-group justify-content-lg-start date-pick-label">
                    <label className="form-label text-white font-size-16px inter-font font-weight-normal">From Date</label>
                    <DateTimePicker
                      name="startdate"
                      value={values.startdate}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      showTimeSelect
                      selected={values.startdate}
                      timeFormat="hh:mm a"
                      timeIntervals={30}
                      autoComplete="off"
                      onChange={date => {
                        setStartDate(date)
                        setFieldValue("startdate", date);
                        // const timeStamp = new Date(date);
                        // setMaxHours(new Date(timeStamp.setHours(timeStamp.getHours() + 12)))
                        setEndDate("")
                        setFieldValue("enddate", "");
                      }}
                    />
                  </div>
                  {touched.startdate && errors.startdate ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.startdate}
                      </div>
                    </div>
                  ) : null}

                  <div className="d-flex form-group justify-content-lg-start date-pick-label2">
                    <label className="form-label text-white font-size-16px inter-font font-weight-normal">To Date</label>
                    <DateTimePicker
                      name="enddate"
                      value={values.enddate}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeFormat="hh:mm a"
                      showTimeSelect
                      selected={values.enddate}
                      minDate={values.startdate}
                      // maxDate={selectedFeed && selectedFeed._id && maxHours === "" ? new Date(values.startdate).setHours(new Date(values.startdate).getHours() + 12) : maxHours}
                      // timeIntervals={30}
                      autoComplete="off"
                      onChange={date => {
                        setEndDate(date)
                        setFieldValue("enddate", date);
                      }}
                    />
                  </div>
                  {touched.enddate && errors.enddate ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.enddate}
                      </div>
                    </div>
                  ) : null}
                  </Col>

                  <Col lg={6}>
                    {/* <label className="text-white font-size-16px inter-font font-weight-normal">Note : </label> */}
                    {/* <p><b>Difference between From date and To date must be lesser or equal to 12 hours</b></p> */}

                    <div className="form-group">
                      <label className="form-label text-white font-size-16px inter-font font-weight-normal">Channel</label>
                      <input
                        value={values.channel}
                        placeholder="Enter Channel"
                        type="text"
                        name="channel"
                        className={
                          errors.channel && touched.channel
                            ? "form-control re_inputRouded is-invalid border-less-input"
                            : "form-control re_inputRouded border-less-input"
                        }
                        onChange={(e) => {

                          setFieldValue("channel", e.target.value);
                          setFeedField({ value: e.target.value })
                        }}

                      />
                      {touched.channel && errors.channel ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.channel}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label text-white font-size-16px inter-font font-weight-normal">Feed Name</label>
                      <input
                        value={values.feed_name}
                        placeholder="Enter Feed Name"
                        type="text"
                        name="feed_name"
                        className={
                          errors.feed_name && touched.feed_name
                            ? "form-control re_inputRouded is-invalid border-less-input"
                            : "form-control re_inputRouded border-less-input"
                        }
                        onChange={(e) => {

                          setFieldValue("feed_name", e.target.value);
                          setFeedNameField({ value: e.target.value })
                        }}

                      />
                      {touched.feed_name && errors.feed_name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.feed_name}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label text-white font-size-16px inter-font font-weight-normal">Feed Image</label>
                      <div
                        className="pro_img text-center"
                        style={{
                          backgroundImage: `url(${file.url || selectedFeed.image})`,
                        }}
                      >
                        <input
                          type="file"
                          name="image"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => uploadImage(e, setFieldValue)}
                        />
                        <div className="edt_img_btn">
                          {/* <span className="material-icons">upload</span>{selectedFeed.image ? "Edit" : "Upload"} */}
                          <div className="upload-pic-btn"><span className="material-icons">upload</span>Upload</div>
                        </div>
                      </div>
                      {touched.image && errors.image ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.image}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <label className="text-white font-size-16px inter-font font-weight-normal">Suggestion for best UI Experience: </label>
                    <p><b>Image Dimensions: <span className="text-danger">{Constants.ImageConstants.LIVE_FEED_DIMENSIONS}</span></b></p>
                    <p>Image Compression Tool: <span><a href="https://imagecompressor.com/" target="_blank" className="text-info">https://imagecompressor.com/</a></span></p>

                    <div className="form-group mb-0 mt-4">
                      <div className="form-group mr-5 mb-1 mt-2">
                        {isModelLoading ? (
                          <div className="ml-3 basic-verification-loader text-center">
                            <CircularProgress />
                          </div>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-blue spinnerBtn add-avtar-btn"
                            disabled={isModelLoading}
                          >
                            <span>{selectedFeed._id ? "Edit" : "Add"}</span>

                          </button>
                        )}

                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddLiveFeed