import axios from "axios";
import { TicketsAndSupportActions } from "./tickets&SupportAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};
//get all ticket api
export const getAllTicketsAsync = (values) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      let { _id } = getState().auth.user;

      dispatch(TicketsAndSupportActions.getAllTicketsStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/supportTicket/getAllSupportTickets`,
        method: "POST",
        data: values,
      });

      if (data.statusCode === 200) {
        return dispatch(
          TicketsAndSupportActions.getAllTicketsSuccess(data)
        );
      }
      dispatch(TicketsAndSupportActions.getAllTicketsError());

    } catch (error) {
      dispatch(TicketsAndSupportActions.getAllTicketsError());
      return dispatch(
        showSuccessSnackbar(
          "error",
          "Error while fetching Data.Please try again after some time",
          3000
        )
      );
    }
  };
};
//add comment api
export const addCommentAsync = (selectedTicketID, comment) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      let { _id } = getState().auth.user;
      dispatch(TicketsAndSupportActions.addCommentStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/supportTicket/addComment/${selectedTicketID}`,
        method: "PUT",
        data: {
          "senderId": _id,
          "msg": comment
        },
      });
      if (data.statusCode === 200) {
        dispatch(TicketsAndSupportActions.addCommentSuccess(data));
        return dispatch(
          showSuccessSnackbar("success", "Your comment has been submitted successfully", 3000)
        );
      }
      dispatch(TicketsAndSupportActions.addCommentError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(TicketsAndSupportActions.addCommentError());
      return dispatch(
        showSuccessSnackbar("error", "Please try again later.", 3000)
      );
    }
  };
};
//add faq api
export const addFaqAsync = (faq, projectId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(TicketsAndSupportActions.addFaqStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/addFAQ/${_id}/${projectId}`,
        method: "POST",
        data: faq,
      });
      if (data.responseCode === 200) {
        dispatch(TicketsAndSupportActions.addFaqSuccess(data.responseData));
        dispatch(showSuccessSnackbar("success", data.responseMessage, 3000));
        return data;
      }
      dispatch(TicketsAndSupportActions.addFaqError());
      dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
      return data;
    } catch (error) {
      dispatch(TicketsAndSupportActions.addFaqError());
      return dispatch(
        showSuccessSnackbar("error", "Please try again later.", 3000)
      );
    }
  };
};
//change ticket status api
export const changeTicketStatusAsync = (ticketId, ticketStatus) => {
  
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      let { _id } = getState().auth.user;
      dispatch(TicketsAndSupportActions.changeTicketStatusStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/supportTicket/updateSupportTicket/${ticketId}`,
        method: "PUT",
        data: {"status": ticketStatus},
      });
      if (data.statusCode === 200) {
        dispatch(
          TicketsAndSupportActions.changeTicketStatusSuccess(data)
        );
        return dispatch(
          showSuccessSnackbar("success", "ticket status updated successfully", 3000)
        );
      }
      dispatch(TicketsAndSupportActions.changeTicketStatusError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(TicketsAndSupportActions.changeTicketStatusError());
      return dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating ticket status. Please try again later.",
          3000
        )
      );
    }
  };
};
//update ticket details api
export const updateTicketDetailsAsync = (ticketId, details) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = process.env.REACT_APP_BASE_URL;
      let { _id } = getState().auth.user;
      dispatch(TicketsAndSupportActions.updateTicketsDetailsStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/updateTicketDetails/${_id}/${ticketId}`,
        method: "PUT",
        data: details,
      });
      if (data.responseCode === 200) {
        dispatch(
          TicketsAndSupportActions.updateTicketsDetailsSuccess(
            data.responseData
          )
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(TicketsAndSupportActions.updateTicketsDetailsError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(TicketsAndSupportActions.updateTicketsDetailsError());
      return dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating ticket details. Please try again later.",
          3000
        )
      );
    }
  };
};
//update remaining budget api
export const updateRemainingBudgetAsync = (details) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      const projectId = details.projectId
      const budget = details.budget
      let { _id } = getState().auth.user;

      await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/updateRemainingBudget/${_id}/${projectId}/${budget}`,
        method: "GET"
      });
      
      return true

    } catch (error) {
      // console.log({error})
      return false
    }
  };
};
