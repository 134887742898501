import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ProfileActions } from "../redux/profileAction";
import { updateProfileAsync } from "../redux/profileApi";

const ProfileBasicDetails = (props) => {

  const dispatch = useDispatch();

  const { profileEmailChangeModal, profileContactChangeModal } = useSelector(
    (state) => state.profile,
    shallowEqual
  );
  useEffect(() => {
    if (props && props.selectedTab !== "basic-details") {
      formik.resetForm();//reset form
    }
  }, [props && props.selectedTab]);

  const { user, isLoading, role } = useSelector((state) => state.auth, shallowEqual);

  const initialValues = {
    email: user.email || "",
    displayName: user.displayName || "",
    firstName: user.firstName || "",
    lastName: user.lastName || ""
  };
  //formik validation schema
  const ProfileSchema = () =>
    Yup.object().shape({
      // firstName: Yup.string()
      //   .trim()
      //   .required("First name is required"),
      // lastName: Yup.string()
      //   .trim()
      //   .required("Last name is required"),
      email: Yup.string()
        .trim()
        .email("Enter valid email")
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters")
        .required("Email is required")
    });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ProfileSchema,
    onSubmit: (values, { setErrors, resetForm }) => {

      let updatedValues = {}
      // updatedValues.displayName = `${values.firstName} ${values.lastName}`
      // updatedValues.firstName = values.firstName
      // updatedValues.lastName = values.lastName
      updatedValues.displayName = values.displayName

      if (values.email !== user.email) {
        updatedValues.email = values.email
      }

      dispatch(updateProfileAsync(updatedValues, user._id));//call update profile details api
      //resetForm();
    },
  });

  return (
    <>
      <div className="tblmrgn mt-0 lwpddng cstm_pddng">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-6-as">
            <form
              className="form def_form frmwtpddng"
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                {/* <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input
                      placeholder="First Name"
                      type="text"
                      className={`form-control wth_chng border-less-input`}
                      name="firstName"
                      {...formik.getFieldProps("firstName")}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.firstName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input
                      placeholder="Last Name"
                      type="text"
                      className={`form-control wth_chng border-less-input`}
                      name="lastName"
                      {...formik.getFieldProps("lastName")}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.lastName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Display Name</label>
                    <input
                      placeholder="Display Name"
                      type="text"
                      className={`form-control wth_chng border-less-input`}
                      name="displayName"
                      {...formik.getFieldProps("displayName")}
                    />
                    {formik.touched.displayName && formik.errors.displayName ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.displayName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <div className="re_grpPhone position-relative">
                      <input
                        placeholder="username@company.com"
                        type="email"
                        className={`form-control wth_chng border-less-input`}
                        name="email"
                        {...formik.getFieldProps("email")}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                <button
                  type="submit"
                  disabled={Object.keys(formik.errors).length > 0 || isLoading}
                  className="btn btn-blue defpddng show-more-btn font-family-base mt-3 btn_new"
                >
                  <span>Update</span>
                  {isLoading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </form>
            {/*   <ProfileEmailVerifyModal
              profileEmailChangeModal={profileEmailChangeModal}
              onCloseEmailChangeModal={onCloseEmailChangeModal}
              emailId={formik.values.emailId}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileBasicDetails;
