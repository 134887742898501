export const TeamManagementMap = {
  UPDATE_TEAM_START: "UPDATE_TEAM_START",
  UPDATE_TEAM_SUCCESS: "UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_ERROR: "UPDATE_TEAM_ERROR",

  GET_TEAM_START: "GET_TEAM_START",
  GET_TEAM_SUCCESS: "GET_TEAM_SUCCESS",
  GET_TEAM_ERROR: "GET_TEAM_ERROR",

  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_TEAM_LIST: "REFRESH_TEAM_LIST",
  SET_TEAM_BATCH_NUMBER: "SET_TEAM_BATCH_NUMBER",
  SELECT_TEAM_DETAILS: "SELECT_TEAM_DETAILS",
  SELECT_CATEGORY: "SELECT_CATEGORY"

};

export const TeamManagementActions = {
  updateTeamStart: (data) => ({
    type: TeamManagementMap.UPDATE_TEAM_START,
    payload: data,
  }),
  updateTeamSuccess: (data) => ({
    type: TeamManagementMap.UPDATE_TEAM_SUCCESS,
    payload: data,
  }),
  updateTeamError: (errors) => ({
    type: TeamManagementMap.UPDATE_TEAM_ERROR,
    payload: { errors },
  }),

  getTeamStart: (data) => ({
    type: TeamManagementMap.GET_TEAM_START,
    payload: data,
  }),
  getTeamSuccess: (data) => ({
    type: TeamManagementMap.GET_TEAM_SUCCESS,
    payload: data,
  }),
  getTeamError: (errors) => ({
    type: TeamManagementMap.GET_TEAM_ERROR,
    payload: { errors },
  }),

  searchTextChange: (data) => ({
    type: TeamManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),

  refreshTeamList: () => ({
    type: TeamManagementMap.REFRESH_TEAM_LIST,
  }),

  setTeamBatchNumber: (data) => ({
    type: TeamManagementMap.SET_TEAM_BATCH_NUMBER,
    payload: data,
  }),

  setTeamDetails: (data) => ({
    type: TeamManagementMap.SELECT_TEAM_DETAILS,
    payload: data,
  }),

  setSelectCategory: (data) => ({ 
    type: TeamManagementMap.SELECT_CATEGORY, 
    payload: data
  })
};
