import React from 'react'
import Chart from 'react-apexcharts'
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const EvolutionChart = () => {
    
    // if(values) {
    //     values && values.map(ele => {
    //         chartValues.push(ele.count)
    //     });
    //     values && values.map(ele => {
    //         chartKeys.push(ele.name)
    //     });   
    // }

    const { isCatEvoLoding, categoryEvolution} = useSelector(
      (state) => state.reportsAndAnaytics,
      shallowEqual
    );

    const Evolutionvalues = {
      series: categoryEvolution,
      options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
        dataLabels: {
            enabled: false
          },
        stroke: {
            curve: 'straight'
          },
        //   title: {
        //     text: 'Product Trends by Month',
        //     align: 'left'
        //   },
        grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
        xaxis: {
          
          labels: {
            format: "MM"
          }
          
        }
      },
      
    };

    return (
        <>
            <div>
                <h1>Top Categories Evolution</h1>

                <div className="donut">
                    <Chart
                        options={Evolutionvalues.options}
                        series={Evolutionvalues.series}
                        type="line"
                        width= {360}
                    />
                </div>
                <div>
                    <span>
                    </span>
                </div>
            </div>
        </>
    )
}

export default EvolutionChart