import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table, Modal } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NotificationManagementActions } from "../../redux";
import BasicPagination from "../../../pagination/BasicPagination";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { deleteNotificationAsync, updateNotficationAsync } from "../../redux/notificationManagementApi";
import { toTimestamp } from '../../../../utils/appUtils';

const NotificationManagementTable = ({ onEditClick }) => {
  const { isLoading, notificationList, skip, limit } = useSelector(
    (state) => state.notificationManagement,
    shallowEqual
  );

  const [showTargetUsers, setShowTargetUsers] = useState(false);
  const [targetNotification, setTargetNotification] = useState({});

  const [
    selectedNotificationMessage,
    setSelectedNotificationMessage,
  ] = useState({});
  //show target user model 
  const showUserModel = (notificationLists) => {
    setShowTargetUsers(true);
    setTargetNotification(notificationLists);
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);
  //on page change
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(NotificationManagementActions.setNotificationBatchNumber(count));
  };
  //show delete model 
  const onDeleteClick = (notificationLists) => {
    setSelectedNotificationMessage(notificationLists);
    deleteShow();
  };
  //call delete notification api and close delete model
  const onDeactiveClick = () => {
    dispatch(deleteNotificationAsync(selectedNotificationMessage._id));
    setSelectedNotificationMessage({});
    deleteClose();
  };

  const onActivateClick = (notificationLists) => {
    dispatch(deleteNotificationAsync(notificationLists._id));
  };
  //call duplicate notification api
  const duplicateNotification = (notificationLists) => {
    dispatch(updateNotficationAsync(notificationLists._id))
  }

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Notification</th>
                  <th>Target Users</th>
                  <th>Sending Time</th>
                  <th width="120px">Action</th>
                </tr>
              </thead>
              <tbody>
                {notificationList && notificationList.data && notificationList.data.notification.length ? (
                  notificationList.data.notification.map((notificationLists, index) => (
                    <tr key={index}>
                      <td>
                        <span className="f-w-500">
                          {skip * limit + (index + 1)}
                        </span>
                      </td>
                      <td>{notificationLists.notificationText}</td>
                      <td>
                        <span className="f-w-500">
                          <button className="dup-btn-bg" onClick={() => showUserModel(notificationLists)}>show users</button>
                        </span>
                      </td>
                      <td>{toTimestamp(notificationLists.sendingTime)}</td>
                      <td className="d-inline-flex">
                        <button onClick={() => duplicateNotification(notificationLists)} className="dup-btn-bg">
                          <span>Duplicate</span>
                        </button>
                        <a
                          title="Delete"
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => onDeleteClick(notificationLists)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4 btm-pagination">
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-left">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {notificationList && notificationList.recordsTotal} Records
              </div>
              <div className="col-md-8 text-left mb-5 aspgntn">
                <BasicPagination
                  totalRecords={notificationList.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>

            </div>
          
        </div>
      </div>
      
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className="form def_form frmwtpddng">
          <p className="fn_s_18 f-w-400 text-center mb-10">
            Are you sure want to delete?
          </p>
          <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
            <div className="form-group mr-5 mb-1 mt-2">
              <button
                // className="btn_new btn-sm"
                type="button"
                className="btn btn-def btn_pdng mr-3 btn_new"
                onClick={deleteClose}
              >
                Close
              </button>
            </div>
            <div className="form-group mr-5 mb-1 mt-2">
              <button
                // className="btn_new btn-sm"
                type="button"
                className="btn btn-delete btn_pdng btn_new"
                onClick={onDeactiveClick}
              >
                Yes, Delete it
              </button>
            </div>
          </div>
        </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showTargetUsers}
        onHide={() => setShowTargetUsers(false)}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <div>
              {
                targetNotification && targetNotification.user &&
                targetNotification.user.map((user, index) => (
                    <div key={index} className="d-flex justify-content-between align-content-center text-left px-2 py-2 rounded-xl mb-2">
                      <h3 className="font-size-12px text-white inter-font f-w-700 modal-title h4">{user.userName}</h3>
                      <img style={{ height: '5%', width: '5%' }} src={user && user.picture} alt="user image" />
                    </div>
                ))
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NotificationManagementTable;
