import React, { useEffect } from "react";
// import CKEditor from "@ckeditor/ckeditor5-react";
/* import { CKEditor } from "ckeditor4-react"; */
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useParams, Redirect, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
} from "./../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { addEmailAsync, updateEmailAsync } from "../redux/emailManagementApi";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CustomEditor from "../../CustomEditor/CustomEditor";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddTemplate = ({ showAddTemplate, setShowAddTemplate }) => {


  const history = useHistory();
  const dispatch = useDispatch();


  const initialValues = {
    type: "EMAIL",
    mailTitle: "",
    mailSubject: "",
    mailBody: "",
  };
  //call after template data save to close model
  const redirectBack = () => {
    setShowAddTemplate(false)
  };
  //formik validation schema
  const addEmailSchema = Yup.object().shape({
    mailTitle: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Email Title"
      )
      .required("Please enter Email title"),
    mailSubject: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Email subject"
      )
      .required("Please enter Email subject"),
    mailBody: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter valid Email body"
      )
      .required("Please enter Email body"),
  });

  return (
    <>
      <Modal
        show={showAddTemplate}
        onHide={() => setShowAddTemplate(false)}
        centered
        className="def_modal delete_modal"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Add Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={addEmailSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              dispatch(
                addEmailAsync(values, setSubmitting, resetForm, redirectBack)
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Row className="justify-content-center">
                <Col lg={12}>
                  <Form className="form def_form frmwtpddng">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Title</label>
                          <input
                            placeholder="Enter Title"
                            type="text"
                            name="mailTitle"
                            className={
                              errors.mailTitle && touched.mailTitle
                                ? "form-control re_inputRouded is-invalid border-less-input"
                                : "form-control re_inputRouded border-less-input"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailTitle}
                          />
                          {touched.mailTitle && errors.mailTitle ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.mailTitle}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Subject</label>
                          <input
                            placeholder="Enter Subject"
                            type="text"
                            name="mailSubject"
                            className={
                              errors.mailSubject && touched.mailSubject
                                ? "form-control re_inputRouded is-invalid border-less-input"
                                : "form-control re_inputRouded border-less-input"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailSubject}
                          />
                          {touched.mailSubject && errors.mailSubject ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {errors.mailSubject}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Email Body</label>

                          <CKEditor
                            data={values.mailBody}
                            editor={ClassicEditor}
                            config={{
                              removePlugins: ['MediaEmbed', "EasyImage", "ImageUpload"]
                            }}
                            className={
                              errors.mailBody && touched.mailBody
                                ? "form-control re_inputRouded is-invalid"
                                : "form-control re_inputRouded"
                            }

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              // Set field touched value
                              setFieldTouched("mailBody", true);

                              // Set field value
                              setFieldValue("mailBody", data);
                            }}
                          />
                          {touched.mailBody && errors.mailBody ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.mailBody}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0 mt-4">
                      <div className="mb-1 mt-2">
                        {isSubmitting ? (
                          <div className="ml-3 basic-verification-loader text-center">
                            <CircularProgress />
                          </div>
                        ) : (
                          <Button
                            variant="blue"
                            className="px-5 defpddng spinnerBtn btn_new add-avtar-btn d-flex justify-content-center"
                            size="lg"
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}

                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddTemplate;
