import { FaqMap } from './faqAction';

const initialState = {
    isLoading: false,
    refreshFaq: true,
    faqLists: {},
    selectedFAQ: {},
    reDirect: false,
    skip: 0,
    limit: 10
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FaqMap.CREATE_FAQ_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case FaqMap.CREATE_FAQ_SUCCESS: {
            return {
                ...state,
                refreshFaq: true,
                reDirect: true
            }
        }
        case FaqMap.CREATE_FAQ_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case FaqMap.GET_ALL_FAQ_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case FaqMap.GET_ALL_FAQ_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshFaq: false,
                faqLists: action.payload
            }
        }
        case FaqMap.GET_ALL_FAQ_ERROR: {
            return {
                ...state,
                isLoading: false,
                refreshFaq: false
            }
        }
        case FaqMap.UPDATE_FAQ_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case FaqMap.UPDATE_FAQ_SUCCESS: {

            return {
                ...state,
                refreshFaq: true,
                selectedFAQ: {
                    ...state.selectedFAQ,
                    ...action.payload
                },
                reDirect: true
            }
        }
        case FaqMap.UPDATE_FAQ_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case FaqMap.DELETE_FAQ_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case FaqMap.DELETE_FAQ_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshFaq: true
            }
        }
        case FaqMap.DELETE_FAQ_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case FaqMap.SET_SELECTED_FAQ: {
            return {
                ...state,
                selectedFAQ: action.payload,
                reDirect: false
            }
        }
        default: return { ...state }
    }
}